export const dataPost = [
  {
    "name": "RABAT RP",
    "lat": "34.01961",
    "lon": "-6.8372217"
  },
  { "name": "RABAT AEREPORT", "lat": "34.047255", "lon": "-6.762192" },
  { "name": "RABAT ETAT MAJOR FAR", "lat": "34.011155", "lon": "-6.830535" },
  { "name": "RABAT LA GARDE ROYALE", "lat": "34.009599", "lon": "-6.837034" },
  {
    "name": "RABAT 1 BATAILLON DE GARDE",
    "lat": "34.004876",
    "lon": "-6.829591"
  },
  {
    "name": "RABAT HOPITAL MILITAIRE MOHAMMED V",
    "lat": "33.9690452",
    "lon": "-6.8717283"
  },
  {
    "name": "RABAT INSPECTION GENERAL DES FORCES AUXILIAIRES",
    "lat": "33.994407",
    "lon": "-6.860661"
  },
  { "name": "SALE MEDINA", "lat": "34.0363797", "lon": "-6.8188647" },
  { "name": "TEMARA PPAL", "lat": "33.9273942", "lon": "-6.9166902" },
  { "name": "TEMARA FAOUARATE", "lat": "33.9021249", "lon": "-6.9230846" },
  { "name": "TEMARA AL WIFAK", "lat": "33.920192", "lon": "-6.93448" },
  { "name": "EL HARHOURA", "lat": "33.925958", "lon": "-6.958883" },
  { "name": "TEMARA OULED METAA", "lat": "33.937162", "lon": "-6.894948" },
  { "name": "TEMARA FATH EL KHEIR", "lat": "33.910412", "lon": "-6.921438" },
  { "name": "AIN ATTIG", "lat": "33.89835", "lon": "-6.950136" },
  { "name": "SALE TABRIQUET", "lat": "34.045066", "lon": "-6.806945" },
  { "name": "SALE 1░ B.A.F.R.A", "lat": "34.042134", "lon": "-6.77187" },
  { "name": "SALE EL MOHAMMADI", "lat": "34.0467324", "lon": "-6.7839101" },
  { "name": "SALE MAAMORA", "lat": "34.04225", "lon": "-6.734131" },
  { "name": "SALE MOULAY ISMAIL", "lat": "34.0303515", "lon": "-6.7805949" },
  { "name": "RABAT AGDAL", "lat": "34.0006478", "lon": "-6.8451956" },
  { "name": "RABAT FAL OULED OUMEIR", "lat": "33.999317", "lon": "-6.849705" },
  { "name": "RABAT CHELLAH", "lat": "34.0107948", "lon": "-6.828365" },
  { "name": "RABAT MECHOUAR", "lat": "34.0052895", "lon": "-6.8317983" },
  {
    "name": "RABAT YACOUB EL MANSOUR",
    "lat": "34.0016096",
    "lon": "-6.8693707"
  },
  { "name": "RABAT TOUR HASSAN", "lat": "34.021189", "lon": "-6.827751" },
  { "name": "RABAT AKKARI", "lat": "34.0110005", "lon": "-6.8592547" },
  { "name": "FES PPAL", "lat": "34.041105", "lon": "-4.998991" },
  { "name": "FES AL FADILA", "lat": "34.015068", "lon": "-5.0449461" },
  { "name": "FES GARE", "lat": "34.0470457", "lon": "-5.005458" },
  { "name": "FES HAY TARIK", "lat": "34.0279441", "lon": "-5.0239921" },
  { "name": "FES AL WIFAQ", "lat": "34.0193454", "lon": "-5.0269801" },
  { "name": "FES LES MERINIDES", "lat": "34.0692759", "lon": "-4.9914784" },
  { "name": "FES LOUIZATE", "lat": "34.0588771", "lon": "-4.9560625" },
  { "name": "FES AN-NARJISS", "lat": "34.0171045", "lon": "-4.9735934" },
  { "name": "FES FAR", "lat": "34.037724", "lon": "-4.981742" },
  { "name": "RIBAT EL KHEIR", "lat": "33.814511", "lon": "-4.407359" },
  { "name": "KARIA BA MOHAMED", "lat": "34.3663764", "lon": "-5.2091718" },
  { "name": "FES AL BARAKA", "lat": "None", "lon": "None" },
  { "name": "TISSA", "lat": "34.2866561", "lon": "-4.6610088" },
  { "name": "SIDI HARAZEM", "lat": "34.02932", "lon": "-4.881816" },
  { "name": "FES BATHA", "lat": "34.0609869", "lon": "-4.9822945" },
  { "name": "FES EL KARAOUYINE", "lat": "34.0655076", "lon": "-4.9737543" },
  { "name": "FES FEKHKHARINE", "lat": "34.0639756", "lon": "-4.9638207" },
  { "name": "FES JDID", "lat": "34.0545705", "lon": "-4.9899495" },
  { "name": "FES ATLAS", "lat": "34.0322942", "lon": "-4.9942061" },
  { "name": "FES AL QODS", "lat": "34.018582", "lon": "-4.969941" },
  { "name": "MEKNES PPAL", "lat": "33.897826", "lon": "-5.5498848" },
  { "name": "MEKNES KORTOBA", "lat": "33.8626918", "lon": "-5.544751" },
  { "name": "MEKNES SIDI BOUZEKRI", "lat": "33.8639947", "lon": "-5.5345559" },
  { "name": "MEKNES MARJANE", "lat": "33.862224", "lon": "-5.576302" },
  {
    "name": "MEKNES ACADEMIE R.MILITAIRE",
    "lat": "33.872785",
    "lon": "-5.553546"
  },
  { "name": "MEKNES 2Úme BAFRA", "lat": "33.8791095", "lon": "-5.5194283" },
  { "name": "MEKNES AGDAL", "lat": "33.8807773", "lon": "-5.5461002" },
  {
    "name": "MEKNES CENTRE INSTRUCTION BLINDES FAR",
    "lat": "33.862585",
    "lon": "-5.51916"
  },
  { "name": "EL HAJEB", "lat": "33.6840255", "lon": "-5.3727302" },
  { "name": "EL HAJEB MEDINA", "lat": "33.691594", "lon": "-5.370912" },
  {
    "name": "EL HAJEB PLACE D&#039;ARMES FAR",
    "lat": "33.687684",
    "lon": "-5.367411"
  },
  { "name": "MOULAY IDRISS", "lat": "34.056973", "lon": "-5.520435" },
  { "name": "BOUFEKRANE", "lat": "33.761406", "lon": "-5.483492" },
  { "name": "EL HADJ KADDOUR", "lat": "33.826278", "lon": "-5.424312" },
  { "name": "MEKNES MEDINA", "lat": "33.8948115", "lon": "-5.5624321" },
  { "name": "MEKNES BENI MHAMED", "lat": "33.8808497", "lon": "-5.5627741" },
  { "name": "AIN TAOUJDATE", "lat": "33.937361", "lon": "-5.214529" },
  { "name": "AGOURAI", "lat": "33.641553", "lon": "-5.587642" },
  { "name": "SEBAA AIOUN", "lat": "33.903563", "lon": "-5.373669" },
  { "name": "OUJDA PPAL", "lat": "34.6842608", "lon": "-1.9146252" },
  { "name": "OUJDA 18 MAI", "lat": "34.683083", "lon": "-1.9522459" },
  { "name": "OUJDA SAMARA", "lat": "34.6512576", "lon": "-1.9411348" },
  { "name": "OUJDA BASSATINE", "lat": "34.709653", "lon": "-1.88136" },
  { "name": "OUJDA AEROPORT ANGAD", "lat": "34.785752", "lon": "-1.939516" },
  { "name": "OUJDA BADR", "lat": "34.6740781", "lon": "-1.921662" },
  { "name": "OUJDA AL QODS", "lat": "34.670777", "lon": "-1.9081222" },
  { "name": "OUJDA MIR ALI", "lat": "34.6795826", "lon": "-1.9303885" },
  {
    "name": "OUJDA QUARTIER INDUSTRIEL",
    "lat": "34.6989244",
    "lon": "-1.9006804"
  },
  { "name": "OUJDA BNI OUKIL", "lat": "34.653423", "lon": "-2.001845" },
  { "name": "OUJDA LES IRIS", "lat": "34.6558524", "lon": "-1.923388" },
  { "name": "OUJDA NAJD", "lat": "34.661602", "lon": "-1.873794" },
  { "name": "AHFIR", "lat": "34.9546363", "lon": "-2.0984665" },
  { "name": "AIN BENI MATHAR", "lat": "34.016611", "lon": "-2.025047" },
  { "name": "EL AIOUNE", "lat": "34.5839962", "lon": "-2.5062937" },
  { "name": "JERADA", "lat": "34.3088742", "lon": "-2.1614823" },
  { "name": "ZELLIDJA BOUBKER", "lat": "34.478171", "lon": "-1.73135" },
  { "name": "TOUISSITE", "lat": "34.479127", "lon": "-1.764134" },
  { "name": "BENI DRAR", "lat": "34.858353", "lon": "-1.992786" },
  { "name": "AIN ES SFA", "lat": "34.75056", "lon": "-2.141368" },
  { "name": "AIN EL GUENFOUDA", "lat": "34.4839996", "lon": "-2.0449832" },
  { "name": "KENITRA PPAL", "lat": "34.2609252", "lon": "-6.5819773" },
  { "name": "KENITRA 3Þme  BAFRA", "lat": "34.289187", "lon": "-6.605004" },
  {
    "name": "KENITRA ANCIENNE MEDINA",
    "lat": "34.2653687",
    "lon": "-6.5727961"
  },
  { "name": "KENITRA SAKNIA", "lat": "34.2493915", "lon": "-6.5519299" },
  { "name": "KENITRA EL BOUCHTIA", "lat": "34.2643157", "lon": "-6.5589331" },
  { "name": "KENITRA FAR", "lat": "34.289346", "lon": "-6.604843" },
  { "name": "KENITRA OULAD OUJIH", "lat": "34.2607013", "lon": "-6.6201317" },
  { "name": "KENITRA EL MENZEH", "lat": "34.2522692", "lon": "-6.5210912" },
  {
    "name": "KENITRA MAGHRIB AL ARABI",
    "lat": "34.2435925",
    "lon": "-6.6107105"
  },
  { "name": "KENITRA EL HAOUZIA", "lat": "34.2661012", "lon": "-6.6160709" },
  { "name": "SIDI YAHIA DU RHARB", "lat": "34.3098556", "lon": "-6.3078046" },
  { "name": "ALLAL TAZI", "lat": "34.52174", "lon": "-6.323774" },
  { "name": "DAR GUEDDARI", "lat": "34.4196906", "lon": "-6.0914362" },
  {
    "name": "KENITRA NOUVELLE MEDINA",
    "lat": "34.2589822",
    "lon": "-6.5611929"
  },
  { "name": "AIN JEMAA", "lat": "34.0275707", "lon": "-5.7976753" },
  { "name": "FES BEN DEBBAB", "lat": "34.065041", "lon": "-4.999246" },
  { "name": "KENITRA AL MASSIRA", "lat": "34.248575", "lon": "-6.55679" },
  { "name": "FES AIN KADOUS", "lat": "34.0614058", "lon": "-5.0089073" },
  { "name": "SEBAA ROUADI", "lat": "34.033206", "lon": "-5.101164" },
  { "name": "FES SIDI BRAHIM", "lat": "34.027042", "lon": "-4.987101" },
  { "name": "RABAT SOUISSI", "lat": "33.9835942", "lon": "-6.8266672" },
  { "name": "BOUZNIKA", "lat": "33.7884078", "lon": "-7.1611065" },
  { "name": "BOUZNIKA HAY RIAD", "lat": "33.780226", "lon": "-7.158199" },
  { "name": "AIN EL OUDA", "lat": "33.80619", "lon": "-6.790836" },
  { "name": "BASSATINE AL MENZEH", "lat": "33.856914", "lon": "-6.853556" },
  { "name": "SALE HAY ESSALAM", "lat": "34.0407127", "lon": "-6.7926742" },
  {
    "name": "RABAT MADINAT AL IRFANE",
    "lat": "33.9810031",
    "lon": "-6.8664471"
  },
  { "name": "SKHIRATE", "lat": "33.851836", "lon": "-7.03893" },
  { "name": "BOUKNADEL", "lat": "34.121913", "lon": "-6.739635" },
  { "name": "SIDI YAHIA ZAERS", "lat": "33.826202", "lon": "-6.90465" },
  { "name": "RABAT AL-INBIAT", "lat": "33.992321", "lon": "-6.810644" },
  { "name": "AIN CHEGGAG", "lat": "33.8821423", "lon": "-5.041861" },
  { "name": "EL MENZEL", "lat": "33.837959", "lon": "-4.545524" },
  { "name": "MOULAY YACOUB", "lat": "34.088653", "lon": "-5.178536" },
  { "name": "RAS TABOUDA", "lat": "34.004089", "lon": "-4.7138" },
  { "name": "TAFRANNT", "lat": "34.623982", "lon": "-5.124728" },
  { "name": "BIR TAMTAM", "lat": "33.982306", "lon": "-4.643885" },
  { "name": "SIDI AHMED EL BERNOUSSI", "lat": "34.112457", "lon": "-4.997618" },
  { "name": "EL ADERJ", "lat": "33.6315", "lon": "-4.432053" },
  { "name": "FES DOKKARATE", "lat": "34.0411428", "lon": "-5.0190032" },
  { "name": "TABOUDA", "lat": "34.735419", "lon": "-5.135862" },
  { "name": "RABAT DAR EL HADITH", "lat": "34.0161729", "lon": "-6.8489081" },
  { "name": "RABAT RYAD", "lat": "33.9634694", "lon": "-6.8604926" },
  { "name": "FES JNANATE", "lat": "34.0697959", "lon": "-4.9651659" },
  { "name": "SALE SIDI MOUSSA", "lat": "34.0538572", "lon": "-6.8104425" },
  { "name": "MEHDIYA", "lat": "34.2606703", "lon": "-6.6659492" },
  { "name": "SALE BETTANA", "lat": "34.0296335", "lon": "-6.8088466" },
  { "name": "SALE KARIA OULAD MOUSSA", "lat": "34.02095", "lon": "-6.767144" },
  { "name": "FES BEN SOUDA", "lat": "34.0171267", "lon": "-5.0539899" },
  { "name": "FES ZOUAGHA", "lat": "34.021644", "lon": "-5.035893" },
  { "name": "RABAT AL MASSIRA", "lat": "33.9770429", "lon": "-6.8869727" },
  { "name": "RABAT NATIONS UNIES", "lat": "33.9939411", "lon": "-6.8456827" },
  { "name": "TEMARA MASSIRA", "lat": "33.9266164", "lon": "-6.8977726" },
  { "name": "RABAT ENNAHDA", "lat": "33.9772197", "lon": "-6.8180493" },
  { "name": "RABAT OCEAN", "lat": "34.0238071", "lon": "-6.8454185" },
  { "name": "FES AL ADARISSA", "lat": "34.019032", "lon": "-5.0112704" },
  { "name": "SALA AL JADIDA", "lat": "33.99865", "lon": "-6.744174" },
  { "name": "RABAT HAY EL KHAIR", "lat": "33.9974557", "lon": "-6.8743542" },
  { "name": "SALE LAYAYDA", "lat": "34.062028", "lon": "-6.7727548" },
  { "name": "SALE BAB CHAAFA", "lat": "34.0419985", "lon": "-6.8274102" },
  { "name": "FES ZOHOR", "lat": "34.0105502", "lon": "-4.9802345" },
  { "name": "FES SOUNDOUSS", "lat": "34.0583749", "lon": "-5.0325778" },
  { "name": "AIN LEUH", "lat": "33.28588", "lon": "-5.337145" },
  { "name": "SIDI ADDI", "lat": "33.396246", "lon": "-5.334021" },
  { "name": "RABAT DIOUR JEMAA", "lat": "34.0139975", "lon": "-6.8480793" },
  { "name": "RABAT EL MENZEH", "lat": "33.9719614", "lon": "-6.8960895" },
  { "name": "MEKNES AL MANSOUR", "lat": "33.8663644", "lon": "-5.576849" },
  { "name": "MEKNES HAY ECHOUHADA", "lat": "33.8908996", "lon": "-5.5038661" },
  { "name": "RABAT CITE YOUSSOUFIA", "lat": "33.9925367", "lon": "-6.8185267" },
  { "name": "AZROU AHADAF", "lat": "33.4440875", "lon": "-5.2286232" },
  { "name": "AZROU PPAL", "lat": "33.4440584", "lon": "-5.219284" },
  { "name": "MRIRT", "lat": "33.1671751", "lon": "-5.566442" },
  { "name": "EL HAMMAM", "lat": "33.175042", "lon": "-5.470049" },
  { "name": "TIMAHDITE", "lat": "33.2369444", "lon": "-5.06" },
  { "name": "RABAT ANNAKHIL", "lat": "33.954417", "lon": "-6.864595" },
  { "name": "ERFOUD", "lat": "31.434348", "lon": "-4.234211" },
  { "name": "RISSANI", "lat": "31.283218", "lon": "-4.269374" },
  { "name": "AOUFOUS", "lat": "31.688251", "lon": "-4.177986" },
  { "name": "JORF", "lat": "31.490166", "lon": "-4.40132" },
  { "name": "ALNIF", "lat": "31.116043", "lon": "-5.168332" },
  { "name": "SEFFALATE", "lat": "31.243766", "lon": "-4.275262" },
  { "name": "ZRIGATE", "lat": "31.651221", "lon": "-4.208182" },
  { "name": "FES AGDAL", "lat": "34.0424808", "lon": "-5.003677" },
  { "name": "RABAT GUICH OUDAYA", "lat": "33.9383499", "lon": "-6.88662" },
  { "name": "IFRANE", "lat": "33.5288521", "lon": "-5.1078111" },
  {
    "name": "IFRANE AL AKHAWAYN UNIVERSITY",
    "lat": "33.539597",
    "lon": "-5.106293"
  },
  { "name": "SOUK EL HAD OUED IFRANE", "lat": "33.29464", "lon": "-5.489742" },
  {
    "name": "TEMARA HAY MAGHRIB AL ARABI",
    "lat": "33.9202287",
    "lon": "-6.8896991"
  },
  {
    "name": "TEMARA MOULAY ALI CHERIF",
    "lat": "33.9324828",
    "lon": "-6.9059292"
  },
  { "name": "SALE HSSAINE", "lat": "34.0181627", "lon": "-6.7636997" },
  { "name": "SALE HAY KARIMA", "lat": "34.0543327", "lon": "-6.7946014" },
  { "name": "SALE DAR HAMRA", "lat": "34.0634723", "lon": "-6.7806352" },
  { "name": "KENITRA KHABBAZATE", "lat": "34.2632373", "lon": "-6.5697894" },
  { "name": "KENITRA TAYBIYA", "lat": "34.2726379", "lon": "-6.5992923" },
  { "name": "RABAT EL KAMRA", "lat": "33.9909689", "lon": "-6.8738687" },
  {
    "name": "KHENIFRA AMALOU IGHRIBEN",
    "lat": "32.9373013",
    "lon": "-5.6563712"
  },
  { "name": "KHENIFRA PPAL", "lat": "32.935138", "lon": "-5.6715954" },
  { "name": "AIT ISHAK", "lat": "32.764673", "lon": "-5.726217" },
  { "name": "EL KEBAB", "lat": "32.741813", "lon": "-5.52401" },
  
  { "name": "KAF NSOUR", "lat": "32.912725", "lon": "-5.960928" },
  { "name": "TIGHESSALINE", "lat": "32.782404", "lon": "-5.656862" },
  { "name": "SIDI YAHYA OU SAAD", "lat": "32.681703", "lon": "-5.575513" },
  { "name": "SOUK HAD BOUHSOUSSEN", "lat": "33.147235", "lon": "-6.076217" },
  { "name": "OUAOUMANA", "lat": "32.709347", "lon": "-5.809316" },
  { "name": "TILOUINE", "lat": "31.640478", "lon": "-4.798614" },
  { "name": "ERRACHIDIA PPAL", "lat": "31.9331753", "lon": "-4.4252104" },
  { "name": "P.A.FAR ERRACHIDIA", "lat": "31.927735", "lon": "-4.431331" },
  {
    "name": "ERRACHIDIA OULED EL HAJ",
    "lat": "31.9364463",
    "lon": "-4.4046968"
  },
  { "name": "BOUDENIB", "lat": "31.948184", "lon": "-3.603698" },
  { "name": "GOULMIMA", "lat": "31.690632", "lon": "-4.954472" },
  { "name": "BENI TAJJITE", "lat": "32.293008", "lon": "-3.467978" },
  { "name": "TINEJDAD", "lat": "31.510373", "lon": "-5.029775" },
  { "name": "ASSOUL", "lat": "31.948735", "lon": "-5.207951" },
  {
    "name": "ERRACHIDIA BOUTALAMINE",
    "lat": "31.9199992",
    "lon": "-4.4395548"
  },
  { "name": "TADIRHOUST", "lat": "31.819199", "lon": "-4.960017" },
  { "name": "MELLAB", "lat": "31.54661", "lon": "-4.851915" },
  { "name": "ASRIR OUED FERKLA", "lat": "31.500094", "lon": "-5.067433" },
  { "name": "MIDELT", "lat": "32.6783414", "lon": "-4.7394177" },
  { "name": "ZAIDA", "lat": "32.816006", "lon": "-4.95819" },
  { "name": "MIDELT IKHERMJIOUEN", "lat": "32.674079", "lon": "-4.750299" },
  { "name": "MIDELT MIMLAL", "lat": "32.6895478", "lon": "-4.748143" },
  { "name": "KERROUCHEN", "lat": "32.796618", "lon": "-5.319875" },
  { "name": "TOUNFITE", "lat": "32.46535", "lon": "-5.250116" },
  { "name": "ITZER", "lat": "32.879191", "lon": "-5.051233" },
  { "name": "BOUMIA", "lat": "32.726245", "lon": "-5.096948" },
  { "name": "BOUAYACHE", "lat": "32.721409", "lon": "-4.439405" },
  { "name": "AGHBALOU NSERDANE", "lat": "32.677646", "lon": "-5.296022" },
  { "name": "KENITRA MAAMOURA", "lat": "34.241608", "lon": "-6.5489459" },
  { "name": "RICH", "lat": "32.25912", "lon": "-4.503558" },
  { "name": "TALSINNT", "lat": "32.534921", "lon": "-3.438807" },
  { "name": "GOURRAMA", "lat": "32.338835", "lon": "-4.074898" },
  { "name": "IMILCHIL", "lat": "32.156596", "lon": "-5.630927" },
  { "name": "GUERS TIALLALINE", "lat": "32.245097", "lon": "-4.393206" },
  { "name": "SALE HAY EL AMAL", "lat": "34.0685583", "lon": "-6.7923215" },
  { "name": "RABAT TECHNOPOLIS", "lat": "33.992824", "lon": "-6.721863" },
  { "name": "MEKNES BASSATINE", "lat": "33.8931227", "lon": "-5.5140692" },
  { "name": "SEBT JEHJOUH", "lat": "33.717868", "lon": "-5.670024" },
  { "name": "TNINE MHAYA", "lat": "33.981073", "lon": "-5.242126" },
  { "name": "NZALATE BNI AMMAR", "lat": "34.122839", "lon": "-5.430572" },
  { "name": "MEKNES OUJEH AROUS", "lat": "33.9016373", "lon": "-5.5775035" },
  { "name": "MEKNES AL WIFAK", "lat": "33.8975076", "lon": "-5.5806926" },
  { "name": "TOULAL", "lat": "33.8899756", "lon": "-5.5965713" },
  { "name": "MEKNES BORJ MY OMAR", "lat": "33.8994255", "lon": "-5.5454001" },
  { "name": "MEKNES ZITOUN", "lat": "33.8683655", "lon": "-5.5471892" },
  { "name": "MEKNES HAY TAKADOUM", "lat": "33.8849012", "lon": "-5.5795339" },
  { "name": "OUISLANE", "lat": "33.9113921", "lon": "-5.4896451" },
  {
    "name": "KHENIFRA OUED ED-DAHAB",
    "lat": "32.9243296",
    "lon": "-5.6700075"
  },
  { "name": "BOULEMANE", "lat": "33.366037", "lon": "-4.731637" },
  { "name": "IMOUZZER MARMOUCHA", "lat": "33.471216", "lon": "-4.294565" },
  { "name": "EL MERS", "lat": "33.444452", "lon": "-4.448546" },
  { "name": "ALMIS GUIGOU", "lat": "33.382917", "lon": "-4.829714" },
  { "name": "AIT HAMZA", "lat": "33.350128", "lon": "-4.88772" },
  { "name": "ENJIL", "lat": "33.231015", "lon": "-4.632687" },
  { "name": "GUERCIF", "lat": "34.223686", "lon": "-3.351134" },
  { "name": "GUERCIF NOUGD", "lat": "34.2329867", "lon": "-3.3547515" },
  { "name": "SAKA", "lat": "34.620725", "lon": "-3.414197" },
  { "name": "BERKINE", "lat": "33.766758", "lon": "-3.845958" },
  { "name": "MEZGUITEM", "lat": "34.5019444", "lon": "-3.6427778" },
  { "name": "MAHIRIJA", "lat": "33.989906", "lon": "-3.277501" },
  { "name": "TADDARAT", "lat": "34.216405", "lon": "-3.584995" },
  { "name": "IMOUZZER KANDAR", "lat": "33.728808", "lon": "-5.010278" },
  { "name": "MATMATA", "lat": "34.099305", "lon": "-4.550582" },
  { "name": "TAHALA", "lat": "34.047595", "lon": "-4.425707" },
  { "name": "BOUZEMLANE", "lat": "34.010168", "lon": "-4.552811" },
  { "name": "ZERARDA", "lat": "33.975985", "lon": "-4.376646" },
  { "name": "MISSOUR", "lat": "33.0471446", "lon": "-3.9889357" },
  { "name": "OUTAT EL HAJ", "lat": "33.344253", "lon": "-3.704002" },
  { "name": "KSABI", "lat": "32.830017", "lon": "-4.398689" },
  { "name": "TINDIT", "lat": "33.663659", "lon": "-3.622272" },
  { "name": "OULED ALI", "lat": "33.461397", "lon": "-3.96939" },
  { "name": "OURTZARH", "lat": "34.535409", "lon": "-4.965852" },
  { "name": "RHAFSAI", "lat": "34.6314997", "lon": "-4.9142897" },
  { "name": "RETBA", "lat": "34.777641", "lon": "-4.937362" },
  { "name": "SEFROU", "lat": "33.8260102", "lon": "-4.8389277" },
  {
    "name": "SEFROU YOUSSEF IBN TACHAFIN",
    "lat": "33.832031",
    "lon": "-4.836959"
  },
  { "name": "BHALIL", "lat": "33.85605", "lon": "-4.871197" },
  { "name": "SKOURA SEGHROUCHEN", "lat": "33.518962", "lon": "-4.544941" },
  { "name": "TAZOUTA", "lat": "33.667952", "lon": "-4.634484" },
  { "name": "AZZABA", "lat": "33.827438", "lon": "-4.701971" },
  { "name": "KHLALFA", "lat": "34.672694", "lon": "-4.62724" },
  { "name": "BOUHOUDA", "lat": "34.5910225", "lon": "-4.55493" },
  { "name": "TAOUNATE ASTAR", "lat": "34.549348", "lon": "-4.648767" },
  { "name": "TAOUNATE PPAL", "lat": "34.5373537", "lon": "-4.6383334" },
  { "name": "TAHAR ES SOUK", "lat": "34.657746", "lon": "-4.284743" },
  { "name": "AIN AICHA", "lat": "34.46145", "lon": "-4.689169" },
  { "name": "BNI OULID", "lat": "34.590049", "lon": "-4.447599" },
  { "name": "BNI KORRA", "lat": "34.570099", "lon": "-4.579649" },
  { "name": "AIN MEDIOUNA", "lat": "34.49523", "lon": "-4.550644" },
  { "name": "OULED M&#039;KOUDOU", "lat": "33.809482", "lon": "-4.50267" },
  
  { "name": "TAZA PPAL", "lat": "34.2219948", "lon": "-4.0073705" },
  { "name": "TAZA MLY YOUSSEF", "lat": "34.213368", "lon": "-4.011048" },
  { "name": "TAZA BIT GHOULAM", "lat": "34.2242425", "lon": "-4.0025023" },
  { "name": "AKNOUL", "lat": "34.654771", "lon": "-3.865407" },
  { "name": "AJDIR", "lat": "34.743199", "lon": "-3.973875" },
  { "name": "BOURED", "lat": "34.731354", "lon": "-4.093823" },
  { "name": "TAZA HAUT", "lat": "34.2088853", "lon": "-4.0143375" },
  { "name": "TAINESTE", "lat": "34.55522", "lon": "-4.132268" },
  { "name": "TIZI OUZLI", "lat": "34.7625", "lon": "-3.794304" },
  { "name": "HAD MSILA", "lat": "34.425291", "lon": "-4.176234" },
  { "name": "OUED AMLIL", "lat": "34.190914", "lon": "-4.270063" },
  { "name": "KAHF EL RHAR", "lat": "34.508289", "lon": "-4.25952" },
  { "name": "MEKNASSA ACHARQIA", "lat": "34.35107", "lon": "-3.921497" },
  { "name": "MERHRAOUA", "lat": "33.931239", "lon": "-4.045898" },
  { "name": "BAB EL MROUJ", "lat": "34.415568", "lon": "-4.043768" },
  { "name": "ARBAA BENI FTAH", "lat": "34.4971357", "lon": "-4.0387791" },
  { "name": "BENI LENNT", "lat": "34.325839", "lon": "-4.206219" },
  { "name": "SEBT BNI FRASSEN", "lat": "34.378871", "lon": "-4.37564" },
  { "name": "HAD OULAD ZBAIR", "lat": "34.288894", "lon": "-4.3211" },
  { "name": "BAB MARZOUKA", "lat": "34.210862", "lon": "-4.14404" },
  { "name": "TAZA GARE", "lat": "34.2306316", "lon": "-4.0099025" },
  { "name": "KHEMISSET PPAL", "lat": "33.824435", "lon": "-6.065309" },
  { "name": "KHEMISSET FATH", "lat": "33.8329218", "lon": "-6.076169" },
  { "name": "KHEMISSET AZHAR", "lat": "33.8249986", "lon": "-6.0717776" },
  { "name": "SFASSIF", "lat": "33.894063", "lon": "-5.804303" },
  { "name": "KHEMIS AIT YADDINE", "lat": "33.9753024", "lon": "-6.0138291" },
  { "name": "SIDI ALLAL LEMSEDDER", "lat": "33.872257", "lon": "-6.150801" },
  { "name": "KSAR EL KEBIR PPAL", "lat": "34.9979028", "lon": "-5.9074635" },
  { "name": "TATOUFET", "lat": "35.034413", "lon": "-5.766704" },
  {
    "name": "KSAR EL KEBIR HAY ESSALAM",
    "lat": "34.9984114",
    "lon": "-5.9009993"
  },
  { "name": "LARACHE AL OUAFA", "lat": "35.177246", "lon": "-6.1466789" },
  { "name": "LARACHE PPAL", "lat": "35.1956598", "lon": "-6.1506365" },
  { "name": "ASILAH", "lat": "35.4680713", "lon": "-6.030668" },
  { "name": "TLATA RISSANA", "lat": "35.220323", "lon": "-5.947461" },
  { "name": "HAD RHARBIA", "lat": "35.515688", "lon": "-5.931212" },
  { "name": "AL AOUAMRA", "lat": "35.068057", "lon": "-6.090803" },
  { "name": "SEBT BNI GARFET", "lat": "35.2556795", "lon": "-5.8366182" },
  { "name": "KHEMIS SAHEL", "lat": "35.2551079", "lon": "-6.0799644" },
  { "name": "TNINE SIDI EL YAMANI", "lat": "35.36953", "lon": "-5.972692" },
  { "name": "DAR CHAOUI", "lat": "35.530528", "lon": "-5.717925" },
  { "name": "OUEZZANE", "lat": "34.79742", "lon": "-5.578088" },
  { "name": "AIN DORIJ", "lat": "34.613528", "lon": "-5.289788" },
  { "name": "OUEZZANE ROUIDA", "lat": "34.793506", "lon": "-5.5756259" },
  { "name": "OUEZZANE RMEL", "lat": "34.798213", "lon": "-5.5844477" },
  { "name": "TEROUAL", "lat": "34.675416", "lon": "-5.272764" },
  { "name": "SIDI REDOUANE", "lat": "34.685289", "lon": "-5.448511" },
  { "name": "ROMMANI", "lat": "33.5272422", "lon": "-6.6047681" },
  { "name": "SIDI BETTACHE", "lat": "33.567748", "lon": "-6.894948" },
  { "name": "ZHILIGA", "lat": "33.295212", "lon": "-6.544359" },
  { "name": "HAD BRACHOUA", "lat": "33.693946", "lon": "-6.631982" },
  { "name": "AIN SBIT", "lat": "33.556318", "lon": "-6.520171" },
  { "name": "SIDI KACEM", "lat": "34.2240607", "lon": "-5.708929" },
  { "name": "KHENICHET", "lat": "34.43896", "lon": "-5.685366" },
  { "name": "SIDI KACEM EZAOUIA", "lat": "34.204923", "lon": "-5.711067" },
  { "name": "SIDI SLIMANE", "lat": "34.2621976", "lon": "-5.9253592" },
  {
    "name": "SIDI SLIMANE OULAD EL GHAZI",
    "lat": "34.259429",
    "lon": "-5.922808"
  },
  {
    "name": "SIDI SLIMANE 5Þme BAFRA",
    "lat": "34.2349026",
    "lon": "-6.0572326"
  },
  { "name": "DAR BEL AMRI", "lat": "34.188622", "lon": "-5.969398" },
  { "name": "SOUK A.G. HAY ES SALAM", "lat": "34.67587", "lon": "-5.992237" },
  { "name": "MOULAY BOUSELHAM", "lat": "34.878789", "lon": "-6.281476" },
  {
    "name": "SOUK EL ARBAA DU RHARB",
    "lat": "34.6850835",
    "lon": "-6.0040739"
  },
  { "name": "ARBAOUA", "lat": "34.91018", "lon": "-5.924284" },
  { "name": "HAD KOURT", "lat": "34.616957", "lon": "-5.734136" },
  { "name": "MECHRA BEL KSIRI", "lat": "34.576848", "lon": "-5.9540815" },
  { "name": "LALLA MIMOUNA", "lat": "34.847771", "lon": "-6.070223" },
  { "name": "JORF EL MELHA", "lat": "34.4876652", "lon": "-5.5085103" },
  { "name": "SOUK TLETA EL GHARB", "lat": "34.618903", "lon": "-6.136237" },
  { "name": "SIDI BOUSBER", "lat": "34.564056", "lon": "-5.361151" },
  { "name": "AIN DEFFALI", "lat": "34.595684", "lon": "-5.552409" },
  
  { "name": "TIFLET MEDINA", "lat": "33.8933443", "lon": "-6.3116509" },
  { "name": "OULMES", "lat": "33.422269", "lon": "-6.006354" },
  { "name": "TIDDAS", "lat": "33.568088", "lon": "-6.267491" },
  { "name": "MAAZIZ", "lat": "33.667483", "lon": "-6.319043" },
  { "name": "SIDI ABDERRAZAK", "lat": "33.962438", "lon": "-6.1626" },
  {
    "name": "SIDI ALLAL EL BAHRAOUI",
    "lat": "34.0108348",
    "lon": "-6.5331665"
  },
  { "name": "TIFLET PPAL", "lat": "33.8933598", "lon": "-6.3239166" },
  {
    "name": "SALA AL JADIDA BOUREGREG",
    "lat": "33.995091",
    "lon": "-6.729543"
  },
  { "name": "TANGER 20 AOUT", "lat": "35.7701761", "lon": "-5.8210856" },
  { "name": "KSAR SGHIR", "lat": "35.840595", "lon": "-5.557934" },
  { "name": "TANGER PPAL", "lat": "35.778219", "lon": "-5.806961" },
  { "name": "TANGER AL IRFANE", "lat": "35.7326049", "lon": "-5.881784" },
  { "name": "TANGER BENDIBANE", "lat": "35.753071", "lon": "-5.814184" },
  { "name": "TANGER EL OUAFAE", "lat": "35.7496905", "lon": "-5.8466042" },
  { "name": "TANGER BRANES", "lat": "35.762267", "lon": "-5.837173" },
  { "name": "TANGER DAR MOUIGNA", "lat": "35.758944", "lon": "-5.821563" },
  { "name": "TANGER AEROPORT", "lat": "35.72592", "lon": "-5.912829" },
  { "name": "TANGER BIR CHIFAE", "lat": "35.7447358", "lon": "-5.8168531" },
  { "name": "TANGER COMPLEXE HASSANI", "lat": "35.744819", "lon": "-5.843066" },
  { "name": "TANGER MESNANA", "lat": "35.7544032", "lon": "-5.8530468" },
  { "name": "TANGER PORT MED", "lat": "35.88872", "lon": "-5.495481" },
  { "name": "TANGER SOUANI", "lat": "35.7677952", "lon": "-5.8155281" },
  { "name": "TANGER DRISSIA", "lat": "35.760034", "lon": "-5.802032" },
  { "name": "TANGER MSALLAH", "lat": "35.7809478", "lon": "-5.8152652" },
  { "name": "MELLOUSSA", "lat": "35.735995", "lon": "-5.632038" },
  { "name": "TANGER DRADEB", "lat": "35.7856368", "lon": "-5.8290035" },
  { "name": "TANGER VAL FLEURI", "lat": "35.7776272", "lon": "-5.8311921" },
  { "name": "TANGER AL MAJD", "lat": "35.7455849", "lon": "-5.7977665" },
  { "name": "GZENAYA", "lat": "35.707438", "lon": "-5.90594" },
  { "name": "BERKANE PPAL", "lat": "34.9271874", "lon": "-2.3314311" },
  { "name": "BERKANE BOUHDILA", "lat": "34.928511", "lon": "-2.338876" },
  { "name": "BERKANE EL GRABA", "lat": "34.920282", "lon": "-2.323415" },
  { "name": "SAIDIA", "lat": "35.08594", "lon": "-2.226403" },
  { "name": "SAIDIA MARINA", "lat": "35.10936", "lon": "-2.293525" },
  { "name": "TAFORHALT", "lat": "34.809569", "lon": "-2.40989" },
  { "name": "AKLIM", "lat": "34.891209", "lon": "-2.518409" },
  { "name": "MADAGH", "lat": "35.010212", "lon": "-2.338908" },
  { "name": "SIDI BOUHOURIA", "lat": "34.738447", "lon": "-2.360322" },
  { "name": "HASSI BLAL", "lat": "34.308108", "lon": "-2.206187" },
  { "name": "AIN ERREGADA", "lat": "34.9341436", "lon": "-2.2329391" },
  { "name": "OUJDA ER-RIAD", "lat": "34.6800348", "lon": "-1.9036228" },
  { "name": "SIDI YAHIA D&#039;OUJDA", "lat": "34.667325", "lon": "-1.867633" },
  { "name": "BOUARFA", "lat": "32.537341", "lon": "-1.958584" },
  { "name": "TENDRARA", "lat": "33.0521649", "lon": "-2.0029852" },
  { "name": "BOUANANE", "lat": "32.037996", "lon": "-3.049234" },
  { "name": "AIN CHOUATER", "lat": "31.850869", "lon": "-2.965896" },
  { "name": "NAIMA", "lat": "34.605387", "lon": "-2.2190238" },
  { "name": "OUJDA OUED ENNACHEF", "lat": "34.674988", "lon": "-1.9281408" },
  { "name": "OUJDA TAKKADOUM", "lat": "34.681826", "lon": "-1.906396" },
  { "name": "EL BESSARA", "lat": "34.710192", "lon": "-2.244703" },
  { "name": "BERKANE EL OUIDADIYA", "lat": "34.9163452", "lon": "-2.3154867" },
  { "name": "OUJDA ISLY", "lat": "34.688642", "lon": "-1.9190643" },
  { "name": "FIGUIG", "lat": "32.1098006", "lon": "-1.2287388" },
  { "name": "FIGUIG ZENAGA", "lat": "32.098145", "lon": "-1.2355838" },
  { "name": "OUJDA ESSAADA", "lat": "34.6784853", "lon": "-1.887213" },
  { "name": "OUJDA NAHDA", "lat": "34.6817023", "lon": "-1.93869" },
  { "name": "NADOR OULAD MIMOUN", "lat": "35.167915", "lon": "-2.935952" },
  { "name": "HASSI BERKANE", "lat": "34.838633", "lon": "-2.87672" },
  { "name": "RAS EL MA", "lat": "35.138183", "lon": "-2.42373" },
  { "name": "KARIAT ARKMANE", "lat": "35.103038", "lon": "-2.740228" },
  { "name": "SELOUANE", "lat": "35.068043", "lon": "-2.942741" },
  { "name": "TLETA JBEL", "lat": "35.183689", "lon": "-3.047635" },
  { "name": "NADOR", "lat": "35.1761234", "lon": "-2.9251179" },
  { "name": "NADOR OULED BOUTAYEB", "lat": "35.1669522", "lon": "-2.9489735" },
  { "name": "ZAIO", "lat": "34.9416496", "lon": "-2.7340964" },
  { "name": "SEGANGAN", "lat": "35.154635", "lon": "-3.002411" },
  { "name": "TAFERSITE", "lat": "35.007099", "lon": "-3.54125" },
  { "name": "BEN TIB", "lat": "35.045194", "lon": "-3.452545" },
  { "name": "DRIOUCH", "lat": "34.979624", "lon": "-3.39207" },
  { "name": "DAR KEBDANI", "lat": "35.118612", "lon": "-3.335367" },
  { "name": "MIDAR", "lat": "34.943517", "lon": "-3.533847" },
  { "name": "MONTE ARRUI", "lat": "35.01122", "lon": "-3.000138" },
  { "name": "BENI ENZAR", "lat": "35.2668512", "lon": "-2.9328494" },
  { "name": "TAOURIRT", "lat": "34.4151967", "lon": "-2.8944415" },
  { "name": "TAOURIRT NAHDA", "lat": "34.3951271", "lon": "-2.8935671" },
  { "name": "DEBDOU", "lat": "33.984148", "lon": "-3.040861" },
  { "name": "BOUDINAR", "lat": "35.156054", "lon": "-3.631834" },
  { "name": "MHAJER", "lat": "35.118486", "lon": "-3.49093" },
  { "name": "TIZTOUTINE", "lat": "34.971201", "lon": "-3.155994" },
  { "name": "AIN ZORAH", "lat": "34.652497", "lon": "-3.534615" },
  { "name": "KHEMIS TEMSAMANE", "lat": "35.120661", "lon": "-3.639822" },
  { "name": "HAD BNI CHIKER", "lat": "35.276436", "lon": "-3.014454" },
  { "name": "ASMATEN", "lat": "35.31875", "lon": "-5.357139" },
  {
    "name": "TETOUAN ZONE INDUSTRIELLE",
    "lat": "35.6063269",
    "lon": "-5.3091323"
  },
  { "name": "TETOUAN PPAL", "lat": "35.5705832", "lon": "-5.3739802" },
  { "name": "TETOUAN TOUILAA", "lat": "35.580974", "lon": "-5.3568864" },
  { "name": "FNIDEQ", "lat": "35.847624", "lon": "-5.3551457" },
  { "name": "MARTIL", "lat": "35.616652", "lon": "-5.2778605" },
  { "name": "MDIQ", "lat": "35.6848275", "lon": "-5.3228036" },
  { "name": "OUED LAOU", "lat": "35.448207", "lon": "-5.090564" },
  { "name": "TETOUAN MHANECH", "lat": "35.5646903", "lon": "-5.3631843" },
  { "name": "TETOUAN MY EL HASSAN", "lat": "35.572421", "lon": "-5.354582" },
  { "name": "TETOUAN SIDI TALHA", "lat": "35.5748102", "lon": "-5.3811872" },
  { "name": "TETOUAN TOUABEL", "lat": "35.5812282", "lon": "-5.3456681" },
  { "name": "BNI KARRICH FOUKI", "lat": "35.50742", "lon": "-5.422949" },
  { "name": "AL HOCEIMA PPAL", "lat": "35.2435468", "lon": "-3.9317241" },
  { "name": "TARGUIST", "lat": "34.93579", "lon": "-4.315031" },
  { "name": "IMZOUREN", "lat": "35.146771", "lon": "-3.850459" },
  { "name": "BNI HADIFA", "lat": "35.0221", "lon": "-4.142092" },
  { "name": "TORRES DE ALCALA", "lat": "35.156429", "lon": "-4.324853" },
  { "name": "BENI BOUAYACH", "lat": "35.101048", "lon": "-3.841012" },
  { "name": "IMRABTEN", "lat": "35.072888", "lon": "-3.961566" },
  { "name": "ISSAGUEN", "lat": "34.917828", "lon": "-4.570305" },
  { "name": "BNI AMMART", "lat": "34.812037", "lon": "-4.159339" },
  { "name": "ARBAA TAOURIRT", "lat": "34.931498", "lon": "-3.835926" },
  { "name": "CHEFCHAOUEN", "lat": "35.1680529", "lon": "-5.2655813" },
  { "name": "BAB BERRET", "lat": "34.996366", "lon": "-4.890375" },
  { "name": "ZOUMI", "lat": "34.797092", "lon": "-5.344816" },
  { "name": "BNI BOUFRAH", "lat": "35.100565", "lon": "-4.311034" },
  { "name": "ROUADI", "lat": "35.140932", "lon": "-4.136017" },
  { "name": "AL HOCEIMA SIDI ABID", "lat": "35.2497437", "lon": "-3.9363108" },
  { "name": "AIT YOUSSEF OU ALI", "lat": "35.193551", "lon": "-3.915947" },
  { "name": "BNI AHMED", "lat": "34.847049", "lon": "-5.078312" },
  { "name": "JEBHA", "lat": "35.204706", "lon": "-4.66635" },
  { "name": "SENADA", "lat": "35.08108", "lon": "-4.214292" },
  { "name": "MOKRISSET", "lat": "34.92254", "lon": "-5.351515" },
  { "name": "BAB TAZA", "lat": "35.061545", "lon": "-5.195256" },
  { "name": "STEHA", "lat": "35.345246", "lon": "-4.955215" },
  { "name": "TETOUAN TABOULA", "lat": "35.5681375", "lon": "-5.4000539" },
  { "name": "OUJDA EL AOUNIA", "lat": "34.691786", "lon": "-1.888425" },
  { "name": "OUJDA HABOUS", "lat": "34.6711343", "lon": "-1.8942672" },
  { "name": "OUJDA LAZARET", "lat": "34.6914242", "lon": "-1.8714899" },
  { "name": "OUJDA LAMHARCHI", "lat": "34.691208", "lon": "-1.9430673" },
  { "name": "OUJDA EL AMAL", "lat": "34.6837998", "lon": "-1.9071056" },
  { "name": "OUJDA JAWHARA", "lat": "34.6690376", "lon": "-1.9293197" },
  { "name": "OUJDA L UNIVERSITE", "lat": "34.6530062", "lon": "-1.8967295" },
  { "name": "NADOR LAARI CHEIKH", "lat": "35.1731747", "lon": "-2.9253432" },
  { "name": "CASA PPAL", "lat": "33.592695", "lon": "-7.619046" },
  { "name": "CASABLANCA BACHKOU", "lat": "33.5573374", "lon": "-7.627379" },
  { "name": "CASABLANCA ENNASSIM", "lat": "33.5150733", "lon": "-7.6661074" },
  { "name": "CASABLANCA HAY SADRI", "lat": "33.569181", "lon": "-7.558484" },
  {
    "name": "CASABLANCA BOULEVARD 10 MARS",
    "lat": "33.561631",
    "lon": "-7.565329"
  },
  { "name": "CASABLANCA ANASSI", "lat": "33.5945039", "lon": "-7.4991449" },
  { "name": "CASA DRIS HARTI", "lat": "33.5478418", "lon": "-7.5740326" },
  { "name": "CASA AL AZHAR", "lat": "33.6008676", "lon": "-7.4842001" },
  { "name": "CASABLANCA AL MOUSTAKBAL", "lat": "33.52714", "lon": "-7.64969" },
  {
    "name": "CASABLANCA AIN SBAA AL HADIKA",
    "lat": "33.6031976",
    "lon": "-7.5319467"
  },
  { "name": "CASABLANCA EMILE ZOLA", "lat": "33.595658", "lon": "-7.593441" },
  { "name": "CASABLANCA BOULEVARD TAH", "lat": "33.53374", "lon": "-7.58103" },
  {
    "name": "CASABLANCA BOULEVARD ESSMARA",
    "lat": "33.53864",
    "lon": "-7.59194"
  },
  { "name": "CASA AEROPORT MOHAMED V", "lat": "33.37428", "lon": "-7.581317" },
  { "name": "CASABLANCA BASE NAVALE", "lat": "33.607078", "lon": "-7.61816" },
  {
    "name": "CASABLANCA AEROPORT MOHAMED V TERMINAL 2",
    "lat": "33.37428",
    "lon": "-7.581317"
  },
  { "name": "CASA AIN SEBAA", "lat": "33.6045872", "lon": "-7.5393261" },
  { "name": "AIN HARROUDA", "lat": "33.6366898", "lon": "-7.4497162" },
  { "name": "MOHAMMADIA PPAL", "lat": "33.7060582", "lon": "-7.3963899" },
  { "name": "MOHAMMADIA GARE", "lat": "33.6965165", "lon": "-7.3874876" },
  { "name": "MOHAMMADIA EL ALIA", "lat": "33.6868979", "lon": "-7.3908216" },
  { "name": "CASA HAY HASSANI", "lat": "33.5734179", "lon": "-7.6720848" },
  { "name": "CASA SIDI EL KHADIR", "lat": "33.568403", "lon": "-7.6864949" },
  { "name": "DAR BOUAZZA", "lat": "33.515659", "lon": "-7.817722" },
  { "name": "CASABLANCA HAJ FATEH", "lat": "33.540563", "lon": "-7.682732" },
  { "name": "BOUSKOURA", "lat": "33.45643", "lon": "-7.650299" },
  { "name": "TIT MELLIL", "lat": "33.5556364", "lon": "-7.483618" },
  { "name": "SIDI MAAROUF", "lat": "33.5092924", "lon": "-7.6582325" },
  { "name": "CASA AIN CHOK", "lat": "33.556079", "lon": "-7.5965993" },
  { "name": "CASA BOURGOGNE", "lat": "33.5950868", "lon": "-7.6373502" },
  { "name": "CASA HAY MY RACHID", "lat": "33.5734962", "lon": "-7.558347" },
  { "name": "CASA BOURSE", "lat": "33.5945081", "lon": "-7.6132478" },
  { "name": "CASA AIN DIAB", "lat": "33.5922627", "lon": "-7.6764138" },
  { "name": "CASA DERB SIDNA", "lat": "33.5766516", "lon": "-7.604481" },
  { "name": "CASA EL FIDA", "lat": "33.567386", "lon": "-7.604631" },
  { "name": "CASA MAARIF", "lat": "33.579941", "lon": "-7.636231" },
  { "name": "CASA MERS SULTAN", "lat": "33.583673", "lon": "-7.61433" },
  { "name": "CASA OASIS", "lat": "33.5568289", "lon": "-7.6338432" },
  { "name": "CASA GARE", "lat": "33.591075", "lon": "-7.591494" },
  { "name": "CASA ROCHES NOIRES", "lat": "33.6003158", "lon": "-7.5841069" },
  { "name": "CASA TAKADOUM", "lat": "33.5864389", "lon": "-7.5667074" },
  { "name": "CASA SIDI OTHMANE", "lat": "33.55955", "lon": "-7.574118" },
  { "name": "CASA LAHRAOUYINE", "lat": "33.545121", "lon": "-7.54396" },
  {
    "name": "CASABLANCA FORCES AUXILIARES",
    "lat": "33.568367",
    "lon": "-7.563435"
  },
  { "name": "CASA HAY EL INARA", "lat": "33.5370676", "lon": "-7.5997174" },
  { "name": "CASA HAY EL FARAH", "lat": "33.567554", "lon": "-7.590724" },
  { "name": "CASA BANDOENG", "lat": "33.5925543", "lon": "-7.6072437" },
  { "name": "CASA BOUSMARA", "lat": "33.6001739", "lon": "-7.6154539" },
  { "name": "CASA CITE DJEMAA", "lat": "33.5509602", "lon": "-7.56875" },
  { "name": "CASA SIDI BERNOUSSI", "lat": "33.610549", "lon": "-7.509783" },
  { "name": "EL GARA", "lat": "33.274971", "lon": "-7.234859" },
  { "name": "MEDIOUNA", "lat": "33.454745", "lon": "-7.520955" },
  { "name": "CASA EL HANK", "lat": "33.603545", "lon": "-7.6530048" },
  { "name": "CASA SIDI MOUMEN", "lat": "33.578944", "lon": "-7.546299" },
  { "name": "CASA HAY EL BARAKA", "lat": "33.5687472", "lon": "-7.54367" },
  { "name": "MOHAMMADIA HASSANIA", "lat": "33.675602", "lon": "-7.3904783" },
  { "name": "CASA HAY EL OULFA", "lat": "33.557476", "lon": "-7.6807416" },
  { "name": "CASA LISSASFA", "lat": "33.5316413", "lon": "-7.6726105" },
  { "name": "CASABLANCA CHARAF", "lat": "None", "lon": "None" },
  { "name": "CASA DERB GHALLEF", "lat": "33.5697025", "lon": "-7.6310765" },
  { "name": "CASA HAY ESSALAM", "lat": "33.570452", "lon": "-7.651012" },
  { "name": "CASA OUM RABIAA", "lat": "33.5624435", "lon": "-7.6687293" },
  { "name": "CASA AIN SEBAA PLAGE", "lat": "33.619705", "lon": "-7.535739" },
  { "name": "CASA DAR LAMANE", "lat": "33.5932871", "lon": "-7.5585723" },
  { "name": "CASA KSAR BHAR", "lat": "33.5491115", "lon": "-7.5830367" },
  { "name": "CASA HAY IFRIQUIA", "lat": "33.554474", "lon": "-7.587884" },
  { "name": "CASA AIN BORJA", "lat": "33.5845385", "lon": "-7.583191" },
  { "name": "CASA 2 MARS", "lat": "33.5687952", "lon": "-7.6110216" },
  { "name": "CASA HAY SALMIA", "lat": "33.5385688", "lon": "-7.5542325" },
  { "name": "CASABLANCA HAY MOULAY ABDELLAH", "lat": "None", "lon": "None" },
  { "name": "CASA AL QODS", "lat": "33.617711", "lon": "-7.484764" },
  { "name": "CASABLANCA HAY AL MASSIRA", "lat": "None", "lon": "None" },
  { "name": "MOHAMMEDIA LA COLLINE", "lat": "33.697717", "lon": "-7.373039" },
  { "name": "MOHAMMEDIA RACHIDIA", "lat": "33.684155", "lon": "-7.3759595" },
  { "name": "CASA MCALLA", "lat": "33.539446", "lon": "-7.593238" },
  { "name": "CASABLANCA GHANDI", "lat": "33.5696729", "lon": "-7.6443165" },
  {
    "name": "CASABLANCA HAY ESSALAMA",
    "lat": "33.5657122",
    "lon": "-7.5574901"
  },
  {
    "name": "CASABLANCA AHL LOGHLAM",
    "lat": "33.6004498",
    "lon": "-7.4899131"
  },
  { "name": "CASA ALANDALOUS", "lat": "None", "lon": "None" },
  {
    "name": "CASA BOULEVARD MOHAMED VI",
    "lat": "33.5652272",
    "lon": "-7.5959033"
  },
  {
    "name": "CASA QUARTIER DES HOPITAUX",
    "lat": "33.5778538",
    "lon": "-7.6155907"
  },
  {
    "name": "CASABLANCA MOUSSA BNOU NOUCEIR",
    "lat": "33.591235",
    "lon": "-7.6275815"
  },
  { "name": "CASABLANCA AL AMAL", "lat": "None", "lon": "None" },
  { "name": "CASA BD EL GUERNAOUI", "lat": "33.6113513", "lon": "-7.4967675" },
  { "name": "CASA ATTACHAROUK", "lat": "33.5739275", "lon": "-7.5258064" },
  { "name": "CASA EL HOUDA", "lat": "33.5823945", "lon": "-7.5245833" },
  { "name": "CASABLANCA ROUDANI", "lat": "None", "lon": "None" },
  { "name": "CASA SIDI SOUFI", "lat": "33.6023365", "lon": "-7.6345124" },
  { "name": "CASABLANCA OUED SEBOU", "lat": "33.551231", "lon": "-7.681621" },
  {
    "name": "CASABLANCA BOULEVARD ABDELMOUMEN",
    "lat": "33.577666",
    "lon": "-7.625316"
  },
  { "name": "CASA IBN TACHAFINE", "lat": "33.5849552", "lon": "-7.5733888" },
  { "name": "CASA MEKDAD LAHRIZI", "lat": "33.5437397", "lon": "-7.5617105" },
  { "name": "AGADIR AL FIDDIA", "lat": "30.4080198", "lon": "-9.5554474" },
  { "name": "AIT MELLOUL HAY AZROU", "lat": "30.3595532", "lon": "-9.4622621" },
  { "name": "LQLIAA", "lat": "30.297664", "lon": "-9.460967" },
  { "name": "AGADIR EL KHIAM", "lat": "30.4221712", "lon": "-9.5725036" },
  { "name": "AGADIR FOUNTY", "lat": "30.4018941", "lon": "-9.5901257" },
  { "name": "INEZGANE TARRAST", "lat": "30.3497281", "lon": "-9.5488438" },
  {
    "name": "AIT MELLOUL ZONE INDUSTRIELLE",
    "lat": "30.3314003",
    "lon": "-9.491753"
  },
  { "name": "BENSERGAO", "lat": "30.3931445", "lon": "-9.5686506" },
  { "name": "AGADIR HAY DAKHLA", "lat": "30.4112271", "lon": "-9.5566169" },
  { "name": "INEZGANE MESDOURA", "lat": "30.3517129", "lon": "-9.537655" },
  { "name": "TAALAT", "lat": "30.149444", "lon": "-9.176111" },
  { "name": "AMSKROUD", "lat": "30.528803", "lon": "-9.331909" },
  { "name": "AGADIR ANZA", "lat": "30.4449184", "lon": "-9.6560812" },
  {
    "name": "AGADIR QUARTIER INDUSTRIEL",
    "lat": "30.4139924",
    "lon": "-9.5891414"
  },
  { "name": "AGADIR FAR", "lat": "30.416667", "lon": "-9.591667" },
  { "name": "TIMOULAY", "lat": "29.167573", "lon": "-9.573383" },
  { "name": "TIKIOUINE", "lat": "30.3770485", "lon": "-9.5031926" },
  {
    "name": "GUELMIM PORTE DU SAHARA",
    "lat": "28.9990479",
    "lon": "-10.0486762"
  },
  { "name": "TEMSIA", "lat": "30.363403", "lon": "-9.413674" },
  { "name": "AIT AMIRA", "lat": "30.17675", "lon": "-9.487255" },
  { "name": "TAMRI", "lat": "30.695244", "lon": "-9.826182" },
  { "name": "AGADIR TALBORJT", "lat": "30.423685", "lon": "-9.5910082" },
  { "name": "BIOUGRA AVENUE HASSAN II", "lat": "None", "lon": "None" },
  { "name": "AGADIR PPAL", "lat": "30.420719", "lon": "-9.597514" },
  { "name": "AGADIR HAY AL WAFA", "lat": "30.4208528", "lon": "-9.5590979" },
  { "name": "AGADIR DRARGA", "lat": "30.3830543", "lon": "-9.4842133" },
  {
    "name": "AGADIR CITE SIDI MOHAMED",
    "lat": "30.4251679",
    "lon": "-9.583506"
  },
  { "name": "AGADIR AEREPORT", "lat": "30.330065", "lon": "-9.410627" },
  { "name": "AGADIR PORT", "lat": "30.4243823", "lon": "-9.6288568" },
  { "name": "AGADIR SOUK EL HAD", "lat": "30.4946625", "lon": "-9.5784205" },
  { "name": "AGADIR HAY ESSALAM", "lat": "30.4004274", "lon": "-9.5467008" },
  { "name": "INEZGANE PPAL", "lat": "30.3584111", "lon": "-9.53311" },
  { "name": "OULAD TEIMA", "lat": "30.3955482", "lon": "-9.2105703" },
  { "name": "AIT BAHA", "lat": "30.069117", "lon": "-9.158379" },
  { "name": "BIOUGRA", "lat": "30.2149157", "lon": "-9.3696213" },
  { "name": "KHMIS IDA OU GNIDIF", "lat": "29.86039", "lon": "-9.008317" },
  { "name": "IMOUZZER IDA OU TANANE", "lat": "30.673002", "lon": "-9.480177" },
  { "name": "IFRANE ATLAS SEGHIR", "lat": "29.221311", "lon": "-9.490052" },
  { "name": "ARGANA", "lat": "30.778697", "lon": "-9.126285" },
  { "name": "AIT MELLOUL", "lat": "30.3466346", "lon": "-9.4990593" },
  { "name": "AIT MELLOUL HAY RIAD", "lat": "30.3336042", "lon": "-9.5042199" },
  { "name": "BOUIZAKARNE", "lat": "29.1782341", "lon": "-9.7124908" },
  { "name": "GUELMIM PPAL", "lat": "28.9877539", "lon": "-10.0568086" },
  { "name": "GUELMIM FAR", "lat": "28.985797", "lon": "-10.054573" },
  { "name": "TAN TAN PPAL", "lat": "28.4365793", "lon": "-11.0980542" },
  { "name": "TAN TAN MEDINA", "lat": "28.4282508", "lon": "-11.1089869" },
  { "name": "IFNI PPAL", "lat": "29.3831391", "lon": "-10.1725647" },
  { "name": "TLETA EL AKHSASS", "lat": "29.3553757", "lon": "-9.756192" },
  { "name": "FAM EL HISN", "lat": "29.0131648", "lon": "-8.8874567" },
  { "name": "TARHJIJT", "lat": "29.060871", "lon": "-9.420452" },
  { "name": "TARFAYA", "lat": "27.9392919", "lon": "-12.9255331" },
  { "name": "JEMAA NTIGHIRT", "lat": "29.379733", "lon": "-9.452762" },
  { "name": "ASSA", "lat": "28.60864", "lon": "-9.424062" },
  { "name": "TAFRAOUTE", "lat": "29.7203253", "lon": "-8.974778" },
  { "name": "MESTI", "lat": "29.265218", "lon": "-10.112368" },
  { "name": "SEBT EL GUERDANE", "lat": "30.383135", "lon": "-9.018161" },
  { "name": "TNINE HILALA", "lat": "30.063111", "lon": "-9.014598" },
  { "name": "TNINE AMELLOU", "lat": "29.293688", "lon": "-10.021546" },
  { "name": "TIOURHZA", "lat": "29.386352", "lon": "-9.959125" },
  { "name": "AIT ERKHA", "lat": "29.360991", "lon": "-9.620126" },
  { "name": "AKKA", "lat": "29.391617", "lon": "-8.255099" },
  { "name": "DCHEIRA", "lat": "30.3637503", "lon": "-9.5269718" },
  { "name": "TANALT", "lat": "29.775083", "lon": "-9.168933" },
  { "name": "TAROUDANNT", "lat": "30.4726237", "lon": "-8.8672382" },
  { "name": "IRHREM", "lat": "30.0887", "lon": "-8.459677" },
  { "name": "TATA", "lat": "29.7403796", "lon": "-7.9667871" },
  { "name": "AIT ABDALLAH", "lat": "29.813489", "lon": "-8.768528" },
  { "name": "OULAD BERREHIL", "lat": "30.6388535", "lon": "-8.4759415" },
  { "name": "TAFINGOULT", "lat": "30.766084", "lon": "-8.391784" },
  { "name": "AOULOUZ", "lat": "30.672707", "lon": "-8.178572" },
  { "name": "TISSINT", "lat": "29.906259", "lon": "-7.318157" },
  { "name": "KHEMIS ISSAFEN", "lat": "29.858192", "lon": "-8.528153" },
  { "name": "SBOUYA", "lat": "29.151098", "lon": "-10.204915" },
  { "name": "TIZNIT", "lat": "29.6948262", "lon": "-9.732897" },
  { "name": "TIZNIT BIR ANZARANE", "lat": "29.690979", "lon": "-9.7241234" },
  {
    "name": "TIZNIT SIDI ABDERRAHMANE",
    "lat": "29.7009398",
    "lon": "-9.7284794"
  },
  { "name": "TIZNIT AL MASSIRA", "lat": "29.6830172", "lon": "-9.7195182" },
  { "name": "ANEZI", "lat": "29.658255", "lon": "-9.362521" },
  { "name": "MIRLEFT", "lat": "29.579748", "lon": "-10.032208" },
  { "name": "MASSA", "lat": "29.991623", "lon": "-9.645341" },
  { "name": "ARBAA RAS MOUKA", "lat": "29.802197", "lon": "-9.541004" },
  { "name": "SEBT BOUNAAMANE", "lat": "29.530931", "lon": "-9.815973" },
  { "name": "ARBAA SAHEL", "lat": "29.598704", "lon": "-9.921749" },
  { "name": "HAD AIT BELFAA", "lat": "30.047627", "lon": "-9.560885" },
  { "name": "SIDI BIBI", "lat": "30.239224", "lon": "-9.524986" },
  { "name": "FREIJA", "lat": "30.5051603", "lon": "-8.797887" },
  { "name": "TNINE AGLOU", "lat": "29.765831", "lon": "-9.799861" },
  { "name": "IFNI HAY LALLA MERYEM", "lat": "29.38641", "lon": "-10.16127" },
  { "name": "SEBT TATAOUTE", "lat": "30.24752", "lon": "-8.709339" },
  { "name": "FASK", "lat": "28.983097", "lon": "-9.832557" },
  { "name": "HAD TAHALA", "lat": "29.637744", "lon": "-9.090218" },
  { "name": "TAGANTE", "lat": "29.129086", "lon": "-9.779536" },
  { "name": "TIRHMI", "lat": "29.582739", "lon": "-9.42304" },
  { "name": "KSABI AIT LAHCEN", "lat": "28.996345", "lon": "-10.170309" },
  {
    "name": "TAN TAN CITE ADMINISTRATIVE",
    "lat": "28.4385298",
    "lon": "-11.1072314"
  },
  { "name": "SEBT OUIJJANE", "lat": "29.649179", "lon": "-9.536908" },
  
  { "name": "DCHEIRA HAY ILHAM", "lat": "30.3693646", "lon": "-9.5395379" },
  { "name": "TAROUDANT MEDINA", "lat": "30.470139", "lon": "-8.884576" },
  { "name": "AOURIR", "lat": "30.498001", "lon": "-9.672411" },
  { "name": "MARRAKECH SIDI GHANEM", "lat": "31.6736106", "lon": "-8.0493334" },
  { "name": "MARRAKECH ANNAKHIL", "lat": "31.6474403", "lon": "-7.9857409" },
  { "name": "MARRAKECH BAB KHEMIS", "lat": "31.6365561", "lon": "-7.98626" },
  { "name": "MARRAKECH SIDI ABBAD", "lat": "31.6562171", "lon": "-8.0176324" },
  { "name": "MARRAKECH AL FADL", "lat": "31.6778666", "lon": "-8.0251748" },
  {
    "name": "MARRAKECH DIOUR ECHCHOUHADA",
    "lat": "31.6067673",
    "lon": "-7.9620181"
  },
  { "name": "TAMESLOUHTE", "lat": "31.496467", "lon": "-8.097289" },
  { "name": "MARRAKECH AMERCHICH", "lat": "31.6576806", "lon": "-8.0037949" },
  { "name": "AIT HADI", "lat": "31.396401", "lon": "-8.770705" },
  { "name": "SIDI ZOUINE", "lat": "31.664039", "lon": "-8.350586" },
  {
    "name": "MARRAKECH HAY AL MASSIRA",
    "lat": "31.629133",
    "lon": "-8.060363"
  },
  { "name": "TNINE OUDAYA", "lat": "31.634792", "lon": "-8.246902" },
  { "name": "LALLA TAKARKOUSTE", "lat": "31.358174", "lon": "-8.131945" },
  { "name": "TIZGUINE", "lat": "31.243402", "lon": "-8.319118" },
  { "name": "MARRAKECH BAB RHMATE", "lat": "31.6243734", "lon": "-7.9761118" },
  { "name": "KETTARA", "lat": "31.86503", "lon": "-8.182229" },
  { "name": "MARRAKECH DOUAR IZIKI", "lat": "31.6249032", "lon": "-8.0588311" },
  { "name": "MARRAKECH HAY HASSANI", "lat": "31.630902", "lon": "-8.041658" },
  { "name": "IGOUDAR", "lat": "31.267474", "lon": "-8.256215" },
  { "name": "EL AAMRIA", "lat": "32.209544", "lon": "-7.262751" },
  { "name": "TNINE OURIKA", "lat": "31.375209", "lon": "-7.788486" },
  { "name": "RHMATE", "lat": "31.422511", "lon": "-7.802621" },
  { "name": "MARRAKECH HAY MOHAMMADI", "lat": "31.650498", "lon": "-7.996373" },
  { "name": "MARRAKECH PPAL", "lat": "31.6325505", "lon": "-8.0093873" },
  {
    "name": "MARRAKECH ABRAJ KOUTOUBIA",
    "lat": "31.573565",
    "lon": "-8.056884"
  },
  { "name": "MARRAKECh BORJ ZAITOUN", "lat": "31.587357", "lon": "-8.048822" },
  { "name": "MARRAKECH AZZOUZIA", "lat": "31.682816", "lon": "-8.061733" },
  { "name": "MARRAKECH TARGA", "lat": "31.636201", "lon": "-8.0493415" },
  { "name": "MARRAKECH DAOUDIATE", "lat": "31.6503424", "lon": "-7.9916096" },
  { "name": "MARRAKECH EL BAHIA", "lat": "31.6220186", "lon": "-7.9845661" },
  {
    "name": "Marrakech Jardins des orangers",
    "lat": "31.587968",
    "lon": "-8.039267"
  },
  { "name": "MARRAKECH MENARA", "lat": "31.6025526", "lon": "-8.0273044" },
  { "name": "MARRAKECH BASE DES E.A.", "lat": "31.61473", "lon": "-8.034879" },
  { "name": "MARRAKECH ATLAS", "lat": "31.6004966", "lon": "-8.050645" },
  {
    "name": "MARRAKECH CAMP EL GHOUL",
    "lat": "31.6369135",
    "lon": "-8.0213714"
  },
  { "name": "MARRAKECH GARE", "lat": "31.6298158", "lon": "-8.018775" },
  { "name": "AMIZMIZ", "lat": "31.222124", "lon": "-8.241934" },
  { "name": "BENGUERIR VILLE", "lat": "32.236256", "lon": "-7.957229" },
  { "name": "CHICHAOUA", "lat": "31.541319", "lon": "-8.766618" },
  { "name": "DEMNATE", "lat": "31.731685", "lon": "-7.004567" },
  {
    "name": "EL KELAA DES SRARHNA PPAL",
    "lat": "32.053774",
    "lon": "-7.4062564"
  },
  { "name": "IMI N TANOUNTE", "lat": "31.17716", "lon": "-8.84798" },
  { "name": "SKHOUR REHAMNA", "lat": "32.483226", "lon": "-7.914826" },
  { "name": "MARRAKECH MEDINA", "lat": "31.6248689", "lon": "-7.9896247" },
  { "name": "AIT OURIR", "lat": "31.562756", "lon": "-7.6671" },
  { "name": "OUARZAZATE PPAL", "lat": "30.9204919", "lon": "-6.9106633" },
  { "name": "OUARZAZATE TARMIGT", "lat": "30.9010095", "lon": "-6.911028" },
  { "name": "OUARZAZATE FAR", "lat": "30.917229", "lon": "-6.910859" },
  { "name": "ZAGORA", "lat": "30.3252258", "lon": "-5.8399174" },
  { "name": "TALIOUINE", "lat": "30.532071", "lon": "-7.924058" },
  { "name": "TAZNAKHTE", "lat": "30.578224", "lon": "-7.203276" },
  { "name": "EL KELAA MGOUNA", "lat": "31.2367794", "lon": "-6.1295182" },
  { "name": "SKOURA AHL AL OUST", "lat": "31.063494", "lon": "-6.556027" },
  { "name": "TAGOUNITE", "lat": "29.9725018", "lon": "-5.5820927" },
  { "name": "AGDZ", "lat": "30.695792", "lon": "-6.451807" },
  { "name": "TAZZARINE", "lat": "30.781838", "lon": "-5.56693" },
  { "name": "ASKAOUN", "lat": "30.740281", "lon": "-7.776181" },
  { "name": "AGADIR ISLANE", "lat": "None", "lon": "None" },
  { "name": "OULED TEIMA HAY MOHAMMADI", "lat": "None", "lon": "None" },
  { "name": "TINERHIR", "lat": "31.521328", "lon": "-5.5305487" },
  { "name": "BOUMALNE DADES", "lat": "31.3702739", "lon": "-5.9837186" },
  { "name": "FOUM ZGUID", "lat": "30.085248", "lon": "-6.873051" },
  { "name": "MSEMRIR", "lat": "31.694929", "lon": "-5.821614" },
  { "name": "KHEMIS DADES", "lat": "31.306491", "lon": "-6.031333" },
  { "name": "MHAMID", "lat": "29.825411", "lon": "-5.718899" },
  { "name": "AMERZGANE", "lat": "31.045114", "lon": "-7.210615" },
  { "name": "IGHREM NOUGDAL", "lat": "31.229521", "lon": "-7.421109" },
  { "name": "IKNIOUEN", "lat": "31.168958", "lon": "-5.673095" },
  { "name": "ARBAA DOUIRANE", "lat": "31.213324", "lon": "-8.779326" },
  { "name": "TOUNDOUTE", "lat": "31.272224", "lon": "-6.587438" },
  { "name": "DAKHLA AL WAKALA", "lat": "23.701667", "lon": "-15.951808" },
  { "name": "TARHZOUTE NAIT ATTA", "lat": "31.455952", "lon": "-5.422493" },
  { "name": "TAMEGROUTE", "lat": "30.262118", "lon": "-5.688552" },
  { "name": "NKOB", "lat": "30.871362", "lon": "-5.86436" },
  { "name": "TNINE BOUCHANE", "lat": "32.28765", "lon": "-8.318346" },
  { "name": "TALOUET", "lat": "31.289698", "lon": "-7.240888" },
  {
    "name": "OUARZAZATE TASSOUMAATE",
    "lat": "30.9238512",
    "lon": "-6.9296801"
  },
  {
    "name": "MARRAKECH ALLAL EL FASSI",
    "lat": "31.645182",
    "lon": "-8.000139"
  },
  { "name": "MARRAKECH AZLI", "lat": "31.6250563", "lon": "-8.0405277" },
  { "name": "LAAYOUNE PPAL", "lat": "27.1615514", "lon": "-13.2028748" },
  { "name": "LAAYOUNE HAY ERRAHMA", "lat": "27.1400059", "lon": "-13.1931827" },
  { "name": "LAAYOUNE SOUK JEMAL", "lat": "27.1625668", "lon": "-13.2154343" },
  { "name": "LAAYOUNE HAY TAAOUN", "lat": "27.144398", "lon": "-13.204078" },
  { "name": "LAAYOUNE AL AMAL", "lat": "27.130862", "lon": "-13.1685948" },
  { "name": "LAAYOUNE FAR", "lat": "27.15988", "lon": "-13.193936" },
  { "name": "LAAYOUNE 4Úme BAFRA", "lat": "27.138438", "lon": "-13.219471" },
  { "name": "LAAYOUNE RHIBA", "lat": "27.1446268", "lon": "-13.1994644" },
  { "name": "LAAYOUNE AL MASSIRA", "lat": "27.154275", "lon": "-13.1874441" },
  { "name": "LAAYOUNE PORT", "lat": "27.097588", "lon": "-13.415124" },
  { "name": "LAAYOUNE VILLE", "lat": "27.1463333", "lon": "-13.1849671" },
  
  {
    "name": "MARRAKECH PONT MASSIRA",
    "lat": "31.6387244",
    "lon": "-8.0607623"
  },
  { "name": "LAAYOUNE ENNAHDA", "lat": "27.0968116", "lon": "-13.4092775" },
  { "name": "ESSEMARA", "lat": "26.74019", "lon": "-11.67056" },
  { "name": "ESSEMARA ECHAHID", "lat": "26.738317", "lon": "-11.677458" },
  { "name": "BOUJDOUR", "lat": "26.127382", "lon": "-14.482306" },
  
  
  { "name": "DAKHLA HAY ESSALAM", "lat": "23.690669", "lon": "-15.950207" },
  {
    "name": "LAAYOUNE HAY MOULAY RACHID",
    "lat": "27.145794",
    "lon": "-13.19362"
  },
  { "name": "DAKHLA", "lat": "23.700744", "lon": "-15.930047" },
  { "name": "DAKHLA BIR ANZARANE", "lat": "23.700339", "lon": "-15.934529" },
  { "name": "DAKHLA FAR", "lat": "27.138438", "lon": "-13.219471" },
  { "name": "ZAG", "lat": "30.085239", "lon": "-6.873061" },
  { "name": "FOUM OUDI", "lat": "32.278491", "lon": "-6.429955" },
  { "name": "AZILAL", "lat": "31.9674955", "lon": "-6.5660262" },
  { "name": "AZILAL MEDINA", "lat": "31.958343", "lon": "-6.573512" },
  { "name": "TANANNT", "lat": "31.862488", "lon": "-6.941659" },
  { "name": "BZOU", "lat": "32.091074", "lon": "-7.056243" },
  { "name": "TABANNT", "lat": "31.658381", "lon": "-6.421074" },
  { "name": "AIT MHAMED", "lat": "31.878496", "lon": "-6.474043" },
  { "name": "BNI AYAT", "lat": "32.216709", "lon": "-6.583632" },
  {
    "name": "EL KELAA DES SRARHNA VILLE",
    "lat": "32.0465491",
    "lon": "-7.408602"
  },
  {
    "name": "EL KELAA DES SRARHNA EL QODS",
    "lat": "32.0601595",
    "lon": "-7.397436"
  },
  { "name": "TAGUELFT", "lat": "32.238498", "lon": "-6.126517" },
  { "name": "ZAOUIA AHENSAL", "lat": "31.833914", "lon": "-6.10606" },
  { "name": "BEN AHMED", "lat": "33.0664238", "lon": "-7.2434999" },
  { "name": "SOUK TLETA LOULAD", "lat": "32.991527", "lon": "-7.127895" },
  { "name": "FOUM JEMAA", "lat": "31.959816", "lon": "-6.981152" },
  { "name": "SIDI HAJJAJ", "lat": "32.910217", "lon": "-7.258193" },
  { "name": "FOUM EL ANSER", "lat": "32.37293", "lon": "-6.262038" },
  { "name": "SIDI JABER", "lat": "32.369941", "lon": "-6.453215" },
  { "name": "KHEMIS OULAD AYAD", "lat": "32.2003244", "lon": "-6.7999229" },
  {
    "name": "BENI MELLAL OULAD HAMDANE",
    "lat": "32.3361831",
    "lon": "-6.3675846"
  },
  { "name": "OULED MBAREK", "lat": "32.281142", "lon": "-6.455981" },
  { "name": "OULED YAICHE", "lat": "32.4354365", "lon": "-6.328113" },
  { "name": "BENI MELLAL PPAL", "lat": "32.3334411", "lon": "-6.3592816" },
  {
    "name": "BENI MELLAL SIDI ABDELHALIM",
    "lat": "32.3352156",
    "lon": "-6.3493091"
  },
  { "name": "DAR OULD ZIDOUH", "lat": "32.308009", "lon": "-6.89751" },
  { "name": "OUAOUIZARHT", "lat": "32.158766", "lon": "-6.353901" },
  { "name": "AFOURER", "lat": "32.2119568", "lon": "-6.5315974" },
  {
    "name": "BENI MELLAL AIN ASSERDOUNE",
    "lat": "32.3403803",
    "lon": "-6.3447252"
  },
  { "name": "AIT ATTAB", "lat": "32.110637", "lon": "-6.710672" },
  { "name": "TAGHZIRT", "lat": "32.437782", "lon": "-6.20745" },
  { "name": "TILOUGGUITE", "lat": "32.029326", "lon": "-6.211897" },
  { "name": "BINE EL OUIDANE", "lat": "32.108388", "lon": "-6.460546" },
  { "name": "TNINE TIMOULILT", "lat": "32.202888", "lon": "-6.464727" },
  { "name": "BERRECHID PPAL", "lat": "33.265857", "lon": "-7.588512" },
  { "name": "BERRECHID ROUDANI", "lat": "None", "lon": "None" },
  { "name": "BERRECHID YASMINA", "lat": "33.263377", "lon": "-7.5812638" },
  { "name": "BERECHID HAY TISSIR", "lat": "33.274917", "lon": "-7.582803" },
  { "name": "DEROUA OULAD ZIANE", "lat": "33.374755", "lon": "-7.540778" },
  { "name": "NOUACEUR", "lat": "33.373548", "lon": "-7.547943" },
  { "name": "TISLIT", "lat": "31.97454", "lon": "-6.857346" },
  { "name": "BERRECHID HAY HASSANI", "lat": "33.2694142", "lon": "-7.5737939" },
  { "name": "BEJJAAD 11 JANVIER", "lat": "32.769197", "lon": "-6.39606" },
  { "name": "TACHREFT", "lat": "32.834698", "lon": "-6.47562" },
  { "name": "TLETA CHOUGRANE", "lat": "32.8977", "lon": "-6.380289" },
  { "name": "HAD BNI BATAO", "lat": "32.828772", "lon": "-6.289997" },
  { "name": "BEJJAAD PPAL", "lat": "32.770086", "lon": "-6.3908833" },
  { "name": "FKIH BEN SALAH", "lat": "32.5049052", "lon": "-6.6869429" },
  { "name": "SEBT OULAD NEMMA", "lat": "32.294479", "lon": "-6.7012766" },
  { "name": "SIDI AISSA", "lat": "32.343512", "lon": "-6.654328" },
  {
    "name": "BENI MELLAL HAY MOHAMMADI",
    "lat": "32.346993",
    "lon": "-6.358777"
  },
  { "name": "HAD BRADIA", "lat": "32.448498", "lon": "-6.536264" },
  { "name": "BRADIA SANABIL", "lat": "32.453595", "lon": "-6.540706" },
  { "name": "BNI OUKIL", "lat": "32.574637", "lon": "-6.838002" },
  { "name": "FQUIH BEN SALEH GARE", "lat": "32.490189", "lon": "-6.687943" },
  { "name": "KASBA TADLA PPAL", "lat": "32.5989812", "lon": "-6.2622714" },
  {
    "name": "KASBA TADLA CASERNE ASSOU OUBASLAM",
    "lat": "32.590177",
    "lon": "-6.272035"
  },
  { "name": "EL KSIBA", "lat": "32.566093", "lon": "-6.032342" },
  { "name": "ZAOUIA ECHCHEIKH", "lat": "32.64396", "lon": "-5.918117" },
  {
    "name": "ZAOUIA ECHEIKH BD HASSAN II",
    "lat": "32.638887",
    "lon": "-5.922575"
  },
  { "name": "ARHBALA", "lat": "32.47889", "lon": "-5.642475" },
  {
    "name": "KASBA TADLA HAY BOUDRAA",
    "lat": "32.6005404",
    "lon": "-6.2794651"
  },
  { "name": "EL KSIBA MASSIRA", "lat": "32.567178", "lon": "-6.032983" },
  { "name": "KHOURIBGA PPAL", "lat": "32.8819433", "lon": "-6.9115699" },
  {
    "name": "KHOURIBGA 5 GROUPE MECANISE DES FAR",
    "lat": "32.866413",
    "lon": "-6.950934"
  },
  { "name": "KHOURIBGA AL MASSIRA", "lat": "32.88916", "lon": "-6.8918395" },
  { "name": "BOUJNIBA", "lat": "32.8979672", "lon": "-6.777796" },
  { "name": "BOULANOUAR", "lat": "32.856892", "lon": "-6.876921" },
  { "name": "HATTANE", "lat": "32.839769", "lon": "-6.803278" },
  { "name": "KHOURIBGA ZELLAGA", "lat": "32.8880789", "lon": "-6.9160492" },
  { "name": "EL GUEFFAF", "lat": "32.922213", "lon": "-6.719652" },
  { "name": "SEBT BENI YAKHLEF", "lat": "32.951658", "lon": "-6.821094" },
  { "name": "KHOURIBGA AL QODS", "lat": "32.8854143", "lon": "-6.9301925" },
  { "name": "KHOURIBGA HAY AL FATH", "lat": "32.8902512", "lon": "-6.9286931" },
  { "name": "KHOURIBGA AL AMAL", "lat": "32.890159", "lon": "-6.927817" },
  { "name": "OUED ZEM PPAL", "lat": "32.861495", "lon": "-6.5706571" },
  { "name": "MOULAY BOUAZZA", "lat": "33.22655", "lon": "-6.196445" },
  
  { "name": "OUED ZEM AL WAHDA", "lat": "32.8591767", "lon": "-6.5779822" },
  { "name": "SETTAT", "lat": "33.001954", "lon": "-7.620497" },
  { "name": "SETTAT HAY MIMOUNA", "lat": "33.0049375", "lon": "-7.6276714" },
  { "name": "EL BOROUJ", "lat": "32.5068664", "lon": "-7.1909273" },
  { "name": "OULAD ABBOU", "lat": "33.116657", "lon": "-7.937413" },
  { "name": "OULAD SAID", "lat": "32.968424", "lon": "-7.814881" },
  { "name": "RAS EL AIN", "lat": "32.980979", "lon": "-7.393836" },
  { "name": "BNI KHLOUG", "lat": "32.641554", "lon": "-7.380589" },
  { "name": "SIDI RAHHAL CHAOUIA", "lat": "32.780353", "lon": "-7.771638" },
  { "name": "GUISSER", "lat": "32.7720301", "lon": "-7.5095308" },
  { "name": "SETTAT HAY EL FARAH", "lat": "33.008533", "lon": "-7.6268452" },
  { "name": "BIR JDID", "lat": "33.375008", "lon": "-7.993892" },
  { "name": "SIDI RAHHAL PLAGE", "lat": "33.472091", "lon": "-7.956647" },
  { "name": "HAD SOUALEM", "lat": "33.4186679", "lon": "-7.8599668" },
  { "name": "EL JADIDA JOUHARA", "lat": "33.2331664", "lon": "-8.4932175" },
  { "name": "SETTAT SAMIR", "lat": "33.0019379", "lon": "-7.6102102" },
  { "name": "EL JADIDA BOUCHRIT", "lat": "33.2539771", "lon": "-8.5087891" },
  { "name": "ARBAA IDA OUGOURD", "lat": "31.424067", "lon": "-9.637526" },
  {
    "name": "EL JADIDA BOULEVARD EL AL AOUIYINE",
    "lat": "33.23589",
    "lon": "-8.524398"
  },
  { "name": "EL JADIDA BD IBN BADIS", "lat": "33.235794", "lon": "-8.504317" },
  { "name": "SIDI BOUZID", "lat": "33.2302026", "lon": "-8.5519083" },
  { "name": "MOULAY ABDELLAH", "lat": "33.198697", "lon": "-8.58798" },
  { "name": "TNINE GHARBIA", "lat": "32.5701196", "lon": "-8.8549108" },
  { "name": "OULED AMRANE", "lat": "32.390845", "lon": "-8.517843" },
  { "name": "EL JADIDA PPAL", "lat": "33.2513695", "lon": "-8.5019964" },
  {
    "name": "EL JADIDA BIR ANZARANE",
    "lat": "33.2230587",
    "lon": "-8.4924316"
  },
  { "name": "EL JADIDA ESSAADA", "lat": "33.2412359", "lon": "-8.5273916" },
  { "name": "AZEMMOUR", "lat": "33.2887579", "lon": "-8.3432821" },
  { "name": "KHEMIS DES ZEMAMRA", "lat": "32.625473", "lon": "-8.6961696" },
  { "name": "SIDI BENNOUR", "lat": "32.6494829", "lon": "-8.4233366" },
  { "name": "SIDI BENNOUR AL MASSIRA", "lat": "32.648978", "lon": "-8.431192" },
  { "name": "OULED FREJ", "lat": "32.9565033", "lon": "-8.2217887" },
  { "name": "SIDI SMAIL", "lat": "32.8238099", "lon": "-8.5100887" },
  { "name": "ARBAA AOUNATE", "lat": "32.741409", "lon": "-8.19432" },
  { "name": "EL JADIDA PLATEAU", "lat": "33.2495953", "lon": "-8.5163959" },
  { "name": "EL JADIDA BD MOHAMED RAFII", "lat": "None", "lon": "None" },
  { "name": "OUALIDIA", "lat": "32.7345777", "lon": "-9.0336478" },
  { "name": "SEBT BENI HLAL", "lat": "32.8004692", "lon": "-8.3776975" },
  { "name": "SAFI PPAL", "lat": "32.294012", "lon": "-9.234803" },
  { "name": "SAFI AL MASSIRA", "lat": "32.2833935", "lon": "-9.225643" },
  { "name": "CHEMAIA", "lat": "32.0802859", "lon": "-8.6220842" },
  { "name": "JEMAA SEHAIM", "lat": "32.354543", "lon": "-8.841151" },
  { "name": "YOUSSOUFIA", "lat": "32.2436692", "lon": "-8.5346429" },
  { "name": "YOUSSOUFIA  AL QODS", "lat": "32.2534451", "lon": "-8.5352612" },
  { "name": "TLETA SIDI BOUGUEDRA", "lat": "32.255809", "lon": "-8.979655" },
  { "name": "SEBT GZOULA", "lat": "32.1073208", "lon": "-9.0865396" },
  { "name": "SAFI SIDI BOUDAHAB", "lat": "32.2970977", "lon": "-9.2432061" },
  { "name": "HAD HARARA", "lat": "32.438171", "lon": "-9.135277" },
  { "name": "TLETA IRHOUD", "lat": "31.859606", "lon": "-8.88088" },
  { "name": "SAFI SIDI OUASSEL", "lat": "32.2568418", "lon": "-9.250094" },
  { "name": "ESSAOUIRA PPAL", "lat": "31.5101604", "lon": "-9.7663549" },
  { "name": "TAMANAR", "lat": "30.999738", "lon": "-9.678676" },
  { "name": "SMIMOU", "lat": "31.212379", "lon": "-9.703634" },
  { "name": "MÆDIQ MARINA", "lat": "35.754027", "lon": "-5.340782" },
  { "name": "TLATA HANCHANE", "lat": "31.5257773", "lon": "-9.4429315" },
  { "name": "TAFTECHT", "lat": "31.581543", "lon": "-9.253355" },
  { "name": "TALMEST", "lat": "31.8160256", "lon": "-9.3376441" },
  { "name": "BIZDAD", "lat": "31.27492", "lon": "-9.374181" },
  { "name": "AIT DAOUD", "lat": "31.080678", "lon": "-9.351098" },
  { "name": "SIDI AHMED", "lat": "32.212649", "lon": "-8.495232" },
  { "name": "SAFI SIDI ABDELKRIM", "lat": "32.3070072", "lon": "-9.2343977" },
  { "name": "BENSLIMANE PPAL", "lat": "33.6117668", "lon": "-7.1199131" },
  { "name": "BENI YAKHLEF", "lat": "33.683599", "lon": "-7.340546" },
  { "name": "FEDALATE", "lat": "33.568732", "lon": "-7.30752" },
  { "name": "BENSLIMANE FATH", "lat": "33.625028", "lon": "-7.129725" },
  { "name": "SAFI AZIB DRAI", "lat": "32.292944", "lon": "-9.219847" },
  { "name": "MELLILA", "lat": "33.388845", "lon": "-7.146059" },
  {
    "name": "ESSAOUIRA GARE ROUTIERE",
    "lat": "33.5288521",
    "lon": "-5.1078111"
  },
  { "name": "SIDI MOUSSA BEN ALI", "lat": "33.562633", "lon": "-7.373482" },
  { "name": "HAD DRAA", "lat": "31.577423", "lon": "-9.537343" },
  { "name": "ZAOUIA BEN HMIDA", "lat": "31.754376", "lon": "-9.372475" },
  { "name": "SAHRIJ", "lat": "31.803303", "lon": "-7.149729" },
  { "name": "LAATAOUIA ECHCHAIBIA", "lat": "31.8335424", "lon": "-7.3121913" },
  { "name": "TAMELELT", "lat": "31.816475", "lon": "-7.50901" },
  { "name": "ASNI", "lat": "31.247219", "lon": "-7.984041" },
  { "name": "SIDI BOU OTHMANE", "lat": "31.902589", "lon": "-7.943834" },
  { "name": "BENGUERIR HASSAN II", "lat": "32.227744", "lon": "-7.94793" },
  { "name": "SIDI MOKHTAR", "lat": "31.579132", "lon": "-9.011192" },
  { "name": "SIDI RAHHAL", "lat": "31.652372", "lon": "-7.4704752" },
  { "name": "TLETA NYACOUB", "lat": "30.991135", "lon": "-8.182944" },
  { "name": "TAHANAOUTE", "lat": "31.367329", "lon": "-7.946302" },
  { "name": "HAD RAS EL AIN", "lat": "31.753332", "lon": "-7.618614" },
  { "name": "NZALATE LAADIM", "lat": "32.097854", "lon": "-7.952653" },
  { "name": "BENGUERIR PPAL", "lat": "32.2384275", "lon": "-7.9604665" },
  { "name": "ESSAOUIRA SCALA", "lat": "31.513418", "lon": "-9.7708471" },
  {
    "name": "BENGUERIR BASE MILITAIRE",
    "lat": "32.129541",
    "lon": "-7.906637"
  },
  { "name": "TAMENSOURT", "lat": "None", "lon": "None" }
]

export const dataNext = [
  { "name": "SNTL / 745-02 / avant", "lat": 0, "lon": 0 },
  { "name": "delgation de sante sale", "lat": 34.046305, "lon": -6.822746 },
  {
    "name": "DÚlÚgation de la santÚ de settat",
    "lat": 33.00474611150426,
    "lon": -7.611600161365506
  },
  { "name": "SNTL / 914-02 / avant", "lat": 0, "lon": 0 },
  {
    "name": "CENTRE BAB SEBTA MOULAY SLIMANE",
    "lat": 34.0397812,
    "lon": -6.825026
  },
  {
    "name": "Pharmacie Provinciale Armoire Frigorifique E",
    "lat": 35.18639177954828,
    "lon": -6.15597451901436
  },
  { "name": "Logiphar 65322-E-6/ AVANT ", "lat": 0, "lon": 0 },
  {
    "name": "CENTRE BAB SEBTA MOULAY SLIMANE",
    "lat": 34.040997,
    "lon": -6.820179
  },
  { "name": "SNTL / 745-02 / arriere", "lat": 0, "lon": 0 },
  {
    "name": "Pharmacie Provinciale Armoire Frigorifique E",
    "lat": 35.18637040636998,
    "lon": -6.15599798834324
  },
  { "name": "SNTL 930-02/ / avant", "lat": 0, "lon": 0 },
  { "name": "SNTL / 1102-02 / arriere", "lat": 0, "lon": 0 },
  { "name": "DELEGATION DE SANTE RABAT", "lat": 34.018029, "lon": -6.840893 },
  { "name": "DELEGATION DE SANTE RABAT", "lat": 34.0181149, "lon": -6.8433015 },
  { "name": "chp hassan 2", "lat": 32.53366, "lon": -6.54235 },
  { "name": "Delegation SRES", "lat": 33.589531, "lon": -7.552379 },
  {
    "name": "Pharmacie Provinciale Armoire Frigorifique A",
    "lat": 35.18640772862031,
    "lon": -6.156441311657958
  },
  { "name": "Koudiat Ecole", "lat": 33.586063, "lon": -7.560087 },
  { "name": "SNTL 930-02/ / arriere", "lat": 0, "lon": 0 },
  {
    "name": "DÚlÚgation de la santÚ de settat",
    "lat": 33.00473711402883,
    "lon": -7.611600161365506
  },
  {
    "name": "DÚlÚgation de la santÚ de settat",
    "lat": 33.00473711402885,
    "lon": -7.611589432529446
  },
  { "name": "SNTL / 746-02 / arriere", "lat": 0, "lon": 0 },
  { "name": "SNTL / 1102-02 / avant", "lat": 0, "lon": 0 },
  {
    "name": "DISPENSAIRE RURAL ARBIAA SHOUL",
    "lat": 33.977214,
    "lon": -6.614217
  },
  {
    "name": "DELEGATION DE SANTE SIDI SLIMANE",
    "lat": 34.26831,
    "lon": -5.925174
  },
  { "name": "HOPITAL DE PROXEMITE DEMNAT", "lat": 31.76241, "lon": -7.010258 },
  { "name": "HOPITAL DE PROXEMITE DEMNAT", "lat": 31.75941, "lon": -7.010258 },
  {
    "name": "DÚlÚgation Sidi Slimane",
    "lat": 34.266019599960615,
    "lon": -5.9181220534423735
  },
  {
    "name": "pharmacie provinciale al hociema B",
    "lat": 35.238291237121935,
    "lon": -3.9288116763806413
  },
  {
    "name": "Pharmacie Provinciale Armoires Frigorifique A-2",
    "lat": 35.238286855682524,
    "lon": -3.928805641410358
  },
  { "name": "chp temara", "lat": 33.91857, "lon": -6.927946 },
  { "name": "COMPLEX SPORTIF BOUCHOUK", "lat": 34.092777, "lon": -6.777043 },
  { "name": "chp temara", "lat": 33.918555, "lon": -6.92785 },
  {
    "name": "CSU Targisst Armoires Frigorifique B",
    "lat": 34.93731,
    "lon": -4.313351
  },
  {
    "name": "CSU Bni Boufrah Armoires Frgorifique A",
    "lat": 35.102184,
    "lon": -4.3112158
  },
  { "name": "CSR ameur", "lat": 34.082316, "lon": -6.757649 },
  { "name": "csy lquods", "lat": 32.53099, "lon": -6.55475 },
  {
    "name": "DELEGATION DE SANTE SIDI SLIMANE",
    "lat": 34.258315,
    "lon": -5.915188
  },
  {
    "name": "PHARMACIE PREFECTORALE-AV SAHARA HAY SALAM",
    "lat": 34.043926,
    "lon": -6.788309
  },
  { "name": "DELEGATION DE SANTE SALE", "lat": 34.0370272, "lon": -6.8148666 },
  {
    "name": "CSU Bni Boufrah RÚfrigÚrateur 1",
    "lat": 35.102176319635504,
    "lon": -4.311214458895487
  },
  { "name": "chp temara", "lat": 33.918548583984375, "lon": -6.910759 },
  { "name": "chp temara", "lat": 33.91856, "lon": -6.927855 },
  {
    "name": "Centre de Rencontre des GÚnÚrations Armoire Frigorifique A",
    "lat": 33.60247,
    "lon": -7.619282
  },
  { "name": "CHP MIDELT Chambre froide A", "lat": 32.699401, "lon": -4.757972 },
  { "name": "La cuisine chp azilal ", "lat": 31.967635, "lon": -6.564044 },
  { "name": "chp hassan 2", "lat": 32.53966, "lon": -6.54235 },
  {
    "name": "CENTRE DE SANTE RURAL NIVEAU 1 SIDI AZZOUZ",
    "lat": 33.985632,
    "lon": -6.514809
  },
  { "name": "chp temara", "lat": 33.918566, "lon": -6.927865 },
  { "name": "CSU KALAA MAGOUNA -1", "lat": 31.14385, "lon": -6.07251 },
  { "name": "DELEGATION DE SANTE SALE", "lat": 34.0360318, "lon": -6.8153462 },
  {
    "name": "CSU KALAA MAGOUNA Armoire frigorifique A2",
    "lat": 31.14185,
    "lon": -6.07251
  },
  {
    "name": "PHARMACIE PREFECTORALE-AV SAHARA HAY SALAM",
    "lat": 34.04392,
    "lon": -6.788305
  },
  {
    "name": "DELEGATION DE SANTE SIDI KACEM",
    "lat": 34.229022,
    "lon": -5.715583
  },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  { "name": "CHP MIDELT Chambre froide A", "lat": 32.697401, "lon": -4.757972 },
  { "name": "chp temara", "lat": 33.918548583984375, "lon": -6.910759 },
  {
    "name": "Centre de Rencontre des GÚnÚrations Armoire Frigorifique A",
    "lat": 33.57385871656109,
    "lon": -7.6141046357421915
  },
  { "name": "CHP MIDELT Chambre froide A", "lat": 32.699401, "lon": -4.757972 },
  { "name": "DELEGATION DE SANTE SALE", "lat": 34.01063051, "lon": -6.8137467 },
  {
    "name": "chp temara",
    "lat": 33.918548583984375,
    "lon": -6.927846431732178
  },
  { "name": "HOPITAL DE PROXEMITE DEMNAT", "lat": 31.76041, "lon": -7.010258 },
  {
    "name": "DISPENSAIRE RURAL ARBIAA SHOUL",
    "lat": 33.977224,
    "lon": -6.614218
  },
  {
    "name": "DELEGATION DE SANTE SIDI KACEM",
    "lat": 34.219022,
    "lon": -5.705583
  },
  {
    "name": "DELEGATION DE SANTE SIDI SLIMANE",
    "lat": 34.25844,
    "lon": -5.925174
  },
  {
    "name": "CENTRE DE SANTE RURAL NIVEAU 1 SIDI AZZOUZ",
    "lat": 33.975632,
    "lon": -6.513809
  },
  {
    "name": "H¶pital Roi Fahd",
    "lat": 35.76783066751905,
    "lon": -5.796281720237739
  },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  {
    "name": "CSU Targisst Armoires Frigorifique A",
    "lat": 34.937313,
    "lon": -4.313351
  },
  { "name": "chp temara", "lat": 33.918548583984375, "lon": -6.92622 },
  {
    "name": "PHARMACIE PREFECTORALE-AV SAHARA HAY SALAM",
    "lat": 34.043921,
    "lon": -6.788306
  },
  {
    "name": "CSU Targisst Armoires Frigorifique B",
    "lat": 34.93730450295238,
    "lon": -4.31335234110451
  },
  { "name": "606051KMM / MILITAIRE MARRAKECH- BSLS AVANT", "lat": 0, "lon": 0 },
  { "name": "604424KMM/BSLS KENITRA", "lat": 0, "lon": 0 },
  {
    "name": " 606124FAR/1░BSI-BOUKNADAL-SALE",
    "lat": 34.261756524459805,
    "lon": -6.5478515625
  },
  {
    "name": "CS rural-2 TINZOLINE Armoire frigorifique A",
    "lat": 30.50435866449519,
    "lon": -6.101770941139228
  },
  {
    "name": "606049FAR/1░BSI-BOUKNADAL-SALE",
    "lat": 34.5970415161444,
    "lon": -6.2841796875
  },
  {
    "name": "DOUAR KADOUR BEN BRIK COMMUNE OULED IMLOUL",
    "lat": 32.03662,
    "lon": -7.7481
  },
  {
    "name": "606245FAR/1░BSI-BOUKNADAL-SALE",
    "lat": 34.397844946449894,
    "lon": -6.4599609375
  },
  { "name": "CSR-1 Imegdal", "lat": 31.121203, "lon": -8.121225 },
  { "name": "Bizdad", "lat": 31.286511, "lon": -9.338767 },
  { "name": "Vaccinodrome MÚdiouna", "lat": 33.46136, "lon": -7.519626 },
  {
    "name": "CS rural-2 Tamezmoute Armoire frigorifique A",
    "lat": 30.667500748883878,
    "lon": -6.1437476858444136
  },
  {
    "name": "CS rural-2 TINZOLINE Armoire frigorifique A",
    "lat": 30.504307823231333,
    "lon": -6.101491991401679
  },
  {
    "name": " 606124FAR/1░BSI-BOUKNADAL-SALE",
    "lat": 34.27083595165,
    "lon": -6.50390625
  },
  { "name": "CSU 2 RAS EL MA", "lat": 35.12805, "lon": -2.423821 },
  {
    "name": "CS urbain-2 AGDEZ Armoire frigorifique A",
    "lat": 30.699542,
    "lon": -6.448125
  },
  { "name": "HOPITAL HASSAN 2", "lat": 34.629807, "lon": -4.912567 },
  { "name": "CSR 1 BOUARG", "lat": 35.106354, "lon": -2.87641 },
  { "name": "MILITAIRE MARRAKECH -605995KMM ", "lat": 0, "lon": 0 },
  {
    "name": "CS rural-2 TINZOLINE Armoire frigorifique A",
    "lat": 30.504324,
    "lon": -6.101551
  },
  { "name": "CSU 2 ZAIO", "lat": 34.941463, "lon": -2.735038 },
  {
    "name": "CS urbain-2 AGDEZ Armoire frigorifique A",
    "lat": 30.698542,
    "lon": -6.447125
  },
  { "name": "605520-MILITAIRE MARRAKECH ARRIERE", "lat": 0, "lon": 0 },
  { "name": "CSU SELOUAN JADID", "lat": 35.06749, "lon": -2.92053 },
  { "name": "Dr Mestitef", "lat": 34.67148208, "lon": -5.222894191 },
  { "name": "DR IHADADAN", "lat": 35.160061, "lon": -2.983158 },
  { "name": "DELEGATION DE SANTE SALE", "lat": 34.036229, "lon": -6.812854 },
  { "name": "CS rural TERGHISTE", "lat": 32.250707, "lon": -5.422172 },
  {
    "name": "606049FAR/1░BSI-BOUKNADAL-SALE",
    "lat": 34.66935854524558,
    "lon": -6.30615234375
  },
  { "name": "CSR 1 BOUARG", "lat": 35.107354, "lon": -2.87641 },
  { "name": "605520-MILITAIRE MARRAKECH", "lat": 0, "lon": 0 },
  { "name": "CSR 1 OULAD SETTOUT", "lat": 34.906776, "lon": -2.80801 },
  {
    "name": "606051KMM /MILITAIRE MARRAKECH- BSLS ARRIERE",
    "lat": 0,
    "lon": 0
  },
  { "name": "CSU2 Chemaia", "lat": 32.078079, "lon": -8.625568 },
  {
    "name": " 606245FAR/1░BSI-BOUKNADAL-SALE",
    "lat": 34.379712580462204,
    "lon": -6.41601562500004
  },
  { "name": "Vaccinodrome MÚdiouna", "lat": 33.46136, "lon": -7.519626 },
  { "name": "MILITAIRE MARRAKECH-605995KMM ", "lat": 0, "lon": 0 },
  { "name": "CSU ZAWYA CHEIKH", "lat": 32.644139, "lon": -5.922184 },
  { "name": "CSU 2 RAS EL MA", "lat": 35.13705, "lon": -2.423821 },
  { "name": "CSR1 OULAD SETTOUT", "lat": 34.907776, "lon": -2.80801 },
  { "name": "CHU Ibno Rochd", "lat": 33.578758, "lon": -7.617974 },
  { "name": "CHU Ibno Rochd", "lat": 33.578758, "lon": -7.617914991401666 },
  {
    "name": "H¶pital Mohammed 5 (Armoire3)",
    "lat": 33.25121273588659,
    "lon": -8.508842177909852
  },
  { "name": "FAR/3░ BSI-MEKNES", "lat": 0, "lon": 0 },
  { "name": "67804A13/LOGIPHAR AVANT", "lat": 0, "lon": 0 },
  { "name": "CHU Souissi", "lat": 33.984051, "lon": -6.85095 },
  {
    "name": "hopital sidi mohamed ben abdellah",
    "lat": 31.511742,
    "lon": -9.76378
  },
  { "name": "CSC BOUTFERDA ", "lat": 32.356113, "lon": -5.831861 },
  { "name": "65360W6/LOGIPHAR AVANT ", "lat": 0, "lon": 0 },
  { "name": "604423kmm/ BSLS GUERCIF", "lat": 0, "lon": 0 },
  {
    "name": "CHU Ibno Rochd",
    "lat": 33.578791519278575,
    "lon": -7.618051784061426
  },
  {
    "name": "instutit nationale d'oncologie rabat",
    "lat": 33.972437,
    "lon": -6.871812
  },
  { "name": "97787-D-6 / LOGIPHAR", "lat": 0, "lon": 0 },
  {
    "name": "instutit nationale d'oncologie rabat",
    "lat": 33.972437,
    "lon": -6.871812
  },
  { "name": "605996kmm/ BSLS MEKNES", "lat": 0, "lon": 0 },
  {
    "name": "cvr hay karima",
    "lat": 34.054588317871094,
    "lon": -6.795708179473877
  },
  { "name": "606667KMM/BSLS GUERCIF", "lat": 0, "lon": 0 },
  { "name": "604423kmm/ BSLS GUERCIF", "lat": 0, "lon": 0 },
  {
    "name": "CHU Ibno Rochd",
    "lat": 33.57875353076189,
    "lon": -7.617920355819696
  },
  {
    "name": "instutit nationale d'oncologie rabat",
    "lat": 33.972437,
    "lon": -6.871812
  },
  { "name": "605996kmm/ BSLS MEKNES", "lat": 0, "lon": 0 },
  {
    "name": "Hopital azemmour A2",
    "lat": 33.283331403372635,
    "lon": -8.357051895089159
  },
  {
    "name": "hopital sidi mohamed ben abdellah",
    "lat": 31.511751,
    "lon": -9.763738
  },
  { "name": "DELEGATION DE SANTE SALE", "lat": 34.0362215, "lon": -6.812855 },
  {
    "name": "67897A13/LOGIPHAR-AVANT",
    "lat": 33.55970664841198,
    "lon": -7.569580078125
  },
  { "name": "CHU SOUISSI", "lat": 33.984051, "lon": -6.85095 },
  {
    "name": "H¶pital Mohammed 5 (Armoire2)",
    "lat": 33.25128451387752,
    "lon": -8.508957512897492
  },
  {
    "name": "H¶pital Mohammed 5 (Armoire3)",
    "lat": 33.251347319571245,
    "lon": -8.508828766864777
  },
  {
    "name": "DELEGATION HAY HASSANI A",
    "lat": 33.571174000000006,
    "lon": -7.679630999999998
  },
  {
    "name": "605519FAR/4░BSLS MEKNES",
    "lat": 33.916013113401696,
    "lon": -5.526123046875
  },
  { "name": "67804A13/LOGIPHAR ARRIERE", "lat": 0, "lon": 0 },
  { "name": "HOPITAL 20 AOUT ", "lat": 33.572811, "lon": -7.621419 },
  { "name": "csr-1 dar jamaa", "lat": 31.253406, "lon": -8.373169 },
  {
    "name": "605515KMM/BSLS TAZA",
    "lat": 34.295044440107475,
    "lon": -4.00551132812498
  },
  { "name": "C/S IKSSANE", "lat": 35.112946, "lon": -2.984806 },
  { "name": "606667KMM/BSLS GUERCIF", "lat": 0, "lon": 0 },
  { "name": "67898A13/LOGIPHAR ARRIERE ", "lat": 0, "lon": 0 },
  { "name": "CHU SOUISSI", "lat": 33.984052, "lon": -6.850951 },
  {
    "name": "CHU IBNO ROCHD CENTRE DE M╔TIER",
    "lat": 33.579472,
    "lon": -7.620644
  },
  { "name": "HOPITAL 20 AOUT ", "lat": 33.573597, "lon": -7.619399 },
  { "name": "HOPITAL 20 AOUT ", "lat": 33.572811, "lon": -7.621419 },
  { "name": "97787-D-6 / LOGIPHAR", "lat": 0, "lon": 0 },
  { "name": "605515KMM/BSLS TAZA", "lat": 0, "lon": 0 },
  { "name": "C/S BENI SIDEL JBEL", "lat": 35.179826, "lon": -3.0487 },
  { "name": "67897A13/LOGIPHAR ARRIERE ", "lat": 0, "lon": 0 },
  { "name": "HOPITAL 20 AOUT", "lat": 33.572811, "lon": -7.621419 },
  { "name": "65360W6/LOGIPHAR ARRIERE ", "lat": 0, "lon": 0 },
  { "name": "65363W6/LOGIPHAR AVANT", "lat": 0, "lon": 0 },
  {
    "name": "Hopital azemmour A1",
    "lat": 33.28316155365205,
    "lon": -8.357068323619375
  },
  { "name": "67805A13/LOGIPHAR AVANT", "lat": 0, "lon": 0 },
  { "name": "67898A13/LOGIPHAR AVANT ", "lat": 0, "lon": 0 },
  { "name": "65363W6/LOGIPHAR ARRIERE", "lat": 0, "lon": 0 },
  { "name": "FAR/3░ BSI-MEKNES", "lat": 0, "lon": 0 },
  { "name": "centre loubila", "lat": 33.60455, "lon": -7.634509 },
  {
    "name": "hopital sidi mohamed ben abdellah",
    "lat": 31.511837,
    "lon": -9.763539
  },
  {
    "name": "instutit nationale d'oncologie rabat",
    "lat": 33.972437,
    "lon": -6.871812
  },
  { "name": "605519FAR/4░BSLS MEKNES", "lat": 0, "lon": 0 },
  {
    "name": "hopital sidi mohamed ben abdellah",
    "lat": 31.511686,
    "lon": -9.76357
  },
  { "name": "DELEGATION DE SANTE SALE", "lat": 34.0362216, "lon": -6.812858 },
  { "name": "89002-D-6 / LOGIPHAR", "lat": 0, "lon": 0 },
  { "name": "C/S BENI SIDEL LOUTA (3)", "lat": 35.11342, "lon": -3.068782 },
  { "name": "hopitale Razi", "lat": 34.039024, "lon": -6.815506 },
  { "name": "HOPITAL 20 AOUT ", "lat": 33.572811, "lon": -7.621419 },
  {
    "name": "Hopital 20 aout centre dentaire",
    "lat": 33.573453,
    "lon": -7.620323
  },
  { "name": "CARREFOUR", "lat": 32.946949, "lon": -5.660001 },
  { "name": "CARREFOUR", "lat": 32.945949, "lon": -5.660001 },
  { "name": "chp temara", "lat": 33.918697, "lon": -6.928052 },
  {
    "name": "PHARMACIE PROVINCIAL AALIA BAYDA",
    "lat": 33.570858,
    "lon": -7.478446
  },
  { "name": "chp temara", "lat": 33.9110697, "lon": -6.928652 },
  { "name": "chp temara", "lat": 33.9181097, "lon": -6.928059 },
  {
    "name": "H¶pital Hay Jadid Armoires Frigorifique A-1",
    "lat": 35.765392665966004,
    "lon": -5.826966498182304
  },
  { "name": "pharmacie hospitaliere sale", "lat": 34.029638, "lon": -6.753247 },
  { "name": "DR Tankert", "lat": 29.2354235, "lon": -9.4567659 },
  { "name": "CARREFOUR", "lat": 32.944949, "lon": -5.660001 },
  { "name": "chp temara", "lat": 33.918697, "lon": -6.928052 },
  {
    "name": "H¶pital Hay Jadid Armoires Frigorifique A-1",
    "lat": 35.76528167,
    "lon": -5.826823
  },
  { "name": "SOCIETE COBAG SALE", "lat": 34.032111, "lon": -6.781833 },
  { "name": "CARREFOUR", "lat": 32.943949, "lon": -5.660001 },
  { "name": "pharmacie hospitaliere sale", "lat": 34.029678, "lon": -6.753947 },
  { "name": "SOCIETE COBAG SALE", "lat": 34.012113, "lon": -6.781913 },
  { "name": "SOCIETE COBAG SALE", "lat": 34.032113, "lon": -6.781933 },
  { "name": "chp temara", "lat": 33.91857, "lon": -6.92652 },
  {
    "name": "centre de cente medical de proximite had kourt",
    "lat": 34.617903,
    "lon": -5.730235
  },
  { "name": "CHP KHENIFRA", "lat": 32.936797, "lon": -5.671093 },
  {
    "name": "C/S Issaken AF A",
    "lat": 34.91404632150854,
    "lon": -4.575268000000007
  },
  { "name": "chp temara", "lat": 33.91896, "lon": -6.92692 },
  { "name": "chp temara", "lat": 33.911056, "lon": -6.92692 },
  { "name": "Centre de santÚ hay errahma", "lat": 34.065547, "lon": -6.790007 },
  {
    "name": "pharmacie hospitaliere sale",
    "lat": 34.0211638,
    "lon": -6.753287
  },
  { "name": "chp temara", "lat": 33.91856, "lon": -6.92622 },
  { "name": "chp temara", "lat": 33.911856, "lon": -6.926255 },
  { "name": "CHP KHENIFRA", "lat": 32.934797, "lon": -5.671093 },
  { "name": "pharmacie hospitaliere sale", "lat": 34.039638, "lon": -6.763247 },
  { "name": "chp temara", "lat": 33.91857, "lon": -6.92623 },
  { "name": "chp temara", "lat": 33.92556, "lon": -6.92626 },
  { "name": "pharmacie hospitaliere sale", "lat": 34.029838, "lon": -6.753241 },
  { "name": "chp temara", "lat": 33.918697, "lon": -6.928052 },
  { "name": "pharmacie hospitaliere sale", "lat": 34.029631, "lon": -6.753249 },
  { "name": "pharmacie hospitaliere sale", "lat": 34.029678, "lon": -6.753947 },
  {
    "name": "CSU 1 El Haouta RÚfrigÚrateur 4",
    "lat": 35.16910545892025,
    "lon": -5.262733895394995
  },
  { "name": "H¶pital midelt", "lat": 32.677307, "lon": -4.738627 },
  {
    "name": "DELEGATION DE KHOURIBGA SRES ARMOIRE 1",
    "lat": 32.893746,
    "lon": -6.904737
  },
  { "name": "CSR IAAZANENE", "lat": 35.266045, "lon": -3.110354 },
  {
    "name": "DIPENSAIRE RURAL AIT SIDI BOUMOUSSA AIT OUMGHAR",
    "lat": 32.588132,
    "lon": -5.040358
  },
  {
    "name": "H¶pital midelt Armoire frigorifique F",
    "lat": 32.67766822831682,
    "lon": -4.738519711639408
  },
  {
    "name": "CS Tizgui -2",
    "lat": 31.578441289771064,
    "lon": -5.584517271163933
  },
  { "name": "CSU BIR ANZARAN", "lat": 31.50585, "lon": -5.531969 },
  {
    "name": "CS ET MAISON D ACCOUCHEMENT LEQBAB",
    "lat": 32.743266,
    "lon": -5.523815
  },
  {
    "name": "DELEGATION DE KHOURIBGA SRES ARMOIRE 2",
    "lat": 32.891746,
    "lon": -6.904737
  },
  {
    "name": "DELEGATION DE KHOURIBGA SRES ARMOIRE 2",
    "lat": 32.892746,
    "lon": -6.904737
  },
  { "name": "89696-D-6 / LOGIPHAR", "lat": 0, "lon": 0 },
  {
    "name": "POINT DE DEBARQUEMENT AMENAGE DE TARGHA",
    "lat": 35.390106,
    "lon": -5.006944
  },
  {
    "name": "POINT DE DEBARQUEMENT AMENAGE DE TARGHA",
    "lat": 35.39025468943787,
    "lon": -5.006085693115225
  },
  {
    "name": "CHP DRIOUCH A",
    "lat": 34.99159316198692,
    "lon": -3.376313481506348
  },
  {
    "name": "centre medical okba bnou nafii -1",
    "lat": 34.247532,
    "lon": -6.550684
  },
  { "name": "ECOLE EL BAKRI", "lat": 33.599312, "lon": -7.630887 },
  { "name": "42316CTM ", "lat": 0, "lon": 0 },
  { "name": "33432LOGIPHAR ARRIERE", "lat": 0, "lon": 0 },
  { "name": "184679MAROC/SANTE/AVANT", "lat": 0, "lon": 0 },
  { "name": "Centre de santÚ Rahma 2", "lat": 33.540585, "lon": -7.71584 },
  { "name": "CS BIADA", "lat": 32.3065253, "lon": -9.2364705 },
  {
    "name": "POINT DE DEBARQUEMENT AMENAGE DE TARGHA",
    "lat": 35.39106810327567,
    "lon": -5.007577001327506
  },
  { "name": "Ministre de la sante /217879/ ArriÞre", "lat": 0, "lon": 0 },
  { "name": "44929CTM AVANT", "lat": 0, "lon": 0 },
  {
    "name": "CHP DRIOUCH B",
    "lat": 34.99267425729894,
    "lon": -3.3743501045074464
  },
  {
    "name": "CHP DRIOUCH B",
    "lat": 34.989413349157765,
    "lon": -3.3711207248535158
  },
  {
    "name": "centre medical okba bnou nafii -2",
    "lat": 34.247532,
    "lon": -6.550684
  },
  { "name": "C/S HASSAN II A", "lat": 32.680588, "lon": -4.736956 },
  { "name": "184679MAROC/SANTE/ARRIERE", "lat": 0, "lon": 0 },
  {
    "name": "CSR2 BZOU",
    "lat": 32.083261173684605,
    "lon": -7.0476886987114025
  },
  { "name": "71993-D-6 /CTM AVANT", "lat": 0, "lon": 0 },
  { "name": "44928CTM AVANT", "lat": 0, "lon": 0 },
  { "name": "44929CTM ARRIERE", "lat": 0, "lon": 0 },
  {
    "name": "H¶pital Princesse Lalla Hasna test spoutnik",
    "lat": 32.23588632101335,
    "lon": -8.511010797622678
  },
  {
    "name": "CHP DRIOUCH A",
    "lat": 34.994335424706264,
    "lon": -3.3719790317382814
  },
  {
    "name": "Centre de santÚ Ibn mouhajir al jadida",
    "lat": 33.251243,
    "lon": -8.510004
  },
  { "name": "Ministre de la sante /217879/ Avant", "lat": 0, "lon": 0 },
  {
    "name": "Centre de santÚ ouled Ghadbane",
    "lat": 33.17514,
    "lon": -8.603624
  },
  { "name": "44928CTM ARRIERE", "lat": 0, "lon": 0 },
  { "name": "33432LOGIPHAR AVANT ", "lat": 0, "lon": 0 },
  { "name": "71992CTM AVANT ", "lat": 0, "lon": 0 },
  {
    "name": "Hopital DARRAK -4",
    "lat": 30.32422941069668,
    "lon": -5.838060193778998
  },
  {
    "name": "Hopital DARRAK Armoire frigorifique E",
    "lat": 30.32423867163566,
    "lon": -5.838344507934576
  },
  { "name": "CSR1 ZARKAT", "lat": 34.91029, "lon": -4.30753 },
  {
    "name": "POINT DE DEBARQUEMENT AMENAGE DE TARGHA",
    "lat": 35.38908265937458,
    "lon": -5.0057209126892
  },
  {
    "name": "CHP DRIOUCH A",
    "lat": 34.991030635201106,
    "lon": -3.3698332645263673
  },
  {
    "name": "Hopital Ibn Rochd (PHARMACIE) AR",
    "lat": 33.5787849,
    "lon": -7.6204138
  },
  {
    "name": "Hopital DARRAK Armoire frigorifique E",
    "lat": 30.324303498184058,
    "lon": -5.83875756812287
  },
  { "name": "71992CTM ARRIERE", "lat": 0, "lon": 0 },
  {
    "name": "H¶pital militaire Mohamed 5 A1",
    "lat": 33.97781,
    "lon": -6.890417
  },
  { "name": "41905CTM", "lat": 0, "lon": 0 },
  { "name": "CHP DRIOUCH A", "lat": 34.992226, "lon": -3.373009 },
  { "name": "CSU 2 ECHEMMAIA", "lat": 32.091513, "lon": -8.634014 },
  {
    "name": "CHP DRIOUCH B",
    "lat": 34.9926830466332,
    "lon": -3.377686772521973
  },
  { "name": "C/S DIOUR JAMAA", "lat": 34.015671, "lon": -6.848989 },
  { "name": "CENTRE DE SANTE NOUR", "lat": 33.996254, "lon": -6.867464 },
  { "name": "71993-D-6 /CTM ARRIERE", "lat": 0, "lon": 0 },
  { "name": "BMH TEMARA MASSIRA 1", "lat": 33.931397, "lon": -6.898159 },
  { "name": "CHR BENI MELLAL A1", "lat": 32.321772, "lon": -6.353659 },
  { "name": "CHR BENI MELLAL B2", "lat": 32.329191, "lon": -6.352572 },
  { "name": "csu 2 ait daoud armoire A1", "lat": 31.07425, "lon": -9.359608 },
  { "name": "931-02/SNTL AVANT", "lat": 0, "lon": 0 },
  { "name": "CENTRE DE SANTE HAY ALMATAR", "lat": 33.231098, "lon": -8.525569 },
  { "name": "DR TALZEMT", "lat": 33.5999603, "lon": -4.1927538 },
  { "name": "ECOLE EL BAKRI", "lat": 33.599312, "lon": -7.630887 },
  { "name": "CS COMMUNAL TAZNAKHTE", "lat": 30.578161, "lon": -7.202746 },
  {
    "name": "HOPITAL LOCAL ZOUBAIR SKIREJ",
    "lat": 34.681606,
    "lon": -6.003903
  },
  { "name": "750-02/SNTL/AVANT ", "lat": 0, "lon": 0 },
  { "name": "Maison des jeunes Al amal", "lat": 33.976695, "lon": -6.886017 },
  {
    "name": "Centre de santÚ urbain Azemour",
    "lat": 33.288925,
    "lon": -8.343374
  },
  { "name": "CS communal boukhlal", "lat": 30.387966, "lon": -5.791168 },
  { "name": "csc tamanar Armoire A1", "lat": 30.980024, "lon": -9.6977 },
  { "name": "68502 CTM - avant", "lat": 0, "lon": 0 },
  { "name": "SNTL 1104-02 - avant", "lat": 0, "lon": 0 },
  { "name": "centre de sante al wahda", "lat": 33.987696, "lon": -6.802357 },
  { "name": "CS communal boukhlal", "lat": 30.387967, "lon": -5.791168 },
  { "name": "820-02/SNTL ARRIERE", "lat": 0, "lon": 0 },
  { "name": "SNTL 1104-02 - arrirere", "lat": 0, "lon": 0 },
  { "name": "csu iziki", "lat": 31.62545, "lon": -8.066759 },
  { "name": "CHR BENI MELLAL A2", "lat": 32.322772, "lon": -6.353659 },
  {
    "name": "HOPITAL LOCAL ZOUBAIR SKIREJ",
    "lat": 34.682606,
    "lon": -6.003903
  },
  { "name": "CS communal boukhlal", "lat": 30.387967, "lon": -5.791168 },
  { "name": "CS HAD OULAD ISSA", "lat": 32.952728, "lon": -8.699452 },
  { "name": "SNTL 913-02 Avant", "lat": 0, "lon": 0 },
  { "name": "750-02/SNTL/ARRIERE", "lat": 0, "lon": 0 },
  { "name": "820-02/SNTL AVANT", "lat": 0, "lon": 0 },
  {
    "name": "SNTL 913-02 Arriere",
    "lat": 33.658638541692405,
    "lon": -7.4095916748046875
  },
  {
    "name": "SNTL- 1107-02 ArriÞre",
    "lat": 33.660067360928736,
    "lon": -7.410621643066406
  },
  { "name": "salle couverte hay karima", "lat": 34.054542, "lon": -6.796094 },
  { "name": "CHR BENI MELLAL B1", "lat": 32.328191, "lon": -6.352572 },
  { "name": "931-02/SNTL ARRIERE", "lat": 0, "lon": 0 },
  { "name": "89002-D-6 / LOGIPHAR", "lat": 0, "lon": 0 },
  { "name": "CS COMMUNAL TAZNAKHTE", "lat": 30.579161, "lon": -7.202746 },
  {
    "name": "centre de santÚ KENZ TAMESNA",
    "lat": 33.823449,
    "lon": -6.9393479
  },
  { "name": "68502 CTM - Arriere", "lat": 0, "lon": 0 },
  { "name": "SNTL- 1107-02 Avant", "lat": 0, "lon": 0 },
  { "name": "CS COMMUNAL AGUELMOUSS", "lat": 33.09193, "lon": -5.50384 },
  {
    "name": "DEPOT REGIONAL DES MEDICAMENTS",
    "lat": 34.706856,
    "lon": -1.883316
  },
  {
    "name": "CS communal avec maison d'accouchement TAMEGROUTE Armoire frigorifique A",
    "lat": 30.260853149419752,
    "lon": -5.686916626983636
  },
  {
    "name": "Centre d'isolement covid-19",
    "lat": 34.79616349350952,
    "lon": -5.567462394477467
  },
  { "name": "cs talsint", "lat": 32.535966, "lon": -3.442096 },
  { "name": "CSU GHAR NHAL", "lat": 32.343915, "lon": -6.348829 },
  { "name": "Camion BSLS -MILITAIRE CASA- arriere", "lat": 0, "lon": 0 },
  {
    "name": "DEPOT REGIONAL DES MEDICAMENTS",
    "lat": 34.705856,
    "lon": -1.883316
  },
  { "name": "CS AGOUDIM RÚf 1", "lat": 32.383259, "lon": -5.178341 },
  { "name": "HOPITAL SIDI BENNOUR", "lat": 32.652367, "lon": -8.413468 },
  {
    "name": "CS Tagonit -3",
    "lat": 29.973562674859945,
    "lon": -5.581450652778625
  },
  { "name": "csu-1 bab taghzout", "lat": 31.638051, "lon": -7.990668 },
  { "name": "csu kasabah", "lat": 31.615913, "lon": -7.98788 },
  {
    "name": "CS communal avec maison d'accouchement ALNIF Armoire frigorifique B",
    "lat": 31.116066,
    "lon": -5.168663
  },
  { "name": "Dispensaire rural AIT ABDI", "lat": 32.091808, "lon": -5.747284 },
  {
    "name": "CS Tagonit Armoire frigorifique A-1",
    "lat": 29.973513881774096,
    "lon": -5.581392985284805
  },
  { "name": "DÚp¶t SRESS", "lat": 34.925194, "lon": -2.326118 },
  {
    "name": "DEPOT REGIONAL DES MEDICAMENTS",
    "lat": 34.701856,
    "lon": -1.883316
  },
  {
    "name": "hopital lalla hasna ARMOIRE FRIGORIFIQUE B1",
    "lat": 32.25028,
    "lon": -8.522847
  },
  {
    "name": "CS communal avec maison d'accouchement TAMEGROUTE Armoire frigorifique A",
    "lat": 30.260869366538113,
    "lon": -5.686943449073785
  },
  { "name": "csr ait lhaj", "lat": 31.444237, "lon": -9.440317 },
  { "name": "HOPITAL SIDI BENNOUR", "lat": 32.652367, "lon": -8.413468 },
  { "name": "Camion BSLS -MILITAIRE CASA- avant", "lat": 0, "lon": 0 },
  { "name": "CS AIT ISSHAK", "lat": 32.763961, "lon": -5.724392 },
  {
    "name": "unitee des soins essentiels et maison d'accouchement",
    "lat": 34.122456,
    "lon": -6.740869
  },
  { "name": "DELEGATION GUERCIF", "lat": 34.24255, "lon": -3.33788 },
  { "name": "bab khmiss", "lat": 34.0333315, "lon": -6.8181851 },
  { "name": "cmp temara", "lat": 33.925112, "lon": -6.912259 },
  { "name": "CSU 1 20 AOUT BEJAAD", "lat": 32.771333, "lon": -6.391012 },
  {
    "name": "delegation provinciale rhamna",
    "lat": 32.24099,
    "lon": -7.961294
  },
  {
    "name": "Centre hospitalier PrÚfectoral BEN M'SICK",
    "lat": 33.55233,
    "lon": -7.578226
  },
  { "name": "DR 20 AOUT", "lat": 28.432457, "lon": -11.115568 },
  { "name": "cmp temara CF2", "lat": 33.925159, "lon": -6.911759 },
  { "name": "csr-2 ourika", "lat": 31.374073, "lon": -7.77943 },
  { "name": "csu al harchi", "lat": 35.24721145, "lon": -3.932253 },
  { "name": "cmp temara CF2", "lat": 33.925155, "lon": -6.910758 },
  { "name": "cmp temara", "lat": 33.928152, "lon": -6.910759 },
  { "name": "cmp temara", "lat": 33.925852, "lon": -6.9107511 },
  { "name": "CHP KHENIFRA", "lat": 32.961544, "lon": -5.662159 },
  { "name": "CSR-2 TOUAMA", "lat": 31.531772, "lon": -7.485671 },
  { "name": "CHP KHENIFRA", "lat": 32.967544, "lon": -5.662159 },
  { "name": "CSR-1 SOKHOUR RHAMNA", "lat": 32.47986, "lon": -7.911946 },
  { "name": "CSU1 OULED HAMDAN", "lat": 32.32337593, "lon": -6.371611 },
  { "name": "cmp temara CF2", "lat": 33.925152, "lon": -6.910759 },
  { "name": "cmp temara CF2", "lat": 33.925159, "lon": -6.910751 },
  { "name": "CSR-1 SOKHOUR RHAMNA", "lat": 32.47933, "lon": -7.913834 },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments",
    "lat": 30.421113244704436,
    "lon": -9.592885842922206
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments",
    "lat": 30.421194197627816,
    "lon": -9.592633715274806
  },
  {
    "name": "delegation provinciale rhamna",
    "lat": 32.241582,
    "lon": -7.961364
  },
  { "name": "C/S LAZARET", "lat": 34.681634, "lon": -1.883656 },
  { "name": "centre de sante souani", "lat": 34.053187, "lon": -6.814281 },
  { "name": "CSU EL MENZEL", "lat": 33.8352612, "lon": -4.545891 },
  { "name": "BAB KHMISS", "lat": 34.0333315, "lon": -6.8181851 },
  { "name": "centre de sante takaddoum", "lat": 33.984217, "lon": -6.824677 },
  { "name": "CSU PEPINIERE NIVEAU 1", "lat": 34.0381088, "lon": -6.813495 },
  { "name": "CSU PEPINIERE NIVEAU 1", "lat": 34.038467, "lon": -6.813586 },
  { "name": "CHP KHENIFRA", "lat": 32.962544, "lon": -5.662159 },
  { "name": "cmp temara", "lat": 33.928052, "lon": -6.955759 },
  { "name": "csc zemran lgharbia", "lat": 31.732013, "lon": -7.49697 },
  { "name": "delegation tahannaout", "lat": 31.360443, "lon": -7.934314 },
  { "name": "DISPENSAIRE RURAL IJETTI", "lat": 35.184303, "lon": -3.602754 },
  { "name": "delegation tahannaout", "lat": 31.360445, "lon": -7.934315 },
  {
    "name": "labo EPIDEMIO CHAMBRE FROIDE A1",
    "lat": 32.281867,
    "lon": -9.228992
  },
  { "name": "CAMION -MILITAIRE CASA- 606887", "lat": 0, "lon": 0 },
  { "name": "DISPENSAIRE RURAL IZRAY", "lat": 35.10376, "lon": -3.792097 },
  { "name": "Commune eddachra", "lat": 32.226882, "lon": -7.248535 },
  { "name": "CSR 1 FOUM EL ANSEUR", "lat": 32.371338, "lon": -6.25932 },
  { "name": "csu-1 tahannaout", "lat": 31.34886, "lon": -7.951658 },
  {
    "name": "labo EPIDEMIO CHAMBRE FROIDE A1",
    "lat": 32.281865,
    "lon": -9.22899
  },
  { "name": "CS COMMUNAL SIDI JABER", "lat": 32.370349, "lon": -6.455936 },
  { "name": "CAMION -MILITAIRE CASA- 606887", "lat": 0, "lon": 0 },
  {
    "name": "labo EPIDEMIO CHAMBRE FROIDE A1",
    "lat": 32.281869,
    "lon": -9.228994
  },
  {
    "name": "centre de sante moulay ismail",
    "lat": 34.028316,
    "lon": -6.782709
  },
  {
    "name": "Hopital DARRAK Chambre froide A",
    "lat": 30.325158456411643,
    "lon": -5.837985076057435
  },
  { "name": "HOPITAL LOCAL DEL KSIBA", "lat": 32.565969, "lon": -6.022424 },
  {
    "name": "HOPITAL DE PROXIMITE ZAIO CH FROID B",
    "lat": 34.945161,
    "lon": -2.745968
  },
  {
    "name": "HOPITAL DE PROXIMITE ZAIO CH FROID B",
    "lat": 34.943161,
    "lon": -2.742968
  },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.93036281444899,
    "lon": -4.429855512897483
  },
  {
    "name": "centre de sante moulay ismail",
    "lat": 34.027752,
    "lon": -6.7102885
  },
  { "name": "HOPITAL DE PROXIMITE ZAIO", "lat": 34.943161, "lon": -2.744968 },
  {
    "name": "centre de santÚ rurale niveau 1 SIDI M'HAMED AKHDIM",
    "lat": 32.7439745,
    "lon": -8.7261563
  },
  { "name": "CF3-CHP RHAMNA", "lat": 32.241849, "lon": -7.959036 },
  { "name": "CSU2 TLET LOULAD", "lat": 32.991329, "lon": -7.126285 },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.930417447266663,
    "lon": -4.429903792659751
  },
  { "name": "centre medical ouled zaers", "lat": 33.801422, "lon": -6.786075 },
  {
    "name": "unitÚe des soins essentiels et maison d'accouchement",
    "lat": 34.1207455,
    "lon": -6.7341791
  },
  {
    "name": "CS urbain ZAGOURA Armoire frigorifique A-1",
    "lat": 30.324965054347853,
    "lon": -5.837772673610684
  },
  { "name": "PP AIN AICHA DE TAOUNAT ", "lat": 34.461835, "lon": -4.690285 },
  { "name": "HOPITAL DE PROXIMITE ZAIO", "lat": 34.946161, "lon": -2.745968 },
  {
    "name": "instutitu nationale d'oncologie rabat",
    "lat": 33.972889,
    "lon": -6.8715385
  },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.930572240073786,
    "lon": -4.429997669975272
  },
  {
    "name": "HOPITAL DE PROXIMITE ZAIO CH FROID C",
    "lat": 34.944161,
    "lon": -2.745968
  },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.930444763663314,
    "lon": -4.429941343585959
  },
  {
    "name": "HOPITAL DE PROXIMITE ZAIO CH FROID B",
    "lat": 34.941161,
    "lon": -2.745968
  },
  { "name": "cs ambaj", "lat": 32.129246, "lon": -2.822923 },
  { "name": "centre el kouass", "lat": 33.984392, "lon": -6.878254 },
  { "name": "centre medical ouled zaers", "lat": 33.801422, "lon": -6.786076 },
  { "name": "centre de sante el fath", "lat": 33.986029, "lon": -6.88793 },
  {
    "name": "HOPITAL MILITAIRE D'INSTRUCTION MOHAMMED V - 1",
    "lat": 33.97107046030899,
    "lon": -6.86903025731421
  },
  {
    "name": "Hopital DARRAK Chambre froide A",
    "lat": 30.325089,
    "lon": -5.838087
  },
  { "name": "PP AIN AICHA DE TAOUNAT ", "lat": 34.4608312, "lon": -4.690276 },
  {
    "name": "H¶pital Iamzorn RÚfrigÚrateur 3",
    "lat": 35.152034557722736,
    "lon": -3.8572064778677717
  },
  { "name": "CS Tisrawlin", "lat": 32.23814, "lon": -5.240141 },
  { "name": "CSU BOUANANE", "lat": 32.037144, "lon": -3.04904 },
  { "name": "CSR Aghran Kadi", "lat": 35.0187911, "lon": -5.32193517 },
  { "name": "CF3-CHP RHAMNA", "lat": 32.24162, "lon": -7.958879 },
  { "name": "centre de sante el amal", "lat": 33.979439, "lon": -6.883728 },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.930556305532132,
    "lon": -4.429584609786978
  },
  { "name": "centre medical Tamesna", "lat": 33.833641, "lon": -6.914522 },
  {
    "name": "H¶pital Iamzorn RÚfrigÚrateur 2",
    "lat": 35.15203675076969,
    "lon": -3.8572500637642637
  },
  {
    "name": "instutitu nationale d'oncologie rabat",
    "lat": 33.972889,
    "lon": -6.8715385
  },
  {
    "name": "HOPITAL DE PROXIMITE ZAIO CH FROID C",
    "lat": 34.943261,
    "lon": -2.745968
  },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.93068605814806,
    "lon": -4.429651665012351
  },
  { "name": "centre medical Tamesna", "lat": 33.833643, "lon": -6.914523 },
  { "name": "DÚlÚgation Errachidia", "lat": 31.951722, "lon": -4.639878 },
  {
    "name": "CS urbain ZAGOURA Armoire frigorifique A-1",
    "lat": 30.324972,
    "lon": -5.837829
  },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.930671261806324,
    "lon": -4.429809915344229
  },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.93044704002935,
    "lon": -4.430021809856406
  },
  {
    "name": "Hopital DARRAK Chambre froide A",
    "lat": 30.32476486942824,
    "lon": -5.8383659497375495
  },
  { "name": "centre medical ouled zaers", "lat": 33.801422, "lon": -6.786075 },
  { "name": "HOPITAL DE PROXIMITE ZAIO", "lat": 34.943161, "lon": -2.743968 },
  {
    "name": "societe rabat animation et developpement",
    "lat": 33.974742,
    "lon": -6.860658
  },
  { "name": "HOPITAL DE PROXIMITE ZAIO", "lat": 34.942161, "lon": -2.745968 },
  {
    "name": "centre de sante moulay bouchaib",
    "lat": 33.278637,
    "lon": -8.343605
  },
  { "name": "centre medical Tamesna", "lat": 33.833941, "lon": -6.914722 },
  {
    "name": "HOPITAL DE PROXIMITE ZAIO CH FROID B",
    "lat": 34.943161,
    "lon": -2.745968
  },
  { "name": "centre de sante ben ahmed", "lat": 33.068813, "lon": -7.246043 },
  {
    "name": "HOPITAL MILITAIRE D'INSTRUCTION MOHAMMED V - 1",
    "lat": 33.9711608268797,
    "lon": -6.86896085515595
  },
  { "name": "201971WW/ ARRIERE", "lat": 0, "lon": 0 },
  {
    "name": "Vaccinodromes dar taliban a la commune territoriale de tarmigte",
    "lat": 212,
    "lon": -6.916644
  },
  { "name": "MIHUMI", "lat": 33.950324829631974, "lon": -6.836242462890625 },
  {
    "name": "Capteur back up Super CongÚlateur 1 ",
    "lat": 33.55296223481728,
    "lon": -7.556529809786983
  },
  { "name": "CSR2 ANERGUI", "lat": 32.06622, "lon": -5.93709 },
  { "name": "cs talsint", "lat": 32.535114, "lon": -3.442398 },
  { "name": "csr saada", "lat": 31.629924, "lon": -8.111129 },
  { "name": "CS BNITADJIT REF-2", "lat": 32.296192, "lon": -3.463562 },
  { "name": "chp temara", "lat": 33.918548583984375, "lon": -6.92622 },
  {
    "name": "HOPITAL LOCAL ZOUBAIR SKIREJ",
    "lat": 34.682606,
    "lon": -6.003903
  },
  { "name": "Cs sraghna", "lat": 31.6784408, "lon": -8.0155249 },
  { "name": "Laboratoire CHP", "lat": 32.502134, "lon": -6.684354 },
  {
    "name": "C.S Sidi Hmida",
    "lat": 33.96744918823242,
    "lon": -6.745730400085449
  },
  {
    "name": "club socio educatif derb ghalef",
    "lat": 33.574817,
    "lon": -7.6298962
  },
  { "name": "centre de sante DR FARAJ", "lat": 33.5594983, "lon": -7.6239586 },
  {
    "name": "Centre santÚ arrahma",
    "lat": 34.00221252441406,
    "lon": -6.863555908203125
  },
  {
    "name": "centre de vaccination covid-19 hay salam",
    "lat": 34.67362594604492,
    "lon": -5.989727020263672
  },
  { "name": "cs zenaga ", "lat": 32.0963508, "lon": -1.2353332 },
  { "name": "╔cole el bakri", "lat": 33.59935, "lon": -7.630841 },
  { "name": "14309-D-8 arriere REATRANSPHARMA", "lat": 0, "lon": 0 },
  { "name": "14311-D-8 avant REATRANPHARMA", "lat": 0, "lon": 0 },
  { "name": "DR SREMETE", "lat": 31.7639193, "lon": -6.7419882 },
  { "name": "65362W6/LOGIPHAR avant", "lat": 0, "lon": 0 },
  {
    "name": "Centre mirador Protection civile ain diab",
    "lat": 33.5875862,
    "lon": -7.6861903
  },
  { "name": "CS urbain-2 AGDEZ -1", "lat": 30.698543, "lon": -6.447126 },
  { "name": "H¶pital midelt Armoire frigorifique F", "lat": 0, "lon": 0 },
  { "name": "14311-D-8 ArriÞre REATRANPHARMA", "lat": 0, "lon": 0 },
  { "name": "CSU SIDI AMARA", "lat": 31.607528, "lon": -7.988266 },
  { "name": "DR iligh", "lat": 29.9594155, "lon": -7.8603941 },
  {
    "name": "centre santÚ sidi hmida salÚ",
    "lat": 33.96744918823242,
    "lon": -6.745730400085449
  },
  {
    "name": "Centre de santÚ Lissasfa AR 4 (G-1)",
    "lat": 33.53494936242746,
    "lon": -7.669300198552258
  },
  { "name": "65362W6/LOGIPHAR ARRIERE", "lat": 0, "lon": 0 },
  { "name": "CS CENTRE 44", "lat": 31.955762, "lon": -8.207543 },
  {
    "name": "centre d'addictologie KÚnitra AR3(1)",
    "lat": 34.245635986328125,
    "lon": -6.580725193023682
  },
  { "name": "cs village toba 2", "lat": 32.5361182, "lon": -1.9659591 },
  { "name": "REATRANSPHARMA / 14308 / ArriÞre", "lat": 0, "lon": 0 },
  { "name": "14309-D-8 avant  REATRANSPHARMA", "lat": 0, "lon": 0 },
  {
    "name": "Centre de santÚ urbain niveau 1 El ghazoua",
    "lat": 33.2299776,
    "lon": -8.5070584
  },
  {
    "name": "Csu Al Matar RÚfrigÚrateur 1",
    "lat": 32.2659361,
    "lon": -9.2414904
  },
  { "name": "Úcole raid oulfa", "lat": 33.552105, "lon": -7.700499 },
  {
    "name": "C.S Sidi Hmida",
    "lat": 33.96744918823242,
    "lon": -6.745730400085449
  },
  {
    "name": "congÚlateur N░1 BSLS nouacer",
    "lat": 33.3981773,
    "lon": -7.5713205
  },
  { "name": "Dr KRAZZA", "lat": 32.246784, "lon": -6.571195 },
  {
    "name": "CHR BENI MELLAL CH FROID A-1",
    "lat": 32.327732,
    "lon": -6.353681
  },
  { "name": "Super congÚlateur N░12", "lat": 33.552962, "lon": -7.55653 },
  { "name": "Centre santÚ medina", "lat": 32.2993551, "lon": -9.2386536 },
  {
    "name": "centre de sante urbain tabriquet",
    "lat": 34.0458363,
    "lon": -6.7973345
  },
  { "name": "C/S SIDI ABED", "lat": 34.745276, "lon": -5.320563 },
  {
    "name": "DÚp¶t rÚgionale des mÚdicaments rabat AR 2",
    "lat": 33.9794327,
    "lon": -6.8204806
  },
  {
    "name": "centre de sante rural niveau 2 alarjat",
    "lat": 34.0271422,
    "lon": -6.6489584
  },
  {
    "name": "Delegaition hay hassani",
    "lat": 33.57101821899414,
    "lon": -7.6793413162231445
  },
  {
    "name": "Hopital Ibn Rochd (PHARMACIE) AR",
    "lat": 33.5787849,
    "lon": -7.6204138
  },
  {
    "name": "congÚlateur N░2 BSLS nouacer",
    "lat": 33.3981773,
    "lon": -7.5713205
  },
  { "name": "CSR ameur", "lat": 34.0778694152832, "lon": -6.751908302307129 },
  { "name": "cs talsint", "lat": 32.5409308, "lon": -3.4364665 },
  { "name": "Super congÚlateur N░14", "lat": 33.552962, "lon": -7.55653 },
  { "name": "Super congÚlateur N░15", "lat": 33.552962, "lon": -7.55653 },
  {
    "name": "Capteur back up Super CongÚlateur  3",
    "lat": 33.55296223481728,
    "lon": -7.556529809786983
  },
  { "name": "C/S RAFIA", "lat": 32.157833, "lon": -7.295501 },
  { "name": "Centre de Sante Ameur", "lat": 34.0789278, "lon": -6.7515738 },
  {
    "name": "Dakhilia interna sidi redouane",
    "lat": -5.452219,
    "lon": 34.687672
  },
  {
    "name": "DÚp¶t rÚgionale des mÚdicaments rabat AR 1",
    "lat": 33.9794327,
    "lon": -6.8204806
  },
  {
    "name": "DÚp¶t rÚgionale des mÚdicaments rabat AR 1",
    "lat": 33.9794327,
    "lon": -6.8204806
  },
  { "name": "Super congÚlateur N░10", "lat": 33.552962, "lon": -7.55653 },
  { "name": "CSR ZARKAT", "lat": 34.91029, "lon": -4.30753 },
  {
    "name": "Centre Formation Ameur Pour la Qualification et l'insertion des jeunes Ameur",
    "lat": 34.0750191,
    "lon": -6.7659614
  },
  { "name": "Super congÚlateur N░11", "lat": 33.552962, "lon": -7.55653 },
  { "name": "H¶pital hassan 2\r\n", "lat": 32.994399, "lon": -7.611621 },
  { "name": "DEVICE-7012", "lat": 0, "lon": 0 },
  { "name": "DEVICE-7010", "lat": 0, "lon": 0 },
  { "name": "CS gzoula", "lat": 32.13549353672379, "lon": -9.083804492065436 },
  { "name": "CS gzoula", "lat": 32.134476, "lon": -9.083547 },
  { "name": "CS gzoula", "lat": 32.114567, "lon": -9.079227 },
  { "name": "csc laakarta (souk ayir)", "lat": 32.673981, "lon": -9.097961 },
  { "name": "CSU Akka", "lat": 29.388101969268163, "lon": -8.258031253967276 },
  { "name": "AMALOU 2", "lat": 32.942146, "lon": -5.657134 },
  {
    "name": "Logiphar 65322-E-6/ ARRIERE ",
    "lat": -0.45702613522013025,
    "lon": -0.34300868946567586
  },
  { "name": "CS communal SIDI ALI NOUITI", "lat": 32.548946, "lon": -7.500765 },
  {
    "name": "PP Tetouane Armoire B",
    "lat": 35.57371072864484,
    "lon": -5.354720787119873
  },
  {
    "name": "PP Tetouane Armoire B",
    "lat": 35.573709637833666,
    "lon": -5.354730174851425
  },
  { "name": "EL Ouafae", "lat": 33.57532151808062, "lon": -7.554108021349694 },
  {
    "name": "DÚlÚgation de la santÚ de settat",
    "lat": 33.00490983474699,
    "lon": -7.611364561748551
  },
  { "name": "CS said hajji", "lat": 34.067551, "lon": -6.804666 },
  {
    "name": "DÚlÚgation de la santÚ de settat 2",
    "lat": 33.00496291981716,
    "lon": -7.611383904623993
  },
  {
    "name": "csr sidi kaouki",
    "lat": 31.406881297481846,
    "lon": -9.71595873909781
  },
  { "name": "CSU Akka", "lat": 29.388331, "lon": -8.25816 },
  {
    "name": "centre sidi el ghandour",
    "lat": 33.8014663897623,
    "lon": -5.936025913672427
  },
  { "name": "CSR  AGHBAL", "lat": 34.938622, "lon": -2.132166 },
  { "name": "CSR Azla", "lat": 35.55324, "lon": -5.24752473 },
  {
    "name": "CS LEBKHATA",
    "lat": 34.208785498069126,
    "lon": -2.379538707300926
  },
  {
    "name": "CS Tagherout",
    "lat": 30.75835871256449,
    "lon": -6.4569395344143174
  },
  {
    "name": "H¶pital hassan 2\r\n",
    "lat": 32.9930863823576,
    "lon": -7.611873110721445
  },
  { "name": "CSU 1 CHOUIBIR", "lat": 34.244603, "lon": -3.337816 },
  { "name": "CSU 1 CHOUIBIR", "lat": 34.245603, "lon": -3.337816 },
  { "name": "CSR Tioute", "lat": 30.400797, "lon": -8.688769 },
  {
    "name": "CS BEN OUZIN",
    "lat": 31.985301998690776,
    "lon": -4.418376975759315
  },
  {
    "name": "CSR2 ELBARRADIA",
    "lat": 32.43885830959703,
    "lon": -6.51711027529007
  },
  {
    "name": "INRA SETTAT 2",
    "lat": 32.95460691004583,
    "lon": -7.625396231899466
  },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.56064564679973,
    "lon": -5.401771238379593
  },
  {
    "name": "H¶pital hassan 2\r\n",
    "lat": 32.99654810063127,
    "lon": -7.611755009975761
  },
  { "name": "GSPR 48728 A 10 / ARRIERE", "lat": 0, "lon": 0 },
  {
    "name": "DISPENSAIRE RURAL TADDART",
    "lat": 31.357528932864632,
    "lon": -7.391867637634278
  },
  { "name": "dr jabryÚne", "lat": 34.883235931, "lon": -5.5402836799 },
  {
    "name": "DEPOT PROVINCIAL COVID-19 BERKANE",
    "lat": 34.924332,
    "lon": -2.327164
  },
  {
    "name": "DEPOT PROVINCIAL COVID-19 BERKANE",
    "lat": 34.924622,
    "lon": -2.321011
  },
  {
    "name": "Centre de SantÚ Communal Tazarine -1",
    "lat": 30.78161560865078,
    "lon": -5.566415533729558
  },
  { "name": "CSR 1 MELG EL OUIDANE", "lat": 34.559899, "lon": -3.026581 },
  {
    "name": "Centre de SantÚ Rural Oulmes ",
    "lat": 33.42995840070876,
    "lon": -6.004654721565248
  },
  {
    "name": "Centre de SantÚ Rural Oulmes ",
    "lat": 33.43051801493842,
    "lon": -6.004708365745546
  },
  { "name": "Centre de SantÚ Rural Oulmes", "lat": 33.430424, "lon": -6.00407 },
  { "name": "CS sidi maarouf", "lat": 33.5634465, "lon": -7.6085991 },
  { "name": "CS SalÚ el Jadida", "lat": 34.05392, "lon": -6.788307 },
  {
    "name": "CS  SALA ALJADIDA",
    "lat": 33.99518087394024,
    "lon": -6.734442741248255
  },
  { "name": "CSR Beni Mathar", "lat": 34.00413513, "lon": -2.029308795 },
  {
    "name": "DR AIN ZEBDA",
    "lat": 35.06261513521804,
    "lon": -2.352751795129931
  },
  { "name": "CS OUMKALTOUM", "lat": 33.6041173, "lon": -7.5044347 },
  {
    "name": "CSR RAS ASFOUR ",
    "lat": 34.551579394051515,
    "lon": -1.8515005956592414
  },
  {
    "name": "CS GUEFAIT",
    "lat": 34.24466126871391,
    "lon": -2.3859158481032128
  },
  {
    "name": "CSR Tazemourte",
    "lat": 30.476683665386417,
    "lon": -8.84770584523011
  },
  {
    "name": "CSR RISLANE",
    "lat": 34.75908682370076,
    "lon": -2.4297275113154364
  },
  {
    "name": "CSR RISLANE",
    "lat": 34.75939858565587,
    "lon": -2.4290635862750514
  },
  { "name": "CS MRIYJA", "lat": 34.02551374924211, "lon": -2.3836589130960695 },
  {
    "name": "CSR CHOUIHIA ARGANA",
    "lat": 34.87322388470626,
    "lon": -2.61909640639358
  },
  {
    "name": "CSR CHOUIHIA ARGANA",
    "lat": 34.873547376247046,
    "lon": -2.6188247378927447
  },
  { "name": "CS derb al khayr", "lat": 33.542091, "lon": -7.633716 },
  {
    "name": "BSLS Nouaceur Chambre Froide (Reactif 3)\t",
    "lat": 33.380311377496746,
    "lon": -7.563478327666823
  },
  {
    "name": "BSLS Nouaceur Chambre Froide (Reactif 3)\t",
    "lat": 33.366090537121586,
    "lon": -7.569614567341444
  },
  { "name": "H¶pital hassan 2\r\n", "lat": 32.995399, "lon": -7.611621 },
  { "name": "CS Skoura", "lat": 33.5198364, "lon": -4.547245 },
  { "name": "cs maatarka ", "lat": 33.266975, "lon": -2.699009 },
  { "name": "DELEGATION DE SANTE SALE", "lat": 34.036221, "lon": -6.812855 },
  {
    "name": "CS Al Massira ",
    "lat": 34.22826766646368,
    "lon": -3.9866523219538883
  },
  { "name": "CSR 2 Ait Yahya Oualla", "lat": 33.4186134, "lon": -5.2561679 },
  { "name": "DR Ain Lahnach", "lat": 33.791556, "lon": -5.2116185 },
  { "name": "CSR 1 Ain Leuh", "lat": 33.291832, "lon": -5.3404699 },
  { "name": "CSR 2 Tizguite", "lat": 33.565937, "lon": -5.141086 },
  { "name": "CSR 2 BENSMIM", "lat": 33.504364, "lon": -5.188038 },
  { "name": "CSR 2 TIMAHDITE", "lat": 33.2369156, "lon": -5.0641189 },
  {
    "name": "Centre de SantÚ Communal Tazarine-2",
    "lat": 30.78176078262541,
    "lon": -5.566603288360601
  },
  {
    "name": "centre de santÚ et maison d'accouchemet ait hani- 1",
    "lat": 31.775099,
    "lon": -5.456077
  },
  { "name": " CSR 2 Oued IFRANE", "lat": 33.2989688, "lon": -5.4894019 },
  { "name": "Super congÚlateur N░8", "lat": 33.552962, "lon": -7.55653 },
  {
    "name": "INRA SETTAT 3",
    "lat": 32.953786770907925,
    "lon": -7.626203526088222
  },
  {
    "name": "INRA SETTAT 4",
    "lat": 32.953694943661986,
    "lon": -7.626160736118154
  },
  { "name": "CSU AKLIM", "lat": 34.888733, "lon": -2.520319 },
  { "name": "CSU EL QODS", "lat": 34.932594, "lon": -2.32048 },
  {
    "name": "CSR TZAYEST",
    "lat": 34.937970603019686,
    "lon": -2.4275361876151806
  },
  { "name": "CSR FEZOUANE", "lat": 34.917248, "lon": -2.204304 },
  { "name": "CSU AIN REGADDA", "lat": 34.933087, "lon": -2.21429 },
  { "name": "CSR MADAGH", "lat": 35.011974, "lon": -2.326014 },
  { "name": "Centre Social LAATAMNA", "lat": 35.008808, "lon": -2.259172 },
  { "name": "CSU SAIDIA", "lat": 35.077225, "lon": -2.219209 },
  {
    "name": "CSU 2 AIN BENI MATHAR",
    "lat": 34.01493621072177,
    "lon": -2.0244499543879395
  },
  {
    "name": "CS ain beni mathar",
    "lat": 34.014971400769035,
    "lon": -2.0253991892949967
  },
  {
    "name": "PHARMACIE PROVINCIAL AALIA BAYDA",
    "lat": 33.5708913192519,
    "lon": -7.47840530927825
  },
  {
    "name": "CSU 1 HAY EL JADID",
    "lat": 34.30553764941473,
    "lon": -2.1718972564709365
  },
  { "name": "CS HAY NASSIM", "lat": 32.926696, "lon": -5.662589 },
  {
    "name": "H¶pital Mohammed 5 rÚfreg 1",
    "lat": 35.16952831484938,
    "lon": -5.269841682209015
  },
  {
    "name": "CSU Irhalen",
    "lat": 30.36727388063615,
    "lon": -9.540832431877137
  },
  { "name": "DR OUANANA", "lat": 34.644104, "lon": -5.362826 },
  {
    "name": "CHP KHENIFRA (chambre nÚgative)",
    "lat": 32.969544,
    "lon": -5.662159
  },
  {
    "name": "CHP KHENIFRA (chambre nÚgative)",
    "lat": 32.969544,
    "lon": -5.662159
  },
  {
    "name": "CHP KHENIFRA (chambre nÚgative)",
    "lat": 32.969544,
    "lon": -5.662159
  },
  {
    "name": "CHP KHENIFRA (chambre nÚgative)",
    "lat": 32.969544,
    "lon": -5.662159
  },
  {
    "name": "DR Gharouzum R2",
    "lat": 35.15449754519457,
    "lon": -5.2756226493202085
  },
  {
    "name": "CSU 1 El Haouta RÚfrigÚrateur 4",
    "lat": 35.16910545892025,
    "lon": -5.262733895394995
  },
  { "name": "DR Zaouia", "lat": 35.1616821, "lon": -5.504873275 },
  {
    "name": "Centre de SantÚ Communal Tazarine-2",
    "lat": 30.780925721202035,
    "lon": -5.566935539245606
  },
  { "name": "csr-1 ahmed chefquaoui", "lat": 31.312398, "lon": -7.743838 },
  { "name": "csu-1 tahannaout", "lat": 31.348856, "lon": -7.951654 },
  { "name": "cs bnitadjit", "lat": 32.2948703, "lon": -3.4673376 },
  {
    "name": "CS Imouzer ",
    "lat": 30.420270290372798,
    "lon": -9.592754160713188
  },
  {
    "name": "CS Bab Berred",
    "lat": 34.994389731846844,
    "lon": -4.889917011674109
  },
  {
    "name": "CSR El Guerdane",
    "lat": 30.371536811153945,
    "lon": -9.137641769346232
  },
  { "name": "CS BENI CHIKER", "lat": 35.276638, "lon": -3.014133 },
  { "name": "CENTRE MEDICAL BIR RAMI", "lat": 34.219223, "lon": -6.6316953 },
  { "name": "C/S BNI OUAL", "lat": 34.6815227, "lon": -5.6430602 },
  {
    "name": "CSR 2 Sahel RÚfrigirateur 2",
    "lat": 35.254254188353514,
    "lon": -6.080363252761273
  },
  { "name": "Camion BSLS -MILITAIRE CASA- 606960", "lat": 0, "lon": 0 },
  { "name": "Camion BSLS -MILITAIRE CASA- 606960 ", "lat": 0, "lon": 0 },
  {
    "name": "CSR Brahim Roudani",
    "lat": 30.472306605686306,
    "lon": -9.159196258930198
  },
  { "name": " CS AL FATH", "lat": 33.661728, "lon": -7.358494 },
  {
    "name": "unitee des soins essentiels et maison d'accouchement",
    "lat": 34.123456,
    "lon": -6.750869
  },
  { "name": "CSR-1 SIDI TIJI", "lat": 32.170209, "lon": -8.862819 },
  { "name": "CS TAALALIN", "lat": 32.939145, "lon": -5.678652 },
  { "name": "SNTL / 1112-02 / avant", "lat": 0, "lon": 0 },
  { "name": "CS SIDI AISSA", "lat": 32.4152689, "lon": -8.8999283 },
  { "name": "csr-1 AKERMOUD", "lat": 31.764272, "lon": -9.534767 },
  { "name": "CSU PEPINIERE NIVEAU 1", "lat": 34.038467, "lon": -6.813586 },
  { "name": "GSPR 48728 A 10/ AVANT", "lat": 0, "lon": 0 },
  { "name": "GSPR 48729A10 /Avant", "lat": 0, "lon": 0 },
  { "name": "GSPR 48729A10 / ARRIERE", "lat": 0, "lon": 0 },
  {
    "name": "Centre de SantÚ El Yassmine",
    "lat": 33.8339199536547,
    "lon": -6.0477609493110505
  },
  { "name": "SALLE DE SPORT COUVERTE EST", "lat": 33.857838, "lon": -5.580223 },
  { "name": "CS NASR 2", "lat": 34.719296, "lon": -1.900959 },
  { "name": "C/S HAY ANDALOUS", "lat": 34.65884, "lon": -1.885386 },
  { "name": "C/S neuf juillet", "lat": 33.5956687, "lon": -7.6129766 },
  { "name": "C/S neuf juillet", "lat": 33.5956687, "lon": -7.6129766 },
  {
    "name": "CSR Kasar Majaz",
    "lat": 35.85020453773521,
    "lon": -5.545339883432394
  },
  { "name": "CSR2 AIT OUQABLI", "lat": 32.349426, "lon": -6.01183 },
  { "name": "PP AIN AICHA DE TAOUNAT ", "lat": 34.460837, "lon": -4.690271 },
  { "name": "PP AIN AICHA DE TAOUNAT ", "lat": 34.462835, "lon": -4.690275 },
  { "name": "PP AIN AICHA DE TAOUNAT ", "lat": 34.460935, "lon": -4.690273 },
  { "name": "CS Brikcha", "lat": 34.96854088837469, "lon": -5.56486836043238 },
  {
    "name": "CSR Indouzal",
    "lat": 30.24674365835965,
    "lon": -8.708232699405677
  },
  {
    "name": "CSR2 AIT BOUGUEMAZ (TABANT )",
    "lat": 31.658279,
    "lon": -6.421606
  },
  { "name": "CSR SIDI MOUSSA LAMHAYA", "lat": 34.541309, "lon": -2.056257 },
  { "name": "CS BOULANOIR", "lat": 34.527672, "lon": -2.059358 },
  { "name": "DR OULAD SLIMANE", "lat": 34.38607, "lon": -3.221451 },
  { "name": "CSR GTETER", "lat": 34.37014, "lon": -3.030864 },
  { "name": "CS GUETTAR", "lat": 34.657556, "lon": -1.949861 },
  {
    "name": "DELEGATION DE KHOURIBGA SRES ARMOIRE 2",
    "lat": 32.8860493,
    "lon": -6.9121523
  },
  {
    "name": "DELEGATION DE KHOURIBGA SRES ARMOIRE 3",
    "lat": 32.8860493,
    "lon": -6.9121523
  },
  {
    "name": "DELEGATION DE KHOURIBGA SRES ARMOIRE 3",
    "lat": 32.8860493,
    "lon": -6.9121523
  },
  {
    "name": "C/S MARBOUH",
    "lat": 32.08457441812832,
    "lon": -7.408470511436463
  },
  { "name": "CSR-1 SIDI TIJI", "lat": 32.162337, "lon": -8.854898 },
  { "name": "CSR MESDOURA", "lat": 34.0437114, "lon": -4.8486199 },
  { "name": "CSU 2 AOULAD AYAD", "lat": 34.075367, "lon": -4.602499 },
  { "name": "CSU Lalla Meriem", "lat": 29.3809597, "lon": -10.1746273 },
  { "name": "CSU 1 TAKADDOUM", "lat": 34.408872, "lon": -2.896012 },
  { "name": "CENTRE DE SANTE SAADA", "lat": 34.6911595, "lon": -1.9155138 },
  { "name": "DR khmiss Ahl Louta", "lat": 33.555095, "lon": -7.274332 },
  {
    "name": "Centre de Sante Ait Ourible",
    "lat": 33.782900000000005,
    "lon": -6.128072974205017
  },
  {
    "name": "CSR Azaghar N'Irs",
    "lat": 30.304569899402388,
    "lon": -8.247714775463109
  },
  { "name": "CSR AHL ANGAD", "lat": 34.754708, "lon": -1.86185 },
  {
    "name": "Csu Jules Cot",
    "lat": 35.783447546351795,
    "lon": -5.81349142889549
  },
  { "name": "CS OULED ABBAS", "lat": 34.659866, "lon": -1.865218 },
  { "name": "csc had hrara", "lat": 32.43961, "lon": -9.134953 },
  {
    "name": "CSR Migrane",
    "lat": 30.27788778569544,
    "lon": -8.767281709157942
  },
  { "name": "csc had hrara", "lat": 32.440506, "lon": -9.133762 },
  { "name": "csc had hrara", "lat": 32.440506, "lon": -9.133762 },
  { "name": "CS EL AOUNIA", "lat": 34.68869, "lon": -1.900613 },
  { "name": "CSR mesti", "lat": 29.2632164, "lon": -10.1145939 },
  {
    "name": "CSU AL FATH",
    "lat": 34.670895848053846,
    "lon": -1.8680085247384028
  },
  { "name": "DR Tssiwant", "lat": 33.3527, "lon": -3.94692 },
  { "name": "CSR amello", "lat": 29.2818392, "lon": -10.0770593 },
  { "name": "CS NASR 1", "lat": 34.709602, "lon": -1.88061 },
  { "name": "CS Bir Anzaran", "lat": 33.547504, "lon": -7.566776 },
  {
    "name": "HOPITAL LOCAL ZOUBAIR SKIREJ",
    "lat": 34.682606,
    "lon": -6.003903
  },
  { "name": "CSC aoutat el lhaj", "lat": 33.34787, "lon": -3.7054563 },
  {
    "name": "CSC aoutat el lhaj",
    "lat": 33.348379496329876,
    "lon": -3.7075977841308605
  },
  {
    "name": "CSC avec module d'accouchement aoulad bouzaouia",
    "lat": 33.033482,
    "lon": -4.047548
  },
  {
    "name": "CS bnitadjit",
    "lat": 32.31499127724556,
    "lon": -3.453316629274499
  },
  { "name": "CS BIR ANZARAN", "lat": 23.693966, "lon": -15.949857 },
  { "name": "csr 2 Taghzoute", "lat": 31.4541217, "lon": -5.42255 },
  {
    "name": "csr 2 taghazout",
    "lat": 31.454295366154806,
    "lon": -5.422351115399745
  },
  { "name": "CSR 1 BSARA", "lat": 34.707188, "lon": -2.227625 },
  { "name": "CSR Niveau 2 Ain Leuh", "lat": 33.291832, "lon": -5.3404699 },
  { "name": "CSU 1 NAIMA", "lat": 34.603855, "lon": -2.215567 },
  { "name": "CS BNI KHALED", "lat": 34.878433, "lon": -2.008932 },
  {
    "name": "CS Salam 1 ",
    "lat": 33.59835433959961,
    "lon": -7.488680362701416
  },
  { "name": "CSR ait erkha", "lat": 29.3609381, "lon": -9.6226269 },
  { "name": "CSR El Ganzra ", "lat": 34.06324, "lon": -5.937952 },
  { "name": "PHARMACIE PROVINCIALE", "lat": 0, "lon": 0 },
  { "name": "47702A10/GPRS ARIERRE", "lat": 0, "lon": 0 },
  { "name": "CSC OURTZAGH", "lat": 34.534565, "lon": -4.9705544 },
  {
    "name": "CSR Niveau 2 Sidi El Mekhfi",
    "lat": 33.3961792,
    "lon": -5.3361664
  },
  { "name": "CSR1 IMLIL", "lat": 31.754301, "lon": -7.007934 },
  { "name": "Centre Sres", "lat": 33.2616903, "lon": -7.5867415 },
  { "name": "Centre Sres", "lat": 33.2616903, "lon": -7.5867415 },
  { "name": "cs figuig", "lat": 32.1095863, "lon": -1.2334621 },
  {
    "name": "C/S MARBOUH",
    "lat": 32.08391083354072,
    "lon": -7.410589456558228
  },
  { "name": "Csr 2 ain dorrij", "lat": 34.6195221, "lon": -5.2979012 },
  {
    "name": "DÚp¶t rÚgionale des mÚdicaments rabat AR 2",
    "lat": 33.9794327,
    "lon": -6.8204806
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments",
    "lat": 30.421749301578807,
    "lon": -9.593317678573603
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments",
    "lat": 30.421497192259192,
    "lon": -9.593296220901484
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments",
    "lat": 30.421779369619312,
    "lon": -9.593124559524531
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments",
    "lat": 30.421555015555185,
    "lon": -9.593070915344233
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments",
    "lat": 30.421592022446653,
    "lon": -9.592821469905848
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments",
    "lat": 30.42144862066404,
    "lon": -9.59265785515594
  },
  { "name": "DÚp¶t provincial tinghir", "lat": 31.5214478, "lon": -5.529129 },
  {
    "name": "CSU 2 Beni Ahmed",
    "lat": 34.846412560843774,
    "lon": -5.078619167201843
  },
  {
    "name": "commune el kelaa(csu alqods)",
    "lat": 32.05862740028184,
    "lon": -7.396223545074463
  },
  { "name": "CSR1 OUAZENT", "lat": 31.7202368, "lon": -6.787932 },
  { "name": "CS COMMUNAL BNI BATAO", "lat": 32.828273, "lon": -6.288719 },
  { "name": "47703A10/GSPR ARRIERE", "lat": 0, "lon": 0 },
  { "name": "CSR Laqsir", "lat": 33.832992, "lon": -5.159987 },
  { "name": "CS -2 AYT AHMED", "lat": 31.423792, "lon": -6.210399 },
  { "name": "CSR2 AIT MHAMMED", "lat": 31.878227, "lon": -6.471824 },
  {
    "name": "centre de sante jorf el melha",
    "lat": 34.4881592,
    "lon": -5.5085964
  },
  { "name": "CS Oulad Zidane", "lat": 33.34951, "lon": -7.349199 },
  { "name": "beggara", "lat": 34.3426018, "lon": -5.8695378 },
  { "name": "DR AIT KERMOUN", "lat": 29.4103579, "lon": -9.575801 },
  { "name": "DR TILOUA", "lat": 29.4293843, "lon": -9.2894815 },
  { "name": "dr louihat", "lat": 31.9802524, "lon": -8.5484983 },
  {
    "name": "INRA SETTAT 1",
    "lat": 32.95380141642255,
    "lon": -7.625731563507085
  },
  { "name": "CS Ait Moussa", "lat": 29.348035, "lon": -9.379244 },
  { "name": "CS Agader izri", "lat": 29.3943576, "lon": -9.2432575 },
  { "name": "CSU Niveau 1 Sidi Ifni", "lat": 29.375492, "lon": -10.173652 },
  { "name": "CS Ait abdelah sidi ifni", "lat": 29.3393838, "lon": -9.8831463 },
  { "name": "CS Iminfast", "lat": 29.077387, "lon": -10.100707 },
  { "name": "CSR MIRLEFT", "lat": 29.570437, "lon": -10.040337 },
  { "name": "CSR SBOUYA", "lat": 29.0776068, "lon": -10.1025188 },
  { "name": "CS Bofourna", "lat": 29.5074685, "lon": -9.9298296 },
  { "name": "CS DFILIA", "lat": 29.1511687, "lon": -10.0911034 },
  { "name": "CS SIDI MBAREK", "lat": 29.382354, "lon": -9.810438 },
  { "name": "CSR IDOUALI", "lat": 29.3127599, "lon": -9.5818514 },
  { "name": "CSR TIGHIRT", "lat": 29.3752, "lon": -9.454156 },
  { "name": "CS SIDI MBAREK", "lat": 29.382354, "lon": -9.810438 },
  { "name": "CSR IBDER", "lat": 29.4346932, "lon": -9.2845404 },
  { "name": "CSR MIRGHT", "lat": 29.4234986, "lon": -9.7064844 },
  { "name": "CSR MIRGHT", "lat": 29.4239379, "lon": -9.7079633 },
  { "name": "CSR Anfeg", "lat": 29.442948, "lon": -9.236976 },
  { "name": "CSR Anfeg", "lat": 29.4433357, "lon": -9.2393145 },
  { "name": "CSR TAJJAJT", "lat": 29.413496, "lon": -9.5845433 },
  {
    "name": "CSR Tyoughza",
    "lat": 29.309350381914935,
    "lon": -10.092401531738286
  },
  { "name": "CSR Tyoughza", "lat": 29.3063566, "lon": -10.0934315 },
  { "name": "CSU Lalla Meriem", "lat": 29.3889765, "lon": -10.1564022 },
  { "name": "CSU LAKHSSAS", "lat": 29.3661874, "lon": -9.7532157 },
  { "name": "DP de Sidi Ifni", "lat": 29.3809597, "lon": -10.1746273 },
  { "name": "DR Anamer", "lat": 29.237315, "lon": -10.2262805 },
  { "name": "DR AGUENDO", "lat": 29.4703046, "lon": -10.0271452 },
  { "name": "DR AIT BOMERIEM", "lat": 29.4688596, "lon": -9.3209406 },
  { "name": "DR Ait Izza", "lat": 29.4018429, "lon": -9.922725 },
  { "name": "DR ALLALENE", "lat": 29.3500712, "lon": -10.0744267 },
  { "name": "DR AOUDINT", "lat": 29.221528, "lon": -10.259287 },
  { "name": "DR IDAOURAHMAN", "lat": 29.274427, "lon": -9.619138 },
  { "name": "DR IDAOURAHMAN", "lat": 29.274427, "lon": -9.619138 },
  { "name": "DR Inkern", "lat": 29.4478846, "lon": -9.3933049 },
  { "name": "DR Taghloulou", "lat": 29.4837122, "lon": -9.3480217 },
  { "name": "Hopital sidi ifni", "lat": 29.3809597, "lon": -10.1746273 },
  {
    "name": "DR toutline",
    "lat": 28.99658314349505,
    "lon": -10.05194735581971
  },
  { "name": "DR iguissil", "lat": 29.0850089, "lon": -9.995631 },
  { "name": "DR Iguissil", "lat": 29.0850557, "lon": -9.9957487 },
  { "name": "DP Assa zag", "lat": 28.6120586, "lon": -9.4502449 },
  { "name": "DP Assa zag", "lat": 28.6120586, "lon": -9.4502449 },
  {
    "name": "DP Assa zag",
    "lat": 28.612256391554162,
    "lon": -9.450218077909849
  },
  { "name": "DP Assa zag", "lat": 28.6120586, "lon": -9.4502449 },
  { "name": "DP Assa zag", "lat": 28.61205389067274, "lon": -9.45016979814758 },
  {
    "name": "DP Assa zag",
    "lat": 28.612115111910633,
    "lon": -9.450051780950925
  },
  {
    "name": "CSR aouinat ighoumane",
    "lat": 28.502249329112725,
    "lon": -10.52433326574554
  },
  { "name": "CSR aouinat ighoumane", "lat": 28.5023059, "lon": -10.5236949 },
  { "name": "CSR LABOUIRATE", "lat": 27.9338531, "lon": -9.9641142 },
  { "name": "CSR AOUINAT LAHNA", "lat": 28.481749, "lon": -9.847722 },
  { "name": "CSR AOUINAT LAHNA", "lat": 28.481749, "lon": -9.847722 },
  { "name": "CSU HAY ALMOUSTACHFA", "lat": 28.6101971, "lon": -9.4351483 },
  { "name": "CSR tilioune", "lat": 29.0202624, "lon": -10.2360097 },
  { "name": "CSR ADAY", "lat": 29.051825, "lon": -9.360292 },
  { "name": "CSR  AIT BOUFLAN", "lat": 29.26988, "lon": -9.738072 },
  { "name": "CSR  ifrane", "lat": 29.215685, "lon": -9.492828 },
  { "name": "CSR IFRANE", "lat": 29.215687, "lon": -9.49283 },
  { "name": "CSU BOUIZAKARNE", "lat": 29.180068, "lon": -9.712922 },
  { "name": "DR AMSRA", "lat": 29.2059927, "lon": -9.5392457 },
  { "name": "CSU elkramez ", "lat": 28.9907899, "lon": -10.0669205 },
  { "name": "Dr tainzert", "lat": 29.0976601, "lon": -9.346756 },
  { "name": "DR Tankert", "lat": 29.2354235, "lon": -9.4567659 },
  { "name": "DR targamait", "lat": 28.839334, "lon": -9.575114 },
  { "name": "Dr targamait", "lat": 28.8393345, "lon": -9.577303 },
  { "name": "DR tayirt", "lat": 28.9879467, "lon": -10.0502047 },
  { "name": "Dr tighmart", "lat": 28.9433651, "lon": -9.9536839 },
  {
    "name": "CSR transmission de transfÞre de sang",
    "lat": 28.9686026,
    "lon": -10.0340367
  },
  { "name": "CS RASE AMLILE", "lat": 28.7290458, "lon": -10.5089486 },
  { "name": "CSR  tao", "lat": 29.0906993, "lon": -10.2653921 },
  { "name": "CSR Tiglite", "lat": 28.4849358, "lon": -10.3087807 },
  { "name": "CSR labiar", "lat": 28.8379021, "lon": -10.430666 },
  { "name": "CSR tagant", "lat": 29.1295185, "lon": -9.7816067 },
  { "name": "CSR  tagant", "lat": 29.1295185, "lon": -9.7816067 },
  { "name": "CSR  Tao", "lat": 29.0907177, "lon": -10.2653167 },
  { "name": "CSR  TIMOLAY", "lat": 29.167768, "lon": -9.577563 },
  { "name": "CSR  AIT BOUFLAN", "lat": 29.26988, "lon": -9.738072 },
  { "name": "CSR  fask", "lat": 28.9895172, "lon": -9.8342962 },
  { "name": "CS amtoudi", "lat": 29.2427357, "lon": -9.1914571 },
  { "name": "CSU  BOUIZAKARNE ", "lat": 29.180069, "lon": -9.712923 },
  { "name": "dÚlÚgation guelmim", "lat": 28.974086, "lon": -10.048641 },
  { "name": "dÚlÚgation guelmim", "lat": 28.974086, "lon": -10.048641 },
  { "name": "dÚlÚgation guelmim", "lat": 28.974086, "lon": -10.048641 },
  { "name": "DR Rejja Fellah", "lat": 28.9810846, "lon": -10.0488103 },
  { "name": "CS amhiriche", "lat": 28.9832379, "lon": -10.0699892 },
  { "name": "DR AMSRA", "lat": 29.2059929, "lon": -9.5392884 },
  { "name": "CSU   elmassira ", "lat": 28.989109, "lon": -10.058656 },
  { "name": "DR  taidalt", "lat": 28.762022, "lon": -9.7734728 },
  { "name": "Hopital assrir", "lat": 28.9247208, "lon": -10.00665 },
  {
    "name": "CSU EL OUATIA ",
    "lat": 28.420391085674304,
    "lon": -11.10694780626531
  },
  { "name": "CSU QUARTIER ADMINISTRATIF", "lat": 28.440872, "lon": -11.108587 },
  { "name": "CSU El hay el jadid", "lat": 28.41237893, "lon": -11.01222003 },
  { "name": "CSU Al hay el jadid", "lat": 28.41337893, "lon": -11.01322003 },
  { "name": "DR AIN ERRAHMA", "lat": 28.428925, "lon": -11.110755 },
  { "name": "DR AIN ERRAHMA", "lat": 28.428925, "lon": -11.110755 },
  { "name": "Hopital hassan 2", "lat": 28.4543364, "lon": -11.123776 },
  { "name": "Hopital hassan 2", "lat": 28.4440441, "lon": -11.1115916 },
  { "name": "DR  Ikhrididen", "lat": 30.60494, "lon": -9.343704 },
  { "name": "Hopital hassan 2", "lat": 28.4843364, "lon": -11.153776 },
  { "name": "Hopital hassan 2", "lat": 28.4435345, "lon": -11.1131537 },
  { "name": "Hopital hassan 2", "lat": 28.4435345, "lon": -11.1131537 },
  { "name": "Hopital hassan 2", "lat": 28.4643364, "lon": -11.133776 },
  { "name": "Hopital hassan 2", "lat": 28.4543364, "lon": -11.123776 },
  { "name": "Hopital hassan 2 ", "lat": 28.4543364, "lon": -11.123776 },
  { "name": "hopital hassan 2", "lat": 28.4543364, "lon": -11.123776 },
  { "name": "DP DE BOUJDOUR ", "lat": 26.118692, "lon": -14.486911 },
  { "name": "CSU1 AL AOUDA", "lat": 26.111631, "lon": -14.48228 },
  { "name": "Dar El hay Elkhair ", "lat": 26.132481, "lon": -14.479299 },
  { "name": "Club D'enfant At-tanmia", "lat": 26.125469, "lon": -14.466881 },
  { "name": "DP DE BOUJDOUR", "lat": 26.116692, "lon": -14.486911 },
  { "name": "DP DE BOUJDOUR ", "lat": 26.115692, "lon": -14.486911 },
  { "name": "DP DE BOUJDOUR ", "lat": 26.118692, "lon": -14.486911 },
  { "name": "DP DE BOUJDOUR ", "lat": 26.111692, "lon": -14.486911 },
  { "name": "DP DE BOUJDOUR", "lat": 26.119692, "lon": -14.486911 },
  {
    "name": "DP DE BOUJDOUR ",
    "lat": 26.118673937654336,
    "lon": -14.486909658895485
  },
  {
    "name": "centre de santÚ urbain niveau 1 AL KHAIR",
    "lat": 26.131395,
    "lon": -14.479578
  },
  { "name": "DP ESSEMARA", "lat": 26.743173, "lon": -11.663605 },
  { "name": "DP ESSEMARA", "lat": 26.742173, "lon": -11.663605 },
  { "name": "DP ESSEMARA", "lat": 26.744173, "lon": -11.663605 },
  { "name": "DP ESSEMARA", "lat": 26.748173, "lon": -11.663605 },
  { "name": "DP ESSEMARA ", "lat": 26.747173, "lon": -11.663605 },
  { "name": "DP  ESSEMARA", "lat": 26.746173, "lon": -11.663605 },
  {
    "name": "DP ESSEMARA",
    "lat": 26.74315762111824,
    "lon": -11.703357165543531
  },
  { "name": "DP ESSEMARA", "lat": 26.7442406, "lon": -11.6752885 },
  { "name": "Hopital hassan 2", "lat": 23.713549, "lon": -15.922464 },
  { "name": "Hopital hassan 2", "lat": 23.713549, "lon": -15.9246527 },
  { "name": "Hopital hassan 2", "lat": 23.7132519, "lon": -15.9245288 },
  { "name": "Hopital hassan 2", "lat": 23.7141046, "lon": -15.9247245 },
  { "name": "Hopital hassan 2", "lat": 23.7141046, "lon": -15.9247245 },
  { "name": "Hopital hassan 2", "lat": 23.7141046, "lon": -15.9247245 },
  { "name": "Hopital hassan 2", "lat": 23.7141046, "lon": -15.9247245 },
  { "name": "Hopital hassan 2", "lat": 23.7132275, "lon": -15.9244844 },
  { "name": "Hopital hassan 2", "lat": 23.7132275, "lon": -15.9244844 },
  { "name": "Hopital hassan 2", "lat": 23.7132275, "lon": -15.9244844 },
  {
    "name": "MAISON DE LA FEMME ARTISANE",
    "lat": 23.713598,
    "lon": -15.947423
  },
  { "name": "CS COMMUNAL BIRGANDOUZ", "lat": 22.054143, "lon": -16.751307 },
  { "name": "CS TAWARTA EDDAKHLA", "lat": 23.79582, "lon": -15.914306 },
  { "name": "CS El Gargarate", "lat": 21.363894, "lon": -16.960959 },
  { "name": "DPR des Medicament", "lat": 27.1555857, "lon": -13.1919623 },
  {
    "name": "DPR des Medicament",
    "lat": 27.155566607596057,
    "lon": -13.191960958895503
  },
  {
    "name": "DPR des Medicament",
    "lat": 27.155597632750805,
    "lon": -13.19196230000001
  },
  {
    "name": "DPR des Medicament",
    "lat": 27.155617918424234,
    "lon": -13.191954253372966
  },
  { "name": "DPR des mÚdicaments", "lat": 27.1555857, "lon": -13.1919623 },
  {
    "name": "DPR des mÚdicaments",
    "lat": 27.149706719919084,
    "lon": -13.190124988954924
  },
  {
    "name": "DPR des mÚdicaments ",
    "lat": 27.149658986391188,
    "lon": -13.1900847558197
  },
  {
    "name": "SRES LAAYOUNE",
    "lat": 27.149690013186643,
    "lon": -13.190173268717192
  },
  {
    "name": "SRES LAAYOUNE",
    "lat": 27.14971865329789,
    "lon": -13.190157175463103
  },
  {
    "name": "SRES lAAYOUNE",
    "lat": 27.14969478653904,
    "lon": -13.190099507969283
  },
  {
    "name": "CENTRE HOSPITALIER DU CANCER",
    "lat": 27.13522010824221,
    "lon": -13.152587403704826
  },
  {
    "name": "CENTRE HOSPITALIER DU CANCER",
    "lat": 27.129300256450414,
    "lon": -13.141268084655753
  },
  {
    "name": "CENTRE HOSPITALIER DU CANCER",
    "lat": 27.13528336333396,
    "lon": -13.152414401223366
  },
  {
    "name": "CENTRE HOSPITALIER DU CANCER",
    "lat": 27.13523204316793,
    "lon": -13.152469386508171
  },
  {
    "name": "CENTRE HOSPITALIER DU CANCER",
    "lat": 27.1352583,
    "lon": -13.1524372
  },
  {
    "name": "CENTRE HOSPITALIER DU CANCER",
    "lat": 27.129644,
    "lon": -13.141311
  },
  {
    "name": "CENTRE HOSPITALIER DU CANCER",
    "lat": 27.13522010824221,
    "lon": -13.15248547976226
  },
  {
    "name": "HOPITAL BEN LMAHDI",
    "lat": 27.13524308134613,
    "lon": -13.15023861226844
  },
  {
    "name": "HOPITAL BEN LMAHDI",
    "lat": 27.136230549920903,
    "lon": -13.150247999999992
  },
  { "name": "HOPITAL BEN LMAHDI", "lat": 27.137258, "lon": -13.150248 },
  {
    "name": "HOPITAL BEN LMAHDI",
    "lat": 27.138198326974013,
    "lon": -13.150288233135216
  },
  { "name": "CS El Wifaq", "lat": 27.125269, "lon": -13.182957 },
  {
    "name": "Csu Mohamed Salim ben bachair",
    "lat": 27.1303842,
    "lon": -13.1453278
  },
  {
    "name": "CS Madina al jadida",
    "lat": 27.11638069152832,
    "lon": -13.174575805664062
  },
  { "name": "CSU 20 AOUT", "lat": 27.153288, "lon": -13.202873 },
  {
    "name": "mohammed salam al boukhari ",
    "lat": 27.164225,
    "lon": -13.208874
  },
  { "name": "DR Foum Eloued Village", "lat": 27.173838, "lon": -13.36867 },
  { "name": "CS DAyaat", "lat": 27.187717, "lon": -13.331074 },
  { "name": "CSU EL MARSA", "lat": 27.09713, "lon": -13.40776 },
  {
    "name": "CSU SLIMA BEN BACHIR BEN AMMAR PREMIER NIVEAU",
    "lat": 27.1430301,
    "lon": -13.1995349
  },
  { "name": " CSU TARFAYA ", "lat": 27.9388914, "lon": -12.9264879 },
  { "name": " CSU TARFAYA", "lat": 27.9388914, "lon": -12.9264879 },
  { "name": "CSU tarfaya", "lat": 27.9388914, "lon": -12.9264879 },
  { "name": "CSU TARFAYA", "lat": 27.9388914, "lon": -12.9264879 },
  { "name": " CSU  TARFAYA ", "lat": 27.9388914, "lon": -12.9264879 },
  { "name": "CSU TARFAYA", "lat": 27.9388914, "lon": -12.9264879 },
  {
    "name": "Centre de SantÚ Rural Hagounia",
    "lat": 27.13817,
    "lon": -13.175336
  },
  { "name": "CSR Daoura ", "lat": 27.461026, "lon": -12.992336 },
  { "name": "CSR AIT HAMZA", "lat": 33.3499603, "lon": -4.89047 },
  { "name": "CSR AIT HAMZA", "lat": 33.3499603, "lon": -4.89047 },
  { "name": "H¶pital La Marche Verte ", "lat": 33.0480957, "lon": -3.9968379 },
  { "name": "H¶pital La Marche Verte ", "lat": 33.0480957, "lon": -3.9968379 },
  { "name": "H¶pital La Marche Verte", "lat": 33.0480957, "lon": -3.9968379 },
  {
    "name": "Hopital Aoulad Ali Youssef Bni Hassan",
    "lat": 33.4857254,
    "lon": -4.0240789
  },
  {
    "name": "CSU Avec Module D'accouchement Aoulad Ali Youssef",
    "lat": 33.4622307,
    "lon": -3.9718735
  },
  {
    "name": "CSU Avec Module D'accouchement Aoulad Ali Youssef",
    "lat": 33.4622307,
    "lon": -3.9718735
  },
  { "name": "CSC el orjane", "lat": 33.4046211, "lon": -3.7340143 },
  { "name": "CS niveau 1 ouled mellouk", "lat": 33.331841, "lon": -3.729383 },
  { "name": "CSR ahachad", "lat": 33.532856, "lon": -4.2428694 },
  {
    "name": "CSC Avec Module D'accouchement Imouzar Marmoucha",
    "lat": 33.4677162,
    "lon": -4.3040157
  },
  {
    "name": "CSC Avec Module D'accouchement Imouzar Marmoucha",
    "lat": 33.467682,
    "lon": -4.30152
  },
  {
    "name": "CSC Avec Module D'accouchement Imouzar Marmoucha",
    "lat": 33.4677162,
    "lon": -4.3040157
  },
  { "name": "CSC Ait Bazt", "lat": 33.4137421, "lon": -4.2917051 },
  { "name": "CSR Sarghina", "lat": 33.3488178, "lon": -4.4412148 },
  { "name": "H¶pital El Mers Niveau 2", "lat": 33.4456825, "lon": -4.4506416 },
  { "name": "CSC Skoura", "lat": 33.5198364, "lon": -4.547245 },
  { "name": "CSU ENJIL AKHATAR ", "lat": 33.2297592, "lon": -4.6375966 },
  { "name": "CSU ENJIL AKHATAR", "lat": 33.2297592, "lon": -4.6375966 },
  { "name": "CSU Boulemane", "lat": 33.368618, "lon": -4.7307677 },
  { "name": "DR Ait Benmoussa", "lat": 33.3242676, "lon": -4.6378374 },
  { "name": "CSR GUIGUO", "lat": 33.3863869, "lon": -4.8360248 },
  { "name": "DR Magdoul", "lat": 32.9378014, "lon": -4.124557 },
  { "name": "DR oulad sguir", "lat": 33.0272446, "lon": -4.0320296 },
  { "name": "CSC Alkassabi", "lat": 32.82967, "lon": -4.4011383 },
  { "name": "CSC Alkassabi", "lat": 32.82967, "lon": -4.4011383 },
  { "name": "DR Taourit", "lat": 32.7745857, "lon": -4.3238568 },
  { "name": "DR Tagourast", "lat": 32.6857643, "lon": -4.0361319 },
  { "name": "CSC  JBABRA", "lat": 34.4319038, "lon": -4.9662228 },
  { "name": "CSR NIVEAU 2 BOUCHABEL", "lat": 34.2709969, "lon": -5.3107889 },
  { "name": "CS SIDI EL ABED", "lat": 34.3016357, "lon": -5.0302677 },
  { "name": "CSC L'OULJA", "lat": 34.2882195, "lon": -4.950397 },
  { "name": "CS AIN MAATOUF", "lat": 34.44751, "lon": -4.483678 },
  {
    "name": "centre de santÚ rass lwad ",
    "lat": 34.4002342,
    "lon": -4.5178409
  },
  { "name": "CSC EL MSSASSA", "lat": 34.252328, "lon": -4.481599 },
  { "name": "CSR 2 BNI OULID", "lat": 34.5921969, "lon": -4.4507897 },
  { "name": "CS OUAD LOWAN", "lat": 34.5476445, "lon": -4.466178 },
  { "name": "CSR 1 BOUAADEL", "lat": 34.544384, "lon": -4.518777 },
  { "name": "CSR 2 AIN AICHA", "lat": 34.4616086, "lon": -4.6914413 },
  { "name": "DR EL ONSER", "lat": 34.5890102, "lon": -4.5654372 },
  { "name": "CSC OULED KASBAH", "lat": 34.616127, "lon": -4.4096279 },
  { "name": "CSC OULED KASBAH", "lat": 34.616127, "lon": -4.4096279 },
  { "name": "CS THAR SOUK", "lat": 34.6540794, "lon": -4.2837668 },
  { "name": "CS THAR SOUK", "lat": 34.6540794, "lon": -4.2837668 },
  { "name": "CSC TAMEDIT", "lat": 34.6843834, "lon": -4.2058954 },
  { "name": "CSU 2 TISSA", "lat": 34.2860471, "lon": -4.6667609 },
  { "name": "CS LHAJ MHEMED", "lat": 34.7427101, "lon": -5.0120902 },
  { "name": "CS OULED SALEH", "lat": 34.7022133, "lon": -4.9917035 },
  { "name": "CSR 1 RATBA", "lat": 34.7777233, "lon": -4.9374693 },
  { "name": "HOPITAL OUDKA", "lat": 34.676437, "lon": -4.905102 },
  { "name": "HOPITAL ELBIBANE", "lat": 34.618687, "lon": -4.85128 },
  { "name": "CSC GALAZ", "lat": 34.5404854, "lon": -4.803927 },
  { "name": "HOPITAL LKHlALFA", "lat": 34.673964, "lon": -4.625828 },
  { "name": "CSR 1 OULED TAHER", "lat": 34.5020027, "lon": -4.7954307 },
  { "name": "CSU TAOUNAT", "lat": 34.5401029, "lon": -4.6590866 },
  { "name": "CSC MEKANSSA", "lat": 34.319588, "lon": -5.448911 },
  { "name": "HOPITAL KARIA", "lat": 34.3658142, "lon": -5.2055335 },
  { "name": "CS LAGHOUZI", "lat": 34.4773399, "lon": -5.2982358 },
  { "name": "CSC MOULAY ABDELKARIM", "lat": 34.482849, "lon": -5.245356 },
  { "name": "CSU 2 OULED AYAD", "lat": 34.075367, "lon": -4.602499 },
  { "name": "CSC Sidi Ali Bourakba", "lat": 34.8145341, "lon": -3.7304449 },
  {
    "name": "CS Bni Kourama",
    "lat": 34.54120699437439,
    "lon": -4.18322080476278
  },
  { "name": "CS 1 smia ", "lat": 34.0298805, "lon": -4.3945642 },
  { "name": "CS Aknoul", "lat": 34.6503792, "lon": -3.8682444 },
  { "name": "CS Alkodss 2", "lat": 34.2355309, "lon": -3.9949744 },
  { "name": "CS Bab Mrouj", "lat": 34.4159775, "lon": -4.0457897 },
  { "name": "CS Bin Jradi", "lat": 34.2284584, "lon": -4.0071745 },
  { "name": "CS Bni Frassen", "lat": 34.3789825, "lon": -4.3782454 },
  { "name": "CSC  Bni Lent", "lat": 34.3276825, "lon": -4.2059641 },
  { "name": "CSC Bni Lent", "lat": 34.3276825, "lon": -4.2059641 },
  { "name": "CSU Ouahda", "lat": 34.2308617, "lon": -4.0202365 },
  { "name": "CSC Bouhlou", "lat": 34.1318741, "lon": -4.4218397 },
  { "name": "CSC Bouhlou", "lat": 34.132015, "lon": -4.419657 },
  { "name": "CSC Rbaa El Fouki", "lat": 34.3836136, "lon": -4.2830901 },
  { "name": "CSR Niveau 2 Maghraoua", "lat": 33.9312553, "lon": -4.0491862 },
  { "name": "CSR Niveau 2 Maghraoua", "lat": 33.9312553, "lon": -4.0491862 },
  { "name": "CSR Niveau 1 Bouchfaa", "lat": 34.1029015, "lon": -4.2900996 },
  { "name": "CSR Niveau 1 Bouchfaa", "lat": 34.1029015, "lon": -4.2900996 },
  { "name": "CSR Niveau 1 Tazarine", "lat": 33.8783188, "lon": -4.1888223 },
  { "name": "CSR Niveau 1 Tazarine", "lat": 33.8783188, "lon": -4.1888223 },
  {
    "name": "CS Sidi Moujahid",
    "lat": 34.03229520000001,
    "lon": -5.343704700000004
  },
  { "name": "CSU Ajdir", "lat": 34.737444, "lon": -3.96105 },
  { "name": "CSU Ajdir", "lat": 34.7439338, "lon": -3.9739759 },
  { "name": "CSU Bab Zaytouna", "lat": 34.2090034, "lon": -4.0213938 },
  { "name": "CSU Jiyarine", "lat": 34.207859, "lon": -4.0016275 },
  { "name": "CSU Lamtmata", "lat": 34.098114, "lon": -4.5520253 },
  { "name": "CSU Oued Amlil", "lat": 34.192989, "lon": -4.266615 },
  { "name": "CS Douar Boured", "lat": 34.734148, "lon": -4.089396 },
  { "name": "CS El Gouzate", "lat": 34.4938278, "lon": -4.1368842 },
  { "name": "CS El Gouzate", "lat": 34.4938278, "lon": -4.1368842 },
  { "name": "CS Yara Hdoud", "lat": 34.770966, "lon": -3.882637 },
  { "name": "CSC Brarha", "lat": 34.3457738, "lon": -4.3668329 },
  { "name": "CSC Brarha", "lat": 34.3457738, "lon": -4.3668329 },
  { "name": "CSC Maknassa El Gharbia", "lat": 34.280319, "lon": -4.079208 },
  { "name": "CS Tamtrocht", "lat": 33.7825966, "lon": -4.075933 },
  { "name": "CS Boyablan", "lat": 33.7825966, "lon": -4.075933 },
  { "name": "CSC Ouled Chrif", "lat": 34.2811149, "lon": -4.2076417 },
  {
    "name": "CSC Avec Maison D'accouchement Kaf El Ghar",
    "lat": 34.507595,
    "lon": -4.260045
  },
  { "name": "CSR 1 Bab Boudir", "lat": 34.069866, "lon": -4.119472 },
  { "name": "CSR1 Jbarna", "lat": 34.5159988, "lon": -3.89696 },
  { "name": "CSR1 Malal", "lat": 34.6392918, "lon": -4.0175192 },
  { "name": "CSR1 Traiba", "lat": 34.4155731, "lon": -3.9771173 },
  { "name": "CS Dar Al Oumouma Msila", "lat": 34.4240532, "lon": -4.1787844 },
  { "name": "CS Dar Al Oumouma Msila", "lat": 34.2173233, "lon": -4.0069132 },
  { "name": "CS El Massira 2", "lat": 34.23106, "lon": -3.987409 },
  { "name": "PP Taza", "lat": 34.2173233, "lon": -4.0069132 },
  { "name": "DR Ain Al Hamra", "lat": 34.731842, "lon": -3.952808 },
  { "name": "DR Bni Mhamed", "lat": 34.761194, "lon": -3.653074 },
  { "name": "DR Kaouane", "lat": 34.1493034, "lon": -4.3478904 },
  { "name": "DR Oued Broun", "lat": 34.5069351, "lon": -3.9843831 },
  { "name": "DR Taghilaste", "lat": 34.7879448, "lon": -3.7634149 },
  { "name": "DR Tizi Ouasli", "lat": 34.7609778, "lon": -3.8117946 },
  { "name": "CS Bab Mrouj", "lat": 34.3631821, "lon": -4.0637546 },
  { "name": "DR Tastite", "lat": 34.8099747, "lon": -3.744937 },
  { "name": "CSC Beni Ftah", "lat": 34.07552, "lon": -4.507732 },
  { "name": "DR Inahnah", "lat": 34.740991, "lon": -3.97273 },
  { "name": "DR Zaouya", "lat": 34.093937, "lon": -4.5597024 },
  {
    "name": "MAISON D'ACCOUHEMENT De MEKNASSA CHARKIA",
    "lat": 34.3617172,
    "lon": -3.9153788
  },
  {
    "name": "Maison des Jeunes Almassira Alkhadra",
    "lat": 34.0480537,
    "lon": -4.4252896
  },
  { "name": "Centre sidi redouane", "lat": 34.6855147, "lon": -5.4467931 },
  {
    "name": "Maison des jeunes Du 18  Novembre Ouled Azbayer",
    "lat": 34.2897453,
    "lon": -4.3214107
  },
  { "name": "CSU Bayt Ghoulam 2", "lat": 34.221127, "lon": -4.004887 },
  {
    "name": "CSU 1 TOULAL",
    "lat": 33.8723158896503,
    "lon": -5.547704468432719
  },
  {
    "name": "CSU 1 TOULAL",
    "lat": 33.889652252197266,
    "lon": -5.594330310821533
  },
  { "name": "CSU Wejah El Aarosse", "lat": 33.9008318, "lon": -5.5788726 },
  { "name": "CS Dyour Essalam", "lat": 33.8835991, "lon": -5.5779874 },
  {
    "name": "CSU 1 TOUARGA",
    "lat": 33.875858306884766,
    "lon": -5.564410209655762
  },
  { "name": "CSU 1 EL ISMAILIA", "lat": 33.8818102, "lon": -5.5492087 },
  { "name": "CSU Ras Aghil", "lat": 33.8809852, "lon": -5.6080603 },
  { "name": "CSU Borj Molay Omar", "lat": 33.908304, "lon": -5.5297705 },
  { "name": "CSU Oum Rabie", "lat": 33.9065881, "lon": -5.5382106 },
  {
    "name": "Centre Socio-Culturel Des Anciens Combattants",
    "lat": 33.90431594848633,
    "lon": -5.527316093444824
  },
  { "name": "CS Ouislane", "lat": 33.9188919, "lon": -5.4924049 },
  { "name": "CSU SAADA OUISLAN", "lat": 33.908485, "lon": -5.490077 },
  { "name": "CSR Hay Salam ", "lat": 33.895559, "lon": -5.58336 },
  {
    "name": "CSR 1 Dar Oum Soltane",
    "lat": 33.90129305825812,
    "lon": -5.63791874550781
  },
  { "name": "CSR 2 Ain Orma", "lat": 33.896912, "lon": -5.751979 },
  { "name": "DR Ain Jamaa", "lat": 34.0287247, "lon": -5.7990999 },
  { "name": "CSR 1 Mejjat", "lat": 33.8132896, "lon": -5.5107088 },
  { "name": "CSR 1 Cherkaoua", "lat": 34.194298, "lon": -5.389047 },
  { "name": "CSR 1 Hadj Keddour", "lat": 33.8283958, "lon": -5.4274297 },
  {
    "name": "Laboratoire De Diagnostic EpidÚmiologique Et D'hygiÞne Du Milieu ",
    "lat": 34.0420189,
    "lon": -4.9870815
  },
  { "name": "OFPPT HAY AL ADARISSA", "lat": 34.01897, "lon": -5.01682 },
  { "name": "CS Bab El Boujat", "lat": 34.058118, "lon": -4.995709 },
  { "name": "CS AIN BAIDA", "lat": 33.963005, "lon": -4.936833 },
  { "name": "DR Ait Brahim", "lat": 33.8432541, "lon": -5.2528668 },
  { "name": "CSR Bouderbala", "lat": 33.815545, "lon": -5.275767 },
  { "name": "CSR Bouderbala", "lat": 33.8145447, "lon": -5.277956 },
  {
    "name": "CSU Ain Taoujdat",
    "lat": 33.937711063588175,
    "lon": -5.215970077246084
  },
  {
    "name": "CSU Niveau 1 Ain Taoujdate Jradi",
    "lat": 33.939562477515175,
    "lon": -5.218115844457998
  },
  {
    "name": "Maison Des Jeunes Taoujtat",
    "lat": 33.939491271972656,
    "lon": -5.216920375823975
  },
  {
    "name": "CSU Avec Module D'accouchement Sbaayoune",
    "lat": 33.908255924276595,
    "lon": -5.379665397900386
  },
  { "name": "CS Souk El Gour", "lat": 33.847916, "lon": -5.313348 },
  { "name": "CS Souk El Gour", "lat": 33.847916, "lon": -5.313348 },
  { "name": "H¶pital Benkadouch", "lat": 33.7896996, "lon": -5.3523636 },
  {
    "name": "CSR Niveau 1 Ait Harzellah",
    "lat": 33.7621651,
    "lon": -5.3364196
  },
  { "name": "CSU El Hajeb", "lat": 33.6960769, "lon": -5.36966 },
  { "name": "CHU El Hajeb", "lat": 33.6960099, "lon": -5.3719946 },
  { "name": "CSR Niveau 1 Ait Oulal", "lat": 33.832992, "lon": -5.159987 },
  { "name": "CSC Akdar", "lat": 33.6173744, "lon": -5.4332433 },
  { "name": "CS Ait Yazeem", "lat": 33.7327385, "lon": -5.578907 },
  { "name": "CS Ait Yaazem", "lat": 33.7327385, "lon": -5.578907 },
  { "name": "DR Ait Marghad", "lat": 33.813228, "lon": -5.628982 },
  { "name": "CS Sebt Jehjouh", "lat": 33.7180634, "lon": -5.6718841 },
  { "name": "CSR 1 Commune Jahjouh", "lat": 33.7182, "lon": -5.6698 },
  { "name": "CSR 1 Commune Jahjouh", "lat": 33.7182, "lon": -5.6698 },
  { "name": "CS Ait Oukhalfen", "lat": 33.6761513, "lon": -5.657773 },
  {
    "name": "CS Ait Oukhalfen",
    "lat": 33.675722736864344,
    "lon": -5.661807042358397
  },
  { "name": "MAISON DES JEUNES  IJRI", "lat": 33.7876205, "lon": -5.715755 },
  {
    "name": "CSR Niveau 1 Ait Bourazouine",
    "lat": 33.727241,
    "lon": -5.486601
  },
  { "name": "CS Agouray", "lat": 33.63570785522461, "lon": -5.598196983337402 },
  { "name": "CS Agouray", "lat": 33.6404034, "lon": -5.5899207 },
  {
    "name": "CS  Tamchachat",
    "lat": 33.51135475869642,
    "lon": -5.649455126953122
  },
  {
    "name": "CS Tamchachate",
    "lat": 33.51493286493567,
    "lon": -5.65426164550781
  },
  { "name": "DR Lassadam", "lat": 33.6857394, "lon": -5.3699731 },
  { "name": "CSU Abdelkrim Alkhattabi", "lat": 33.7532463, "lon": -5.392458 },
  { "name": "CSC rass tabouda", "lat": 34.0045242, "lon": -4.7162004 },
  { "name": "CS bouzemlane", "lat": 34.011261, "lon": -4.553256 },
  { "name": "CSR 1 AIN TMKNAY", "lat": 33.855356, "lon": -4.4939207 },
  { "name": "CS adraj", "lat": 33.6299667, "lon": -4.4354806 },
  { "name": "CSC Shahid baha", "lat": 33.8289795, "lon": -4.8313193 },
  { "name": "CS Ain Cheggag", "lat": 33.8775787, "lon": -5.0457115 },
  { "name": "CSR 1 Mtarnagha 2", "lat": 33.8317604, "lon": -4.8220148 },
  { "name": "CS mtarnagha", "lat": 33.8515817, "lon": -4.5775459 },
  { "name": "CSR 1 ahl sidi lahcen", "lat": 33.78205, "lon": -4.68152 },
  { "name": "CSR sidi hmed tadli", "lat": 33.8301277, "lon": -4.845592 },
  { "name": "CS sidi khiar", "lat": 33.8118, "lon": -4.98137 },
  { "name": "CSU ain chifaa", "lat": 33.8106575, "lon": -4.9855018 },
  { "name": "CSU 1 azaba", "lat": 33.8113611, "lon": -4.7006887 },
  { "name": "CSU 1 Dar alhamraa", "lat": 33.6962395, "lon": -4.3731022 },
  { "name": "CSU 2 imouzar kandar", "lat": 33.7338219, "lon": -5.017355 },
  { "name": "CSU 2 imouzar kandar", "lat": 33.7338219, "lon": -5.017355 },
  { "name": "CS zrarda", "lat": 33.9729729, "lon": -4.3794995 },
  { "name": "CS zrarda", "lat": 33.9729729, "lon": -4.3794995 },
  { "name": "CS 1 smia", "lat": 34.0298805, "lon": -4.3945642 },
  { "name": "CS AGHBALO AFORAR", "lat": 33.9340647, "lon": -4.739384 },
  {
    "name": "CS MAISON D'accouchement tazouta",
    "lat": 33.6675427,
    "lon": -4.6356272
  },
  { "name": "CSU DAR ELHOKONE", "lat": 33.8085201, "lon": -4.5395302 },
  { "name": "DR louata", "lat": 33.884418, "lon": -4.560347 },
  { "name": "DR senhaja", "lat": 33.7881279, "lon": -4.8209992 },
  { "name": "DR ICHMLALEN", "lat": 33.8385884, "lon": -4.311421 },
  { "name": "Hopital basabis", "lat": 33.7728386, "lon": -5.0723968 },
  {
    "name": "CSU Mougou Commune Sidi Youssef Ibn Ahmed",
    "lat": 33.8191719,
    "lon": -4.7690249
  },
  {
    "name": "Maison de maternitÚ bir tamtam",
    "lat": 33.9821205,
    "lon": -4.6444917
  },
  { "name": "OULAD MKODO", "lat": 33.8091526, "lon": -4.5041061 },
  {
    "name": "Maison Des jeunes Med Ben Yahya Al-Bahluli",
    "lat": 33.8600464,
    "lon": -4.8722668
  },
  {
    "name": "CSR Niveau 2 Ait Yahya Oualla",
    "lat": 33.4186134,
    "lon": -5.2561679
  },
  { "name": "CSU 1 Tarik Ibnou Ziyad", "lat": 33.4371567, "lon": -5.2093373 },
  { "name": "H¶pital 20 Aout ", "lat": 33.4378586, "lon": -5.2136874 },
  { "name": "DÚlÚgation Ifrane", "lat": 33.434773, "lon": -5.222699 },
  { "name": "CSC De Proximite Rass Lma", "lat": 33.9694939, "lon": -5.0886736 },
  {
    "name": "CS Rass Lma",
    "lat": 33.9699209933881,
    "lon": -5.0890169227539035
  },
  { "name": "CS Ain Chqef", "lat": 33.9540986, "lon": -5.0290242 },
  { "name": "CS 2 Laajara", "lat": 34.204937, "lon": -5.1305685 },
  { "name": "CS Hamriya", "lat": 34.2000008, "lon": -4.9386497 },
  { "name": "CS Sidi Daoud", "lat": 34.3019257, "lon": -5.1422854 },
  { "name": "CS Ain Lah", "lat": 34.0369415, "lon": -5.1406117 },
  { "name": "CS Ain kansara", "lat": 34.148041, "lon": -4.831716 },
  {
    "name": "CSC 2 Sidi Ahmed El Barnoussi",
    "lat": 34.1121216,
    "lon": -4.9999199
  },
  {
    "name": "CSR Daouiyat",
    "lat": 34.03400852063242,
    "lon": -5.1038481018524235
  },
  { "name": "DR Biada", "lat": 34.2476616, "lon": -5.2938457 },
  { "name": "DR Jouaber", "lat": 34.1910477, "lon": -5.2197013 },
  { "name": "DR Ouled Bouziane", "lat": 34.2750244, "lon": -5.0578857 },
  { "name": "DR Ain Bouali", "lat": 34.1608009, "lon": -5.0306106 },
  { "name": "DR Mesdoura", "lat": 34.043823, "lon": -4.846354 },
  { "name": "DR Sidi Msahel", "lat": 34.1279602, "lon": -4.9505856 },
  { "name": "DR BENYAKHLEF", "lat": 34.2065926, "lon": -5.0242181 },
  { "name": "DR tlaha", "lat": 34.0618565, "lon": -5.0923299 },
  { "name": "HOPITAL Urbain ghomra", "lat": 34.0521812, "lon": -5.220037 },
  { "name": "CS Sebt oudaya", "lat": 34.2649192, "lon": -5.4153395 },
  { "name": "DR  ouled hlale", "lat": 34.14789, "lon": -5.149949 },
  { "name": "CSR CHOUIHIYA", "lat": 34.828948, "lon": -2.660011 },
  { "name": "CSU AKLIM", "lat": 34.889733, "lon": -2.520319 },
  { "name": "CSU AKLIM", "lat": 34.886733, "lon": -2.520319 },
  { "name": "CSU SIDI SLIMANE", "lat": 34.909691, "lon": -2.344835 },
  { "name": "CSR  AOULOUT", "lat": 34.897042, "lon": -2.324 },
  { "name": "CSU EL QODS", "lat": 34.9189, "lon": -2.32585 },
  { "name": "CSU EL QODS", "lat": 34.9179, "lon": -2.32585 },
  { "name": "CSU EL MOKAWAMA", "lat": 34.917905, "lon": -2.31576 },
  { "name": "CSU EL MOKAWAMA", "lat": 34.915905, "lon": -2.31576 },
  {
    "name": "CSR SIDI BOUHRIA",
    "lat": 34.739309192200075,
    "lon": -2.3614224721613746
  },
  { "name": "CSU EL QODS", "lat": 34.933594, "lon": -2.32048 },
  {
    "name": "DEPOT PROVINCIAL BERKANE",
    "lat": 34.92536983673124,
    "lon": -2.325718721890606
  },
  {
    "name": "HOPITAL EDDERRAK",
    "lat": 34.83359801129636,
    "lon": -2.2663596826896297
  },
  {
    "name": "HOPITAL EDDERRAK",
    "lat": 34.92446932355009,
    "lon": -2.3256473773156183
  },
  {
    "name": "DÚp¶t SRESS B",
    "lat": 34.92444370491321,
    "lon": -2.3269067885896866
  },
  {
    "name": "DÚp¶t SRESS",
    "lat": 34.92504038481144,
    "lon": -2.3263401755948836
  },
  {
    "name": "CSR TZAYEST",
    "lat": 34.93930212366371,
    "lon": -2.4269875344800766
  },
  { "name": "CSR 1 BOUGHRIBA", "lat": 34.873768, "lon": -2.502891 },
  { "name": "CSR MADAGH", "lat": 35.012974, "lon": -2.326014 },
  {
    "name": "CSR MADAGH",
    "lat": 35.01307395381912,
    "lon": -2.3260166822090245
  },
  { "name": "CS ATAMNA", "lat": 35.009312, "lon": -2.26123 },
  { "name": "CS ATAMNA", "lat": 35.008312, "lon": -2.26123 },
  { "name": "CSU SAIDIA", "lat": 35.075225, "lon": -2.219209 },
  { "name": "CSU SAIDIA ", "lat": 35.080427472386, "lon": -2.2167478357294783 },
  { "name": "CSU AHFIR", "lat": 34.954236, "lon": -2.106628 },
  { "name": "CSU AHFIR", "lat": 34.952797, "lon": -2.106199 },
  { "name": "CSR  AGHBAL", "lat": 34.937622, "lon": -2.132166 },
  {
    "name": "CSR FEZOUANE",
    "lat": 34.91787228270813,
    "lon": -2.203504360659281
  },
  { "name": "CSU AIN REGADDA", "lat": 34.932087, "lon": -2.21429 },
  { "name": "CSU AIN REGADDA", "lat": 34.931087, "lon": -2.21429 },
  {
    "name": "CSR RISLANE",
    "lat": 34.756909783255345,
    "lon": -2.4323595975721846
  },
  { "name": "CSR TAFOUGHALT", "lat": 34.808414, "lon": -2.406603 },
  { "name": "CSU ZRARKA", "lat": 34.716751, "lon": -1.917405 },
  { "name": "CSR SIDI BOUHRIA", "lat": 34.739266, "lon": -2.361057 },
  { "name": "CSR SIDI BOUHRIA", "lat": 34.738266, "lon": -2.361057 },
  { "name": "CSU 1 SIDI MOHA BEN AHMED", "lat": 34.242611, "lon": -3.33788 },
  { "name": "CSR Lamrija", "lat": 33.993629, "lon": -3.27987 },
  { "name": "CSR RCHIDA", "lat": 33.867645, "lon": -3.215296 },
  { "name": "CSR 1 OULED SALEH", "lat": 34.242603, "lon": -3.337816 },
  { "name": "cs mezguitem", "lat": 34.517672, "lon": -3.59789 },
  { "name": "DR LAMDAFRA", "lat": 34.2614703, "lon": -3.3254357 },
  { "name": "CS SIDI BEN JAAFAR", "lat": 33.964321, "lon": -3.666751 },
  {
    "name": "CSC SEBBAB",
    "lat": 34.44150928579771,
    "lon": -3.1199850963251023
  },
  {
    "name": "CSR BOURACHD",
    "lat": 34.27887257888596,
    "lon": -3.338914995062883
  },
  {
    "name": "CS BERKINE",
    "lat": 34.060320421198604,
    "lon": -3.3363295457173674
  },
  { "name": "CSR 2 TADDERT", "lat": 34.21705974425825, "lon": -3.589053 },
  {
    "name": "Station TADDERT ",
    "lat": 34.21669006347656,
    "lon": -3.5889670848846436
  },
  {
    "name": "MAISON DÆACCOUCHEMENT RAS LAKSAR",
    "lat": 34.39992603422135,
    "lon": -3.456062234986304
  },
  { "name": "CSU 1 GHIATA", "lat": 34.27083595165, "lon": -3.3618393990609756 },
  {
    "name": "CS COMMUNAL OULAD BOURIMA",
    "lat": 34.28238141051616,
    "lon": -3.38874212160772
  },
  { "name": "DR EL KHAT", "lat": 34.270401, "lon": -3.323332 },
  { "name": "CSR 2 SAKKA", "lat": 34.619514, "lon": -3.414011 },
  { "name": "CS OULED HEMOUSSA", "lat": 34.242611, "lon": -3.33788 },
  { "name": "CS GUERCIF", "lat": 34.328438, "lon": -3.368163 },
  { "name": "CS GUERCIF", "lat": 34.329438, "lon": -3.368163 },
  { "name": "CS GUERCIF", "lat": 34.321438, "lon": -3.368163 },
  { "name": "DELEGATION GUERCIF", "lat": 34.24455, "lon": -3.33788 },
  { "name": "DELEGATION GUERCIF", "lat": 34.24555, "lon": -3.33788 },
  { "name": "DELEGATION GUERCIF", "lat": 34.24855, "lon": -3.33788 },
  { "name": "DELEGATION GUERCIF", "lat": 34.24355, "lon": -3.33788 },
  { "name": "CS GUERCIF", "lat": 34.323438, "lon": -3.368163 },
  { "name": "CS GUERCIF", "lat": 34.324438, "lon": -3.368163 },
  { "name": "CS GUERCIF", "lat": 34.325438, "lon": -3.368163 },
  { "name": "CS GUERCIF", "lat": 34.322438, "lon": -3.368163 },
  { "name": "DR ALFOKARA", "lat": 34.634361, "lon": -3.027718 },
  { "name": "DR OULED ATMAN", "lat": 34.634937, "lon": -3.025028 },
  { "name": "BOUHASSANE", "lat": 33.835934, "lon": -3.778278 },
  {
    "name": "CS MOULAY ALI CHERIF A",
    "lat": 34.412079871929656,
    "lon": -2.8949669755325402
  },
  { "name": "CS MOULAY ALI CHERIF A", "lat": 34.412102, "lon": -2.894511 },
  {
    "name": "CENTRE DE SNATE MOULAY ALI CHERIF",
    "lat": 34.399559,
    "lon": -2.893779
  },
  { "name": "CS EL AIOUN", "lat": 34.585217, "lon": -2.503859 },
  { "name": "CSR 1 MELG EL OUIDANE", "lat": 34.557899, "lon": -3.026581 },
  { "name": "CSR 1 MELG EL OUIDANE", "lat": 34.558899, "lon": -3.026581 },
  { "name": "CSR 1 MELG EL OUIDANE", "lat": 34.554899, "lon": -3.026581 },
  { "name": "CSU 1 TAKADDOUM", "lat": 34.407872, "lon": -2.896012 },
  { "name": "CSU 1 TAKADDOUM", "lat": 34.406872, "lon": -2.896012 },
  { "name": "CSU 1 20 AOUT", "lat": 34.401646, "lon": -2.904165 },
  { "name": "CSU 1 20 AOUT", "lat": 34.402646, "lon": -2.904165 },
  { "name": "DR krarma", "lat": 34.425313, "lon": -2.921205 },
  { "name": "CSR 1 OULED MHAMMED", "lat": 33.736965, "lon": -3.032119 },
  { "name": "CSR 1 OULED MHAMMED", "lat": 33.80513, "lon": -3.040863 },
  { "name": "CSR 1 BNI REISS", "lat": 33.947201, "lon": -3.159411 },
  { "name": "CSR 1 BNI REISS", "lat": 33.94814, "lon": -3.159024 },
  {
    "name": "CSR 1 BNI REISS",
    "lat": 33.94684948516119,
    "lon": -3.1587450502624503
  },
  { "name": "CS TANCHERFI", "lat": 34.553357, "lon": -2.850698 },
  { "name": "CS TANCHERFI", "lat": 34.552357, "lon": -2.850698 },
  { "name": "CS TANCHERFI", "lat": 34.551357, "lon": -2.850698 },
  { "name": "CS MESTGMER", "lat": 34.547612, "lon": -3.02493 },
  { "name": "CSR 2 SIDI LAHSEN", "lat": 34.101734, "lon": -2.621525 },
  { "name": "CSR 2 SIDI LAHSEN", "lat": 34.102734, "lon": -2.621525 },
  { "name": "CSR 2 SIDI LAHSEN", "lat": 34.103734, "lon": -2.621525 },
  { "name": "CS OUED ZA", "lat": 34.167976, "lon": -2.738739 },
  {
    "name": "CS MECHRAA HAMMADI",
    "lat": 34.43574756686975,
    "lon": -2.8445455668092166
  },
  { "name": "CS MECHRAA HAMMADI", "lat": 34.733695, "lon": -2.800183 },
  { "name": "GTETER", "lat": 34.410238, "lon": -3.163998 },
  {
    "name": "DR OULAD SLIMANE",
    "lat": 34.38710590935354,
    "lon": -3.2213866269836466
  },
  { "name": "PP TAOURIRT", "lat": 34.413162, "lon": -2.891103 },
  { "name": "PP TAOURIRT", "lat": 34.414162, "lon": -2.891103 },
  { "name": "PP TAOURIRT", "lat": 34.415162, "lon": -2.891103 },
  { "name": "PP TAOURIRT", "lat": 34.416162, "lon": -2.891103 },
  { "name": "dÚp¶t provincial taourirt", "lat": 34.41317, "lon": -2.891104 },
  { "name": "dÚp¶t provincial taourirt", "lat": 34.41417, "lon": -2.891104 },
  { "name": " centre social Ain defla", "lat": 34.596767, "lon": -2.506637 },
  {
    "name": "CS EL AIOUN SIDI MELLOUK",
    "lat": 34.46224502046121,
    "lon": -2.641044955416186
  },
  {
    "name": "DEPOT REGIONAL DES MEDICAMENTS",
    "lat": 34.704856,
    "lon": -1.883316
  },
  {
    "name": "DEPOT REGIONAL DES MEDICAMENTS  A",
    "lat": 34.70196072974527,
    "lon": -1.883320023313515
  },
  { "name": "DÚp¶t rÚgional de OUJDA ", "lat": 34.704375, "lon": -1.8834 },
  { "name": "DÚp¶t rÚgional de OUJDA ", "lat": 34.708375, "lon": -1.8844 },
  {
    "name": "DÚp¶t rÚgional de OUJDA ",
    "lat": 34.70943343015496,
    "lon": -1.883311487102517
  },
  { "name": "DÚp¶t rÚgional de OUJDA ", "lat": 34.709375, "lon": -1.8834 },
  { "name": "DÚpot rÚgional de OUAJDA", "lat": 34.6576381, "lon": -1.9496226 },
  { "name": "DIRECTION REGIONAL DE OUJDA", "lat": 34.680428, "lon": -1.92352 },
  {
    "name": "DIRECTION REGIONAL DE L'ORIENTAL ",
    "lat": 34.676794,
    "lon": -1.921522
  },
  { "name": "CHU OUJDA", "lat": 34.656048, "lon": -1.911477 },
  { "name": "CHU OUJDA", "lat": 34.656059, "lon": -1.911472 },
  { "name": "CHU OUJDA", "lat": 34.655903, "lon": -1.910604 },
  { "name": "CSU 2 BNI DRAR", "lat": 34.847455, "lon": -1.985247 },
  { "name": "CSU 2 BNI DRAR", "lat": 34.8588824, "lon": -1.9926861 },
  { "name": "CSU 2 BNI DRAR", "lat": 34.857772, "lon": -1.992665 },
  { "name": "CS BNI KHALED", "lat": 34.877433, "lon": -2.008932 },
  { "name": "CS BNI KHALED", "lat": 34.878994, "lon": -2.009332 },
  { "name": "CS OULED ABBAS", "lat": 34.658866, "lon": -1.865218 },
  { "name": "CSU AL FATH", "lat": 34.693608, "lon": -1.873438 },
  { "name": "CS SIDI YAHYA", "lat": 34.665077, "lon": -1.869897 },
  { "name": "CS SIDI YAHYA", "lat": 34.664948, "lon": -1.869702 },
  { "name": "CS SIDI YAHYA", "lat": 34.661385, "lon": -1.869373 },
  { "name": "CS SIDI YAHYA", "lat": 34.665948, "lon": -1.869702 },
  { "name": "CS NAJD", "lat": 34.657082, "lon": -1.868087 },
  { "name": "CSU ENNAJD", "lat": 34.653427, "lon": -1.876154 },
  { "name": "CSU ENNAJD", "lat": 34.654427, "lon": -1.876154 },
  { "name": "C/S HAY ANDALOUS", "lat": 34.65684, "lon": -1.885386 },
  {
    "name": "CS NASR 1",
    "lat": 34.710845151923834,
    "lon": -1.8843752797622582
  },
  { "name": "CS NASR 1", "lat": 34.711048, "lon": -1.884327 },
  { "name": "CS NASR 2", "lat": 34.70953369140625, "lon": -1.8968489170074463 },
  { "name": "CS LAZARET", "lat": 34.682634, "lon": -1.883656 },
  { "name": "C/S LAZARET", "lat": 34.680634, "lon": -1.883656 },
  { "name": "CENTRE DE SANTE TOBA", "lat": 34.698505, "lon": -1.91374 },
  { "name": "CS KOULOUCH", "lat": 34.701363, "lon": -1.912074 },
  { "name": "CS SIDI SMAIL", "lat": 34.6831451, "lon": -1.9107394 },
  { "name": "CSR AIN SAFAE", "lat": 34.75269, "lon": -2.143803 },
  { "name": "CSR AIN SAFAE", "lat": 34.75169, "lon": -2.143803 },
  { "name": "CSR 1 AIN SAFAE", "lat": 34.75369, "lon": -2.143803 },
  { "name": "CSR 1 BSARA", "lat": 34.706188, "lon": -2.227625 },
  { "name": "CS LAMHARCHI", "lat": 34.677792, "lon": -1.935401 },
  { "name": "CS LAMHARCHI", "lat": 34.677892, "lon": -1.935401 },
  { "name": "CS OUED ENNACHEF", "lat": 34.674622, "lon": -1.93004 },
  { "name": "CSR SIDI MOUSSA LAMHAYA", "lat": 34.543309, "lon": -2.056257 },
  { "name": "CSU ZRARKA", "lat": 34.719751, "lon": -1.917405 },
  { "name": "CSU ZRARKA", "lat": 34.719751, "lon": -1.917405 },
  { "name": "CSU ZRARKA", "lat": 34.717751, "lon": -1.917405 },
  { "name": "CS essalam", "lat": 34.6561325, "lon": -1.9289955 },
  { "name": "CS essalam", "lat": 34.6561325, "lon": -1.9289955 },
  {
    "name": "SITE DE VACCINATION ESSALEM",
    "lat": 34.6756096,
    "lon": -1.8824816
  },
  { "name": "Cs basso", "lat": 34.6790273, "lon": -1.9019342 },
  { "name": "CS BOUDIR", "lat": 34.693192, "lon": -1.904025 },
  { "name": "CS BOUDIR", "lat": 34.693367, "lon": -1.903729 },
  { "name": "Cs Moulay Driss", "lat": 34.68301, "lon": -1.920954 },
  { "name": "CS AL AMAL", "lat": 34.692879, "lon": -1.943511 },
  { "name": "CS AL AMAL", "lat": 34.694879, "lon": -1.943511 },
  { "name": "CSU AL JORF AL AKHDAR", "lat": 34.699479, "lon": -1.937344 },
  { "name": "CSU AL JORF AL AKHDAR", "lat": 34.698479, "lon": -1.937344 },
  { "name": "CSU AL JORF AL AKHDAR", "lat": 34.702412, "lon": -1.937834 },
  { "name": "CS MAKCEM", "lat": 34.673352, "lon": -1.909933 },
  { "name": "CS MAKCEM", "lat": 34.670906, "lon": -1.90037 },
  { "name": "CS EL AOUNIA", "lat": 34.68969, "lon": -1.900613 },
  { "name": "DÚpot rÚgional d'oujda", "lat": 34.6576381, "lon": -1.9496226 },
  { "name": "DÚp¶t rÚgional d'oujda", "lat": 34.676155, "lon": -1.883408 },
  { "name": "CENTRE DE SANTE RIAD", "lat": 34.6792, "lon": -1.873016 },
  {
    "name": "DÚlÚgation provincial driouch CHF A",
    "lat": 34.984194,
    "lon": -3.389422
  },
  {
    "name": "DÚlÚgation provincial driouch AF A",
    "lat": 34.988194,
    "lon": -3.389422
  },
  {
    "name": "DÚlÚgation provincial driouch AF B",
    "lat": 34.989194,
    "lon": -3.389422
  },
  { "name": "CS BEN TIAB", "lat": 35.090412, "lon": -3.489284 },
  { "name": "C/S OUARDANA", "lat": 35.012978, "lon": -3.427294 },
  { "name": "C/S AIT MAIT", "lat": 35.116966, "lon": -3.440201 },
  { "name": "CS Dar kebdani", "lat": 35.116486, "lon": -3.343738 },
  { "name": "C/S TKHABECHT", "lat": 35.174305, "lon": -3.258789 },
  { "name": "C/S BOURAMANA", "lat": 35.14835, "lon": -3.236927 },
  { "name": "CSR1 MHAJER", "lat": 35.1171, "lon": -3.491818 },
  { "name": "C/S KHMISS TEMSSAMENE", "lat": 35.120567, "lon": -3.639843 },
  { "name": "C/S KHMISS TEMSSAMENE", "lat": 35.123089, "lon": -3.63482 },
  {
    "name": "C/S BOUDINAR",
    "lat": 35.08170795654476,
    "lon": -3.5014510422650322
  },
  { "name": "DR BNI BOUYIRI JBEL", "lat": 35.041382, "lon": -3.654842 },
  { "name": "C/S TEFERSSITE", "lat": 35.01244, "lon": -3.540173 },
  { "name": "CSU 2 MIDAR", "lat": 34.973866, "lon": -3.564881 },
  { "name": "CS KASSITA", "lat": 34.88998, "lon": -3.734315 },
  { "name": "CS AZLEF", "lat": 34.897102, "lon": -3.679681 },
  { "name": "C/S OULAD BOUBKER", "lat": 34.827099, "lon": -3.40572 },
  { "name": "C/S 1 OULAD BOUBKER", "lat": 34.828099, "lon": -3.40572 },
  { "name": "C/S AIN ZOHRA", "lat": 34.680586, "lon": -3.5293813 },
  {
    "name": "C/S AIN ZOHRA",
    "lat": 34.65409079008158,
    "lon": -3.5348479999999904
  },
  { "name": "CSR 1 MTALSSA", "lat": 34.977055, "lon": -3.39676 },
  { "name": "CSU HASSI BILAL", "lat": 34.307266, "lon": -2.205991 },
  {
    "name": "CSU AL MASSIRA",
    "lat": 34.298681913752475,
    "lon": -2.186990560189786
  },
  {
    "name": "JERADA MDINA",
    "lat": 34.33610601398676,
    "lon": -2.1533261311961254
  },
  { "name": "CSU ZIANI ", "lat": 34.00514710573117, "lon": -2.013247213577267 },
  { "name": "Jerada Ibn Rochd", "lat": 34.319025, "lon": -2.159576 },
  { "name": "Jerada Ibn Rochd", "lat": 34.318025, "lon": -2.159576 },
  {
    "name": "CSR LAOUINAT",
    "lat": 34.34364015812206,
    "lon": -2.140200901160678
  },
  { "name": "CS ain beni mathar", "lat": 34.014691, "lon": -2.025329 },
  {
    "name": "OULED GHZIEL",
    "lat": 33.95382633182489,
    "lon": -2.6108383387067473
  },
  {
    "name": "CS GANFOUDA",
    "lat": 34.48415267454499,
    "lon": -2.0462576693189565
  },
  { "name": "CS sidi boubker", "lat": 34.477161, "lon": -1.741715 },
  { "name": "CS TOUISSIT", "lat": 34.479382, "lon": -1.762249 },
  { "name": "CS TOUISSIT", "lat": 34.478382, "lon": -1.762249 },
  { "name": "CS TIOULI", "lat": 34.366982639816, "lon": -1.8370722971034483 },
  { "name": "CSR AIN KAICHAR", "lat": 32.731172, "lon": -6.583245 },
  { "name": "centre yassmina", "lat": 32.889049, "lon": -6.901036 },
  { "name": "centre yassmina", "lat": 32.53282, "lon": -6.54061 },
  { "name": "centre zitoune ", "lat": 32.878252, "lon": -6.890532 },
  { "name": "centre zitoune ", "lat": 32.876252, "lon": -6.890532 },
  {
    "name": "chp hassan 2",
    "lat": 32.53364586684196,
    "lon": -6.542218236482138
  },
  {
    "name": "chp hassan 2",
    "lat": 32.53463687849309,
    "lon": -6.542349999999995
  },
  { "name": "DISPENSAIRE RURAL AIN KAHLA", "lat": 32.968586, "lon": -6.90835 },
  { "name": "csu ibn aabdoun", "lat": 32.53089, "lon": -6.55107 },
  { "name": "csu ibn aabdoun", "lat": 32.53089, "lon": -6.55107 },
  { "name": "csu mly youssef", "lat": 32.53107, "lon": -6.54418 },
  { "name": "centre bni khlef", "lat": 32.953205, "lon": -6.82422 },
  { "name": "centre bni khlef", "lat": 32.955205, "lon": -6.82422 },
  { "name": "centre el massira", "lat": 32.52629, "lon": -6.55148 },
  { "name": "centre el massira", "lat": 32.878673, "lon": -6.920775 },
  { "name": "CENTRE LEGFAF", "lat": 32.922391, "lon": -6.72448 },
  { "name": "csu lquods", "lat": 32.53299, "lon": -6.55475 },
  { "name": "csu lquods", "lat": 32.53199, "lon": -6.55475 },
  {
    "name": "csu fath KHOURIBGA",
    "lat": 32.89705203553841,
    "lon": -6.927633128836055
  },
  { "name": "csu fath KHOURIBGA", "lat": 32.53084, "lon": -6.5524 },
  { "name": "csu fath KHOURIBGA", "lat": 32.53084, "lon": -6.5524 },
  { "name": "CENTRE M'FASSIS", "lat": 32.816299, "lon": -6.785949 },
  { "name": "CENTRE M'FASSIS", "lat": 32.817299, "lon": -6.785949 },
  { "name": "CS BOULANOIR", "lat": 32.51248, "lon": -6.52369 },
  { "name": "CS BOULANOIR", "lat": 32.51348, "lon": -6.52369 },
  { "name": "CS BOULANOIR", "lat": 32.51448, "lon": -6.52369 },
  { "name": "CSR SOUK TLAT OULAD AZZOUZ", "lat": 32.693933, "lon": -6.773237 },
  {
    "name": "CSR KHMISS OULAD AZZOUZ",
    "lat": 32.475811835002204,
    "lon": -6.451527298062689
  },
  { "name": "CSU HATTAN", "lat": 32.50144, "lon": -6.48053 },
  { "name": "CSU HATTAN", "lat": 32.50344, "lon": -6.48053 },
  { "name": "CSU HATTAN", "lat": 32.50544, "lon": -6.48053 },
  { "name": "CSR BNI SMIR", "lat": 32.46545, "lon": -6.39355 },
  { "name": "CSU BOUJNIBA", "lat": 32.53522, "lon": -6.777895 },
  { "name": "CSU BOUJNIBA", "lat": 32.53622, "lon": -6.777895 },
  { "name": "CSU BOUJNIBA", "lat": 32.53722, "lon": -6.777895 },
  { "name": "CSU BOUJNIBA", "lat": 32.53822, "lon": -6.777895 },
  { "name": "CSU BOUJNIBA", "lat": 32.53922, "lon": -6.777895 },
  { "name": "DISPENSAIRE OULAD BOUALI", "lat": 32.849022, "lon": -6.708939 },
  { "name": "DISPENSAIRE OULAD BOUALI", "lat": 32.848022, "lon": -6.708939 },
  { "name": "CS KASBAT TROCH", "lat": 32.56196, "lon": -6.578101 },
  { "name": "CS KASBAT TROCH", "lat": 32.56096, "lon": -6.578101 },
  { "name": "CS FTH OUED ZEM", "lat": 32.859004, "lon": -6.576915 },
  { "name": "CS FTH OUED ZEM", "lat": 32.857004, "lon": -6.576915 },
  { "name": "MSALLA OCP", "lat": 32.856004, "lon": -6.576915 },
  { "name": "MSALLA OCP", "lat": 32.868186, "lon": -6.5580725 },
  { "name": "DR OULAD FENNAN", "lat": 32.988057, "lon": -6.577758 },
  { "name": "DR OULAD FENNAN", "lat": 32.989057, "lon": -6.577758 },
  { "name": "CRU 20AOUT OUED ZEM", "lat": 32.863327, "lon": -6.574873 },
  { "name": "CRU 20AOUT OUED ZEM", "lat": 32.862327, "lon": -6.574873 },
  { "name": "CS OULED AISSA", "lat": 32.956674, "lon": -6.416221 },
  { "name": "CSR Brakssa", "lat": 32.951433, "lon": -6.406316 },
  { "name": "CS RURAL BIR MEZOUI", "lat": 32.54374, "lon": -6.678807 },
  { "name": "CS RURAL BIR MEZOUI", "lat": 32.54274, "lon": -6.678807 },
  { "name": "CS RURAL BIR MEZOUI", "lat": 32.54174, "lon": -6.678807 },
  { "name": "CS RURAL BIR MEZOUI", "lat": 32.54074, "lon": -6.678807 },
  {
    "name": "CS URBAIN EL MASSIRA OUED ZEM",
    "lat": 32.86505,
    "lon": -6.566628
  },
  {
    "name": "CS URBAIN EL MASSIRA OUED ZEM",
    "lat": 32.86405,
    "lon": -6.566628
  },
  {
    "name": "CS URBAIN EL MASSIRA OUED ZEM",
    "lat": 32.86305,
    "lon": -6.566628
  },
  {
    "name": "CS URBAIN MOULAY HASSAN BEJAAD",
    "lat": 32.776223,
    "lon": -6.393445
  },
  {
    "name": "CS URBAIN MOULAY HASSAN BEJAAD",
    "lat": 32.774223,
    "lon": -6.393445
  },
  {
    "name": "CS URBAIN MOULAY HASSAN BEJAAD",
    "lat": 32.773223,
    "lon": -6.393445
  },
  { "name": "CSU 1 20 AOUT BEJAAD", "lat": 32.772333, "lon": -6.391012 },
  { "name": "CSU 1 20 AOUT BEJAAD", "lat": 32.773333, "lon": -6.391012 },
  { "name": "CSU 1 20 AOUT BEJAAD", "lat": 32.774333, "lon": -6.391012 },
  { "name": "CSR OULAS BOUGADI", "lat": 33.0427, "lon": -6.42391 },
  { "name": "CSR GNADIZ", "lat": 33.163715, "lon": -6.83525 },
  { "name": "CSR GNADIZ", "lat": 33.164715, "lon": -6.83525 },
  { "name": "CSR OULED FETTATA", "lat": 33.188829, "lon": -6.65746 },
  { "name": "CSR OULED FETTATA", "lat": 33.187829, "lon": -6.65746 },
  { "name": "CS COMMUNAL AIT AMMAR", "lat": 33.175581, "lon": -6.648698 },
  { "name": "CS COMMUNAL AIT AMMAR", "lat": 33.174581, "lon": -6.648698 },
  { "name": "CS COMMUNAL TACHRAFT", "lat": 32.834844, "lon": -6.475532 },
  { "name": "CS COMMUNAL TACHRAFT", "lat": 32.835844, "lon": -6.475532 },
  { "name": "CS OULED KOUACH", "lat": 32.732812, "lon": -6.365604 },
  { "name": "CS OULED KOUACH", "lat": 32.731812, "lon": -6.365604 },
  { "name": "CS COMMUNAL BOUKHROSS", "lat": 32.866003, "lon": -6.110889 },
  { "name": "CS COMMUNAL BOUKHROSS", "lat": 32.867003, "lon": -6.110889 },
  { "name": "CSR1 BNI ZRENTEL", "lat": 32.739354, "lon": -6.24427 },
  { "name": "CSR1 BNI ZRENTEL", "lat": 32.738354, "lon": -6.24427 },
  { "name": "CS COMMUNAL BNI BATAO", "lat": 32.827273, "lon": -6.288719 },
  { "name": "CSR 1 ROUACHED", "lat": 32.867998, "lon": -6.356306 },
  { "name": "CSR 1 ROUACHED", "lat": 32.868998, "lon": -6.356306 },
  { "name": "CS COMMUNAL CHAKRAN", "lat": 32.898882, "lon": -6.381253 },
  { "name": "CS COMMUNAL CHAKRAN", "lat": 32.897882, "lon": -6.381253 },
  { "name": "CS COMMUNAL LMAADNA", "lat": 32.938014, "lon": -6.495473 },
  { "name": "CS OUERRADA", "lat": 32.888528, "lon": -6.633209 },
  { "name": "CSC EL FOKRA", "lat": 32.730392, "lon": -6.941173 },
  { "name": "CSR Ouled abdoun", "lat": 32.905774, "lon": -6.925635 },
  { "name": "CHP KHENIFRA", "lat": 32.964544, "lon": -5.662159 },
  { "name": "CHP  KHENIFRA", "lat": 32.936797, "lon": -5.671093 },
  { "name": "dr taskort", "lat": 32.48941, "lon": -5.670837 },
  { "name": "CS HAY NASSIM", "lat": 32.925696, "lon": -5.662589 },
  { "name": "CSU HAMRIYA", "lat": 32.934193, "lon": -5.674566 },
  { "name": "CSU HAMRIYA", "lat": 32.9331089, "lon": -5.6743464 },
  { "name": "CS TAALALIN", "lat": 32.938145, "lon": -5.678652 },
  { "name": "CS HAY HASSAN 2", "lat": 32.56364, "lon": -5.40362 },
  { "name": "CS HAY ASSAKA", "lat": 32.946856, "lon": -5.678776 },
  { "name": "CSU AMALOU", "lat": 32.93462, "lon": -5.674121 },
  {
    "name": "Centre rÚfÚrence en santÚ reproductive",
    "lat": 32.946717,
    "lon": -5.653149
  },
  {
    "name": "CS ET MAISON D ACCOUCHEMENT LEQBAB",
    "lat": 32.741266,
    "lon": -5.521815
  },
  {
    "name": "CS ET MAISON D ACCOUCHEMENT LEQBAB",
    "lat": 32.742266,
    "lon": -5.521815
  },
  {
    "name": "DISPENSAIRE RURAL TAMEKAYDOUTE",
    "lat": 32.664685,
    "lon": -5.357285
  },
  {
    "name": "DISPENSAIRE RURAL TAMEKAYDOUTE",
    "lat": 32.665685,
    "lon": -5.357285
  },
  { "name": "DISPENSAIRE RURAL TIMDGHASS", "lat": 32.6741, "lon": -5.473296 },
  { "name": "CSR SIDI YAHYA OUSSAD", "lat": 32.40596, "lon": -5.576477 },
  { "name": "CSR SIDI YAHYA OUSSAD", "lat": 32.40496, "lon": -5.576477 },
  { "name": "CS TIGHESSALINE", "lat": 32.762161, "lon": -5.674885 },
  { "name": "CS TIGHESSALINE", "lat": 32.763161, "lon": -5.674885 },
  { "name": "CS RURAL OUAOUMANA", "lat": 32.707975, "lon": -5.80704 },
  { "name": "CS RURAL OUAOUMANA", "lat": 32.707975, "lon": -5.80904 },
  { "name": "CS RURAL OUAOUMANA", "lat": 32.707975, "lon": -5.80704 },
  { "name": "CS AIT ISSHAK", "lat": 32.763983, "lon": -5.724492 },
  { "name": "csr1 lehri", "lat": 32.858557, "lon": -5.623581 },
  { "name": "CS RURAL 1 EL BORJ", "lat": 33.01068, "lon": -5.374 },
  { "name": "CS COMMUNAL TANIFIT", "lat": 33.084915, "lon": -5.492312 },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.939797,
    "lon": -5.671093
  },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.930797,
    "lon": -5.671093
  },
  { "name": "CS COMMUNAL TIGHZA", "lat": 33.185868, "lon": -5.624602 },
  { "name": "DISPENSAIRE RURAL EL HAMMAM", "lat": 33.174016, "lon": -5.471225 },
  { "name": "CRU TAHJAOUIT", "lat": 33.10187, "lon": -5.3342 },
  { "name": "CRU TAHJAOUIT", "lat": 33.10287, "lon": -5.3342 },
  { "name": "CRU MRIRT", "lat": 33.184098, "lon": -5.530049 },
  { "name": "DR BOUCHBEL", "lat": 33.305257, "lon": -5.589599 },
  { "name": "DISPENSAIRE RURAL AROUGOU", "lat": 32.928294, "lon": -5.530717 },
  { "name": "DR Jenan mass", "lat": 33.061337, "lon": -5.689922 },
  { "name": "CS COMMUNAL HAD BOUHASSOUN", "lat": 33.146649, "lon": -6.079662 },
  { "name": "CS COMMUNAL HAD BOUHASSOUN", "lat": 33.147649, "lon": -6.079662 },
  { "name": "DR LAAOUINAT", "lat": 33.18313, "lon": -6.027723 },
  { "name": "CS COMMUNAL MOULAY BOUAZZA", "lat": 33.222662, "lon": -6.198945 },
  { "name": "CS COMMUNAL MOULAY BOUAZZA", "lat": 33.223662, "lon": -6.198945 },
  { "name": "csr2 kaf nsour", "lat": 32.913501, "lon": -5.960089 },
  { "name": "CSR1 LAGOUIDA", "lat": 33.050184, "lon": -5.998549 },
  { "name": "DR S'HAMMED MBAREK", "lat": 33.116559, "lon": -6.12383 },
  { "name": "Sebt Ait RAHOU", "lat": 33.214607, "lon": -6.261458 },
  { "name": "DR TNIN AIT BOUKHAYOU", "lat": 33.340923, "lon": -6.277587 },
  { "name": "CSR1 AJDIR", "lat": 32.946713, "lon": -5.409928 },
  { "name": "CSR SIDI AMMAR", "lat": 32.949486, "lon": -5.836561 },
  {
    "name": "HOPITAL local mrirt",
    "lat": 33.16229901862968,
    "lon": -5.560884504299157
  },
  { "name": "CSR1 AIT WAHABA", "lat": 33.076976, "lon": -5.397901 },
  { "name": "DR AOUARACH", "lat": 32.771687, "lon": -5.887989 },
  { "name": "csu 1 kouasem", "lat": 32.30091, "lon": -6.41081 },
  { "name": "csu1  jamila", "lat": 32.505025, "lon": -6.695881 },
  {
    "name": "dÚlÚgation fquih ben saleh",
    "lat": 32.504852203598034,
    "lon": -6.696231294477473
  },
  {
    "name": "dÚlÚgation fquih ben saleh",
    "lat": 32.504852203598034,
    "lon": -6.696217883432398
  },
  { "name": "DR LAKHLALTA", "lat": 32.261486, "lon": -6.80792 },
  { "name": "CHP FQUIH BEN SALEH", "lat": 32.30255, "lon": -6.41028 },
  {
    "name": "DISPENSAIRE AIT LAARBI SOUSSI",
    "lat": 32.462902,
    "lon": -6.673304
  },
  {
    "name": "DISPENSAIRE RURAL OULED BOUKHADOU",
    "lat": 32.545847,
    "lon": -6.42484
  },
  { "name": "CSR2 BNI OUKIL", "lat": 32.576182, "lon": -6.838474 },
  { "name": "CSR2 BNI OUKIL", "lat": 32.575182, "lon": -6.838474 },
  {
    "name": "DISPENSAIRE RURAL OULED ALI DHIRAT",
    "lat": 32.467654,
    "lon": -6.86275
  },
  { "name": "CS COMMUNAL BENI CHEGDAL", "lat": 32.496465, "lon": -6.70321 },
  { "name": "DR OULED AHMED", "lat": 32.459343, "lon": -6.917695 },
  {
    "name": "MAISON D ACCOUCHEMENT DAR OULD ZIDOUH",
    "lat": 32.18386,
    "lon": -6.54058
  },
  { "name": "DR LEHLAF", "lat": 32.507165, "lon": -6.683486 },
  { "name": "CS COMMUNAL OULED HASSOUN", "lat": 32.30584, "lon": -6.32333 },
  { "name": "CS COMMUNAL OULED HASSOUN", "lat": 32.30584, "lon": -6.32233 },
  { "name": "DISPENSAIRE RURAL TARMASST", "lat": 32.15098, "lon": -6.58086 },
  { "name": "CSR2 OD ABDELLAH", "lat": 32.497859, "lon": -6.45009 },
  { "name": "CSR2 OD ABDELLAH", "lat": 32.496859, "lon": -6.45009 },
  { "name": "DISPENSAIRE RURAL LAMRABTIA", "lat": 32.1639, "lon": -7.05152 },
  { "name": "DR Lmrabta", "lat": 32.3559913, "lon": -6.72949075698 },
  { "name": "CSR HAD BENMOUSSA", "lat": 32.234245, "lon": -6.942788 },
  { "name": "DR OULED MBAREK", "lat": 32.12139, "lon": -6.50576 },
  { "name": "CSU 2 OD AYYAD", "lat": 32.203559, "lon": -6.796084 },
  { "name": "CSU 2 OD AYYAD", "lat": 32.198969, "lon": -6.796058 },
  {
    "name": "DISPENSAIRE RURAL OULED BARAKAT",
    "lat": 32.298768,
    "lon": -6.907664
  },
  { "name": "CSU1 SOUK SEBT", "lat": 32.387082, "lon": -6.655577 },
  { "name": "CSU1 SOUK SEBT", "lat": 32.295289, "lon": -6.701025 },
  { "name": "CSU1 SOUK SEBT", "lat": 32.294289, "lon": -6.701025 },
  { "name": "CSR2 ELBARRADIA", "lat": 32.549358, "lon": -6.525346 },
  { "name": "DR HAL SOUSS", "lat": 32.461256, "lon": -6.297451 },
  { "name": "DR OULED ALI OUED", "lat": 32.26127, "lon": -6.27486 },
  { "name": "CS OULED DRISS", "lat": 32.389378, "lon": -6.3141 },
  {
    "name": "CENTRE DE VACCINATION LAHLALMA",
    "lat": 32.397379,
    "lon": -6.521952
  },
  { "name": "CSR2 SIDI AISSA", "lat": 32.3711, "lon": -6.696433 },
  { "name": "DR OULED MRAH", "lat": 32.3003931, "lon": -6.7102241 },
  { "name": "CSR 2 OULED ZEMMAM", "lat": 32.36133, "lon": -6.68871 },
  { "name": "DR LBOUWKER", "lat": 32.342415, "lon": -6.759263 },
  { "name": "CSU-1 CHTIOUI", "lat": 32.301868, "lon": -6.709905 },
  {
    "name": "CSU-1 CHTIOUI",
    "lat": 32.331305866385485,
    "lon": -6.720461397553263
  },
  { "name": "DR OULED ILLOUL", "lat": 32.299778, "lon": -6.71081 },
  { "name": "CSR 1 OULED BOUAZZA", "lat": 32.263547, "lon": -6.636922 },
  { "name": "CSR 1 OULED BOUAZZA", "lat": 32.264547, "lon": -6.636922 },
  { "name": "DR KRAZZA", "lat": 32.24872, "lon": -6.5705 },
  { "name": "DR OD HAMMOU", "lat": 32.256597, "lon": -6.611956 },
  {
    "name": "CENTRE DE DIAGNOSTIC JNAN EL KHAIR",
    "lat": 32.217094,
    "lon": -6.640934
  },
  { "name": "DR TAKHSAYT", "lat": 32.198452, "lon": -6.7713423 },
  {
    "name": "CS AIT RAMI ARRONDISSEMENT OULED NASER",
    "lat": 32.262631,
    "lon": -6.78763
  },
  { "name": "DR OULED BOURAHMOUNE", "lat": 32.217094, "lon": -6.640934 },
  {
    "name": "DISPENSAIRE OULED HEJJAJ OULED SLIMAN",
    "lat": 32.303348,
    "lon": -6.724812
  },
  {
    "name": "DISPENSAIRE OULED HEJJAJ OULED SLIMAN",
    "lat": 32.304348,
    "lon": -6.724812
  },
  { "name": "DR ROUWAJAH", "lat": 32.31472, "lon": -6.708351 },
  { "name": "DR OULED AYOUB", "lat": 32.4772137, "lon": -6.6213851 },
  { "name": "CS rural-1 AHL MERABAA", "lat": 32.484795, "lon": -6.605863 },
  { "name": "CS rural-1 AHL MERABAA", "lat": 32.584795, "lon": -6.705863 },
  {
    "name": "Dispensaire rural OULED YAALA",
    "lat": 32.436764,
    "lon": -6.527145
  },
  { "name": "DR OULED ABDOUN", "lat": 32.50714, "lon": -6.683485 },
  {
    "name": "cs od ziane",
    "lat": 32.504869061844445,
    "lon": -6.69628141468048
  },
  {
    "name": "CS SIDI CHENNAN",
    "lat": 32.50074079999526,
    "lon": -6.677337884902954
  },
  { "name": "DR OULED NEJJAA", "lat": 32.517795, "lon": -6.574169 },
  { "name": "DR JEBALLA", "lat": 32.608883, "lon": -6.964034 },
  { "name": "DR rural KRARMA LCHHOUB", "lat": 32.53764, "lon": -6.945774 },
  { "name": "dÚlÚgation fquih ben saleh", "lat": 32.30782, "lon": -6.41458 },
  { "name": "dÚlÚgation fquih ben saleh", "lat": 32.30982, "lon": -6.41458 },
  { "name": "DR chorfa", "lat": 32.535751, "lon": -6.751023 },
  { "name": "Laboratoire CHP", "lat": 32.502134, "lon": -6.684354 },
  { "name": "CS URBAIN HAJRA", "lat": 32.602149, "lon": -6.27112 },
  { "name": "CS URBAIN HAJRA", "lat": 32.600149, "lon": -6.27112 },
  { "name": "CSU KASBA TADLA", "lat": 32.35097, "lon": -6.15513 },
  { "name": "CSU KASBA TADLA", "lat": 32.35397, "lon": -6.15513 },
  {
    "name": "CS RURAL 1 CHARIKAT RABH",
    "lat": 32.34319939894835,
    "lon": -6.142489261422427
  },
  { "name": "CS RURAL 1 CHARIKAT RABH", "lat": 32.34413, "lon": -6.14265 },
  { "name": "CSR 1 KAMOUNE", "lat": 32.56563, "lon": -6.16494 },
  { "name": "CSR 1 KAMOUNE", "lat": 32.56363, "lon": -6.16494 },
  { "name": "CSR1 ADOUZ", "lat": 32.36263102071869, "lon": -6.296416112125712 },
  { "name": "DR AIT ROUADI", "lat": 32.638128, "lon": -6.109981 },
  { "name": "HOPITAL LOCAL DEL KSIBA", "lat": 32.566969, "lon": -6.022424 },
  { "name": "HOPITAL LOCAL DEL KSIBA", "lat": 32.564969, "lon": -6.022424 },
  { "name": "HOPITAL LOCAL DEL KSIBA", "lat": 32.561969, "lon": -6.022424 },
  { "name": "CS COMMUNAL OULED SAID L OUED", "lat": 32.3325, "lon": -6.372075 },
  { "name": "CS COMMUNAL OULED SAID L OUED", "lat": 32.3305, "lon": -6.372075 },
  { "name": "CS COMMUNAL OULED YOUSSEF", "lat": 32.28329, "lon": -6.22394 },
  { "name": "CS COMMUNAL OULED IICH", "lat": 32.438831, "lon": -6.329481 },
  { "name": "CS COMMUNAL OULED IICH", "lat": 32.437831, "lon": -6.329481 },
  { "name": "CSU1 MASSIRA 2", "lat": 32.3567815, "lon": -6.3618897 },
  { "name": "CSU1 MASSIRA 2", "lat": 32.3557815, "lon": -6.3618897 },
  { "name": "CSU GHEDIRA HAMRA", "lat": 32.336857, "lon": -6.353655 },
  { "name": "CSU GHEDIRA HAMRA", "lat": 32.335857, "lon": -6.353655 },
  { "name": "CSU1 RMILA", "lat": 32.337236, "lon": -6.343855 },
  { "name": "CSU1 RMILA", "lat": 32.335236, "lon": -6.343855 },
  { "name": "CSU1 RMILA", "lat": 32.338073, "lon": -6.347029 },
  { "name": "CSU SOMAA", "lat": 32.335482, "lon": -6.334658 },
  { "name": "CSU SOMAA", "lat": 32.334482, "lon": -6.334658 },
  { "name": "CSU SOMAA", "lat": 32.336482, "lon": -6.334658 },
  { "name": "CSU GHAR NHAL", "lat": 32.342915, "lon": -6.348829 },
  { "name": "CSU GHAR NHAL", "lat": 32.340915, "lon": -6.348829 },
  { "name": "CSU GHAR NHAL", "lat": 32.341915, "lon": -6.348829 },
  { "name": "CSU EL AMRIA", "lat": 32.339777, "lon": -6.358437 },
  { "name": "CSU EL AMRIA", "lat": 32.336777, "lon": -6.358437 },
  { "name": "CRU1 HAY EL MOHAMMADI", "lat": 32.347274, "lon": -6.355072 },
  {
    "name": "CRU1 HAY EL MOHAMMADI",
    "lat": 32.34986917003327,
    "lon": -6.355072
  },
  { "name": "CSU1 OULED HAMDAN", "lat": 32.32337593, "lon": -6.371611 },
  { "name": "CSU1 OULED HAMDAN", "lat": 32.337131, "lon": -6.372639 },
  { "name": "CSU1 OURBIAA", "lat": 32.316684, "lon": -6.359523 },
  { "name": "CSU1 OURBIAA", "lat": 32.317684, "lon": -6.359523 },
  { "name": "CSR 1 FOUM EL ANSEUR", "lat": 32.373338, "lon": -6.25932 },
  { "name": "CSR 1 FOUM EL ANSEUR", "lat": 32.372338, "lon": -6.25932 },
  { "name": "DR LFARYATA", "lat": 32.40689, "lon": -6.234256 },
  { "name": "CS 1 RURAL TANOUGHA", "lat": 32.474636, "lon": -6.149029 },
  {
    "name": "CS 1 RURAL TANOUGHA",
    "lat": 32.47576337902113,
    "lon": -6.1490607261657715
  },
  { "name": "CSR1 IGHREM LAALAM", "lat": 32.525238, "lon": -6.154448 },
  { "name": "CSR1 IGHREM LAALAM", "lat": 32.527238, "lon": -6.154448 },
  { "name": "csr 1  IGHREM LAALAM", "lat": 32.527036, "lon": -6.154578 },
  {
    "name": "CS COMMUNAL AIT IKKOU AIT ALI OUHAMMOU",
    "lat": 32.519969,
    "lon": -6.145074
  },
  {
    "name": "CS COMMUNAL AIT IKKOU AIT ALI OUHAMMOU",
    "lat": 32.519969,
    "lon": -6.145074
  },
  { "name": "DR OULED ISMAIL", "lat": 32.485799, "lon": -6.396371 },
  { "name": "CS COMMUNAL FOM OUDI", "lat": 32.27241, "lon": -6.428466 },
  { "name": "CS COMMUNAL FOM OUDI", "lat": 32.276241, "lon": -6.428466 },
  { "name": "Csr2 ouled mbarek", "lat": 32.27666, "lon": -6.424824 },
  { "name": "CS COMMUNAL OULED MBAREK", "lat": 32.27739, "lon": -6.453879 },
  { "name": "CS COMMUNAL OULED MBAREK", "lat": 32.27939, "lon": -6.453879 },
  { "name": "CS COMMUNAL SIDI JABER", "lat": 32.371349, "lon": -6.455936 },
  { "name": "CS COMMUNAL SIDI JABER", "lat": 32.372349, "lon": -6.455936 },
  { "name": "CS LAAYAYTA", "lat": 32.342052, "lon": -6.4351187 },
  { "name": "CS LAAYAYTA", "lat": 32.344052, "lon": -6.4351187 },
  { "name": "CS LAAYAYTA", "lat": 32.343052, "lon": -6.4351187 },
  { "name": "CSR 2 TAGHZIRT", "lat": 32.44162, "lon": -6.200945 },
  { "name": "CSR TAGHAZOUT", "lat": 32.57478, "lon": -6.117038 },
  { "name": "C/S ZAWYA CHEIKH", "lat": 32.646139, "lon": -5.922184 },
  { "name": "C/S ZAWYA CHEIKH", "lat": 32.647139, "lon": -5.922184 },
  { "name": "C/S TBIHIT AIT OUM ALBAKHT", "lat": 32.66626, "lon": -5.892494 },
  { "name": "C/S TBIHIT AIT OUM ALBAKHT", "lat": 32.66826, "lon": -5.892494 },
  { "name": "C/S TBIHIT AIT OUM ALBAKHT", "lat": 32.66726, "lon": -5.892494 },
  { "name": "CENTRE DE SANTE GUETTAYA", "lat": 32.572365, "lon": -6.345104 },
  { "name": "DR TIGHBOULA", "lat": 32.601657, "lon": -6.020195 },
  { "name": "CSR2 NAOUR", "lat": 32.485325, "lon": -5.912009 },
  { "name": "CSR2 NAOUR", "lat": 32.484325, "lon": -5.912009 },
  { "name": "CSR2 NAOUR", "lat": 32.486325, "lon": -5.912009 },
  { "name": "CSC TIZI NESLI", "lat": 32.468758, "lon": -5.77153 },
  { "name": "CSC TIZI NESLI", "lat": 32.465758, "lon": -5.77153 },
  {
    "name": "CSC BOUTFERDA",
    "lat": 32.355430603027344,
    "lon": -5.832066535949707
  },
  { "name": "CSC BOUTFERDA", "lat": 32.355113, "lon": -5.831861 },
  {
    "name": "CHR BENI MELLAL CH FROID A-1",
    "lat": 32.326732,
    "lon": -6.353681
  },
  { "name": "DR TIHOUNA OUIDIR", "lat": 32.553962, "lon": -5.66593 },
  { "name": "CSC AGHBALA", "lat": 32.477644, "lon": -5.644924 },
  { "name": "CSC AGHBALA", "lat": 32.48045, "lon": -5.644695 },
  { "name": "HOPITAL DE PROXEMITE DEMNAT", "lat": 31.76141, "lon": -7.010258 },
  {
    "name": "DELEGATION DE LA SANTE AZILAL",
    "lat": 31.966599,
    "lon": -6.5657479
  },
  { "name": "PHARMACIE PROVINCIALE ", "lat": 31.963599, "lon": -6.567479 },
  { "name": "CSR2 AFOURER", "lat": 32.195772, "lon": -6.522048 },
  { "name": "CSR2 AFOURER", "lat": 32.21416, "lon": -6.531207 },
  {
    "name": "CSR2 OUAOUIZEGHT",
    "lat": 32.158337079441836,
    "lon": -6.353508482139587
  },
  {
    "name": "CSR2 OUAOUIZEGHT",
    "lat": 32.15900012307976,
    "lon": -6.353382418315887
  },
  {
    "name": "CSR2 OUAOUIZEGHT",
    "lat": 32.15878440735713,
    "lon": -6.353178570430755
  },
  { "name": "CSU NOUVEAU QUARTIER", "lat": 31.9597683, "lon": -6.5723906 },
  { "name": "CSU QUARTIER ADMINISTRATIF", "lat": 31.96699, "lon": -6.567261 },
  { "name": "CSU TICHIBITE", "lat": 31.949156, "lon": -6.585418 },
  { "name": "CSU DEMNAT KESBAH", "lat": 31.732821, "lon": -6.999422 },
  {
    "name": "CSR2 ANERGUI",
    "lat": 32.067665100097656,
    "lon": -5.935545921325684
  },
  { "name": "CSR2 TIDILI", "lat": 31.727956, "lon": -7.192793 },
  { "name": "CSR2 TIDILI", "lat": 31.727799, "lon": -7.192876 },
  { "name": "CSUA ISRANE", "lat": 31.740025, "lon": -7.008475 },
  { "name": "CSR2 BZOU", "lat": 32.08301800790443, "lon": -7.047149574699409 },
  { "name": "CSR2 BZOU", "lat": 32.08339298290255, "lon": -7.047326600494392 },
  { "name": "CSR1 OUED LAABID", "lat": 32.216312, "lon": -6.583943 },
  { "name": "CSR2 AIT ATTAB", "lat": 32.111819, "lon": -6.69743 },
  { "name": "CSR1 TISQUI", "lat": 32.116905, "lon": -6.614573 },
  { "name": "CSR1 TISQUI", "lat": 32.11348, "lon": -6.770345 },
  { "name": "CSR1 TAOUNZA", "lat": 32.096074, "lon": -6.600267 },
  { "name": "DR INDJARNE", "lat": 32.15261, "lon": -6.624589 },
  { "name": "CSR1 OUZOUD", "lat": 32.012219, "lon": -6.71793 },
  { "name": "CSR1 OUZOUD", "lat": 31.979981, "lon": -6.54538 },
  { "name": "CSR tamda noumarcide", "lat": 31.922558, "lon": -6.642745 },
  { "name": "CSR1 TAMDA NOUMARCIDE", "lat": 31.922723, "lon": -6.6427103 },
  { "name": "CSR2 OUAOULA", "lat": 31.866223, "lon": -6.756707 },
  { "name": "CSR2 OUAOULA", "lat": 31.865223, "lon": -6.756707 },
  {
    "name": "CSR2 Tanant",
    "lat": 31.862926483154297,
    "lon": -6.947295188903809
  },
  { "name": "DR SKOURA", "lat": 31.8930645, "lon": -7.0119705 },
  { "name": "CSR1 AIT MAJDEN", "lat": 31.843675, "lon": -6.966276 },
  { "name": "CSR1 AIT MAJDEN", "lat": 31.842675, "lon": -6.966276 },
  {
    "name": "CSR2 IMINIFRI",
    "lat": 31.72097373427404,
    "lon": -6.971545760327156
  },
  { "name": "CSR2 IMINIFRI", "lat": 31.676945, "lon": -6.986599 },
  {
    "name": "Centre de SantÚ Rural Bouaachiba",
    "lat": 31.701958,
    "lon": -7.297028
  },
  { "name": "DR DRAA", "lat": 31.743767, "lon": -7.123397 },
  { "name": "CSR1 AIT MAALA", "lat": 31.676062, "lon": -7.074724 },
  { "name": "CSR2 FOUM JEMAA", "lat": 31.95967, "lon": -6.981146 },
  { "name": "DR TISLIT", "lat": 32.015605, "lon": -6.828903 },
  {
    "name": "CSR2 AIT MHAMMED",
    "lat": 31.877973556518555,
    "lon": -6.469825744628906
  },
  { "name": "CSR1 BIN ELOUIDANE", "lat": 32.102169, "lon": -6.458835 },
  { "name": "DR TANFARDA", "lat": 32.214207, "lon": -6.642946 },
  { "name": "CSR1  BOUAAZIER", "lat": 32.256266, "lon": -6.926075 },
  { "name": "CSR1  BOUAAZIER", "lat": 32.180117, "lon": -69264900 },
  { "name": "DR RFALA", "lat": 32.175029, "lon": -6.835882 },
  { "name": "DR RFALA", "lat": 32.175029, "lon": -6.835882 },
  { "name": "CSR1 AIT GUIRTE", "lat": 32.122393, "lon": -6.535412 },
  { "name": "DR AIT OUAARDA", "lat": 32.111931, "lon": -6.506298 },
  { "name": "CSR1 TIFERT N'AIT HAMZA", "lat": 32.317657, "lon": -5.973027 },
  { "name": "CSR2 AIT OUQABLI", "lat": 32.3492668, "lon": -6.0117932 },
  {
    "name": "DR AIT CHWARIT (TACHOUARITE)",
    "lat": 31.826354,
    "lon": -6.829665
  },
  {
    "name": "CSR2 ISSOULANE (AIT OUMDISS)",
    "lat": 31.493881,
    "lon": -7.055311
  },
  { "name": "DR IGMIR", "lat": 31.698827, "lon": -6.547003 },
  { "name": "CSR1 BENI AYAT ", "lat": 32.212433, "lon": -6.530117 },
  { "name": "CSR1 BENI AYAT ", "lat": 32.216309, "lon": -6.583954 },
  { "name": "DR LAAOUINA", "lat": 32.2168541, "lon": -6.6914804 },
  { "name": "CSR 2 TAGUELEFT", "lat": 32.2378229, "lon": -6.1201996 },
  {
    "name": "DR  KAROUAL",
    "lat": 31.723988048171922,
    "lon": -6.9078440742759994
  },
  {
    "name": "CSR2 AIT BOUGUEMAZ (TABANT )",
    "lat": 31.659664154052734,
    "lon": -6.407159805297852
  },
  {
    "name": "CSR2 AIT BOUAOULI (ABACHKOU)",
    "lat": 31.619078,
    "lon": -6.630867
  },
  { "name": "CSC 1 TABIA", "lat": 32.031254, "lon": -6.796126 },
  { "name": "DR GUIMI", "lat": 32.00253, "lon": -6.886678 },
  { "name": "CS COMMUNAL TIRST", "lat": 32.036919, "lon": -6.934837 },
  { "name": "CSR1 TIMOULILTE", "lat": 32.207837, "lon": -6.465261 },
  { "name": "csr1 timoulilt", "lat": 32.106016, "lon": -6.426008 },
  {
    "name": "DR AIT TAGUELLA",
    "lat": 31.96200180053711,
    "lon": -6.566400051116943
  },
  { "name": "CSR 2 AGOUDID", "lat": 31.979981, "lon": -6.54538 },
  { "name": "DR AMOUGUEZ", "lat": 31.80651, "lon": -6.899111 },
  { "name": "DR INGUIRT", "lat": 32.0556399, "lon": -6.5444602 },
  {
    "name": "CSR1 AIT BAKHOUCH (AIT MAZIGH)",
    "lat": 32.076883,
    "lon": -6.357174
  },
  { "name": "DR MAHDA", "lat": 31.865826, "lon": -6.859773 },
  { "name": "CSR2 TILOUGUITE", "lat": 32.029361, "lon": -6.211432 },
  { "name": "CSR2 TABAROUCHT", "lat": 32.167428, "lon": -6.217312 },
  { "name": "CSR1 IWARIDEN", "lat": 31.726949, "lon": -6.905054 },
  { "name": "CSR1 LWARIDEN ", "lat": 31.727736, "lon": -6.90444 },
  { "name": "DR IHOUDJINE", "lat": 31.699613, "lon": -7.165631 },
  { "name": "CSR2 AIT AMLIL", "lat": 31.484027, "lon": -6.942464 },
  { "name": "CSR2 AIT AMLIL", "lat": 31.4822383, "lon": -6.9421163 },
  {
    "name": "Ancien BÔtiment MunicipalitÚ d'Ouled Teima",
    "lat": 30.387622023049992,
    "lon": -9.225244626983633
  },
  {
    "name": "Ancien BÔtiment MunicipalitÚ d'Ouled Teima",
    "lat": 30.387622023049992,
    "lon": -9.225244626983633
  },
  { "name": "CSU Talmaklet", "lat": 30.467995, "lon": -8.879548 },
  {
    "name": "CSU Talmaklet",
    "lat": 30.46816178233301,
    "lon": -8.879540790178293
  },
  { "name": "CSR Tioute", "lat": 30.399871626392272, "lon": -8.68930544180298 },
  { "name": "CS AIT ABDELLAH", "lat": 29.814765, "lon": -8.768566 },
  { "name": "CS AIT ABDELLAH", "lat": 29.814836, "lon": -8.768564 },
  { "name": "CS CHAFARNI", "lat": 30.8335463, "lon": -8.3979649 },
  { "name": "CSC Ahmer", "lat": 30.59193432435629, "lon": -9.007737660049438 },
  { "name": "CSC Ahmer", "lat": 30.591902, "lon": -9.008199 },
  { "name": "CSC Ait Makhlouf Boulaajlat", "lat": 30.603148, "lon": -8.911191 },
  {
    "name": "CSC El Feid",
    "lat": 30.660192849878275,
    "lon": -8.174139674591066
  },
  { "name": "CSC El Feid", "lat": 30.661005, "lon": -8.175749 },
  { "name": "CSC Ighri", "lat": 30.470307174959874, "lon": -7.723786645507809 },
  { "name": "CSC Imoulass", "lat": 30.744106, "lon": -8.764167 },
  {
    "name": "CSC Imoulass",
    "lat": 30.744327303619748,
    "lon": -8.764440585319528
  },
  {
    "name": "CSC TAMALOKT",
    "lat": 30.463910908354993,
    "lon": -8.87688547123337
  },
  {
    "name": "CSR TAMALOKT",
    "lat": 30.46367509156605,
    "lon": -8.876448271163943
  },
  { "name": "CSC Tisrass", "lat": 30.722046, "lon": -8.065113 },
  { "name": "CSC Zaouit Sidi Tahar", "lat": 30.431774, "lon": -9.053785 },
  {
    "name": "CSC Zaouit Sidi Tahar",
    "lat": 30.43150110232989,
    "lon": -9.054171238098142
  },
  { "name": "CSR Agadir Melloul", "lat": 30.233122, "lon": -7.794658 },
  {
    "name": "CSR Agadir Melloul",
    "lat": 30.232848548152102,
    "lon": -7.795441205032356
  },
  { "name": "CSR Agadir Nait Taleb", "lat": 30.568724, "lon": -7.856238 },
  {
    "name": "CSR Agadir Nait Taleb",
    "lat": 30.56950920471714,
    "lon": -7.856978289688105
  },
  { "name": "CSR Ait Igass", "lat": 30.54254, "lon": -8.71862 },
  {
    "name": "CSR Ait Igass",
    "lat": 30.54244064928117,
    "lon": -8.718335430549615
  },
  { "name": "CSR Arazane", "lat": 30.502028, "lon": -8.60797 },
  {
    "name": "CSR Arazane",
    "lat": 30.502261412872812,
    "lon": -8.607275307865136
  },
  { "name": "CSR Argana", "lat": 30.756911231100677, "lon": -9.17903466137695 },
  { "name": "CSR Assads", "lat": 30.3465, "lon": -9.040364 },
  { "name": "CSR Assads", "lat": 30.34529634428247, "lon": -9.04087898413085 },
  { "name": "CSR Assaisse", "lat": 30.575679, "lon": -7.611481 },
  {
    "name": "CSR Assaisse",
    "lat": 30.57559586626653,
    "lon": -7.612076450401317
  },
  { "name": "CSR Assaki", "lat": 30.56076043100527, "lon": -8.032553104507455 },
  { "name": "CSR Assaki", "lat": 30.56219238766618, "lon": -8.031158355819711 },
  { "name": "CSR Azrar", "lat": 30.209503, "lon": -8.016977 },
  { "name": "CSR Azrar", "lat": 30.210077847901278, "lon": -8.01621525263977 },
  { "name": "CSR Bigoudine", "lat": 30.721123, "lon": -9.208989 },
  {
    "name": "CSR Bigoudine",
    "lat": 30.720943147548233,
    "lon": -9.208908533729561
  },
  { "name": "CSU Brahim Roudani", "lat": 30.471872, "lon": -9.158979 },
  {
    "name": "CSU Brahim Roudani",
    "lat": 30.47199452201216,
    "lon": -9.15916407242202
  },
  { "name": "CSR Dkheila", "lat": 30.51782, "lon": -9.273881 },
  {
    "name": "CSR El Guerdane",
    "lat": 30.41297055605867,
    "lon": -9.012469571411142
  },
  {
    "name": "CSR El Guerdane",
    "lat": 30.41297055605867,
    "lon": -9.012469571411142
  },
  {
    "name": "CSR Elbeida",
    "lat": 30.42510121829972,
    "lon": -8.960820117721559
  },
  { "name": "CSR Freija", "lat": 30.505187, "lon": -8.736401 },
  {
    "name": "CSR Ida Ougailal",
    "lat": 30.629173391884454,
    "lon": -8.635427843902582
  },
  { "name": "CSR Imgoune", "lat": 30.472407, "lon": -7.876389 },
  { "name": "CSR Imgoune", "lat": 30.4717874557742, "lon": -7.876689407409678 },
  { "name": "CSR Imighzer", "lat": 30.209941, "lon": -8.969814 },
  {
    "name": "CSR Imighzer",
    "lat": 30.21015888532344,
    "lon": -8.969497499336251
  },
  { "name": "CSR Issen", "lat": 30.431427, "lon": -9.257161 },
  { "name": "CSR Issen", "lat": 30.43156923076869, "lon": -9.25708992146111 },
  { "name": "CSR Ksiba", "lat": 30.279812, "lon": -9.155748 },
  { "name": "CSR Ksiba", "lat": 30.2799764560261, "lon": -9.155908932540896 },
  { "name": "CSR Laaouissate", "lat": 30.516909, "lon": -9.301601 },
  {
    "name": "CSR Laaouissate",
    "lat": 30.511732966664827,
    "lon": -9.306107111145028
  },
  { "name": "CSR Lagfifate", "lat": 30.331865, "lon": -9.258695 },
  {
    "name": "CSR Lagfifate",
    "lat": 30.33173188428366,
    "lon": -9.258825087137232
  },
  {
    "name": "CSR Lagfifate",
    "lat": 30.33169484335652,
    "lon": -9.258658790178309
  },
  {
    "name": "CSR Lagfifate",
    "lat": 30.33191708870938,
    "lon": -9.258485787696848
  },
  {
    "name": "CSR Lakhnafif",
    "lat": 30.41789864409632,
    "lon": -9.041720615326886
  },
  { "name": "CSR Lmnizla", "lat": 30.469908, "lon": -8.928848 },
  {
    "name": "CSR Machraa Alain",
    "lat": 30.45331329991558,
    "lon": -8.882950321426385
  },
  { "name": "CSR Migrane", "lat": 30.277904, "lon": -8.767562 },
  {
    "name": "CSR Ouled Rahou",
    "lat": 30.333157718099567,
    "lon": -9.022475457672126
  },
  {
    "name": "CSR Ouled Rahou",
    "lat": 30.33307669223299,
    "lon": -9.022602862600333
  },
  { "name": "CSR Ouneine", "lat": 30.861673, "lon": -8.198225 },
  { "name": "CSR Ouzioua", "lat": 30.73031736363748, "lon": -8.03323274647483 },
  { "name": "CSR Ouzioua", "lat": 30.730318, "lon": -8.033237 },
  {
    "name": "CSR Sidi Ahmad Ou Aamer",
    "lat": 30.420330212893248,
    "lon": -9.165027466270448
  },
  {
    "name": "CSR Sidi Boumoussa",
    "lat": 30.39708252428096,
    "lon": -9.24339381712723
  },
  {
    "name": "CSR Sidi Dahmane",
    "lat": 30.489043774563655,
    "lon": -8.820944046627039
  },
  {
    "name": "CSR Tafraouten",
    "lat": 30.669845877751616,
    "lon": -8.882532167984014
  },
  {
    "name": "CSR Tafraouten",
    "lat": 30.669453682895014,
    "lon": -8.882832575393682
  },
  {
    "name": "CSR talmakant",
    "lat": 30.795399799057005,
    "lon": -9.131119202377315
  },
  { "name": "CSR talmakant", "lat": 30.795515, "lon": -9.131602 },
  {
    "name": "CSR Tanout",
    "lat": 30.902504211754017,
    "lon": -8.832198058860778
  },
  { "name": "CSR Tanout", "lat": 30.902327, "lon": -8.832418 },
  {
    "name": "CSR Tazemourte",
    "lat": 30.473393025582542,
    "lon": -8.875666083328241
  },
  { "name": "CSR Toubkal", "lat": 30.992688, "lon": -7.816604 },
  { "name": "Centre de SantÚ tirit", "lat": 30.406916, "lon": -8.083405 },
  { "name": "CSU Ait Yazza", "lat": 30.505471, "lon": -8.799717 },
  { "name": "CSU Aoulouz", "lat": 30.671511, "lon": -8.177878 },
  {
    "name": "CSU Aoulouz",
    "lat": 30.671769381876246,
    "lon": -8.177647330024715
  },
  {
    "name": "CSU Aoulouz",
    "lat": 30.67191010742888,
    "lon": -8.177963830688473
  },
  {
    "name": "CSU Chrarda",
    "lat": 30.39504539030512,
    "lon": -9.210830300594324
  },
  {
    "name": "CSU Chrarda",
    "lat": 30.39487650033014,
    "lon": -9.210519164348597
  },
  {
    "name": "CSU Essafa",
    "lat": 30.402487975351704,
    "lon": -9.231238699405663
  },
  {
    "name": "CSU Essafa",
    "lat": 30.402487975351704,
    "lon": -9.231238699405663
  },
  { "name": "CSU Mhaita", "lat": 30.4748033, "lon": -8.8673764 },
  { "name": "CS OULED BOUNONA", "lat": 30.475232, "lon": -8.881773 },
  {
    "name": "CSU Taliouine",
    "lat": 30.529687307260378,
    "lon": -7.926991846557612
  },
  { "name": "CS Laghdira", "lat": 33.2851407, "lon": -8.0118865 },
  {
    "name": "Centre MÚdical de la commune Tigouga",
    "lat": 30.844278,
    "lon": -8.617745
  },
  { "name": "CSC TABYA", "lat": 30.016214, "lon": -8.8806782 },
  {
    "name": "CSC Toufalaazte",
    "lat": 30.06708917676442,
    "lon": -8.827538633100515
  },
  {
    "name": "CSR Machraa Alain",
    "lat": 30.45358613653658,
    "lon": -8.882446066131585
  },
  {
    "name": "CSR Sidi Ahmad Ou Aamer",
    "lat": 30.420203000000004,
    "lon": -9.16546198413086
  },
  {
    "name": "CSR Sidi Boumoussa",
    "lat": 30.39708252428096,
    "lon": -9.24339381712723
  },
  { "name": "CSR Toumliline", "lat": 29.944612, "lon": -8.724119 },
  {
    "name": "DÚlÚgation de la SantÚ Taroudant",
    "lat": 30.475336,
    "lon": -8.864691
  },
  { "name": "DR Ait Benhaoua", "lat": 30.158797, "lon": -8.930105 },
  { "name": "DR Aoudoust", "lat": 30.422561, "lon": -7.947885 },
  {
    "name": "DR Assarag",
    "lat": 30.963259599223722,
    "lon": -7.826442834323881
  },
  { "name": "DR Assarag", "lat": 30.963094, "lon": -7.826644 },
  {
    "name": "DR Barrage Abdelmoumen Bigoudine",
    "lat": 30.697956,
    "lon": -9.230136
  },
  {
    "name": "DR Bouaassida",
    "lat": 30.471580457775453,
    "lon": -9.05507555686951
  },
  { "name": "DR El Aasla", "lat": 30.288669, "lon": -9.081848 },
  { "name": "DR Iguidi", "lat": 30.994516383848, "lon": -7.8141967264748 },
  { "name": "DR Iguidi", "lat": 30.994514, "lon": -7.814198 },
  { "name": "DR Ouled Salem", "lat": 30.521359, "lon": -8.928136 },
  { "name": "DR Tidssi", "lat": 30.221087, "lon": -9.147518 },
  { "name": "DR Aaraben", "lat": 30.8289899, "lon": -7.761396 },
  { "name": "CSR Elbeida", "lat": 30.4534991, "lon": -8.9860218 },
  {
    "name": "PP Taroudant",
    "lat": 30.47624637567364,
    "lon": -8.863463435439474
  },
  {
    "name": "PP Taroudant",
    "lat": 30.476207077893136,
    "lon": -8.86369142320574
  },
  {
    "name": "PP Taroudant",
    "lat": 30.476410501526495,
    "lon": -8.864136669902212
  },
  {
    "name": "PP Taroudant",
    "lat": 30.475078856564078,
    "lon": -8.865184042991629
  },
  {
    "name": "CSC Tignatine (ADAR)",
    "lat": 30.12298408454472,
    "lon": -8.38067904232789
  },
  { "name": "CSR Ait Hamed", "lat": 30.631463, "lon": -8.402687 },
  { "name": "CSR Askaoun", "lat": 30.73828604398711, "lon": -7.77575102579499 },
  { "name": "CSR Azaghar N'Irs", "lat": 30.304688, "lon": -8.247696 },
  { "name": "CSR Akka Ighan", "lat": 29.999655, "lon": -7.521523 },
  { "name": "CSR Igli", "lat": 30.568221, "lon": -8.569199 },
  { "name": "CSR Igoudar", "lat": 30.616166, "lon": -8.449215 },
  { "name": "CSR Lamhara", "lat": 30.569693, "lon": -8.487821 },
  { "name": "CSR Nihit", "lat": 30.246418756309858, "lon": -8.47748053124809 },
  {
    "name": "CSR Ouled Aissa",
    "lat": 30.56210535564274,
    "lon": -8.611620552886974
  },
  { "name": "CSR Sidi Abdellah Ou Said", "lat": 30.753482, "lon": -8.683043 },
  {
    "name": "CSR Sidi Ahmed Ou Abdellah ",
    "lat": 30.33814286299606,
    "lon": -8.572364157077795
  },
  { "name": "CSR Sidi Ouaaziz", "lat": 30.70867, "lon": -8.362441 },
  {
    "name": "CSR Tafingoult",
    "lat": 30.765203379897113,
    "lon": -8.39142574684907
  },
  { "name": "CSR Tafraout", "lat": 30.158171, "lon": -8.552142 },
  { "name": "CSR Taoyalte", "lat": 30.807934, "lon": -7.787615 },
  { "name": "CSR Tayerte Amalou", "lat": 30.193007, "lon": -8.745018 },
  {
    "name": "CSR Tindine",
    "lat": 30.543338954230222,
    "lon": -8.855681749484525
  },
  { "name": "CSU Ighreme", "lat": 30.087745, "lon": -8.460406 },
  {
    "name": "CSU Ouled Berhil",
    "lat": 30.63631293930344,
    "lon": -8.48263669940567
  },
  { "name": "CSC AGARD WALOUS", "lat": 30.0163463, "lon": -8.603976 },
  { "name": "CSR Anzergue", "lat": 29.9352441, "lon": -8.5224546 },
  {
    "name": "CSR El Koudia",
    "lat": 30.37169879913433,
    "lon": -9.137604218420023
  },
  { "name": "CSR Indouzal", "lat": 30.246695, "lon": -8.708112 },
  {
    "name": "CSR Magnoune",
    "lat": 30.328381491328706,
    "lon": -8.39175719510651
  },
  { "name": "CSR Sidi Abdellah Ou Said", "lat": 30.753482, "lon": -8.683043 },
  { "name": "CSR Sidi Ouaaziz", "lat": 30.710403, "lon": -8.343904 },
  { "name": "CSR WALKADI", "lat": 30.24599818691161, "lon": -8.48343272963868 },
  { "name": "DR Had Imaoune", "lat": 29.993801, "lon": -8.652575 },
  { "name": "DR Ouzzoune", "lat": 30.1990607, "lon": -8.2875323 },
  { "name": "DR Talgjounte", "lat": 30.730925, "lon": -8.546945 },
  { "name": "DR Talidla", "lat": 30.102121, "lon": -8.604742 },
  { "name": " CSR berguene", "lat": 30.328444, "lon": -8.391666 },
  { "name": "DR Doussaoune", "lat": 30.032339, "lon": -8.315446 },
  { "name": "DR Ouastouane", "lat": 30.249191, "lon": -8.195499 },
  { "name": "DR Talat N'Tlghmine", "lat": 30.4208603, "lon": -8.1893463 },
  { "name": "CSC Ait Ouablli", "lat": 29.219791, "lon": -8.541402 },
  { "name": "CSC Allougoum", "lat": 30.279726, "lon": -6.833592 },
  { "name": "CSC Tizaghte", "lat": 29.745222, "lon": -8.517582 },
  { "name": "CSC Touzounine", "lat": 29.238924, "lon": -8.333427 },
  { "name": "CS Kasbat SAM", "lat": 29.435131, "lon": -8.259629 },
  { "name": "CSR Adis", "lat": 29.676223332341795, "lon": -7.98020768220901 },
  { "name": "CSR Aguinane", "lat": 30.199055, "lon": -7.557539 },
  { "name": "CSR Akka Iguirn", "lat": 29.764685, "lon": -7.702649 },
  { "name": "CSR Akkaighane", "lat": 30.016506, "lon": -7.535625 },
  { "name": "CSR Oum Elguerdane", "lat": 29.654991, "lon": -7.952969 },
  { "name": "CSR Tagmout", "lat": 29.962034, "lon": -8.235714 },
  { "name": "CSR Tamanarte", "lat": 29.163305, "lon": -8.957781 },
  { "name": "CSR Tigzmerte", "lat": 29.716085, "lon": -7.968285 },
  {
    "name": "CSR Tissinte",
    "lat": 29.89968339426094,
    "lon": -7.3206966269836515
  },
  { "name": "CSU 20 Aout", "lat": 29.745531, "lon": -7.973281 },
  {
    "name": "CSU d'accochement Foum Lhisn",
    "lat": 29.012491,
    "lon": -8.887943
  },
  { "name": "CSU Draa", "lat": 29.738979, "lon": -7.967243 },
  { "name": "CSU Foum Zguid", "lat": 30.083805, "lon": -6.871925 },
  { "name": "CSU Icht", "lat": 29.053598, "lon": -8.848382 },
  { "name": "CHP TATA", "lat": 29.75513665767788, "lon": -7.972953936176299 },
  { "name": "CSU OULED HELAL", "lat": 29.743935, "lon": -7.965761 },
  {
    "name": "CS Tata Avenue Mohammed 5",
    "lat": 29.755360200278723,
    "lon": -7.972967347221373
  },
  { "name": "CS Kasbat SAM", "lat": 29.435131, "lon": -8.259629 },
  { "name": "CSR IBNY AAKOUBE", "lat": 30.03422, "lon": -7.931086 },
  {
    "name": "CSR Akka Ighan",
    "lat": 29.99979437205535,
    "lon": -7.5212467324714645
  },
  { "name": "CSR Tlit", "lat": 30.281811179790402, "lon": -7.07360605886077 },
  { "name": "DR DOU-OUGADIR", "lat": 30.03996658, "lon": -7.6257252693 },
  { "name": "DR Idaoutinste", "lat": 29.905006, "lon": -8.529389 },
  { "name": "DR Imitek", "lat": 29.657242, "lon": -8.264892 },
  { "name": "DR Anamer", "lat": 29.275860736101905, "lon": -8.990381466270454 },
  { "name": "DR iligh", "lat": 29.9594155, "lon": -7.8603941 },
  { "name": "Dr Lkasba Tissint", "lat": 29.8549098, "lon": -7.4795761 },
  { "name": "DR Mrimina", "lat": 29.799057, "lon": -7.159169 },
  { "name": "DR Tadakouste", "lat": 29.24234, "lon": -8.691232 },
  {
    "name": "SRES Tata ",
    "lat": 29.750552950845854,
    "lon": -7.972200761901851
  },
  { "name": "CS Lamzar ", "lat": 30.323128, "lon": -9.51482 },
  { "name": "CS Ait Moussa", "lat": 30.365438, "lon": -9.371931 },
  { "name": "CS Al Jihadia ", "lat": 30.367826, "lon": -9.525913 },
  {
    "name": "CS Al Jihadia ",
    "lat": 30.3678815408742,
    "lon": -9.525989442956917
  },
  {
    "name": "CS Al Jihadia ",
    "lat": 30.367940553018503,
    "lon": -9.525868743551246
  },
  { "name": "CSC Oulad Dahou ", "lat": 30.365467, "lon": -9.323332 },
  {
    "name": "CSC Oulad Dahou ",
    "lat": 30.3655433705362,
    "lon": -9.323559987766263
  },
  {
    "name": "CS Lakhmaiss ",
    "lat": 30.296552431201594,
    "lon": -9.467777941716195
  },
  { "name": "CSR Ikhorbane", "lat": 30.33935, "lon": -9.408194 },
  {
    "name": "CSR Ikhorbane",
    "lat": 30.339411922950614,
    "lon": -9.408279830688484
  },
  {
    "name": "CSU Ait-Melloul ",
    "lat": 30.34442755419132,
    "lon": -9.501443623348228
  },
  { "name": "CSU Azrou ", "lat": 30.35758520831067, "lon": -9.470642195106501 },
  {
    "name": "CSU Azrou ",
    "lat": 30.357773835483975,
    "lon": -9.470670358301158
  },
  {
    "name": "CSU Chouhada ",
    "lat": 30.344232104591992,
    "lon": -9.487482784061429
  },
  {
    "name": "CSU Chouhada ",
    "lat": 30.34408627448133,
    "lon": -9.48740902331352
  },
  { "name": "CSU Chouhada ", "lat": 30.344584, "lon": -9.484247 },
  {
    "name": "CSU  Inezgane",
    "lat": 30.353711744919885,
    "lon": -9.535307454036705
  },
  { "name": "CSU Irhalen ", "lat": 30.367098, "lon": -9.540988 },
  {
    "name": "CSU Irhalen ",
    "lat": 30.367440504104913,
    "lon": -9.541293771827698
  },
  { "name": "CSU Kasbat Taher ", "lat": 30.331373, "lon": -9.532109 },
  {
    "name": "CSU Kasbat Taher ",
    "lat": 30.33113917805541,
    "lon": -9.532012440475466
  },
  { "name": "CSU Laazib ", "lat": 30.310089, "lon": -9.474762 },
  {
    "name": "CSU Laazib ",
    "lat": 30.310135311369176,
    "lon": -9.475062407409663
  },
  { "name": "CSU Sidi Daoud ", "lat": 30.296154, "lon": -9.454668 },
  { "name": "CSU Tabarine ", "lat": 30.371729, "lon": -9.532674 },
  {
    "name": "CSU Tabarine",
    "lat": 30.37164453489839,
    "lon": -9.53258816931153
  },
  { "name": "CS Tarrast", "lat": 0, "lon": 0 },
  { "name": "CS Tarrast", "lat": 30.350985, "lon": -9.553656 },
  { "name": "H¶pital Jorf ", "lat": 30.347708, "lon": -9.538986 },
  {
    "name": "H¶pital Jorf ",
    "lat": 30.347920950303315,
    "lon": -9.538878711639407
  },
  {
    "name": "H¶pital Jorf ",
    "lat": 30.348154732167938,
    "lon": -9.539238127647403
  },
  {
    "name": "H¶pital Jorf ",
    "lat": 30.348004278556736,
    "lon": -9.539602908073428
  },
  {
    "name": "Pharmacie H¶spitaliÞre Inezgane",
    "lat": 30.352897277366505,
    "lon": -9.536021588954926
  },
  {
    "name": "PP Inezgane",
    "lat": 30.35313799099637,
    "lon": -9.535351036701202
  },
  {
    "name": "PP Inezgane",
    "lat": 30.353589327456184,
    "lon": -9.534986256275177
  },
  {
    "name": "CS Tarrast ",
    "lat": 30.350953725366434,
    "lon": -9.55090395395942
  },
  {
    "name": "CS Essalam Dr Akalay Othmane ",
    "lat": 30.39555319383724,
    "lon": -9.547406910381325
  },
  {
    "name": "CS Essalam Dr Akalay Othmane ",
    "lat": 30.394993313792547,
    "lon": -9.547937987766273
  },
  { "name": "CSR Amsekroud ", "lat": 30.53462, "lon": -9.31528 },
  {
    "name": "CSR Amsekroud ",
    "lat": 30.53324308331342,
    "lon": -9.317329207687377
  },
  { "name": "CSR Assaka Imsouane", "lat": 30.829063, "lon": -9.805405 },
  { "name": "CSR Tadrart", "lat": 30.77236, "lon": -9.461578 },
  { "name": "CSR Taghazout", "lat": 30.543259, "lon": -9.707951 },
  { "name": "CSR Tamri ", "lat": 30.696909718864124, "lon": -9.82462894477463 },
  { "name": "CSR Tamri ", "lat": 30.696686, "lon": -9.824696 },
  { "name": "CSU ELQODS", "lat": 30.411581, "lon": -9.556324 },
  { "name": "CSU  Amsernat ", "lat": 30.416166, "lon": -9.571978 },
  { "name": "CSU Anza Al Oulia Taddart", "lat": 30.447933, "lon": -9.657744 },
  {
    "name": "CSR1 TIFERT N'AIT HAMZA",
    "lat": 32.321123200221784,
    "lon": -5.978129980374919
  },
  { "name": "CSU Anza Al Oulia Taddart", "lat": 30.457792, "lon": -9.638079 },
  {
    "name": "CSU Hay Aghroud Bensergao ",
    "lat": 30.375590598277206,
    "lon": -9.571907855155944
  },
  {
    "name": "CSU Hay Mohammadi ",
    "lat": 30.420759536044745,
    "lon": -9.555002250331887
  },
  { "name": "CSU LHCHACH", "lat": 30.427353, "lon": -9.586655 },
  { "name": "CSU Quartier Industriel ", "lat": 30.407953, "lon": -9.588457 },
  { "name": "CSU Taddart ", "lat": 30.454504, "lon": -9.653271 },
  {
    "name": "CSU Tatlborjt ",
    "lat": 30.422228388240967,
    "lon": -9.591976017196666
  },
  { "name": "CSU Tikiouine ", "lat": 30.376993, "lon": -9.502369 },
  { "name": "Centre d'insertion Tilila", "lat": 30.396336, "lon": -9.516574 },
  { "name": "CSR Amsekroud ", "lat": 30.526625, "lon": -9.3321841 },
  { "name": "CSR Tamri ", "lat": 30.6934037, "lon": -9.8245588 },
  { "name": "csr tiqqi ", "lat": 30.746546, "lon": -9.327551 },
  {
    "name": "CSU Amsernat",
    "lat": 30.41618450446937,
    "lon": -9.57180767972755
  },
  {
    "name": "DÚlÚgation de la SantÚ Agadir ",
    "lat": 30.419655,
    "lon": -9.589431
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments",
    "lat": 30.421141,
    "lon": -9.593028
  },
  { "name": "DR  Aquesri", "lat": 30.609904, "lon": -9.498703 },
  { "name": "DR Aquesri", "lat": 30.617069, "lon": -9.498578 },
  { "name": "DR Aziar", "lat": 30.771803, "lon": -9.558177 },
  { "name": "DR Azrarague", "lat": 30.4728, "lon": -9.463 },
  { "name": "DR Imsouane", "lat": 30.8423, "lon": -9.78183 },
  { "name": "DR sidi BELKACEM", "lat": 30.847855, "lon": -9.767468 },
  { "name": "DR  Sidi Boushab", "lat": 30.473999, "lon": -9.309799 },
  {
    "name": "DR Tamait Izdar",
    "lat": 30.418327375786063,
    "lon": -9.396219051589972
  },
  { "name": "DR Tamait Izdar", "lat": 30.418901, "lon": -9.395865 },
  { "name": "DR Tamait Oufla", "lat": 30.475775, "lon": -9.367229 },
  { "name": "DR Assaka Idmine", "lat": 30.595266, "lon": -9.401476 },
  { "name": "DR Dar Ben Cheikh", "lat": 30.3606, "lon": -9.431712 },
  { "name": "DR HAD IMSKER", "lat": 30.661062, "lon": -9.653356 },
  {
    "name": "DR Ighallene ",
    "lat": 30.70739001017651,
    "lon": -9.693733749668116
  },
  {
    "name": "CS Imouzer ",
    "lat": 30.420113008856656,
    "lon": -9.593137716602318
  },
  { "name": "CS MIFTAH SOUSS", "lat": 30.386579, "lon": -9.47678 },
  { "name": "CSR Ait Ouafqa", "lat": 29.537638, "lon": -9.291076 },
  {
    "name": "CSR Ait Ouafqa",
    "lat": 29.53709660155061,
    "lon": -9.291794832016
  },
  { "name": "CSR Aday ", "lat": 29.698919, "lon": -9.273448 },
  { "name": "CSR Afella Ighir", "lat": 29.501919, "lon": -8.817601 },
  { "name": "CSR Aglou", "lat": 29.764854, "lon": -9.806889 },
  { "name": "CSR Aglou", "lat": 29.764854, "lon": -9.806889 },
  { "name": "CSR Ait Ahmed", "lat": 29.730335, "lon": -9.309818 },
  { "name": "CSR Ait Ahmed", "lat": 29.730870702462408, "lon": -9.309818 },
  { "name": "CSR Amelne", "lat": 29.720631, "lon": -8.952635 },
  {
    "name": "CSR Amelne",
    "lat": 29.725923195032394,
    "lon": -8.955810735473628
  },
  { "name": "CSR Anzi", "lat": 29.688936, "lon": -9.347005 },
  { "name": "CSR Anzi", "lat": 29.689159690105093, "lon": -9.348356833343503 },
  { "name": "CSR Anzi", "lat": 29.68804123460059, "lon": -9.345975031738279 },
  { "name": "CSR El Maader", "lat": 29.7638, "lon": -9.651649 },
  { "name": "CSU Idaousmlal", "lat": 29.53763, "lon": -9.291076 },
  {
    "name": "CSU Idaousmlal",
    "lat": 29.536808566667464,
    "lon": -9.291526611114511
  },
  {
    "name": "CSU Idaousmlal",
    "lat": 29.53673389057908,
    "lon": -9.293736751342783
  },
  { "name": "CSR Ighir Mouss", "lat": 29.59473, "lon": -9.342166 },
  { "name": "CSR Irigh N'Tahala", "lat": 29.686823, "lon": -9.016064 },
  {
    "name": "CSR Irigh N'Tahala",
    "lat": 29.686832320626962,
    "lon": -9.016815018524163
  },
  { "name": "CSR Tighmi", "lat": 29.58317442071115, "lon": -9.397290793640138 },
  { "name": "CSR Ouijjane ", "lat": 29.629223, "lon": -9.549543 },
  {
    "name": "CSR Ouijjane - 1",
    "lat": 29.629010834085676,
    "lon": -9.549602008598319
  },
  { "name": "CSR Rasmouka", "lat": 29.818304, "lon": -9.587277 },
  {
    "name": "CSR Rasmouka",
    "lat": 29.815697611381825,
    "lon": -9.591826026489251
  },
  { "name": "CSR Reggada", "lat": 29.544434, "lon": -9.696626 },
  {
    "name": "CSR Reggada",
    "lat": 29.54476068259796,
    "lon": -9.697355560852058
  },
  {
    "name": "CSR Reggada",
    "lat": 29.544672011711484,
    "lon": -9.69655626256562
  },
  { "name": "CSR Sahel", "lat": 29.639742, "lon": -9.94783 },
  { "name": "CSR Sahel", "lat": 29.639732675009093, "lon": -9.948790230827338 },
  { "name": "CSR Sahel", "lat": 29.640637195106688, "lon": -9.948323526458747 },
  { "name": "CSR Sidi Bouabdelli", "lat": 29.48184, "lon": -9.830822 },
  { "name": "CSR Tarsouat", "lat": 29.492739, "lon": -9.029421 },
  {
    "name": "CSR Tasrirt ",
    "lat": 29.702317236286625,
    "lon": -8.940188375671395
  },
  { "name": "CSR Tazeroualte", "lat": 29.5017, "lon": -9.45425 },
  {
    "name": "CSR Tazeroualte",
    "lat": 29.50178403968648,
    "lon": -9.454915187835699
  },
  {
    "name": "CSR Tifmit ",
    "lat": 29.537845690877212,
    "lon": -9.291354949737558
  },
  { "name": "CSR Tifmit ", "lat": 29.537638, "lon": -9.291076 },
  { "name": "CSR Tighmi", "lat": 29.582428, "lon": -9.398707 },
  { "name": "CSU Abdellah Chefchaouni", "lat": 29.697531, "lon": -9.720699 },
  { "name": "CSU Abdellah Chefchaouni", "lat": 29.702931, "lon": -9.743081 },
  {
    "name": "CSU Abdellah Chefchaouni",
    "lat": 29.696521459200387,
    "lon": -9.733381948410033
  },
  { "name": "CSU El Mers", "lat": 29.697531, "lon": -9.720699 },
  {
    "name": "CSU El Mers",
    "lat": 29.696003083643685,
    "lon": -9.731555623348232
  },
  { "name": "CSU El Mers", "lat": 29.702931, "lon": -9.743081 },
  { "name": "CSU Tafraout", "lat": 29.721758, "lon": -8.971766 },
  { "name": "CSU Tafraout", "lat": 29.720633, "lon": -8.953385 },
  {
    "name": "CSU Tafraout",
    "lat": 29.72801218098039,
    "lon": -8.952612523803712
  },
  {
    "name": "CSU Tafraout",
    "lat": 29.73054631997327,
    "lon": -8.97286856628418
  },
  { "name": "CSR Tanalt", "lat": 29.774319, "lon": -9.169775 },
  { "name": "CSU Tafraout", "lat": 29.597843, "lon": -9.303253 },
  { "name": "DR  Ait Oumribete", "lat": 29.845556, "lon": -9.61514 },
  { "name": "DR Ait Semlalte", "lat": 29.8581, "lon": -9.45144 },
  { "name": "DR Al fied", "lat": 29.85848549439928, "lon": -9.75167519049073 },
  { "name": "DR Al Gaada", "lat": 29.746315, "lon": -9.782992 },
  { "name": "DR Bouzerze", "lat": 29.676569, "lon": -9.90395 },
  { "name": "DR  Ichafouden ", "lat": 29.637535, "lon": -9.956907 },
  {
    "name": "DR Ighir Melouln ",
    "lat": 29.499606738170908,
    "lon": -9.604941754631046
  },
  { "name": "DR Ighreme", "lat": 29.460987, "lon": -9.674834 },
  { "name": "DR Igrar Sidi Abderrahmane", "lat": 29.725552, "lon": -9.794816 },
  { "name": "DR  Izerbi", "lat": 29.476, "lon": -9.00764 },
  {
    "name": "DR Khmis Tafraout",
    "lat": 29.39958236215351,
    "lon": -9.918433148132328
  },
  { "name": "DR Laaouina", "lat": 29.649019, "lon": -9.798888 },
  { "name": "DR Ouled Noumer", "lat": 29.886446, "lon": -9.657455 },
  { "name": "DR Taghzift", "lat": 29.661615, "lon": -9.16542 },
  { "name": "DR Takaterte", "lat": 29.497585, "lon": -9.388228 },
  { "name": "DR Tiremsane", "lat": 29.648355, "lon": -9.39006 },
  { "name": "DR Tisgharine", "lat": 29.451401, "lon": -9.714736 },
  { "name": "DR Tizoughrane", "lat": 29.526268, "lon": -9.242602 },
  {
    "name": "DR Toumanar",
    "lat": 29.55162732859905,
    "lon": -9.546872861465454
  },
  {
    "name": "DR Assif Oudrar ",
    "lat": 29.59570294154471,
    "lon": -9.251617470703142
  },
  { "name": "PP TIZNIT", "lat": 29.698746, "lon": -9.735828 },
  {
    "name": "Maison de la Culture Mohamed Khair Eddine",
    "lat": 29.697531,
    "lon": -9.720699
  },
  { "name": "S- Salle Omnisport Tiznit", "lat": 29.702931, "lon": -9.743081 },
  {
    "name": "CSR Ait Amira",
    "lat": 30.174887956270602,
    "lon": -9.491285114086148
  },
  {
    "name": "CSR Ait Amira",
    "lat": 30.174788249678514,
    "lon": -9.49147286871719
  },
  { "name": "CSR Ait Milk", "lat": 29.949747, "lon": -9.387096 },
  { "name": "CSR Ait Mzal", "lat": 30.03804, "lon": -9.076838 },
  { "name": "CSR Ait Ouadrim", "lat": 30.038427, "lon": -9.232663 },
  {
    "name": "CSR Ait Ouadrim",
    "lat": 30.038215701307887,
    "lon": -9.232853436840067
  },
  { "name": "CSR Belfaa", "lat": 30.05026068703505, "lon": -9.563965159385672 },
  { "name": "CSR Hellate", "lat": 30.114924, "lon": -9.054683 },
  { "name": "CSR Hilala", "lat": 30.065321, "lon": -9.015287 },
  { "name": "CSR Hilala", "lat": 30.06549277832183, "lon": -9.014932948410026 },
  { "name": "CSR Ida Ougnidif", "lat": 29.861698, "lon": -9.009043 },
  {
    "name": "CSR Imi Mqourn",
    "lat": 30.161096516445607,
    "lon": -9.234436947256079
  },
  {
    "name": "CSR Inchadene",
    "lat": 30.09971639672904,
    "lon": -9.552521358127597
  },
  { "name": "CSR Inchadene", "lat": 30.099909, "lon": -9.553495 },
  { "name": "CSR Ouad Essafa", "lat": 30.255747, "lon": -9.421124 },
  {
    "name": "CSR Sidi Abdellah",
    "lat": 29.96646759316438,
    "lon": -9.278911564487464
  },
  { "name": "CSR Sidi Bibi", "lat": 30.239761, "lon": -9.524569 },
  {
    "name": "CSR Sidi Bibi",
    "lat": 30.239844420201855,
    "lon": -9.52432760118866
  },
  {
    "name": "CSR Sidi Bibi",
    "lat": 30.23970306926263,
    "lon": -9.52443220734024
  },
  { "name": "CSR Takoucht", "lat": 29.95821, "lon": -9.112758 },
  { "name": "CSR Tanalt", "lat": 29.774319, "lon": -9.169775 },
  {
    "name": "CSR Taouss Ait Amira",
    "lat": 30.181670583255478,
    "lon": -9.491431519014368
  },
  {
    "name": "CSR Ait Amira",
    "lat": 30.174586517427823,
    "lon": -9.49085327843475
  },
  { "name": "CSU Ait Baha", "lat": 30.069132, "lon": -9.158921 },
  {
    "name": "CSU Biougra",
    "lat": 30.214460976573115,
    "lon": -9.376653449073784
  },
  {
    "name": "CSU Biougra",
    "lat": 30.214386226686422,
    "lon": -9.376533420220367
  },
  { "name": "CSU Biougra", "lat": 30.214308, "lon": -9.376691 },
  { "name": "CSU Biougra", "lat": 30.2142987286849, "lon": -9.376901553407661 },
  { "name": "CS BELFAA", "lat": 30.05026068703505, "lon": -9.563965159385672 },
  { "name": "CSR Aoueguenz", "lat": 29.820984, "lon": -9.186416 },
  { "name": "CSR Massa", "lat": 29.984619, "lon": -9.64179 },
  {
    "name": "DR Imzilene",
    "lat": 30.257326266475918,
    "lon": -9.341774938657768
  },
  {
    "name": "DR Ait Moussa",
    "lat": 29.855447744575727,
    "lon": -9.304416369380954
  },
  { "name": "DR Ait Moussa", "lat": 29.855364, "lon": -9.304065 },
  { "name": "DR Ait Oubelkassem", "lat": 30.25856, "lon": -9.364272 },
  { "name": "DR Ait Yassine", "lat": 30.007467, "lon": -9.378958 },
  {
    "name": "DR Ait Yassine",
    "lat": 30.00728118491819,
    "lon": -9.378625406082149
  },
  { "name": "DR Allal", "lat": 30.128426, "lon": -9.489202 },
  { "name": "DR Anou Lajdid", "lat": 30.107149, "lon": -9.4342 },
  { "name": "DR Ifentare", "lat": 29.960344, "lon": -9.659617 },
  { "name": "DR Taalate", "lat": 30.14979305830395, "lon": -9.17692752645874 },
  { "name": "DR Taalate", "lat": 30.149858, "lon": -9.176434 },
  { "name": "DR Taddarte", "lat": 30.254915, "lon": -9.474318 },
  { "name": "DR Takade", "lat": 30.25807, "lon": -9.542802 },
  { "name": "DR Takade", "lat": 30.258199740548473, "lon": -9.542694711639397 },
  { "name": "DR Talidel", "lat": 29.976128, "lon": -9.05588 },
  { "name": "DR Zao", "lat": 30.18084499433644, "lon": -9.09855146627044 },
  { "name": "DR Zao", "lat": 30.180634, "lon": -9.098471 },
  { "name": "DR Tiouaziouine", "lat": 29.929283, "lon": -9.216487 },
  {
    "name": "PP Biougra",
    "lat": 30.215502938447294,
    "lon": -9.376365997344964
  },
  {
    "name": "PP Biougra",
    "lat": 30.216175098300635,
    "lon": -9.376993634254449
  },
  {
    "name": "Salle omnisport de biougra",
    "lat": 30.21502685546875,
    "lon": -9.371602058410645
  },
  {
    "name": "centre de santÚ urbain, district administratif",
    "lat": 32.052,
    "lon": -7.404
  },
  { "name": "CS LAARSA", "lat": 32.049902493852116, "lon": -7.406582236289979 },
  { "name": "CS LAARSA", "lat": 32.049598993989704, "lon": -7.406531274318696 },
  { "name": "commune hiadna", "lat": 32.189696, "lon": -7.6315499 },
  {
    "name": "centre de santÚ ouled zerrad",
    "lat": 32.07827926081363,
    "lon": -7.612219154834748
  },
  {
    "name": "centre de santÚ ouled zerrad",
    "lat": 32.078055,
    "lon": -7.612799
  },
  {
    "name": "centre de santÚ collectif ouled sebih",
    "lat": 32.066935,
    "lon": -7.510264
  },
  {
    "name": "DISPONSAIRE OULED HAMMOU MAYATT",
    "lat": 32.1283915068474,
    "lon": -7.469328849110754
  },
  { "name": "csc sidi rahal", "lat": 31.65089, "lon": -7.474905 },
  { "name": "csc sidi rahal", "lat": 31.650427, "lon": -7.47562 },
  { "name": "dispensaire hrawa", "lat": 31.598497, "lon": -7.528631 },
  {
    "name": "csu zemran charquia",
    "lat": 31.769915749874112,
    "lon": -7.372341156005859
  },
  { "name": "csu zemran charquia", "lat": 31.598351, "lon": -7.528317 },
  {
    "name": "dispensaire rural ouled gaid",
    "lat": 31.723558,
    "lon": -7.333359
  },
  { "name": "dispensaire rural bouida", "lat": 31.722901, "lon": -7.333202 },
  { "name": "CS TAMELALT", "lat": 31.732564, "lon": -7.496777 },
  { "name": "CS TAMELALT", "lat": 31.8151772, "lon": -7.5110212 },
  { "name": "CSU ET ATTAOUIA", "lat": 31.854689, "lon": -7.315524 },
  { "name": "CS CHOARA", "lat": 31.79191, "lon": -7.310415 },
  { "name": "CS CHOARA", "lat": 31.792057, "lon": -7.310631 },
  { "name": "CS COMMUNE ATTAOUIA CHAIBIA", "lat": 31.792442, "lon": -7.311185 },
  { "name": "CSU ADZOUZ", "lat": 31.889674, "lon": -7.317738 },
  { "name": "CSU LAATAMNA", "lat": 31.912042, "lon": -7.334889 },
  { "name": "CSU LAATAMNA", "lat": 31.912615, "lon": -7.334962 },
  { "name": "CSC EL FRAITA", "lat": 31.932464, "lon": -7.25977 },
  { "name": "CSC EL FRAITA", "lat": 31.932732, "lon": -7.259997 },
  {
    "name": "csc sidi aissa",
    "lat": 31.90620633236492,
    "lon": -7.212867736816407
  },
  { "name": "csc sidi aissa", "lat": 31.826509, "lon": -7.263723 },
  { "name": "csc ouargui", "lat": 31.815028, "lon": -7.203426 },
  { "name": "csc ouled khalouf", "lat": 31.781775, "lon": -7.075184 },
  { "name": "csc sahrij", "lat": 31.802485, "lon": -7.147489 },
  { "name": "csc sahrij", "lat": 31.802413, "lon": -7.147618 },
  { "name": "dispensaire rural lahmadna", "lat": 31.776886, "lon": -7.121964 },
  { "name": "dispensaire rural lahmadna", "lat": 31.777175, "lon": -7.121553 },
  { "name": "dispensaire lamnassir", "lat": 31.814521, "lon": -7.200564 },
  { "name": "csr-1 sour laaz", "lat": 31.834238, "lon": -7.026311 },
  { "name": "csr-1 sour laaz", "lat": 31.833594, "lon": -7.025667 },
  { "name": "csr bouya omar", "lat": 31.787955, "lon": -7.261108 },
  {
    "name": "centre rÚfÚrence de la santÚ reproductive",
    "lat": 32.045446,
    "lon": -7.407914
  },
  {
    "name": "commune jbil",
    "lat": 31.978021108365425,
    "lon": -7.389507293701172
  },
  {
    "name": "commune jbil",
    "lat": 31.984564313804263,
    "lon": -7.388992309570313
  },
  { "name": "commune ouled ali joumoua", "lat": 31.890803, "lon": -7.195261 },
  {
    "name": "commune joualla",
    "lat": 31.88654946347345,
    "lon": -7.441188097000123
  },
  { "name": "commune joualla", "lat": 31.828983, "lon": -7.412113 },
  {
    "name": "commune zenada",
    "lat": 32.07366556699212,
    "lon": -7.367448806762696
  },
  {
    "name": "commune zenada",
    "lat": 32.074974697864114,
    "lon": -7.369744777679444
  },
  {
    "name": "commune ouled lgarn",
    "lat": 32.11932860418048,
    "lon": -7.338480949401856
  },
  {
    "name": "csc lounasda",
    "lat": 32.14843777358076,
    "lon": -7.38332748413086
  },
  { "name": "commune ouled msabbel", "lat": 32.273228, "lon": -7.231689 },
  { "name": "CSC sidi moussa", "lat": 32.273699, "lon": -7.232507 },
  { "name": "Commune eddachra", "lat": 32.22688, "lon": -7.248533 },
  {
    "name": "Commune eddachra",
    "lat": 31.529626690363585,
    "lon": -9.482888814784102
  },
  {
    "name": "CSR-1 Taouzint",
    "lat": 32.096713529579624,
    "lon": -7.288806438446045
  },
  {
    "name": "CSR-1 Taouzint",
    "lat": 32.097345210061796,
    "lon": -7.288645505905152
  },
  {
    "name": "commune el kelaa(cs el hana)",
    "lat": 32.059045,
    "lon": -7.410904
  },
  {
    "name": "commune el kelaa(csu alqods)",
    "lat": 32.060319,
    "lon": -7.378497
  },
  {
    "name": "commune el kelaa(hopital essalama)",
    "lat": 32.048765785073044,
    "lon": -7.404464632272721
  },
  {
    "name": "commune el kelaa(hopital essalama)",
    "lat": 32.049361422234064,
    "lon": -7.40442305803299
  },
  { "name": "CSR Oulad Cherki", "lat": 32.2309647, "lon": -7.4472714 },
  { "name": "CSR Oulad Cherki", "lat": 32.2309647, "lon": -7.4472714 },
  {
    "name": "DR OULED CHAIKHE LGARNI",
    "lat": 32.184765767633124,
    "lon": -7.518510818481446
  },
  { "name": "CSR MAYAT OULAD SABER", "lat": 32.271954, "lon": -7.524991 },
  { "name": "CS rural SIDI EL HETAB", "lat": 32.385132, "lon": -7.215968 },
  {
    "name": "CS rural SIDI EL HETAB",
    "lat": 32.38484207481874,
    "lon": -7.215667592590333
  },
  { "name": "DR OUELED TARAF", "lat": 32.272526, "lon": -7.23219 },
  { "name": "DR OUELED TARAF", "lat": 32.283139, "lon": -7.210368 },
  { "name": "C/S OULED BOUALI", "lat": 32.225746, "lon": -7.24735 },
  {
    "name": "C/S OULED BOUALI",
    "lat": 32.149455154523984,
    "lon": -7.215700149536134
  },
  {
    "name": "centre de santÚ alamria",
    "lat": 32.206846105089426,
    "lon": -7.261533737182618
  },
  { "name": "centre de santÚ alamria", "lat": 32.2257462, "lon": -7.2495389 },
  { "name": "C/S RAFIA", "lat": 32.149382484833154, "lon": -7.276285886764527 },
  { "name": "csc chtaiba", "lat": 32.226034, "lon": -7.24765 },
  {
    "name": "csr ouled amer",
    "lat": 32.07304735978671,
    "lon": -7.213296890258789
  },
  { "name": "dr sidi med toumi", "lat": 32.017443, "lon": -7.222438 },
  { "name": "CS LAMZAM", "lat": 32.00666, "lon": -7.130824 },
  {
    "name": "C/S MARBOUH",
    "lat": 32.08457441812832,
    "lon": -7.410342693328858
  },
  {
    "name": "csc ouled yaagoub",
    "lat": 32.02505082130773,
    "lon": -7.254613637924195
  },
  { "name": "csc ouled yaagoub", "lat": 32.025092, "lon": -7.272113 },
  {
    "name": "csr-2 ouad lakhdar",
    "lat": 31.848754226349964,
    "lon": -7.088316679000855
  },
  { "name": "dispensaire rural sidi driss", "lat": 31.794654, "lon": -7.03014 },
  { "name": "Csr-1 Zemrane gharbia", "lat": 31.732507, "lon": -7.496247 },
  { "name": "csc ouled yaagoub", "lat": 32.025092, "lon": -7.272113 },
  { "name": "centre lamnassir", "lat": 31.7768275, "lon": -7.210813 },
  { "name": "CS raya", "lat": 32.2539305, "lon": -8.5359518 },
  { "name": "CS ait Hammou", "lat": 32.245563, "lon": -8.283014 },
  { "name": "cs sidi bouothmane rhamna", "lat": 31.900244, "lon": -7.941805 },
  { "name": "csc nzalet l adem", "lat": 32.101699, "lon": -7.952033 },
  {
    "name": "delegation provinciale rhamna",
    "lat": 32.240916,
    "lon": -7.961421
  },
  {
    "name": "delegation provinciale rhamna",
    "lat": 32.241401,
    "lon": -7.961178
  },
  { "name": "CSR-1 SOKHOUR RHAMNA", "lat": 32.479944, "lon": -7.912144 },
  { "name": "CSU TAKADOUM", "lat": 32.256947, "lon": -7.949636 },
  { "name": "CSU TAKADOUM", "lat": 32.255605, "lon": -7.948046 },
  { "name": "cs borouss", "lat": 31.758727, "lon": -7.58173 },
  { "name": "cs borouss", "lat": 31.758363, "lon": -7.851816 },
  { "name": "CF3-CHP RHAMNA", "lat": 32.241317, "lon": -7.958821 },
  { "name": "CSR SIDI BOUBKER", "lat": 31.831735, "lon": -7.736124 },
  { "name": "CSC SIDI ABDELLAH", "lat": 32.592302, "lon": -7.815493 },
  { "name": "CSC SIDI ABDELLAH", "lat": 32.592328, "lon": -7.815923 },
  { "name": "csu jaaidat armoire A1", "lat": 31.70265754, "lon": -7.6842737 },
  { "name": "skoura laananate", "lat": 32.472985, "lon": -7.802594 },
  { "name": "skoura elhadra", "lat": 32.470432, "lon": -7.724072 },
  { "name": "CSU SIDI GHANEM", "lat": 32.680716, "lon": -8.002137 },
  { "name": "CENTRE COMMUNE SIDI MANSOUR", "lat": 32.498515, "lon": -8.09851 },
  { "name": "CENTRE COMMUNE AIT TALEB", "lat": 32.377338, "lon": -8.172818 },
  { "name": "DR OULED LHAJ", "lat": 32.302982, "lon": -8.184181 },
  {
    "name": "CENTRE COMMUNE OULED HASSOUN HAMRI",
    "lat": 32.356595,
    "lon": -7.938358
  },
  { "name": "CENTRE COMMUNE LABRAHLA", "lat": 32.239097, "lon": -8.021318 },
  { "name": "CENTRE COMMUNE LAMHARA", "lat": 32.068708, "lon": -7.684344 },
  { "name": "CS Jbilate", "lat": 31.838144, "lon": -7.846535 },
  { "name": "CS RASS EL AIN", "lat": 31.753212, "lon": -7.618425 },
  { "name": "CS RASS EL AIN", "lat": 31.853212, "lon": -7.718425 },
  { "name": "CS cummunal Tlauh", "lat": 31.809576, "lon": -7.558767 },
  { "name": "CS communal AKARMA", "lat": 31.873259, "lon": -7.644515 },
  { "name": "DR OULAD ABID HMAD", "lat": 32.221722, "lon": -8.200037 },
  { "name": "csu riaden", "lat": 32.190093, "lon": -7.96415 },
  { "name": "CSR 1 Jaafra", "lat": 32.37552642, "lon": -7.727455139 },
  {
    "name": "H¶pital Princesse Lalla Hasna Chambre Froide A-4",
    "lat": 32.23672330001338,
    "lon": -8.51191129101563
  },
  {
    "name": "hopital lalla hasna ARMOIRE FRIGORIFIQUE B1",
    "lat": 32.250279,
    "lon": -8.522845
  },
  {
    "name": "hopital lalla hasna ARMOIRE FRIGORIFIQUE B1",
    "lat": 32.250277,
    "lon": -8.522842
  },
  { "name": "CSR IGHOUD", "lat": 31.86445, "lon": -8.881022 },
  { "name": "Centre santÚ ighoud", "lat": 31.8638573, "lon": -8.8810352 },
  { "name": "csr-2 sidi chiker", "lat": 31.764944, "lon": -8.693343 },
  { "name": "csr-2 sidi chiker", "lat": 31.764946, "lon": -8.693345 },
  { "name": "csr-2 sidi chiker", "lat": 31.764948, "lon": -8.693349 },
  { "name": "CSU2 Chemaia", "lat": 32.078162, "lon": -8.625723 },
  { "name": "CSR1 Tiamime", "lat": 32.057864, "lon": -8.566978 },
  { "name": "CSR1 Tiamime", "lat": 32.057866, "lon": -8.566998 },
  { "name": "CSR1 Jdour", "lat": 32.111107, "lon": -8.782178 },
  { "name": "CSR1 Jdour", "lat": 32.111109, "lon": -8.78218 },
  { "name": "CSR1 Jdour", "lat": 32.11111, "lon": -8.782182 },
  { "name": "Csr 2 Ras El Ain", "lat": 32.02303314, "lon": -8.488985061 },
  { "name": "Csr 2 Ras El Ain", "lat": 32.02303314, "lon": -8.488985061 },
  {
    "name": "Csr 2 Ras El Ain",
    "lat": 32.02286713269036,
    "lon": -8.488968967745912
  },
  { "name": "C/S SBIAT", "lat": 32.205658, "lon": -8.578918 },
  { "name": "C/S SBIAT", "lat": 32.20592807316743, "lon": -8.578982373016355 },
  { "name": "C/S SBIAT", "lat": 32.20537657836742, "lon": -8.578939457672117 },
  { "name": "Centre santÚ youssoufia", "lat": 32.2447543, "lon": -8.5324312 },
  { "name": "DR labdadgha", "lat": 31.941603, "lon": -8.552214 },
  { "name": "DR labdadgha", "lat": 31.941627, "lon": -8.552457 },
  { "name": "csr-1 sidi ahmed", "lat": 32.21294, "lon": -8.493346 },
  { "name": "csr-1 sidi ahmed", "lat": 32.212879, "lon": -8.493446 },
  { "name": "CSR LKHWALKA", "lat": 32.047939, "lon": -8.354482 },
  {
    "name": "CSR LKHWALKA",
    "lat": 33.54930568771337,
    "lon": -7.6439841052166875
  },
  { "name": "CSU-1 ASSALAM", "lat": 32.2361093, "lon": -8.5355401 },
  { "name": "CSR 1 Niveau JNANE BOUIH", "lat": 33.5493191, "lon": -7.6439717 },
  { "name": "CSC ZAOUIAT SIDI BOUTIAB", "lat": 32.1808618, "lon": -8.5926472 },
  {
    "name": "Csu Al Matar RÚfrigÚrateur 1",
    "lat": 32.26635494509359,
    "lon": -9.234589352597327
  },
  {
    "name": "Csu Al Matar RÚfrigÚrateur 1",
    "lat": 32.266334533,
    "lon": -9.234575271
  },
  { "name": "hopital med 5", "lat": 32.287886, "lon": -9.237423 },
  {
    "name": "hopital med 5 chambre froide B",
    "lat": 32.287927,
    "lon": -9.237254
  },
  { "name": "CSR IGHOUD", "lat": 31.86445, "lon": -8.881022 },
  { "name": "jemaa armoire a1", "lat": 32.35418, "lon": -8.840212 },
  { "name": "CSU JAMAA SHAIM", "lat": 32.354162, "lon": -8.840628 },
  {
    "name": "labo EPIDEMIO CHAMBRE FROIDE A1",
    "lat": 32.281863,
    "lon": -9.228988
  },
  { "name": "cs bouguedra", "lat": 32.257887, "lon": -8.981466 },
  { "name": "cs bouguedra", "lat": 32.257889, "lon": -8.981468 },
  { "name": "CS gzoula", "lat": 32.1090091, "lon": -9.0804359 },
  { "name": "CSU BIADA", "lat": 32.308134, "lon": -9.235057 },
  { "name": "SIDI OUASSEL", "lat": 32.275449, "lon": -9.245261 },
  { "name": "OKBA IBN NAFII", "lat": 32.2917672, "lon": -9.241319 },
  { "name": "CSU ANAS", "lat": 32.289815, "lon": -9.236542 },
  { "name": "CSU-1 JRIFAT", "lat": 32.284633, "lon": -9.227114 },
  { "name": "Centre santÚ medina", "lat": 32.2993551, "lon": -9.2386536 },
  { "name": "cs lemsabih", "lat": 32.2837749, "lon": -8.7555385 },
  { "name": "csu zine el abidine", "lat": 32.277842, "lon": -9.235534 },
  { "name": "csu-1 kawki", "lat": 32.257077, "lon": -9.248618 },
  { "name": "csu sidi abdelkrim", "lat": 32.313757, "lon": -9.227768 },
  { "name": "csu sidi abdelkrim", "lat": 32.313759, "lon": -9.22777 },
  { "name": "CSC ELGOURAANI", "lat": 32.320607, "lon": -8.777736 },
  { "name": "CSR 2 BOUGUEDRA", "lat": 32.257612, "lon": -8.981328 },
  { "name": "CENTRE DE SANTE SIDI AISSA", "lat": 32.415192, "lon": -8.898006 },
  { "name": "csc beddouza", "lat": 32.54396, "lon": -9.277317 },
  { "name": "cs lahdar", "lat": 32.459388, "lon": -8.792631 },
  { "name": "cs lahdar", "lat": 32.476034, "lon": -8.828806 },
  { "name": "CSC DAR LAMINE", "lat": 31.967388, "lon": -9.23319 },
  { "name": "CSC LAGHIAT", "lat": 32.034077, "lon": -9.171411 },
  {
    "name": "CSC ET MAISON D ACCOUCHEMENT NEGA",
    "lat": 31.999531,
    "lon": -9.034478
  },
  {
    "name": "CSC ET MAISON D ACCOUCHEMENT NEGA",
    "lat": 31.999746,
    "lon": -9.034569
  },
  { "name": "CSU AZIB DERAI", "lat": 32.294521, "lon": -9.219394 },
  { "name": "CSU 1KARYAT CHAMS", "lat": 32.28788, "lon": -9.20316 },
  { "name": "kariat chems", "lat": 32.291388, "lon": -9.209033 },
  { "name": "csu sidi bouzid", "lat": 32.307193, "lon": -9.235159 },
  { "name": "CSU KOLEA", "lat": 32.26202, "lon": -9.249285 },
  { "name": "ouled selmane", "lat": 32.120143, "lon": -9.163553 },
  { "name": "jemaa armoire a1", "lat": 32.351135, "lon": -8.842767 },
  { "name": "CSC Souria Kdima", "lat": 32.043945, "lon": -9.338638 },
  { "name": "CSC SOUIRIA KDIMA", "lat": 32.04339, "lon": -9.338249 },
  { "name": "CSU- 1 kawki", "lat": 32.257077, "lon": -9.248618 },
  { "name": "csr - khat azzakanne", "lat": 32.215055, "lon": -9.134573 },
  { "name": "Cs ibnou Rochd", "lat": 32.231764, "lon": -9.252681 },
  { "name": "Cs ibnou Rochd", "lat": 32.23174, "lon": -9.252052 },
  { "name": "CSC LAGHIAT", "lat": 32.040175, "lon": -9.161247 },
  { "name": "CSC dar lamine", "lat": 31.972321, "lon": -9.292704 },
  { "name": "SIDI OUASSEL", "lat": 32.275449, "lon": -9.245261 },
  { "name": "csc lemrasla", "lat": 32.040545, "lon": -8.878253 },
  { "name": "csc lemrasla", "lat": 32.040813, "lon": -8.877767 },
  { "name": "CSC LEMSIBIH", "lat": 32.353654, "lon": -8.775914 },
  {
    "name": "maison d'accouchement jamaa shaim",
    "lat": 32.346222,
    "lon": -8.843599
  },
  { "name": "CS MOUL EL BERGUI", "lat": 32.519663, "lon": -8.998329 },
  { "name": "CS MOUL EL BERGUI", "lat": 32.519783, "lon": -8.998057 },
  { "name": "csc laakarta (souk ayir)", "lat": 32.676814, "lon": -9.086138 },
  { "name": "CSR1 LAAMAMRA", "lat": 31.90902, "lon": -8.980494 },
  { "name": "CSR1 LAAMAMRA", "lat": 31.909191, "lon": -8.980323 },
  { "name": "CSC DAR EL CAID", "lat": 32.4345, "lon": -9.049352 },
  { "name": "csc labkhati", "lat": 32.435239, "lon": -8.649584 },
  { "name": "CSC LOULAD", "lat": 32.357013, "lon": -9.142316 },
  { "name": "CSC LOULAD", "lat": 32.357013, "lon": -9.142302 },
  { "name": "cs had hrara", "lat": 32.43897, "lon": -9.134996 },
  { "name": "CS BIADA", "lat": 32.3065253, "lon": -9.2364705 },
  {
    "name": "Úcole national ( facultÚs )",
    "lat": 32.3273399,
    "lon": -9.2638337
  },
  { "name": "Port de safi", "lat": 32.3042143, "lon": -9.2392411 },
  {
    "name": "centre de santÚ mejjat",
    "lat": 31.358884859491035,
    "lon": -8.50919072319167
  },
  {
    "name": "centre de santÚ rural niveau 2 guemassa",
    "lat": 31.4159394,
    "lon": -8.4128368
  },
  {
    "name": "CSC MZOUDA ",
    "lat": 31.297797364635407,
    "lon": -8.575937753703911
  },
  {
    "name": "CSC MZOUDA ",
    "lat": 31.280663494524564,
    "lon": -8.574266249412034
  },
  { "name": "CSC ZAOUIAT NHILA", "lat": 31.26505, "lon": -8.655295 },
  { "name": "csr 2 douirane", "lat": 31.213, "lon": -8.77912 },
  { "name": "C.s.r nfifa", "lat": 31.227688, "lon": -8.856083 },
  {
    "name": "Delegation provinciale chichaoua armoire 2",
    "lat": 31.546452,
    "lon": -8.761479
  },
  { "name": "hopital med 6 ", "lat": 31.539419, "lon": -8.768469 },
  { "name": "csc ouled moumna", "lat": 31.581425, "lon": -9.013727 },
  { "name": "csc el hougate a2", "lat": 31.562485, "lon": -8.894503 },
  { "name": "csc el hougate a2", "lat": 31.562572, "lon": -8.894403 },
  { "name": "cs ait hadi", "lat": 31.397146, "lon": -8.770606 },
  { "name": "csr-2 abdel moumen", "lat": 31.26524, "lon": -8.937925 },
  { "name": "csr-2 touloukoult", "lat": 31.218664, "lon": -9.08279 },
  { "name": "CSR-2 bouabout", "lat": 31.267066, "lon": -9.177787 },
  { "name": "csc irohalen", "lat": 31.084975, "lon": -8.937027 },
  { "name": "CSR-1 RAHHALA", "lat": 31.369727, "lon": -9.013233 },
  { "name": "DR TIRKHAT", "lat": 31.343895, "lon": -9.116306 },
  { "name": "CSR-2 ICHAMRAREN", "lat": 31.096956, "lon": -9.168362 },
  { "name": "CSR-1 KOUZEMT", "lat": 31.096644, "lon": -9.289503 },
  { "name": "Imindounit", "lat": 31.065716, "lon": -8.531315 },
  { "name": "CSR-2 AFELAISSEN", "lat": 31.045456, "lon": -9.023336 },
  { "name": "CSC MHAMED DALIL ROUAFIA", "lat": 31.448004, "lon": -8.625064 },
  { "name": "csc el ahdil", "lat": 31.678981, "lon": -8.819866 },
  { "name": "CSR TIMLILT", "lat": 31.0289927, "lon": -9.1329808 },
  { "name": "CSC SAIDATE", "lat": 31.343552, "lon": -8.7057999 },
  { "name": "csr-2 touloukoult", "lat": 31.219255, "lon": -9.082937 },
  { "name": "DR moulay ibrahim roha", "lat": 31.502819, "lon": -8.791138 },
  { "name": "centre de santÚ mejjat", "lat": 31.3639583, "lon": -8.50389 },
  {
    "name": "Hopital Sidi Mohamed Ben Abdellah",
    "lat": 31.5114374,
    "lon": -9.7648294
  },
  { "name": "csu-1 rahal ben ahmed", "lat": 31.510579, "lon": -9.766639 },
  { "name": "csu-1 la lagune", "lat": 31.513122, "lon": -9.755589 },
  { "name": "csr-1 sidi ahmed ou hamed", "lat": 31.339454, "lon": -9.710115 },
  { "name": "csr-2 SMIMOU", "lat": 31.212245, "lon": -9.704864 },
  { "name": "csc TAFDNA", "lat": 31.098099, "lon": -9.822438 },
  { "name": "CSR2 TAMANAR", "lat": 30.99931, "lon": -9.67794 },
  { "name": "csr-1 ida outghouma", "lat": 30.891318, "lon": -9.695955 },
  { "name": "ctre commune ida o guelloul", "lat": 30.952495, "lon": -9.696392 },
  { "name": "CSCA HANCHANE", "lat": 31.525796, "lon": -9.441439 },
  { "name": "csr-1 mejji", "lat": 31.548487, "lon": -9.346214 },
  { "name": "csr-2 Tafettachet", "lat": 31.582821, "lon": -9.254175 },
  { "name": "csr-2 Tafettachet", "lat": 31.582823, "lon": -9.254177 },
  { "name": "csr-1 m'rameur", "lat": 31.660438, "lon": -9.163664 },
  { "name": "csc m'zilat", "lat": 31.696785, "lon": -9.137622 },
  { "name": "csc LAHSSINATE", "lat": 31.533472, "lon": -9.474417 },
  { "name": "csr-1 ounagha", "lat": 31.533481, "lon": -9.544502 },
  { "name": "cs talmest", "lat": 31.813991, "lon": -9.340623 },
  { "name": "csr-1 nairat", "lat": 31.704649, "lon": -9.314969 },
  { "name": "csr-1 ait tahrya", "lat": 31.657228, "lon": -9.639545 },
  { "name": "CSC Ait aissa", "lat": 30.914745, "lon": -9.42984 },
  { "name": "dispensaire Artnan", "lat": 30.934142, "lon": -9.36341 },
  { "name": "csc commune adaghas", "lat": 30.886741, "lon": -9.297108 },
  { "name": "csr-2 assais akrarou", "lat": 30.919086, "lon": -9.219849 },
  { "name": "cs-2 ait daoud", "lat": 31.07958, "lon": -9.354322 },
  { "name": "csr-1 takouchte", "lat": 31.187789, "lon": -9.449161 },
  { "name": "CSR-1 AIT ZELTEN", "lat": 31.291834, "lon": -9.449269 },
  { "name": "DSISPENSAIRE IDOU ZMZAM", "lat": 31.286641, "lon": -9.338811 },
  { "name": "csr-1 BOUZEMOUR", "lat": 31.005862, "lon": -9.404483 },
  { "name": "CSR-1 DHAR", "lat": 31.157314, "lon": -9.534268 },
  { "name": "imi nitlit", "lat": 31.21473, "lon": -9.548282 },
  { "name": "CSR TARGANTE", "lat": 31.087747, "lon": -9.661124 },
  { "name": "csr zaouiat skiat", "lat": 31.778144, "lon": -9.22689 },
  { "name": "csr-1 sidi laarousssi", "lat": 31.846345, "lon": -9.160401 },
  { "name": "csc brakt radi", "lat": 31.882709, "lon": -9.259922 },
  { "name": "csc sidi abdeljalil", "lat": 31.832822, "lon": -9.332502 },
  { "name": "sidi ali elkrati", "lat": 31.80312, "lon": -9.4005184 },
  { "name": "csr-1 souk aam", "lat": 31.866242, "lon": -9.449146 },
  { "name": "csr meskala", "lat": 31.393792, "lon": -9.41421 },
  { "name": "csr-1 sidi med merzouk", "lat": 31.765032, "lon": -9.012342 },
  {
    "name": "csr-1 kachoula",
    "lat": 31.572612424950545,
    "lon": -9.297105073928833
  },
  { "name": "csr-1 oulad said", "lat": 31.563615, "lon": -9.176887 },
  { "name": "csu-1 Ghazoua", "lat": 31.445468, "lon": -9.730999 },
  { "name": "csr-1 takat", "lat": 31.650478, "lon": -9.468913 },
  { "name": "cs jbilat", "lat": 31.717129, "lon": -9.433433 },
  { "name": "dispensaire rurale birmzouri", "lat": 31.506332, "lon": -9.61525 },
  { "name": "ida ougourd", "lat": 31.423875, "lon": -9.636941 },
  { "name": "imerditssen", "lat": 30.958732, "lon": -9.790707 },
  { "name": "ida oukazzou", "lat": 30.881103, "lon": -9.635685 },
  { "name": "CSR-1 Neknafa", "lat": 31.297345, "lon": -9.563344 },
  { "name": "Ait bayoud", "lat": 31.322649, "lon": -9.322746 },
  { "name": "Bizdad", "lat": 31.286509, "lon": -9.338765 },
  { "name": "tabarda", "lat": 31.372326, "lon": -9.240459 },
  { "name": "CSR 1 Mouarid", "lat": 31.392834, "lon": -9.16592 },
  { "name": "C/S TIGOUDAR", "lat": 31.62863, "lon": -9.62863 },
  { "name": "CSR 1 SIDI AHMED SAYEH", "lat": 31.244404, "lon": -9.769301 },
  { "name": "CS SIDI BOULAALAM", "lat": 31.676513, "lon": -9.273248 },
  {
    "name": "Hopital Sidi Mohamed Ben Abdellah A1",
    "lat": 31.512608,
    "lon": -9.762716
  },
  { "name": "CSCA HANCHANE", "lat": 31.526563, "lon": -9.441307 },
  { "name": "csc tamanar Armoire A1", "lat": 30.979632, "lon": -9.697672 },
  { "name": "csr-1 had draa", "lat": 31.571738, "lon": -9.535933 },
  { "name": "CSR-1 AGLIF", "lat": 31.120389, "lon": -9.392667 },
  { "name": "csu1-allal ben ahmed amkik", "lat": 31.516379, "lon": -9.759174 },
  { "name": "csr-1 lagdadra", "lat": 31.55116, "lon": -9.423741 },
  { "name": "cs talmest", "lat": 31.813992, "lon": -9.340624 },
  { "name": "Csr-1 zaouiat ben hmida", "lat": 31.7428799, "lon": -9.3255579 },
  { "name": "centre tighedouine", "lat": 31.426835, "lon": -7.527518 },
  { "name": "centre tighedouine", "lat": 31.426838, "lon": -7.52752 },
  { "name": "csr-1 tazlida", "lat": 31.439172, "lon": -7.404368 },
  { "name": "csr-1 tazlida", "lat": 31.439176, "lon": -7.40437 },
  { "name": "csr-1 ait hkim", "lat": 31.485386, "lon": -7.204467 },
  { "name": "csr-2abaddou", "lat": 31.605484, "lon": -7.41129 },
  { "name": "csr-2 ourika", "lat": 31.374069, "lon": -7.779427 },
  { "name": "csr-2 ourika", "lat": 31.37407, "lon": -7.779429 },
  {
    "name": "dÚlÚgation provinciale du haouz",
    "lat": 31.361785,
    "lon": -7.933927
  },
  { "name": "csr-1 ahmed chefquaoui", "lat": 31.312396, "lon": -7.743836 },
  { "name": "CS TAKHSIFTE", "lat": 31.405582, "lon": -7.715275 },
  { "name": "CSR-1 GHMAT", "lat": 31.423938, "lon": -7.801998 },
  { "name": "CSR-1 GHMAT", "lat": 31.42394, "lon": -7.801999 },
  { "name": "CSR-2 TOUAMA", "lat": 31.5312, "lon": -7.485402 },
  { "name": "CSR-2 TOUAMA", "lat": 31.531769, "lon": -7.485667 },
  { "name": "DISPENSAIRE RURAL CHOUITER", "lat": 31.57567, "lon": -7.816272 },
  { "name": "CSR-1 AIT FASKA", "lat": 31.566287, "lon": -7.728852 },
  { "name": "csr Asni", "lat": 31.251409, "lon": -7.978292 },
  { "name": "csr Asni", "lat": 31.251411, "lon": -7.978294 },
  { "name": "dispensaire rural imlil", "lat": 31.136286, "lon": -7.918005 },
  { "name": "csr-1 moulay brahim", "lat": 31.285906, "lon": -7.968785 },
  { "name": "csr-1 lalla takerkoust", "lat": 31.359692, "lon": -8.135161 },
  { "name": "csr-2 Tamesloht", "lat": 31.497284, "lon": -8.098043 },
  { "name": "csr-2 Tamesloht", "lat": 31.497286, "lon": -8.098045 },
  { "name": "csr-2 Tamesloht", "lat": 31.497288, "lon": -8.098048 },
  { "name": "csu ait ourir", "lat": 31.564602, "lon": -7.663094 },
  { "name": "CSR-1 Tidili Mesfioua", "lat": 31.459, "lon": -7.61031 },
  { "name": "delegation tahannaout", "lat": 31.360434, "lon": -7.934308 },
  { "name": "delegation tahannaout", "lat": 31.360446, "lon": -7.934317 },
  {
    "name": "CSR 2 TALAT N'YACOUB",
    "lat": 30.989788882995597,
    "lon": -8.183261169311528
  },
  { "name": "CSR 1 IGOUDAR", "lat": 31.26773, "lon": -8.2553117 },
  { "name": "csu-1 tahannaout", "lat": 31.348858, "lon": -7.951656 },
  { "name": "csr-1 sidi badhaj", "lat": 31.301786, "lon": -8.207881 },
  { "name": "Csr 1 amaghrass", "lat": 31.210605, "lon": -8.182371 },
  { "name": "csu amzmiz", "lat": 31.218888, "lon": -8.233623 },
  { "name": "csu amzmiz", "lat": 31.218889, "lon": -8.233625 },
  { "name": "csu amzmiz", "lat": 31.218896, "lon": -8.23363 },
  { "name": "CSR-2 Tazart", "lat": 31.662912, "lon": -7.412552 },
  { "name": "CSR-2 Tazart", "lat": 31.662914, "lon": -7.412554 },
  { "name": "CSR-1 Ait Ouanga", "lat": 31.419557, "lon": -7.685551 },
  { "name": "CSR-1 Ait Aadel", "lat": 31.649823, "lon": -7.265133 },
  { "name": "CSR-1 Ait Aadel", "lat": 31.649825, "lon": -7.265135 },
  { "name": "DR Ait Abdesslam", "lat": 31.492286, "lon": -7.669983 },
  { "name": "DR Sahrij", "lat": 31.650601, "lon": -7.330421 },
  { "name": "CSR 1 OUIRGANE", "lat": 31.17557, "lon": -8.082849 },
  { "name": "CSR-1 Imegdal", "lat": 31.120567, "lon": -8.121239 },
  { "name": "DR Ait Rbaa", "lat": 31.5627, "lon": -7.13507 },
  { "name": "DR AIT OUAHLILI", "lat": 31.573633, "lon": -7.481059 },
  { "name": "DR AGOUJGAL", "lat": 31.577192, "lon": -7.554946 },
  { "name": "CSR TWART ISLA", "lat": 31.495966, "lon": -7.484289 },
  { "name": "CSR-1 Oulad Yahia", "lat": 31.538433, "lon": -8.031082 },
  { "name": "dispensaire rural lamdinate", "lat": 31.110362, "lon": -8.370002 },
  { "name": "csr-1 AZGOUR", "lat": 31.146973, "lon": -8.308297 },
  { "name": "csr-1 AZGOUR", "lat": 31.146977, "lon": -8.308301 },
  { "name": "csr-1 IGOUDAR 1", "lat": 31.267981, "lon": -8.255414 },
  { "name": "dispensaire rural TASSAOUT", "lat": 31.379213, "lon": -8.024842 },
  { "name": "DR MARIGHA", "lat": 31.196234, "lon": -8.047193 },
  { "name": "CSR 1 IJOUKAK", "lat": 30.996801, "lon": -8.155533 },
  { "name": "DR MOULDIKHT", "lat": 30.937014, "lon": -8.269256 },
  { "name": "CSR1 IGHIL", "lat": 30.984365, "lon": -8.291272 },
  {
    "name": "CS rural-1 TIZGUINE",
    "lat": 31.24597540437939,
    "lon": -8.318023681640627
  },
  {
    "name": "CS rural-1 TIZGUINE",
    "lat": 31.25037814985571,
    "lon": -8.32695007324219
  },
  {
    "name": "csr-1 dar jamaa",
    "lat": 31.245094830650817,
    "lon": -8.351325988769533
  },
  { "name": "csr-1 bencheikh", "lat": 31.426839, "lon": -7.959318 },
  { "name": "csr-1 bencheikh", "lat": 31.426841, "lon": -7.95932 },
  { "name": "DR tidrara", "lat": 31.347524, "lon": -7.895733 },
  { "name": "DR  BENKABOUR", "lat": 31.427891, "lon": -7.907205 },
  { "name": "dispensaire rural lhrissane", "lat": 31.600379, "lon": -7.377976 },
  {
    "name": "dispensaire rural tizal ait abdellah",
    "lat": 31.554191,
    "lon": -7.364607
  },
  { "name": "dispensaire rural anzal", "lat": 31.576026, "lon": -7.427989 },
  {
    "name": "dispensaire AMNASS(OUMNSK)",
    "lat": 31.4162997,
    "lon": -8.0782148
  },
  { "name": "csr-1 tamazouzt", "lat": 31.474635, "lon": -7.767096 },
  { "name": "CSR1 ANGAL", "lat": 31.138375, "lon": -8.265931 },
  {
    "name": "DISPENSAIRE RURAL TOUKHRIBINE",
    "lat": 31.507997,
    "lon": -7.564398
  },
  { "name": "CSR 1 OUZGHUITA", "lat": 31.289415, "lon": -8.103587 },
  { "name": "csr-2 Tamesloht", "lat": 31.497886, "lon": -8.097639 },
  { "name": "DELEGATION SRES", "lat": 33.261349, "lon": -7.586554 },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  { "name": "CSU 2 hay Hassani", "lat": 33.269765, "lon": -7.57406 },
  { "name": "CSU 2 hay Hassani", "lat": 33.270134, "lon": -7.574344 },
  { "name": "CSU 2 hay Hassani", "lat": 33.270134, "lon": -7.574344 },
  { "name": "CS ahly", "lat": 33.265801, "lon": -7.590366 },
  { "name": "CS ahly", "lat": 33.265801, "lon": -7.590366 },
  { "name": "CSU Regragua", "lat": 33.256504, "lon": -7.572848 },
  { "name": "CSU Regragua", "lat": 33.256504, "lon": -7.572848 },
  { "name": "CS Deroua", "lat": 33.372738, "lon": -7.534071 },
  { "name": "CS Deroua", "lat": 33.372738, "lon": -7.534071 },
  { "name": "Centre massira", "lat": 33.399849, "lon": -7.529937 },
  { "name": "CS Massira", "lat": 33.399849, "lon": -7.529937 },
  { "name": "CS olad Abbou", "lat": 33.116604, "lon": -7.938091 },
  { "name": "CS olad Abbou", "lat": 33.116604, "lon": -7.938091 },
  { "name": "CS olad Abbou", "lat": 33.116604, "lon": -7.938091 },
  { "name": "CS olad Abbou", "lat": 33.116604, "lon": -7.938091 },
  { "name": "CSU 2 Elgara", "lat": 33.284294, "lon": -7.240553 },
  { "name": "CSU 2 Elgara", "lat": 33.284294, "lon": -7.240553 },
  { "name": "CSU 2 Elgara", "lat": 33.284294, "lon": -7.240553 },
  { "name": "CS Oulad Ben Ammer", "lat": 33.393826, "lon": -7.469912 },
  { "name": "CS Oulad Ben Ammer", "lat": 33.393826, "lon": -7.469912 },
  { "name": "CS Oulad Sebah", "lat": 33.26931, "lon": -7.267963 },
  { "name": "CS Oulad Sebah", "lat": 33.26931, "lon": -7.267963 },
  { "name": "CS Oulad Zidane", "lat": 33.34951, "lon": -7.349199 },
  { "name": "CSU2 SIDI RAHAL", "lat": 33.470192, "lon": -7.963543 },
  { "name": "CSU2 SIDI RAHAL", "lat": 33.470192, "lon": -7.963543 },
  { "name": "CSU 2 Had Soualem", "lat": 33.42281, "lon": -7.862507 },
  { "name": "CSR1 Sebt sahel", "lat": 33.346111, "lon": -7.745077 },
  { "name": "CSU niveau 1 Ouled Allal", "lat": 33.263313, "lon": -7.620567 },
  {
    "name": "CS Battioua",
    "lat": 33.35747016718383,
    "lon": -7.484585055401734
  },
  {
    "name": "CS Battioua",
    "lat": 33.35747912851071,
    "lon": -7.484597021490753
  },
  { "name": "CSR Niveau 1 Riah", "lat": 33.156025, "lon": -7.388928 },
  { "name": "CSC Lmbarkkiyine", "lat": 33.206829, "lon": -7.49226 },
  { "name": "DR OD Moussa", "lat": 33.28709, "lon": -7.776988 },
  { "name": "Cs oulad moussa", "lat": 33.28709, "lon": -7.776988 },
  { "name": "DR Foqra Ouled Aameur", "lat": 33.145638, "lon": -7.496746 },
  { "name": "CSR Lghnmiyin", "lat": 33.19471, "lon": -7.867634 },
  { "name": "CSR Lghnmiyin", "lat": 33.19471, "lon": -7.867634 },
  { "name": "CSR Lghnmiyin", "lat": 33.19471, "lon": -7.867634 },
  { "name": "DR Rgagna", "lat": 33.2533, "lon": -7.427066 },
  { "name": "DR KRARMA", "lat": 33.059258, "lon": -7.819818 },
  { "name": "DR KRARMA", "lat": 33.059258, "lon": -7.819818 },
  { "name": "DR OD RAHOU", "lat": 33.050964, "lon": -7.89735 },
  { "name": "CSC Sidi El Mekki", "lat": 33.211491, "lon": -7.711946 },
  { "name": "CSR1 KHDARA", "lat": 33.426315, "lon": -7.763383 },
  { "name": "DR Sidi Said Maachou", "lat": 33.170834, "lon": -8.089358 },
  { "name": "CSR1 ZRAHNA", "lat": 33.118, "lon": -8.016 },
  { "name": "DR Ben Maachou", "lat": 33.19331, "lon": -8.003005 },
  { "name": "DR BROUZA", "lat": 33.146118, "lon": -7.775964 },
  { "name": "CS labrouj ar", "lat": 32.50494, "lon": -7.188422 },
  { "name": "CS ben ahmed dachra", "lat": 33.0617645, "lon": -7.2442347 },
  { "name": "DRl Ouled si massaoud", "lat": 32.4882267, "lon": -7.5617583 },
  { "name": "Centre de santÚ Ain belal", "lat": 32.554765, "lon": -7.668345 },
  { "name": "CS BNIKHLOUG", "lat": 32.643787, "lon": -7.381926 },
  { "name": "CS BNIKHLOUG", "lat": 32.643787, "lon": -7.381926 },
  { "name": "CS ben ahmed dachra", "lat": 33.062324, "lon": -7.241338 },
  { "name": "CSU2 TLET LOULAD", "lat": 32.991329, "lon": -7.126285 },
  { "name": "DR HADADA", "lat": 32.895512, "lon": -7.99914 },
  {
    "name": "DÚlÚgation de la santÚ de settat 2",
    "lat": 32.968027796856646,
    "lon": -7.573611634765626
  },
  {
    "name": "DELEGATION DU MINISTERE DE LA SANTE SETTAT ARMOIRE A",
    "lat": 33.00597,
    "lon": -7.609558
  },
  { "name": "CENTRE DE SANTE ECHAFAI", "lat": 32.548969, "lon": -7.504928 },
  { "name": "CENTRE DE SANTE DAR CHAFFAI", "lat": 32.550167, "lon": -7.503116 },
  { "name": "CS OULED FARISS", "lat": 32.792534, "lon": -7.211429 },
  { "name": "CS sgamna", "lat": 32.734834, "lon": -7.204032 },
  { "name": "CS Rass Ain", "lat": 32.979321, "lon": -7.390102 },
  { "name": "CS Sidi hajaj AR", "lat": 32.915146, "lon": -7.260401 },
  { "name": "centre de sante ben ahmed", "lat": 33.068813, "lon": -7.246043 },
  { "name": "H¶pital Mzoura", "lat": 32.832005, "lon": -7.869345 },
  { "name": "CS ben ahmed dachra ar", "lat": 33.0621681, "lon": -7.243561 },
  { "name": "CSR 2 olad said", "lat": 32.967445, "lon": -7.813852 },
  { "name": "CS Mohamed ben rahal", "lat": 32.775642, "lon": -7.773416 },
  { "name": "CS Mohamed ben rahal", "lat": 32.77544, "lon": -7.772604 },
  {
    "name": "Centre de SantÚ Communal Guisser - 2",
    "lat": 32.77264310313876,
    "lon": -7.51132010681534
  },
  {
    "name": "CSC Guisser Armoire",
    "lat": 32.77279195075595,
    "lon": -7.510968737434389
  },
  { "name": "CS  ben ahmed dachra", "lat": 33.067371, "lon": -7.234573 },
  { "name": "Centre de santÚ souakka", "lat": 32.70138, "lon": -7.7967633 },
  {
    "name": "Centre multidisciplinaire mimouna",
    "lat": 33.006187,
    "lon": -7.624196
  },
  { "name": "CSR 1 SIDI DAHBI", "lat": 33.060081, "lon": -7.186319 },
  { "name": "Centre mechraa ben abbou", "lat": 32.6455133, "lon": -7.7992467 },
  { "name": "CENTRE DE SANTE OULED FREIHA", "lat": 32.6148, "lon": -7.621345 },
  {
    "name": "Dispensaire rural A¤N dorbane",
    "lat": 33.0832672,
    "lon": -7.2404038
  },
  { "name": "CS communal gdana", "lat": 32.970032, "lon": -7.907431 },
  { "name": "CS Zemamra", "lat": 32.873394, "lon": -7.693838 },
  { "name": "Centre de santÚ Ouled Aafif", "lat": 32.855598, "lon": -7.555775 },
  {
    "name": "Centre de santÚ tnin toualt",
    "lat": 32.7733011,
    "lon": -7.674311
  },
  { "name": "Centre de santÚ Lahouaza", "lat": 32.911381, "lon": -7.695509 },
  { "name": "CS Lkhmissat Chaouia", "lat": 32.834705, "lon": -7.759511 },
  { "name": "Dr talouit", "lat": 32.82858, "lon": -7.6238217 },
  { "name": "CSR Mniaa", "lat": 32.8898399, "lon": -7.2501002 },
  { "name": "CSC ouled hajjaj", "lat": 32.8887209, "lon": -7.321226 },
  { "name": "CSC Laazayz", "lat": 32.875258, "lon": -7.3121997 },
  { "name": "CS Meskoura", "lat": 32.6191258, "lon": -7.2163722 },
  { "name": "CS Merizig", "lat": 32.84849548339844, "lon": -7.09650993347168 },
  { "name": "Centre de santÚ Rima", "lat": 32.6459013, "lon": -7.3825529 },
  {
    "name": "Centre de santÚ Oled Njima",
    "lat": 32.669822692871094,
    "lon": -7.3222126960754395
  },
  { "name": "CSR Ouled si abdellah", "lat": 32.465052, "lon": -7.4115406 },
  { "name": "Centre de santÚ Labna", "lat": 32.997293, "lon": -7.60215 },
  { "name": "CS OD MHAMEDLocali", "lat": 33.0899254, "lon": -7.0617599 },
  { "name": "CS SIDI EL AIDI", "lat": 33.1205717, "lon": -7.6164433 },
  {
    "name": "Dispensaire Rural Sidi sbaa",
    "lat": 33.191253662109375,
    "lon": -7.03438663482666
  },
  { "name": "CS magarto", "lat": 33.1738521, "lon": -7.1562352 },
  { "name": "Centre de santÚ n'khila", "lat": 32.9549602, "lon": -7.0697779 },
  { "name": "CS Oued Naanaa", "lat": 33.020638, "lon": -7.331609 },
  {
    "name": "Centre de SantÚ ouled hamou",
    "lat": 32.6521005,
    "lon": -7.3385097
  },
  {
    "name": "Centre de santÚ rural 1 laqraqra",
    "lat": 32.4021196,
    "lon": -7.1593484
  },
  {
    "name": "centre de santÚ oulad chebana",
    "lat": 33.04877471923828,
    "lon": -7.382578372955322
  },
  { "name": "CS GAADA", "lat": 33.05679, "lon": -7.3211947 },
  { "name": "Centre de santÚ sgmna", "lat": 32.7346033, "lon": -7.2038933 },
  { "name": "CS Ouled boali", "lat": 32.5932293, "lon": -7.0893459 },
  { "name": "Dr Od Ayad", "lat": 32.708784, "lon": -7.101506 },
  { "name": "CS Olad si moussa", "lat": 32.5221109, "lon": -7.3876337 },
  {
    "name": "Centre de santÚ Ouled amer",
    "lat": 32.4184906,
    "lon": -7.2172564
  },
  { "name": "Ouled Nacer", "lat": 32.925419, "lon": -7.39897 },
  {
    "name": "Centre de santÚ Ouled sghir",
    "lat": 32.8179133,
    "lon": -7.5344083
  },
  {
    "name": "Centre de santÚ ben yagrine",
    "lat": 32.7479374,
    "lon": -7.4973175
  },
  { "name": "CS Nkhila Sidi Hajjaj", "lat": 32.9849437, "lon": -7.2435055 },
  { "name": "CS Ouled ghalem", "lat": 32.5086105, "lon": -7.1428366 },
  { "name": "CS OD Mzirg", "lat": 33.0464172, "lon": -6.9622937 },
  { "name": "DR AIN RBAH", "lat": 33.027313, "lon": -7.0505586 },
  {
    "name": "Centre dispensaire El harcha",
    "lat": 32.46276,
    "lon": -7.1068117
  },
  { "name": "Centre dispensaire Gnazra", "lat": 32.8090243, "lon": -7.4663663 },
  { "name": "CS GDANA", "lat": 32.9141229, "lon": -7.2660756 },
  { "name": "DR Lissouf", "lat": 32.88240051269531, "lon": -7.163527488708496 },
  { "name": "H¶pital zemamra", "lat": 32.628208, "lon": -8.702527 },
  { "name": "HOPITAL SIDI BENNOUR", "lat": 32.652367, "lon": -8.413468 },
  { "name": "HOPITAL SIDI BENNOUR", "lat": 32.652367, "lon": -8.413468 },
  { "name": "H¶pital Sidi Bennour", "lat": 32.652348, "lon": -8.4135 },
  {
    "name": "Centre de santÚ beni tssiris",
    "lat": 32.761468,
    "lon": -8.163933
  },
  {
    "name": "Centre de santÚ beni tssiris",
    "lat": 32.761468,
    "lon": -8.163933
  },
  { "name": "Centre de santÚ Aounat", "lat": 32.741966, "lon": -8.197337 },
  { "name": "CS COMMUNAL GHARBIA", "lat": 32.572267, "lon": -8.855913 },
  { "name": "CS zemamra", "lat": 32.627411, "lon": -8.704941 },
  { "name": "CS COMMUNAL M'TAL", "lat": 32.488255, "lon": -8.37636 },
  { "name": "CSR LEGHNADRA", "lat": 32.629966, "lon": -8.61396 },
  { "name": "CSR KODIAT BNI DAGHDOUGH", "lat": 32.400751, "lon": -8.483305 },
  {
    "name": "C/S COMMUNAL OED SI BOUYAHIA",
    "lat": 32.7086707,
    "lon": -8.3128992
  },
  { "name": "Cs Oualidia", "lat": 32.731768, "lon": -9.035607 },
  { "name": "Cs tamda", "lat": 32.440421, "lon": -8.370726 },
  { "name": "DAR CHABAB LMACHREK", "lat": 32.688035, "lon": -8.411843 },
  { "name": "Cs oulad Boussaken", "lat": 32.570663, "lon": -8.121725 },
  { "name": "CS Beni Hilal", "lat": 32.798473, "lon": -8.378205 },
  { "name": "CS JABRIA", "lat": 32.562735, "lon": -8.31613 },
  { "name": "CS Kridide", "lat": 32.4940445, "lon": -8.5741892 },
  { "name": "CSU METRANE", "lat": 32.597374, "lon": -8.230784 },
  { "name": "SRES sidi bennour", "lat": 32.650765, "lon": -8.445976 },
  {
    "name": "Centre de santÚ Khmiss Ksiba",
    "lat": 32.517723,
    "lon": -8.255236
  },
  { "name": "Centre de santÚ Laatatra", "lat": 32.6303709, "lon": -8.4151654 },
  { "name": "CS COMMUNAL OULED AISSA", "lat": 32.649616, "lon": -8.421763 },
  { "name": "Centre de santÚ Laamria", "lat": 32.836689, "lon": -8.204382 },
  {
    "name": "Centre de santÚ Khmiss Ksiba",
    "lat": 32.517685,
    "lon": -8.255176
  },
  { "name": "Centre de santÚ Essalam", "lat": 32.619713, "lon": -8.708247 },
  { "name": "Centre de santÚ Olad Sbeita", "lat": 32.632862, "lon": -8.848477 },
  { "name": "cs riouni", "lat": 32.7575342, "lon": -8.4827824 },
  {
    "name": "Centre de santÚ ouled Amrane",
    "lat": 32.690243035492266,
    "lon": -8.48121538439031
  },
  { "name": "Centre de santÚ El Karia", "lat": 32.650459, "lon": -8.446127 },
  { "name": "Centre de santÚ Laagagcha", "lat": 32.5484791, "lon": -8.7331182 },
  { "name": "Centre de santÚ laatamna", "lat": 32.6768443, "lon": -8.3670615 },
  { "name": "Cs Oualidia", "lat": 32.731768, "lon": -9.035607 },
  { "name": "SRES sidi bennour", "lat": 32.6489196, "lon": -8.4232002 },
  { "name": "Centre de santÚ Bouhmam", "lat": 32.618916, "lon": -8.472948 },
  { "name": "CS saniat Berguig", "lat": 32.7142435, "lon": -8.6188439 },
  {
    "name": "CS omar bnou khettab",
    "lat": 33.56508117926829,
    "lon": -7.583226935564618
  },
  {
    "name": "CSU mers sultan rue ibn khayran",
    "lat": 33.571022,
    "lon": -7.608718
  },
  { "name": "CSU carlotti", "lat": 33.577007, "lon": -7.598532 },
  {
    "name": "Hopital bowafi sidi maarouf",
    "lat": 33.521576484231176,
    "lon": -7.636157517337319
  },
  { "name": "Complexe ideal bourgogne CF", "lat": 33.594814, "lon": -7.644812 },
  {
    "name": "Centre de SantÚ Moulay Youssef",
    "lat": 33.6037969,
    "lon": -7.6344926
  },
  { "name": "CENTRE DE SANTE PUJET", "lat": 33.5785463, "lon": -7.6307015 },
  { "name": "CS Ibn Sina", "lat": 33.571457, "lon": -7.638116 },
  { "name": "CENTRE DE CENTE EL HONK", "lat": 33.594837, "lon": -7.645012 },
  { "name": "salle santÚ nassim", "lat": 33.5212593, "lon": -7.6608997 },
  { "name": "CS Lissasfa ", "lat": 33.53491973, "lon": -7.6692519187 },
  {
    "name": "Centre de santÚ Lissasfa AR B-1",
    "lat": 33.5316046,
    "lon": -7.6748625
  },
  {
    "name": "Centre de santÚ Lissasfa AR 4 (G-1)",
    "lat": 33.535019,
    "lon": -7.66926
  },
  {
    "name": "Centre de santÚ Lissasfa AR 3 (F-1)",
    "lat": 33.535019,
    "lon": -7.66926
  },
  {
    "name": "Centre de santÚ Lissasfa AR 1 (D-1)",
    "lat": 33.534019,
    "lon": -7.66926
  },
  {
    "name": "Centre de santÚ Lissasfa AR 5 (H-1)",
    "lat": 33.535019,
    "lon": -7.66926
  },
  {
    "name": "Centre de santÚ Lissasfa AR 6 (I-1)",
    "lat": 33.53223722395908,
    "lon": -7.673984684528944
  },
  {
    "name": "Centre de sante Lissasfa AR 7 (J-1)",
    "lat": 33.53166485143567,
    "lon": -7.678420935629374
  },
  {
    "name": "Centre de santÚ Lissasfa AR C-1",
    "lat": 33.534918,
    "lon": -7.669252
  },
  {
    "name": "Centre de santÚ Lissasfa AR 2 (E)",
    "lat": 33.535009,
    "lon": -7.669217
  },
  { "name": "Salle couverte Lissasfa", "lat": 33.5341187, "lon": -7.6778803 },
  {
    "name": "Hopital bouskoura",
    "lat": 33.43602551072033,
    "lon": -7.641261376466369
  },
  { "name": "Centre de santÚ ben abid", "lat": 33.498905, "lon": -7.86792 },
  { "name": "CS Ouled Azzouz", "lat": 33.507542, "lon": -7.777885 },
  { "name": "CENTRE DE SANTE LAMAARIF", "lat": 33.418671, "lon": -7.626656 },
  { "name": "C/S RAHMA 3", "lat": 33.535069, "lon": -7.729502 },
  { "name": "C/S RAHMA 2", "lat": 33.548183, "lon": -7.70174 },
  { "name": "CS ouled malek", "lat": 33.55505, "lon": -7.631732 },
  {
    "name": "Point de Contact SantÚ LAATIKIINE",
    "lat": 33.514099,
    "lon": -7.708533
  },
  { "name": "C/S DAR BOUAZZA", "lat": 33.518475, "lon": -7.814517 },
  { "name": "C/S Ouled Aissa", "lat": 33.475711822, "lon": -7.7702140808 },
  { "name": "C/S Ain Jamaa", "lat": 33.47571182, "lon": -7.77021408081 },
  { "name": "CS Ouled Saleh", "lat": 33.360394, "lon": -7.695004 },
  { "name": "CS LAMKANSSA", "lat": 33.5219622, "lon": -7.5680274 },
  { "name": "Centre de sante laamamra", "lat": 33.4148, "lon": -7.63291 },
  {
    "name": "BSLS Nouaceur Chambre Froide (Reactif 3)",
    "lat": 33.396874,
    "lon": -7.572947
  },
  {
    "name": "BSLS Nouaceur Chambre Froide (Reactif 4)",
    "lat": 33.396874,
    "lon": -7.572947
  },
  {
    "name": "Super congÚlateur N░10 BSLS Nouacer",
    "lat": 33.39594,
    "lon": -7.57343
  },
  {
    "name": "Super congÚlateur N░11 BSLS Nouacer",
    "lat": 33.39594,
    "lon": -7.57343
  },
  {
    "name": "Super congÚlateur N░13 BSLS Nouacer",
    "lat": 33.39594,
    "lon": -7.57343
  },
  {
    "name": "Super congÚlateur N░14 BSLS Nouacer",
    "lat": 33.39594,
    "lon": -7.57343
  },
  {
    "name": "Super CongÚlateur N5 BSLS NOUACEUR",
    "lat": 33.396599,
    "lon": -7.57315
  },
  { "name": "C/S ELNASR", "lat": 33.442753, "lon": -7.687201 },
  { "name": "Centre de santÚ Boujaidia", "lat": 33.43755, "lon": -7.641079 },
  { "name": "Centre de santÚ Boujaidia", "lat": 33.43755, "lon": -7.641079 },
  { "name": "Centre de santÚ Lamaarif", "lat": 33.418057, "lon": -7.6197311 },
  {
    "name": "Centre de santÚ Ouled Aissa",
    "lat": 33.475711822,
    "lon": -7.7702140808
  },
  {
    "name": "Centre de santÚ Ouled Aissa",
    "lat": 33.475711822,
    "lon": -7.7702140808
  },
  {
    "name": "Centre de santÚ Ain Jamaa",
    "lat": 33.47571182,
    "lon": -7.77021408081
  },
  {
    "name": "Centre de santÚ Ben Abid",
    "lat": 33.49889468078595,
    "lon": -7.868254416111944
  },
  { "name": "CS Lamkanssa", "lat": 33.510158, "lon": -7.567559 },
  { "name": "CS bouskoura", "lat": 33.455322, "lon": -7.652946 },
  {
    "name": "Super congÚlateur N░3 BSLS nouacer",
    "lat": 33.3981773,
    "lon": -7.5713205
  },
  {
    "name": "Super congÚlateur N░7 BSLS Nouacer",
    "lat": 33.36035566675374,
    "lon": -7.566890978121169
  },
  {
    "name": "Super congÚlateur N░8 BSLS Nouacer",
    "lat": 33.36953127791294,
    "lon": -7.569580078125001
  },
  {
    "name": "CSU DEUXIEME  nouveaux BIR JDID ",
    "lat": 33.376251,
    "lon": -7.993475
  },
  {
    "name": "CSU DEUXIEME  nouveaux BIR JDID ",
    "lat": 33.376251,
    "lon": -7.993475
  },
  { "name": "DR TAGOURATE ", "lat": 33.284973, "lon": -8.33034 },
  { "name": "CS  SIDI ALI", "lat": 33.284973, "lon": -8.33034 },
  {
    "name": "CENTRE DE SANTE MHARZA SAHEL",
    "lat": 33.418945,
    "lon": -8.087079
  },
  {
    "name": "centre de sante rural niveau 1 LGHDIRA",
    "lat": 33.286972,
    "lon": -8.014118
  },
  { "name": "CS mettouh", "lat": 32.869293, "lon": -8.171586 },
  { "name": "CS mettouh", "lat": 32.880844, "lon": -8.171232 },
  { "name": "CS mettouh", "lat": 32.869293, "lon": -8.171586 },
  {
    "name": "Centre de santÚ boulaouane charika",
    "lat": 32.812416,
    "lon": -8.101433
  },
  { "name": "CSC Boulouane kasba", "lat": 32.845863, "lon": -8.074311 },
  {
    "name": "Centre de santÚ Si Hsaien Ben Abderrahmane",
    "lat": 33.101582,
    "lon": -8.253944
  },
  { "name": "CS Zaouia saiss", "lat": 32.828915, "lon": -8.635731 },
  { "name": "Centre de santÚ sebt saiss", "lat": 32.782223, "lon": -8.625409 },
  { "name": "CSR SIDI SMAIL", "lat": 32.827591, "lon": -8.508939 },
  { "name": "CS mogress", "lat": 32.892696, "lon": -8.421263 },
  { "name": "C/S BOUCEDRA", "lat": 33.20118, "lon": -8.165469 },
  { "name": "CS Ouled hamdan", "lat": 33.104156, "lon": -8.249697 },
  {
    "name": "C/S souk jemaa province al jadida",
    "lat": 33.14162445,
    "lon": -8.3444442749
  },
  {
    "name": "Centre de SantÚ Rural Od Rahmoune",
    "lat": 33.249905,
    "lon": -8.334851
  },
  { "name": "Dispensaire Rural Lagaamsa", "lat": 33.259354, "lon": -8.181252 },
  { "name": "CS Ouled Frej", "lat": 33.251243, "lon": -8.209166 },
  { "name": "CS Ouled Frej", "lat": 32.949745, "lon": -8.209166 },
  {
    "name": "CS Rural Niveau 1 LAKOUASSEM",
    "lat": 32.9240847,
    "lon": -8.2956713
  },
  { "name": "CSC Boulouane kasba", "lat": 32.862488, "lon": -8.061564 },
  { "name": "CS SIDI YAHYA ", "lat": 33.251289, "lon": -8.508869 },
  {
    "name": "HOPITAL MOHAMMED 5 (Armoire 7)",
    "lat": 33.251282,
    "lon": -8.508868
  },
  { "name": "C/S SIDI ABED", "lat": 33.041428, "lon": -8.691177 },
  { "name": "C/S SIDI ABED", "lat": 33.041428, "lon": -8.691177 },
  {
    "name": "Centre de santÚ derb ghallef",
    "lat": 33.260021,
    "lon": -8.512548
  },
  {
    "name": "Centre de santÚ derb guabass",
    "lat": 33.2562027,
    "lon": -8.5114384
  },
  { "name": "CS COMMUNAL OULED AISSA", "lat": 32.9527253, "lon": -8.6994587 },
  { "name": "CS SIDI MOHAMMED AKHDIM", "lat": 32.744042, "lon": -8.726001 },
  {
    "name": "Centre de santÚ molay abdelah al jadida",
    "lat": 33.1980265,
    "lon": -8.5898576
  },
  { "name": "Centre saada", "lat": 33.2431658, "lon": -8.5306112 },
  { "name": "CS SIDI MOMEN", "lat": 33.5809259, "lon": -7.5453349 },
  {
    "name": "Centre MÚdical de proximitÚ Sidi Moumen",
    "lat": 33.595089,
    "lon": -7.504688
  },
  { "name": "PP Benslimane", "lat": 33.613918, "lon": -7.126325 },
  { "name": "PP Benslimane", "lat": 33.613918, "lon": -7.126325 },
  { "name": "PP Benslimane", "lat": 33.613918, "lon": -7.126325 },
  {
    "name": "PP Benslimane",
    "lat": 33.6145118090437,
    "lon": -7.12636207044119
  },
  { "name": "Ouled Yehya Louta", "lat": 33.552521, "lon": -7.244277 },
  { "name": "DR Ouled Ali Touallaa", "lat": 33.552521, "lon": -7.244277 },
  {
    "name": "CSR Mellila",
    "lat": 33.38683246572025,
    "lon": -7.147833762311531
  },
  { "name": "CS Ahlaf", "lat": 33.2876995, "lon": -7.2077938 },
  { "name": "CSU Ben Slimane", "lat": 33.614582, "lon": -7.125474 },
  { "name": "CSU Sidi Betach", "lat": 33.568134, "lon": -6.891061 },
  { "name": "CS Laayoune", "lat": 33.667526, "lon": -7.071202 },
  { "name": "CS Cherrat", "lat": 33.812092, "lon": -7.113952 },
  { "name": "DR Karassi", "lat": 33.532448, "lon": -6.972086 },
  {
    "name": "DÚp¶t RÚgionale des MÚdicaments Rabat",
    "lat": 34.05265942137599,
    "lon": -6.811408473445164
  },
  { "name": "CSU Mansouria", "lat": 33.739208, "lon": -7.29141 },
  { "name": "CS Tilal", "lat": 33.72937, "lon": -7.330822 },
  { "name": "CS ben chaqchaq", "lat": 33.763851, "lon": -7.264178 },
  { "name": "CS Bouznika", "lat": 33.788437, "lon": -7.159611 },
  { "name": "CSU Hay Hassani", "lat": 33.622536, "lon": -7.121048 },
  { "name": "CSC Bassbass Lgharbia", "lat": 33.690044, "lon": -7.185258 },
  { "name": "CS Chatbia", "lat": 33.1903891, "lon": -6.9624049 },
  { "name": "CS HAY MOHAMMEDI", "lat": 33.621994, "lon": -7.129376 },
  { "name": "CSC Tlata Ziaida", "lat": 33.494751, "lon": -7.103128 },
  { "name": "CS Laamour", "lat": 33.569477, "lon": -7.308302 },
  { "name": "CSR Rdadna", "lat": 33.5985733, "lon": -7.4891478 },
  { "name": "CSR Bir Nasr", "lat": 33.342768, "lon": -6.915027 },
  { "name": "Depot SRES AR3", "lat": 33.6892166, "lon": -7.3907623 },
  {
    "name": "Centre de santÚ urbain Al alia",
    "lat": 33.6874352,
    "lon": -7.3902073
  },
  { "name": "CSU CHABAB", "lat": 33.690441, "lon": -7.38861 },
  {
    "name": "CENTRE DE SANTE SIDI MOUSSA BEN ALI",
    "lat": 33.563206,
    "lon": -7.374975
  },
  {
    "name": "CENTRE DE SANTE SIDI MOUSSA BEN ALI",
    "lat": 33.563206,
    "lon": -7.374975
  },
  { "name": "CENTRE DE SANTE AIT TEKKI", "lat": 33.66301, "lon": -7.352716 },
  { "name": "CENTRE DE SANTE AIT TEKKI", "lat": 33.66301, "lon": -7.352716 },
  { "name": "CS SIDI moussa el mejdoub", "lat": 33.60957, "lon": -7.386728 },
  {
    "name": "centre de sante urbain elhassania",
    "lat": 33.6751543,
    "lon": -7.3990468
  },
  {
    "name": "centre de sante urbain elhassania",
    "lat": 33.6751543,
    "lon": -7.3990468
  },
  { "name": "CSU Ryad", "lat": 33.683174, "lon": -7.384242 },
  { "name": "CS AIN HARROUDA", "lat": 33.640137, "lon": -7.448836 },
  { "name": "CS AIN HARROUDA", "lat": 33.6400223, "lon": -7.4489691 },
  { "name": "centre de sante siham", "lat": 33.642658, "lon": -7.477609 },
  { "name": "centre de sante siham", "lat": 33.642658, "lon": -7.477609 },
  {
    "name": "Centre vaccinodrom thÚÔtre\r\n",
    "lat": 33.6856858,
    "lon": -7.3892656
  },
  { "name": "CENTRE DE SANTE BEN YAKHLEF", "lat": 33.685211, "lon": -7.341189 },
  { "name": "CS Al Nour", "lat": 33.5461522, "lon": -7.5941606 },
  { "name": "CS les cretes ", "lat": 33.542091, "lon": -7.633716 },
  { "name": "CS dakhla", "lat": 33.541515, "lon": -7.591956 },
  { "name": "CS Al Adarissa", "lat": 33.571634, "lon": -7.679103 },
  { "name": "CS Sidi Massoud", "lat": 33.5126202, "lon": -7.6303423 },
  { "name": "CS sidi massoud ", "lat": 33.5126202, "lon": -7.6303423 },
  { "name": "CS lemkansa", "lat": 33.532253, "lon": -7.573936 },
  { "name": "CS Al Adarissa", "lat": 33.571634, "lon": -7.679103 },
  { "name": "CSR SIDI HAJAJ ", "lat": 33.538269, "lon": -7.417012 },
  { "name": "CS lahraouiine", "lat": 33.545372, "lon": -7.544011 },
  { "name": "CS lahraouiine", "lat": 33.545372, "lon": -7.544011 },
  {
    "name": "CSU AVEC MODULE D'ACCOUCHEMENT ",
    "lat": 33.455738,
    "lon": -7.520249
  },
  { "name": "CSR ABRAR", "lat": 33.46180698328056, "lon": -7.519491600847169 },
  {
    "name": "DELEGATION DE MOULAY RACHID",
    "lat": 33.5589219,
    "lon": -7.5735321
  },
  { "name": "CS moulay rchid Grp 6", "lat": 33.5699283, "lon": -7.5273854 },
  { "name": "CS Mabrouka", "lat": 33.569454, "lon": -7.5670335 },
  { "name": "CS MOULAY RACHID 2", "lat": 33.5681841, "lon": -7.5458907 },
  {
    "name": "CSU niveau 1 Baladia",
    "lat": 33.55741786324217,
    "lon": -7.559971352167682
  },
  { "name": "CS al baida", "lat": 33.5609194, "lon": -7.5327997 },
  { "name": "CS massira 1", "lat": 33.5606928, "lon": -7.5401519 },
  {
    "name": "CSSP SALAMA 3",
    "lat": 33.56427001953125,
    "lon": -7.5594329833984375
  },
  {
    "name": "CSU niveau 1 Essalama",
    "lat": 33.566158294677734,
    "lon": -7.559484004974365
  },
  {
    "name": "CSU Niveau 1 Al Fadl",
    "lat": 33.55330276489258,
    "lon": -7.519222736358643
  },
  {
    "name": "pharmacie delegation ben m'sick",
    "lat": 33.54427,
    "lon": -7.572502
  },
  {
    "name": "pharmacie delegation ben m'sick",
    "lat": 33.544272,
    "lon": -7.572506
  },
  { "name": "CS jawadi", "lat": 33.555256, "lon": -7.584442 },
  {
    "name": "Centre hospitalier PrÚfectoral BEN M'SICK",
    "lat": 33.55233,
    "lon": -7.578226
  },
  { "name": "Delegation SRES", "lat": 33.589508, "lon": -7.552338 },
  { "name": "Delegation SRES", "lat": 33.589508, "lon": -7.552338 },
  { "name": "Delegation SRES", "lat": 33.58953, "lon": -7.552299 },
  { "name": "Delegation SRES", "lat": 33.589508, "lon": -7.552338 },
  { "name": "CSU 11 Janvier", "lat": 33.584656, "lon": -7.549094 },
  { "name": "CSU Ryad", "lat": 33.594131, "lon": -7.568691 },
  { "name": "CS CEINTURE HAY MOHAMMDI", "lat": 33.583775, "lon": -7.554214 },
  { "name": "hay jamal bd du fouarat", "lat": 33.574093, "lon": -7.576598 },
  { "name": "CS Ain Sebaa", "lat": 33.605362, "lon": -7.549011 },
  {
    "name": "CS urbain-2 AGDEZ Armoire frigorifique A",
    "lat": 30.798542,
    "lon": -6.547125
  },
  { "name": "CS urbain-2 AGDEZ -1", "lat": 30.698543, "lon": -6.447126 },
  { "name": "CS urbain-2 AGDEZ", "lat": 30.698642, "lon": -6.447225 },
  { "name": "CS communal Tamnougalt", "lat": 30.67959, "lon": -6.383977 },
  { "name": "Dispensaire rural TIMIDARTE", "lat": 30.679075, "lon": -6.299148 },
  { "name": "CS communal Afra", "lat": 30.717075, "lon": -6.234733 },
  { "name": "CS rural-2 Tamezmoute", "lat": 30.667813, "lon": -6.144132 },
  { "name": "CS communal OULED SLIMANE", "lat": 30.575054, "lon": -6.148407 },
  {
    "name": "CS rural-2 TINZOLINE Armoire frigorifique A",
    "lat": 30.504347109664838,
    "lon": -6.101663652778632
  },
  { "name": "CS communal BOUZAROUAL", "lat": 30.496862, "lon": -6.056171 },
  {
    "name": "CS rural BENI ZOULI Armoire frigorifique A",
    "lat": 30.441860563878624,
    "lon": -5.896550915344241
  },
  {
    "name": "CS rural BENI ZOULI ",
    "lat": 30.441985436081403,
    "lon": -5.896473131282809
  },
  {
    "name": "DR ZAOUIAT SIDI BAGHDAD",
    "lat": 30.325471259328104,
    "lon": -5.8681822550574605
  },
  { "name": "DR OULED LHAJ", "lat": 30.358469, "lon": -5.804779 },
  { "name": "CS communal boukhlal", "lat": 30.387967, "lon": -5.791168 },
  { "name": "CSC Tissergate", "lat": 30.396582, "lon": -5.862499 },
  {
    "name": "CS urbain ZAOUYAT LMAJDOUB Armoire frigorifique A",
    "lat": 30.34954180737705,
    "lon": -5.839968323707581
  },
  {
    "name": "CS urbain ZAOUYAT LMAJDOUB",
    "lat": 30.349272752328293,
    "lon": -5.840029728836062
  },
  {
    "name": "CS urbain ZAOUYAT LMAJDOUB -1",
    "lat": 30.34933293302045,
    "lon": -5.840039116567614
  },
  {
    "name": "CS urbain ZAGOURA Armoire frigorifique A-1",
    "lat": 30.324948847824224,
    "lon": -5.83775389814758
  },
  {
    "name": "CS urbain ZAGOURA -1",
    "lat": 30.32504608692573,
    "lon": -5.8377324404754605
  },
  {
    "name": "Hopital DARRAK Chambre froide A",
    "lat": 30.32500565224115,
    "lon": -5.837872423278809
  },
  {
    "name": "CSR 2 Nkoub Armoire frigorifique A",
    "lat": 30.868341280372963,
    "lon": -5.870819091796876
  },
  {
    "name": "CSR 2 Nkoub 3",
    "lat": 30.870488675598857,
    "lon": -5.865140250331882
  },
  { "name": "CSR 2 Nkoub 1", "lat": 30.870514, "lon": -5.864982 },
  { "name": "CS communal AIT CHROU", "lat": 30.849165, "lon": -5.712075 },
  { "name": "CS AMRAD -1", "lat": 30.647175, "lon": -5.493887 },
  {
    "name": "CS AMRAD -2",
    "lat": 30.647087312487802,
    "lon": -5.4938870000000035
  },
  { "name": "CS THARBALT", "lat": 30.629602, "lon": -5.350577 },
  {
    "name": "CS urbain-2 AMEZROU -2",
    "lat": 30.309778422118978,
    "lon": -5.8227905656414025
  },
  {
    "name": "CS communal avec maison d'accouchement TAMEGROUTE Armoire frigorifique A",
    "lat": 30.260874,
    "lon": -5.686981
  },
  {
    "name": "CS communal avec maison d'accouchement TAMEGROUTE",
    "lat": 30.26092960152567,
    "lon": -5.686982341104501
  },
  { "name": "CS ait sfoul", "lat": 30.227274, "lon": -5.656853 },
  { "name": "DR tinfou", "lat": 30.210491, "lon": -5.608371 },
  { "name": "DR AIT ALI", "lat": 30.179489, "lon": -5.552661 },
  {
    "name": "Dispensaire communal OULED AMEROU",
    "lat": 30.030029,
    "lon": -5.514904
  },
  {
    "name": "Disponsaire Nasrat",
    "lat": 30.486550842588485,
    "lon": -5.7474476097372955
  },
  { "name": "CS communal BLIDA KETAOUA", "lat": 29.98872, "lon": -5.506641 },
  {
    "name": "CS Tagonit -1",
    "lat": 29.969732140521156,
    "lon": -5.58058261871338
  },
  {
    "name": "CS Tagonit -2",
    "lat": 29.973534793099553,
    "lon": -5.581410419643402
  },
  {
    "name": "CS Tagonit Armoire frigorifique A-1",
    "lat": 29.973486,
    "lon": -5.581338
  },
  {
    "name": "CS rural-2 MHAMID EK GHZLAN Armoire frigorifique A",
    "lat": 29.825561855886125,
    "lon": -5.718523263931274
  },
  {
    "name": "CS rural-2 MHAMID EK GHZLAN -1",
    "lat": 29.825507750492662,
    "lon": -5.718740550926211
  },
  { "name": "CS rural-1 TASLA", "lat": 30.565071, "lon": -6.752508 },
  { "name": "CS Bleida mine", "lat": 30.364426, "lon": -6.460337 },
  { "name": "CS HNABO", "lat": 31.885862620983733, "lon": -4.390923568707655 },
  {
    "name": "CSC Avec Module D'accouchement ASSAFALAT",
    "lat": 31.2390442,
    "lon": -4.274952
  },
  { "name": "CSC  SAHLI", "lat": 32.03395234192713, "lon": -4.561359841462831 },
  { "name": "CSR EL KHEN", "lat": 31.9557962, "lon": -4.4691938 },
  {
    "name": "CSU  Ouled Abdelhalim",
    "lat": 31.965645392787895,
    "lon": -4.277370964041328
  },
  {
    "name": "CS Abd Rahmane Sahraoui",
    "lat": 31.930419170900212,
    "lon": -4.430292976686476
  },
  {
    "name": "CS Abd Rahmane Sahraoui",
    "lat": 31.930437381831872,
    "lon": -4.4302876122684465
  },
  {
    "name": "CSC Tamehrach ",
    "lat": 32.01118996740872,
    "lon": -4.329360808125001
  },
  {
    "name": "CSC Avec Maison D'accouchement AGHBALO TAKRDOUSS",
    "lat": 32.045340571146134,
    "lon": -4.461037487631208
  },
  {
    "name": "CSC Avec Maison D'accouchement MELAAB ",
    "lat": 32.08052079586919,
    "lon": -4.281807814332655
  },
  {
    "name": "CSC Avec Maison D'accouchement  Essifa",
    "lat": 32.061906850801556,
    "lon": -4.295654296875001
  },
  {
    "name": "CSC Avec Maison D'accouchement  ASRIR ",
    "lat": 31.499813,
    "lon": -5.067918593917852
  },
  {
    "name": "CSC Avec Maison D'accouchement  KTAA OUAD",
    "lat": 31.539505,
    "lon": -5.012994
  },
  {
    "name": "CSC Avec Maison D'accouchement  KTAA OUAD",
    "lat": 31.539399844294866,
    "lon": -5.013012775463094
  },
  {
    "name": "CSC Avec Maison D'accouchement  KTAA OUAD",
    "lat": 31.539505,
    "lon": -5.013106652778616
  },
  {
    "name": "CSC Avec Maison D'accouchement  TADIROUSTE",
    "lat": 31.818285,
    "lon": -4.958263
  },
  {
    "name": "CSC Avec Maison D'accouchement  TADIROUSTE",
    "lat": 31.818180159562026,
    "lon": -4.958343466270452
  },
  { "name": "CSC FEZNA", "lat": 31.510927, "lon": -4.434705 },
  { "name": "CS HSSEN DAKHEL ", "lat": 31.434576, "lon": -4.228932 },
  {
    "name": "CS HSSEN DAKHEL ",
    "lat": 31.434630925373778,
    "lon": -4.229001737434383
  },
  { "name": "CS IGLI ", "lat": 32.058797144046906, "lon": -4.35018443730371 },
  { "name": "CS IGLI ", "lat": 32.00705368843283, "lon": -4.35018443730371 },
  { "name": "CS IGLI ", "lat": 31.550357, "lon": -4.897167 },
  {
    "name": "CS MERZOUGA ",
    "lat": 31.09788108315152,
    "lon": -4.015100892030714
  },
  {
    "name": "CSR EL MANSOURIA ",
    "lat": 31.993602405769558,
    "lon": -4.386085218220058
  },
  {
    "name": "CSR OULED BEN NAJJI ",
    "lat": 31.92787421928483,
    "lon": -4.422865635581967
  },
  { "name": "CSR OULED BEN NAJJI ", "lat": 31.938963, "lon": -4.423971 },
  {
    "name": "CSR OULED BEN NAJJI ",
    "lat": 31.927974382136753,
    "lon": -4.422750300594327
  },
  { "name": "CSR ZAOUIAT JEDIDA", "lat": 31.62105, "lon": -4.226558 },
  { "name": "CSR 1 BENNI MOUSSA ", "lat": 31.891163, "lon": -4.326494 },
  {
    "name": "CSR 1 BENNI MOUSSA ",
    "lat": 31.891153890647168,
    "lon": -4.32621505026246
  },
  { "name": "CSR EL KHEN", "lat": 31.9694614, "lon": -4.4740213 },
  { "name": "CSR EL KHEN", "lat": 31.96863852529563, "lon": -4.473076 },
  {
    "name": "CSR 2 AMLAGUO ",
    "lat": 31.978051819001276,
    "lon": -4.436845735193183
  },
  {
    "name": "CSR 2 AMLAGUO ",
    "lat": 32.07119869471998,
    "lon": -4.412414424722128
  },
  {
    "name": "CSR 2 OUFOUS ",
    "lat": 31.93973912716978,
    "lon": -4.406907505314044
  },
  { "name": "CSR 2 OUFOUS ", "lat": 31.687784, "lon": -4.17742 },
  {
    "name": "CS TOUROUGUE",
    "lat": 31.97495760523521,
    "lon": -4.395521019077498
  },
  {
    "name": "CSU 20 AOUT GOULMIMA ",
    "lat": 31.940775334391823,
    "lon": -4.392900422693183
  },
  { "name": "CSC OUAKKA", "lat": 31.680737, "lon": -4.9161663 },
  { "name": "CSU 20 AOUT GOULMIMA", "lat": 31.684558, "lon": -4.959974 },
  {
    "name": "CSU 20 AOUT GOULMIMA",
    "lat": 31.69020209298359,
    "lon": -4.949913948061001
  },
  {
    "name": "CSU 20 AOUT GOULMIMA",
    "lat": 31.71882222408327,
    "lon": -4.9822786291036545
  },
  { "name": "CSU  ALLAH BEN ABD ALLAH", "lat": 31.928377, "lon": -4.421375 },
  {
    "name": "CSU Avec UnitÚ D'accouchement BOUDNIB",
    "lat": 31.984266009422324,
    "lon": -4.446417309607461
  },
  {
    "name": "CSU Avec UnitÚ D'accouchement BOUDNIB",
    "lat": 32.08671515581558,
    "lon": -4.421984657051661
  },
  {
    "name": "CSU MOHAMED ZARKTOUNI ",
    "lat": 31.923558212294108,
    "lon": -4.440975766864779
  },
  {
    "name": "CSU MOHAMED ZARKTOUNI ",
    "lat": 31.923548537013307,
    "lon": -4.4410488570604345
  },
  { "name": "CSU QODS ", "lat": 31.937187, "lon": -4.450938 },
  { "name": "CSU QODS ", "lat": 31.937216590583567, "lon": -4.450822665012368 },
  { "name": "CSU QODS ", "lat": 31.937186999999994, "lon": -4.45079047850419 },
  {
    "name": "CSU SIJILMASSA",
    "lat": 31.283079013982533,
    "lon": -4.265629008598331
  },
  { "name": "CSU SIJILMASSA", "lat": 31.283024, "lon": -4.26557 },
  { "name": "CSU SIJILMASSA", "lat": 31.283024, "lon": -4.26557 },
  {
    "name": "CSU SIJILMASSA",
    "lat": 31.282712253492804,
    "lon": -4.26563437301636
  },
  { "name": "CS GHORFA", "lat": 31.254082, "lon": -4.22336 },
  {
    "name": "Centre de santÚR MEZKIDA",
    "lat": 31.959153316146683,
    "lon": -4.38898382458419
  },
  {
    "name": "Centre de santÚ R OULED BAHR",
    "lat": 31.448027,
    "lon": -4.253372
  },
  {
    "name": "Centre de santÚU AL JORF",
    "lat": 31.49171131115117,
    "lon": -4.400865295631415
  },
  {
    "name": "Centre de santÚU AL JORF",
    "lat": 31.491359087562685,
    "lon": -4.400784829360968
  },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.930664432724775,
    "lon": -4.429640936176291
  },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.93077028343227,
    "lon": -4.429970847885123
  },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.93061890550127,
    "lon": -4.429724084655753
  },
  {
    "name": "DÚlÚgation Errachidia",
    "lat": 31.93059728006221,
    "lon": -4.429742860118857
  },
  { "name": "DÚlÚgation Errachidia", "lat": 31.951532, "lon": -4.449977 },
  {
    "name": "Dispensaire Rural Ait Othmane",
    "lat": 32.087967,
    "lon": -4.381036
  },
  { "name": "DR LKSSER JDID", "lat": 31.906187, "lon": -4.354425 },
  { "name": "DR Ouled Chaker", "lat": 31.770399, "lon": -4.215695 },
  { "name": "DR Zaouit Amelkis", "lat": 31.810881, "lon": -4.267219 },
  { "name": "DR AGOUDIM", "lat": 32.06768775655895, "lon": -4.904714288360592 },
  { "name": "DR EL KARAOUI EL JABIL", "lat": 31.315454, "lon": -4.287548 },
  { "name": "DR hassi labyad", "lat": 31.138725, "lon": -4.026857 },
  { "name": "DR MAADID", "lat": 31.467836, "lon": -4.214228 },
  {
    "name": "DR TAZOUGART",
    "lat": 31.854743291605065,
    "lon": -4.4743760013316045
  },
  {
    "name": "DR TILOUINE",
    "lat": 31.996698569574715,
    "lon": -4.458978991521699
  },
  { "name": "DR Tizougaghine", "lat": 31.609486, "lon": -4.950847 },
  { "name": "CHP MIDELT Chambre froide A", "lat": 32.698401, "lon": -4.757972 },
  { "name": "CHP MIDELT Chambre froide B", "lat": 32.69301, "lon": -4.757972 },
  { "name": "CSR2 BOUMIA", "lat": 32.727904, "lon": -5.101181 },
  { "name": "CSR2 BOUMIA -2", "lat": 32.724904, "lon": -5.101181 },
  { "name": "CSR TOUNFIT -1", "lat": 32.4685786, "lon": -5.3113126 },
  { "name": "CSR TOUNFIT -2", "lat": 32.4695786, "lon": -5.3113126 },
  { "name": "CSU SOUK LHED", "lat": 32.693251, "lon": -4.742019 },
  { "name": "CS MIBLADEN", "lat": 32.765408, "lon": -4.627574 },
  { "name": "CS AIT IZDEG", "lat": 32.40062, "lon": -4.45358 },
  { "name": "DISPENSAIRE RURAL ZEBZAT", "lat": 32.658107, "lon": -4.580645 },
  {
    "name": "H¶pital midelt Armoire frigorifique H",
    "lat": 32.67683740100425,
    "lon": -4.738605542327885
  },
  {
    "name": "H¶pital midelt Armoire frigorifique G",
    "lat": 32.677144446780844,
    "lon": -4.739249272491459
  },
  {
    "name": "H¶pital midelt Armoire frigorifique E",
    "lat": 32.677650166935685,
    "lon": -4.738627000000004
  },
  {
    "name": "H¶pital midelt Armoire frigorifique D",
    "lat": 32.677830780582816,
    "lon": -4.738669915344242
  },
  {
    "name": "H¶pital midelt Armoire frigorifique C",
    "lat": 32.677153477523,
    "lon": -4.7391312552948035
  },
  {
    "name": "H¶pital midelt Armoire frigorifique B",
    "lat": 32.677595982770335,
    "lon": -4.73871283068848
  },
  {
    "name": "H¶pital midelt Armoire frigorifique A",
    "lat": 32.67746955292337,
    "lon": -4.73872355952454
  },
  {
    "name": "HOPITAL EL RICH Chambre froide A",
    "lat": 32.277066,
    "lon": -4.490627
  },
  { "name": "DISPENSAIRE RURAL TAGHZOUT", "lat": 32.4952, "lon": -4.28344 },
  { "name": "DISPENSAIRE BOUAYACH", "lat": 32.43166, "lon": -4.2621 },
  { "name": "CS GOURRAMA -2", "lat": 32.34048, "lon": -4.076185 },
  { "name": "CS GOURRAMA -3", "lat": 32.34148, "lon": -4.076185 },
  { "name": "CS GOURRAMA -4", "lat": 32.34248, "lon": -4.076185 },
  { "name": "DISPENSAIRE RURAL BERTAT", "lat": 32.67176, "lon": -4.325643 },
  { "name": "CS AMRSEID -1", "lat": 32.750358, "lon": -4.468684 },
  { "name": "CS AIT OUMGHAR", "lat": 32.670419, "lon": -4.953248 },
  {
    "name": "DISPENSAIRE RURAL BOULABZOUZE",
    "lat": 32.660791,
    "lon": -5.140149
  },
  {
    "name": "CS et maison d'accouchement Zayda -2",
    "lat": 32.822115,
    "lon": -4.978386
  },
  {
    "name": "CS et maison d'accouchement Zayda -1",
    "lat": 32.812115,
    "lon": -4.968386
  },
  { "name": "CS ait N'Ali", "lat": 32.423035, "lon": -4.147499 },
  { "name": "CS rural-1 toulal", "lat": 32.293129, "lon": -3.990413 },
  { "name": "CS Ait Attou", "lat": 32.243786, "lon": -4.359643 },
  {
    "name": "Dispensaire rural AIT kHOUJMANE",
    "lat": 32.266666,
    "lon": -4.413688
  },
  { "name": "CS Communal Kerrandou", "lat": 32.247143, "lon": -4.39203 },
  {
    "name": "CS urbain -2 Tahmidant Rich -1",
    "lat": 32.256889,
    "lon": -4.503875
  },
  { "name": "CS Ait Moussa Ou Ali", "lat": 32.237724, "lon": -4.615632 },
  { "name": "CS rural MZIZEL", "lat": 32.237759, "lon": -4.733388 },
  { "name": "CS ZAOUIA SIDI HAMZA", "lat": 32.427921, "lon": -4.695275 },
  { "name": "CS communal nzala", "lat": 32.49612, "lon": -4.486439 },
  {
    "name": "Dispensaire rural AIT ABBAS",
    "lat": 32.428020152275444,
    "lon": -4.475241673610683
  },
  {
    "name": "CS communal AYAD ( tilichte )",
    "lat": 32.303295,
    "lon": -4.537214
  },
  { "name": "CS ait yaakoub midelt", "lat": 32.340153, "lon": -4.913683 },
  {
    "name": "CS communal et unitÚ d'accouchement tazrine ait yahya",
    "lat": 32.278145,
    "lon": -5.095101
  },
  { "name": "CS AMOUGER", "lat": 32.273548, "lon": -4.935078 },
  {
    "name": "C S imlchil",
    "lat": 32.15633768962298,
    "lon": -5.631534250331873
  },
  {
    "name": "Dispensaire rural AIT YAHYA OUSGHIR",
    "lat": 32.159901,
    "lon": -5.212233
  },
  { "name": "Dispensaire rural AGDAL", "lat": 32.011715, "lon": -5.487679 },
  { "name": "CS communal BOUZMOU", "lat": 32.099243, "lon": -5.52164 },
  { "name": "CS TILMI", "lat": 32.179054, "lon": -5.472979 },
  { "name": "CS agoudim RÚf M 1", "lat": 32.381966, "lon": -5.177719 },
  { "name": "CS Anemzi", "lat": 32.324707, "lon": -5.265627 },
  { "name": "CS Lougagh", "lat": 32.399677, "lon": -5.064494 },
  { "name": "CS bouadel", "lat": 32.414459, "lon": -5.464716 },
  {
    "name": "Dispensaire rural TIKAJOUINE",
    "lat": 32.514481,
    "lon": -5.465936
  },
  { "name": "tizi n'ghachou", "lat": 32.775742, "lon": -5.222391 },
  { "name": "CSC et modele AGHBALOU", "lat": 32.676212, "lon": -5.295595 },
  { "name": "DR AIT OUAHI OU HAKKI", "lat": 32.617355, "lon": -5.304307 },
  { "name": "CS tamayoust", "lat": 32.990559, "lon": -4.887234 },
  { "name": "CS tanourdi", "lat": 32.772152, "lon": -5.130283 },
  {
    "name": "CS et maison d'accouchement ITEZER",
    "lat": 32.877857,
    "lon": -5.049826
  },
  { "name": "Hopital Hassan 2", "lat": 32.680595, "lon": -4.736897 },
  {
    "name": "C/S HASSAN II A",
    "lat": 32.680479635200776,
    "lon": -4.736483931213373
  },
  {
    "name": "Pharmacie dÚlÚgation OUARZAZATE",
    "lat": 30.930381,
    "lon": -6.916488
  },
  { "name": "DISPENSAIRE TALMASLA", "lat": 30.897903, "lon": -6.883021 },
  {
    "name": "Dispensaire rural,ighrem, Agouim",
    "lat": 31.159701,
    "lon": -7.466341
  },
  {
    "name": "DISPENSAIRE RURAL AIT BEN HADDOU",
    "lat": 31.05574,
    "lon": -7.13312
  },
  {
    "name": "DISPENSAIRE RURAL AIT ZINEB TIKERT",
    "lat": 30.954912,
    "lon": -7.098336
  },
  { "name": "CSR AIT ZINEB TIMDLINE", "lat": 30.989279, "lon": -7.134101 },
  { "name": "CR AMERZGANE", "lat": 31.0456361, "lon": -7.2108973 },
  { "name": "CSR ANZAL", "lat": 30.7592538, "lon": -7.288911 },
  {
    "name": "DISPENSAIRE RURAL AIT TAMALAKOUT",
    "lat": 30.761984,
    "lon": -7.372687
  },
  {
    "name": "CS RURAL KHOZAMA TACHOUKCHTE",
    "lat": 30.8035378,
    "lon": -7.5391308
  },
  { "name": "CS TAZNAKHTE", "lat": 30.579583, "lon": -7.202483 },
  { "name": "CS COMMUNAL TAZNAKHTE", "lat": 30.577161, "lon": -7.202746 },
  {
    "name": "DISPENSAIRE RURAL TAMAAROUFT",
    "lat": 30.371633,
    "lon": -7.252461
  },
  {
    "name": "DISPENSAIRE RURAL TAOUNIKHT",
    "lat": 30.4505719,
    "lon": -7.049175
  },
  {
    "name": "DISPENSAIRE RURAL ZAOUIT SIDI BLAL",
    "lat": 30.4760682,
    "lon": -6.9777932
  },
  {
    "name": "Dispensaire rural, iminouassif",
    "lat": 31.234106,
    "lon": -6.690353
  },
  { "name": "CS communal idlsane", "lat": 30.999112, "lon": -6.652988 },
  {
    "name": "dispensaire rural skoura imassine",
    "lat": 31.131224,
    "lon": -6.336324
  },
  {
    "name": "CS rural skoura -1",
    "lat": 31.06347000000001,
    "lon": -6.548277626983634
  },
  { "name": "CS rural skoura -2", "lat": 31.0633337, "lon": -6.5490332 },
  {
    "name": "dispensaire rural skoura hbabza",
    "lat": 31.093351,
    "lon": -6.575075
  },
  { "name": "CS rural moghrane Toundoute", "lat": 31.272833, "lon": -6.586156 },
  {
    "name": "Centre de santÚ rural Bourmane",
    "lat": 31.359482,
    "lon": -6.497377
  },
  {
    "name": "dispensaire rural moghrane iminoulaoune",
    "lat": 31.312684,
    "lon": -6.507507
  },
  { "name": "Dispensaire rural TARGA", "lat": 31.268666, "lon": -6.513228 },
  { "name": "Dispensaire rural N'kob", "lat": 30.60907, "lon": -7.429178 },
  { "name": "Dispensaire rural ANMID", "lat": 30.855066, "lon": -7.606023 },
  { "name": "Dispensaire rural tislday", "lat": 31.126702, "lon": -7.329695 },
  { "name": "Dispensaire rural TAMSTINT", "lat": 31.1644782, "lon": -7.526975 },
  {
    "name": "CS communal avec module d'accouchement TIDILI",
    "lat": 31.099641,
    "lon": -7.608819
  },
  { "name": "CS communal Timjichte", "lat": 30.5196074, "lon": -7.2578989 },
  {
    "name": "Dispensaire rural,tidili, Tamaste",
    "lat": 31.059708,
    "lon": -7.662655
  },
  { "name": "CS communal GHESSAT", "lat": 31.176808, "lon": -6.805986 },
  {
    "name": "Centre de santÚ urbain, douar chems -1",
    "lat": 30.916363806021796,
    "lon": -6.909027099609376
  },
  { "name": "CSU TASSOUMAATE", "lat": 30.921782, "lon": -6.926108 },
  {
    "name": "CS urbain AIT KADIF -1",
    "lat": 30.926560893767945,
    "lon": -6.922598084969042
  },
  {
    "name": "CS rural TARMIGTE -3",
    "lat": 30.89972677878151,
    "lon": -6.912556813491812
  },
  { "name": "CSR 2 ASDIF", "lat": 30.392334, "lon": -7.452973 },
  { "name": "Dispensaire rural Anmiter", "lat": 31.282005, "lon": -7.150792 },
  {
    "name": "CS communal avec modele d'accouchement TELOUET",
    "lat": 31.288683,
    "lon": -7.242501
  },
  {
    "name": "CSC avec module d'accouchement Ighrem nougdal A",
    "lat": 31.23106722668822,
    "lon": -7.424847627539073
  },
  { "name": "CSR Souk ASDIF (Kourkouda)", "lat": 30.468942, "lon": -7.401538 },
  { "name": "CSU BIR ANZARAN", "lat": 31.50685, "lon": -5.531969 },
  { "name": "CSU BIR ANZARAN -1", "lat": 31.50785, "lon": -5.531969 },
  {
    "name": "CSU1 BOUMLAN DADES Armoire frigorifique B",
    "lat": 31.3741578656902,
    "lon": -5.977935791015626
  },
  { "name": "CSU1 BOUMLAN DADES", "lat": 31.360793, "lon": -5.983474 },
  {
    "name": "CSU KALAA MAGOUNA Armoire frigorifique A",
    "lat": 31.14285,
    "lon": -6.07251
  },
  { "name": "CENTRE DE SANTE BOUTHARAR", "lat": 31.387962, "lon": -6.121993 },
  { "name": "dispensaire rural ouzighemte", "lat": 31.589706, "lon": -6.26863 },
  { "name": "CSC AIT HAMOU OUSAID", "lat": 31.572289, "lon": -5.89591 },
  {
    "name": "CS AIT AYOUL",
    "lat": 31.41892189460188,
    "lon": -6.006345748901368
  },
  {
    "name": "CS communal avec maison d'accouchement ALNIF Armoire frigorifique B",
    "lat": 31.120684,
    "lon": -5.1708
  },
  {
    "name": "CS communal avec maison d'accouchement ALNIF Armoire frigorifique A",
    "lat": 31.116001703614177,
    "lon": -5.168582533729557
  },
  { "name": "CS ait zeggane", "lat": 31.113694, "lon": -4.979917 },
  { "name": "CS timerzite", "lat": 31.191559, "lon": -4.968009 },
  { "name": "Centre azekour", "lat": 31.143436, "lon": -5.066007 },
  {
    "name": "Dispensaire rural LAAMAR -1",
    "lat": 31.182951768075366,
    "lon": -5.22542621593856
  },
  { "name": "Dispensaire rural LAAMAR -1", "lat": 31.182938, "lon": -5.225504 },
  { "name": "CS communal M'SSICI", "lat": 31.21905, "lon": -4.820472 },
  {
    "name": "Centre fezzou ( ait firssi )",
    "lat": 30.949413,
    "lon": -4.904788
  },
  { "name": "CS Ait Saadane", "lat": 30.849167, "lon": -5.2675 },
  { "name": "CS Omjerane", "lat": 30.640474, "lon": -5.108523 },
  { "name": "Cs 2 assoul ", "lat": 31.948299, "lon": -5.207584 },
  { "name": "Cs 2 assoul -1", "lat": 31.949102, "lon": -5.207177 },
  { "name": "dispensaire rural Tirga", "lat": 32.005295, "lon": -5.156468 },
  { "name": "Dispensaire Tana", "lat": 32.093165, "lon": -5.10116 },
  { "name": "CS agouray", "lat": 31.969732, "lon": -5.164583 },
  {
    "name": "centre de santÚ et maison d'accouchemet ait hani- 2",
    "lat": 31.77512864264594,
    "lon": -5.456136008598335
  },
  { "name": "Dispensaire TAMTETOUCHTE", "lat": 31.679556, "lon": -5.541558 },
  { "name": "CS rural-2 IKNIOUNE", "lat": 31.169004, "lon": -5.672454 },
  {
    "name": "CSR IKNIOUNE IGHREM AMAZEDAR",
    "lat": 31.290682,
    "lon": -5.504676
  },
  {
    "name": "Csr 2 msemrir",
    "lat": 31.695455797778713,
    "lon": -5.821380615234375
  },
  {
    "name": "DR Ait Marghad",
    "lat": 31.74685416292141,
    "lon": -5.7843017578125
  },
  { "name": "CS cumunal Tassouit", "lat": 31.222679, "lon": -6.081536 },
  { "name": "CS Ait Tazarine", "lat": 31.188259, "lon": -6.203 },
  { "name": "CS communal AZROU", "lat": 31.299877, "lon": -6.155979 },
  {
    "name": "CS Souk El-KhÚmis Dades Armoire frigorifique A-2",
    "lat": 31.308731806474608,
    "lon": -6.039862632751465
  },
  {
    "name": "CS Souk El-KhÚmis Dades -2",
    "lat": 31.307705152974805,
    "lon": -6.0353994369506845
  },
  { "name": "CS Serghine", "lat": 31.267645, "lon": -6.044847 },
  {
    "name": "CS Commune Rurale Ait El farsi",
    "lat": 31.354273,
    "lon": -5.290171
  },
  {
    "name": "Centre de SantÚ Rural Taghzoute",
    "lat": 31.454293078078425,
    "lon": -5.423029533733994
  },
  { "name": "CS rural AIT AALA", "lat": 31.471367, "lon": -5.450932 },
  { "name": "CS ouaklim", "lat": 31.444391, "lon": -5.552156 },
  { "name": "CS imder", "lat": 31.372618, "lon": -5.790833 },
  { "name": "csr bni jamil msstassa", "lat": 35.083817, "lon": -4.384625 },
  {
    "name": "csr bni jamil msstassa",
    "lat": 35.08378601,
    "lon": -4.3844075202
  },
  { "name": "dr ibn ranen", "lat": 35.0325431823, "lon": -4.38622713088989 },
  { "name": "CSR 1 Beni Bounsar", "lat": 34.8455544, "lon": -4.4255009 },
  { "name": "cs bni ahmed imougzane", "lat": 34.7532047, "lon": -4.4610884 },
  { "name": "CSR 2 Tabarrant", "lat": 34.7121427, "lon": -4.5196075 },
  { "name": "CS TAGHZOUT", "lat": 34.769035, "lon": -4.526107 },
  { "name": "CS TAGHZOUT", "lat": 34.769035, "lon": -4.526107 },
  { "name": "CSR1 BNI BCHIR", "lat": 34.777908325, "lon": -4.314601421 },
  { "name": "CSC BENI AMMART", "lat": 34.811657, "lon": -4.156273 },
  { "name": "CSR 1 BOUZINEB", "lat": 34.827873, "lon": -4.100665 },
  {
    "name": "Dr Zaouit Sidi Abdel Kader",
    "lat": 34.897465757227245,
    "lon": -4.087457375146492
  },
  {
    "name": "CSU Targisst Armoires Frigorifique A",
    "lat": 34.93731135088581,
    "lon": -4.313351000000003
  },
  { "name": "CSR1 SIDI BOUTMIM", "lat": 34.946079, "lon": -4.297557 },
  {
    "name": "CSU Bni Boufrah RÚfrigÚrateur 3",
    "lat": 35.102170833660416,
    "lon": -4.311221164418024
  },
  {
    "name": "CSU Bni Boufrah RÚfrigÚrateur 2",
    "lat": 35.102168639270275,
    "lon": -4.311225187731546
  },
  {
    "name": "CSU Bni Boufrah Armoires Frgorifique A",
    "lat": 35.1021807084153,
    "lon": -4.311215799999994
  },
  { "name": "csr 2 tazaghin", "lat": 35.1322898, "lon": -4.0594325 },
  { "name": "C/S Ait Zakri", "lat": 35.14375686645, "lon": -3.989186048507 },
  { "name": "csr 2 roadi", "lat": 35.1397666, "lon": -4.13514232 },
  { "name": "csr 2 ait gamra", "lat": 35.14716339, "lon": -3.9892776 },
  { "name": "csr 1 tala youssef", "lat": 35.2303276, "lon": -3.974965333 },
  { "name": "CSU Bir Anzarane", "lat": 35.2485008, "lon": -3.93412208 },
  { "name": "csu abdellah hammou", "lat": 35.241096496, "lon": -3.93112039 },
  {
    "name": "pharmacie provinciale al hociema A",
    "lat": 35.238290415602066,
    "lon": -3.9288106705522607
  },
  {
    "name": "pharmacie provinciale al hociema B",
    "lat": 35.2382879510424,
    "lon": -3.9288063119626115
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.238298356960456,
    "lon": -3.928812346932895
  },
  {
    "name": "Pharmacie Provinciale Chambre Froide",
    "lat": 35.2383,
    "lon": -3.92881
  },
  {
    "name": "Pharmacie Provinciale Armoires Frigorifique A-2",
    "lat": 35.2382969877608,
    "lon": -3.928812346932895
  },
  { "name": "salle couverte ajdir", "lat": 35.19260787, "lon": -3.8892374 },
  { "name": "csr 1 ait youssef ouali", "lat": 35.1737747, "lon": -3.87103772 },
  { "name": "dr azghar", "lat": 35.19078063, "lon": -3.90599727 },
  { "name": "CSU 2 Imzouren", "lat": 35.14516067, "lon": -3.8527657985687 },
  {
    "name": "CSU 2 Imzouren",
    "lat": 35.145158481767744,
    "lon": -3.8527832329272904
  },
  { "name": "Hopital Beni Bouayach A", "lat": 35.1035, "lon": -3.8407 },
  { "name": "CS NEKKOUR", "lat": 35.06498, "lon": -3.828648 },
  {
    "name": "CENRE DE SANTE COMMUNAL ARBIAA TAOURIRT",
    "lat": 34.936222,
    "lon": -3.833395
  },
  { "name": "CSR 2 CHEKRANE", "lat": 34.953201, "lon": -3.999669 },
  { "name": "csr 2 tifrouin", "lat": 35.01415252, "lon": -3.918078184 },
  { "name": "DR Tazorakht", "lat": 35.001087, "lon": -3.85200119 },
  { "name": "csr louta", "lat": 35.112106323, "lon": -3.90702629 },
  { "name": "CSR Tamssnit", "lat": 35.075889587, "lon": -3.9624481201 },
  { "name": "csc beni abdellah", "lat": 35.053508758, "lon": -4.068116188 },
  { "name": "CSR 1 MOULAY AHMAD CHERIF", "lat": 34.931747, "lon": -4.147544 },
  {
    "name": "C/S Issaken AF A",
    "lat": 34.9141134044765,
    "lon": -4.575273364418037
  },
  { "name": "CSR2 TLAT KETAMA", "lat": 34.876545, "lon": -4.619302 },
  { "name": "CSR 1 TAMSSAOUT", "lat": 34.8241965, "lon": -4.7549773 },
  { "name": "DR Al Tout", "lat": 34.8324623, "lon": -4.7062936 },
  { "name": "DR TAMLOUGIT TLAT KETAMA", "lat": 34.876545, "lon": -4.619302 },
  { "name": "DR Beni Ahmed Ikaouen", "lat": 34.820587, "lon": -4.590889 },
  { "name": "CSR2 IKAOUEN", "lat": 34.752743, "lon": -4.657164 },
  { "name": "CSR 1 Amtar", "lat": 35.242954, "lon": -4.793948 },
  {
    "name": "CS Bab Berred",
    "lat": 34.994389731846844,
    "lon": -4.889917011674109
  },
  {
    "name": "CS Bab Berred",
    "lat": 34.994389731846844,
    "lon": -4.889917011674109
  },
  {
    "name": "H¶pital Mohammed 5",
    "lat": 35.16917871710438,
    "lon": -5.272848717790992
  },
  {
    "name": "H¶pital Mohammed 5 Armoires Frigorifiques C",
    "lat": 35.16959135136706,
    "lon": -5.269885603381634
  },
  {
    "name": "H¶pital Mohammed 5 Armoires Frigorifiques C",
    "lat": 35.16959135136706,
    "lon": -5.269885603381634
  },
  {
    "name": "CSC Talambot",
    "lat": 35.24996268777123,
    "lon": -5.181768419245142
  },
  { "name": "CSC TALAMBOT", "lat": 35.250721, "lon": -5.182273 },
  { "name": "CSC Kaa Assras", "lat": 35.4133605, "lon": -5.0688014 },
  {
    "name": "CSC Kaa Assras",
    "lat": 35.413326070795875,
    "lon": -5.069071967834386
  },
  { "name": "CS Targha", "lat": 35.39206314, "lon": -5.0099225 },
  { "name": "C/S Stehat", "lat": 35.3442955, "lon": -4.95398 },
  { "name": "C/S Stehat", "lat": 35.34437098088252, "lon": -4.954089970569615 },
  { "name": "C/S Stehat", "lat": 35.3441718872285, "lon": -4.953924073610684 },
  {
    "name": "C/S Bab El Ain RÚfrigÚrateur 1",
    "lat": 35.1675605,
    "lon": -5.2654976
  },
  {
    "name": "C/S Bab El Ain RÚfrigÚrateur 2",
    "lat": 35.16757310761251,
    "lon": -5.265572701852421
  },
  {
    "name": "C/S Bab El Ain RÚfrigÚrateur 4",
    "lat": 35.167478824549875,
    "lon": -5.2654412736106915
  },
  { "name": "CSC Bouhamed", "lat": 35.31531906, "lon": -4.96902894 },
  {
    "name": "CSC Bouhamed",
    "lat": 35.31527747588405,
    "lon": -4.969262292184291
  },
  {
    "name": "DR Khmis Louta",
    "lat": 35.21352699586688,
    "lon": -4.994221000000003
  },
  { "name": "CSU Jebha", "lat": 35.204055698493704, "lon": -4.665898355819699 },
  { "name": "CSU Jebha ", "lat": 35.20407104, "lon": -4.665952 },
  { "name": "CSU Jebha", "lat": 35.204055698493704, "lon": -4.665933224536893 },
  {
    "name": "CSU 1 El Haouta RÚfrigÚrateur 2",
    "lat": 35.16914437732617,
    "lon": -5.262750994477465
  },
  { "name": "CSR El Khezana", "lat": 35.04284286, "lon": -5.23185491 },
  {
    "name": "CSC Bab Taza RÚfrigÚrateur 1",
    "lat": 35.0632591,
    "lon": -5.1974258
  },
  {
    "name": "CSR 1 Bni Smih",
    "lat": 35.13840824269878,
    "lon": -4.708559666847414
  },
  { "name": "DR Azaouia MEGOU", "lat": 35.1152153, "lon": -5.195012 },
  { "name": "CSR Cherfat", "lat": 35.065807, "lon": -5.1061539 },
  {
    "name": "CSC Beni Darkoul",
    "lat": 35.056031823435546,
    "lon": -5.068850781614686
  },
  {
    "name": "CSC Beni Darkoul",
    "lat": 35.05604908848462,
    "lon": -5.068622883848428
  },
  {
    "name": "C/S Aouezgane RÚfrigÚrateur 2",
    "lat": 35.01968791662549,
    "lon": -4.517078298776617
  },
  {
    "name": "C/S Aouezgane RÚfrigÚrateur 1",
    "lat": 35.01986694,
    "lon": -4.5170555
  },
  { "name": "CSC Beni salah", "lat": 34.9961357, "lon": -5.0446453 },
  {
    "name": "CSR Ain Hjar Beni Mansour",
    "lat": 35.15847741587977,
    "lon": -4.913266812914854
  },
  {
    "name": "CSR Assifane",
    "lat": 35.11393397504202,
    "lon": -4.968532561655767
  },
  {
    "name": "CSR Assifane",
    "lat": 35.11388543115339,
    "lon": -4.968752502794988
  },
  { "name": "CSR Assifane", "lat": 35.1138229, "lon": -4.968575477 },
  { "name": "DR Laancar", "lat": 35.016361236, "lon": -4.987612247 },
  { "name": "C/S Hadaka", "lat": 34.8734054, "lon": -4.80477762 },
  {
    "name": "C/S Beni Rzine RÚfrigÚrateur 1",
    "lat": 35.01055526,
    "lon": -4.72607183
  },
  {
    "name": "C/S Beni Rzine RÚfrigÚrateur 2",
    "lat": 35.010533231461615,
    "lon": -4.725857253278802
  },
  { "name": "DR Aloui", "lat": 35.03982162, "lon": -4.7788825 },
  {
    "name": "CSC Khmiss Ounane RÚfrigÚrateur 1",
    "lat": 35.029064,
    "lon": -4.81364
  },
  { "name": "DR Beni Yarzine", "lat": 34.95586776, "lon": -5.18709993 },
  { "name": "CSR 2 Fifi", "lat": 34.9444007, "lon": -5.241793155 },
  { "name": "CSR 2 Fifi", "lat": 34.94440537208622, "lon": -5.241596347913533 },
  { "name": "CSC Beni Faghloum", "lat": 34.89949, "lon": -5.1502008 },
  {
    "name": "CSR 1 Malha",
    "lat": 34.92516348831211,
    "lon": -5.0446205060327065
  },
  { "name": "CSC Mansoura", "lat": 34.8332214, "lon": -4.9708271 },
  { "name": "DR Aydi", "lat": 34.8462867, "lon": -4.920093 },
  { "name": "CSR 2 Laouamra", "lat": 35.0634454, "lon": -6.0895997 },
  {
    "name": "CSU 2 Beni Ahmed",
    "lat": 34.84643127149289,
    "lon": -5.078559488051262
  },
  { "name": "CSU 2 Beni Ahmed", "lat": 34.846305, "lon": -5.07860374 },
  { "name": "CSR 1 Kssibat", "lat": 34.8166351, "lon": -5.1098456382 },
  { "name": "DR Gharouzum R1", "lat": 35.1545219421, "lon": -5.27565383 },
  {
    "name": "CSR Litassift",
    "lat": 35.405525313434964,
    "lon": -5.11863124480934
  },
  {
    "name": "CSR Litassift",
    "lat": 35.405425295030554,
    "lon": -5.118498475463102
  },
  {
    "name": "CSU 1 Ain Haouzi",
    "lat": 35.17940315795943,
    "lon": -5.279467824052748
  },
  {
    "name": "CSU 1 Ain Haouzi",
    "lat": 35.17933081172788,
    "lon": -5.279443684171614
  },
  {
    "name": "CSR Dardara",
    "lat": 35.109516666711535,
    "lon": -5.287716860000004
  },
  { "name": "CSR Dardara", "lat": 35.109519958, "lon": -5.28771686 },
  { "name": "DR ANARAY", "lat": 35.250374, "lon": -4.911723 },
  { "name": "DR MAOUASSIF REF", "lat": 34.941605, "lon": -4.709461 },
  { "name": "DR Kalaa", "lat": 35.2002639, "lon": -5.253657341 },
  { "name": "CS Colema ", "lat": 35.56454086303711, "lon": -5.349265098571777 },
  { "name": "CS Colema ", "lat": 35.56454086302611, "lon": -5.34926509857166 },
  { "name": "CS Colema ", "lat": 35.56454086301255, "lon": -5.349265098574522 },
  { "name": "CSU Mhannech ", "lat": 35.5644836541, "lon": -5.36432254153 },
  {
    "name": "CSU My Hassan",
    "lat": 35.576582547648634,
    "lon": -5.3575373177909835
  },
  { "name": "CSU My Hassan", "lat": 35.5766, "lon": -5.35754 },
  {
    "name": "CSU Sidi Frig",
    "lat": 35.57098928236643,
    "lon": -5.362190670552263
  },
  { "name": "CSU Taouilaa ", "lat": 35.58092274859431, "lon": -5.3627 },
  {
    "name": "csu boujjarah",
    "lat": 35.59022245648246,
    "lon": -5.351844311962608
  },
  {
    "name": "CSU Boujjarah",
    "lat": 35.5902257282413,
    "lon": -5.3518469941716225
  },
  {
    "name": "CSU My Hassan",
    "lat": 35.59022818206033,
    "lon": -5.351847664723876
  },
  {
    "name": "CSU 1 Dar Mourssia ",
    "lat": 35.58785186886767,
    "lon": -5.368060682209013
  },
  { "name": "CSU 1 Dar Mourssia ", "lat": 35.5878715, "lon": -5.368058 },
  {
    "name": "CSU Kassabah",
    "lat": 35.57573863652056,
    "lon": -5.3719696705522475
  },
  {
    "name": "CSU Kassabah",
    "lat": 35.575737273041064,
    "lon": -5.371969335276121
  },
  {
    "name": "CSU Samssa ",
    "lat": 35.57464766308815,
    "lon": -5.3960322216346785
  },
  { "name": "CSU Samssa ", "lat": 35.57482, "lon": -5.39684 },
  {
    "name": "CSU Dersa 3 ",
    "lat": 35.57560345528777,
    "lon": -5.385390000000001
  },
  { "name": "CSU Dersa 3 ", "lat": 35.57561, "lon": -5.38539 },
  {
    "name": "CSU 1 Darssa",
    "lat": 35.57614127377575,
    "lon": -5.377099999999994
  },
  {
    "name": "CSU Bab Toute ",
    "lat": 35.571200698244816,
    "lon": -5.372550483809537
  },
  {
    "name": "CSU Hay Maderassi",
    "lat": 35.57181254660935,
    "lon": -5.38209932331352
  },
  {
    "name": "CSU Hay Maderassi",
    "lat": 35.57182563665269,
    "lon": -5.3821033466270425
  },
  { "name": "CSU Hay Maderassi", "lat": 35.57183, "lon": -5.3820953 },
  { "name": "CSR 1 Mellalyene", "lat": 35.6274, "lon": -5.34089 },
  {
    "name": "C/S Nakkata RÚfrigÚrateur 1",
    "lat": 35.571909256785965,
    "lon": -5.41623798650817
  },
  {
    "name": "CSR 1 Zaytoune",
    "lat": 35.55291519898169,
    "lon": -5.359826223313521
  },
  { "name": "CSR 1 Zaytoune", "lat": 35.5529212, "lon": -5.3598222 },
  { "name": "CSR Sdina", "lat": 35.610804417553595, "lon": -5.451077682209024 },
  { "name": "CSR Azla", "lat": 35.55323127128064, "lon": -5.247524729999992 },
  {
    "name": "CSU Oued Laou",
    "lat": 35.44822746625862,
    "lon": -5.091709521104508
  },
  { "name": "csu oued laou", "lat": 35.44822692, "lon": -5.09170818 },
  {
    "name": "Centre Hospitalier Provincial",
    "lat": 35.573729327027365,
    "lon": -5.354707711350925
  },
  { "name": "CSR 1 Bni Saaid", "lat": 35.423454, "lon": -5.1217617 },
  {
    "name": "PP Tetouane CF",
    "lat": 35.573719014322826,
    "lon": -5.354702682209022
  },
  {
    "name": "PP Tetouane armoire C ",
    "lat": 35.57372109135045,
    "lon": -5.35470402331353
  },
  {
    "name": "PP Tetouane Armoire A",
    "lat": 35.573709637833666,
    "lon": -5.354728163194664
  },
  {
    "name": "PP Tetouane armoire F",
    "lat": 35.57370091134357,
    "lon": -5.3547261515379025
  },
  {
    "name": "PP Tetouane armoire D",
    "lat": 35.573694366475394,
    "lon": -5.3547214576721265
  },
  { "name": "CSU Korat Sebaa", "lat": 35.5572204, "lon": -5.387557 },
  {
    "name": "CSU Korat Sebaa",
    "lat": 35.55722476414263,
    "lon": -5.387557000000003
  },
  {
    "name": "CSU Taboulla ",
    "lat": 35.5680056971114,
    "lon": -5.400285000000005
  },
  { "name": "CSU Taboulla ", "lat": 35.56783676, "lon": -5.4005098342 },
  { "name": "CSU Taboulla ", "lat": 35.56799697, "lon": -5.400285 },
  { "name": "CS Mellah", "lat": 35.568836212, "lon": -5.36724281 },
  {
    "name": "CSC Bni Hassan",
    "lat": 35.3186728907488,
    "lon": -5.356940699999992
  },
  { "name": "CSC Bni Hassan", "lat": 35.3186759, "lon": -5.3569407 },
  {
    "name": "CSC Ain Fondak",
    "lat": 35.561597444384205,
    "lon": -5.574205034418029
  },
  {
    "name": "CSC Ain Fondak",
    "lat": 35.561606172192576,
    "lon": -5.574205034418029
  },
  {
    "name": "CSR Bni Harchane",
    "lat": 35.522172646974965,
    "lon": -5.633308975911848
  },
  { "name": "CSC Al Ouad", "lat": 35.2180954493081, "lon": -5.31324845220902 },
  {
    "name": "CSC Al Ouad",
    "lat": 35.43663036158013,
    "lon": -5.525006796444792
  },
  { "name": "CS Sabt Lkadim", "lat": 35.58006286, "lon": -5.499882698 },
  { "name": "CS Jbel Lhbib", "lat": 35.467961, "lon": -5.797779 },
  { "name": "CS  Bghaghza", "lat": 35.433939, "lon": -5.646059 },
  { "name": "Station Bab Toute", "lat": 35.574474334, "lon": -5.350894927 },
  { "name": "CSR 1 Beni layeth", "lat": 35.29141235, "lon": -5.412159919738 },
  {
    "name": "CSU Oued Laou",
    "lat": 35.448229105034486,
    "lon": -5.0917101916567615
  },
  {
    "name": "csu niveau 1 istiklal",
    "lat": 35.68166082132142,
    "lon": -5.3214439999999925
  },
  {
    "name": "Salle Couverte Fnidq RÚfrigÚrateur 1",
    "lat": 35.84876056371031,
    "lon": -5.356449521495823
  },
  { "name": "CSR 1 Alline", "lat": 35.78172683, "lon": -5.40124 },
  { "name": "csr balyounech", "lat": 35.9082, "lon": -5.3892 },
  {
    "name": "csr balyounech",
    "lat": 35.908204888070586,
    "lon": -5.389202011656755
  },
  {
    "name": "csr balyounech",
    "lat": 35.90820814678412,
    "lon": -5.3892073760747845
  },
  { "name": "CS massira Fnideq", "lat": 35.8533833, "lon": -5.35437488 },
  { "name": "H¶pital Mohammed 6", "lat": 35.6919365, "lon": -5.3316379 },
  { "name": "H¶pital Mohammed 6", "lat": 35.69178771, "lon": -5.3295016288 },
  {
    "name": "H¶pital Mohammed 6",
    "lat": 35.69143061005736,
    "lon": -5.32942754232788
  },
  {
    "name": "csu 1 ahrik",
    "lat": 34.65602369867197,
    "lon": -1.9113057030410707
  },
  {
    "name": "csu 1 ahrik",
    "lat": 35.619521627415516,
    "lon": -5.273874164983412
  },
  {
    "name": "CSU 1 ahrik",
    "lat": 35.61950963535718,
    "lon": -5.273869471117636
  },
  { "name": "CSU Miramar martil", "lat": 35.619544982, "lon": -5.2738070487 },
  {
    "name": "Pharmacie Provinciale Armoire Frigorifique A",
    "lat": 35.18644674610723,
    "lon": -6.156430251896392
  },
  {
    "name": "Pharmacie Provinciale Armoire Frigorifique D",
    "lat": 35.186420988694955,
    "lon": -6.156462103126925
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.18646000617671,
    "lon": -6.1564013411045115
  },
  {
    "name": "Pharmacie Provinciale Armoire Frigorifique C",
    "lat": 35.186439073019294,
    "lon": -6.1564641137659
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.18640328508477,
    "lon": -6.156467833746904
  },
  { "name": "CSU 1 Hay El Jadid", "lat": 35.185222, "lon": -6.15475 },
  {
    "name": "CSR 2 Laouamra Armoires Frigorifique A",
    "lat": 35.06632658606405,
    "lon": -6.0931646938657735
  },
  {
    "name": "CS Baladiat laksar lakbir",
    "lat": 35.00183868408203,
    "lon": -5.906252384185791
  },
  { "name": "Centre ouled hmaid ", "lat": 35.0279141, "lon": -5.8947923 },
  { "name": "CSU Khalkhali", "lat": 34.993957, "lon": -5.910765 },
  { "name": "CSU Khalkhali", "lat": 34.993957, "lon": -5.910765 },
  { "name": "H¶pital Ksar Kbir", "lat": 35.01129, "lon": -5.946031 },
  {
    "name": "H¶pital Ksar Kbir",
    "lat": 35.00570053064581,
    "lon": -5.929592157077785
  },
  { "name": "CSU Moulay Ali Boughaleb", "lat": 35.0012, "lon": -5.90808 },
  {
    "name": "CSU Bouchouika",
    "lat": 34.99728950697573,
    "lon": -5.89081699999999
  },
  { "name": "CSR Tattoft RÚfrigirateur 1", "lat": 35.034004, "lon": -5.81081 },
  { "name": "CSR Tattoft RÚfrigirateur 2", "lat": 35.034004, "lon": -5.810818 },
  { "name": "CSU Wafae R1", "lat": 35.170921, "lon": -6.147176 },
  { "name": "CS WAFAA", "lat": 35.174861907958984, "lon": -6.1482110023498535 },
  {
    "name": "CSR 2 Sahel RÚfrigirateur 1",
    "lat": 35.2542572,
    "lon": -6.0803599
  },
  {
    "name": "CSR 2 Sahel RÚfrigirateur 4",
    "lat": 35.254251998065094,
    "lon": -6.080366940798669
  },
  {
    "name": "CSU Kassab RÚfrigÚrateur 1",
    "lat": 35.19550410969657,
    "lon": -6.147227644180289
  },
  {
    "name": "CSU Kassab RÚfrigÚrateur 2",
    "lat": 35.19552931625202,
    "lon": -6.147176682209006
  },
  { "name": "Centre D'HÚmodialyse", "lat": 35.186950683, "lon": -6.155804157 },
  {
    "name": "CSR 1 Ayacha RÚfrigÚrateur 1",
    "lat": 35.38773052652,
    "lon": -5.894258400000001
  },
  {
    "name": "CSR 1 Ayacha RÚfrigÚrateur 2",
    "lat": 35.3877742600241,
    "lon": -5.89414842943039
  },
  { "name": "CSR Rissana Chamalia", "lat": 35.220954, "lon": -5.9465661 },
  {
    "name": "CSR Rissana Chamalia",
    "lat": 35.22095753898473,
    "lon": -5.946567441104508
  },
  {
    "name": "CSR Rissana Chamalia",
    "lat": 35.22091700200157,
    "lon": -5.94657045858965
  },
  {
    "name": "CSR Rissana Janoubia RÚfrigirateur 3",
    "lat": 35.17269286008158,
    "lon": -5.9775749374343885
  },
  {
    "name": "CSR Rissana Janoubia RÚfrigirateur 4",
    "lat": 35.172954314098455,
    "lon": -5.977848858030034
  },
  {
    "name": "CSR Ouled Ouchih RÚfrigÚrateur 1",
    "lat": 35.0631904,
    "lon": -5.9329133
  },
  {
    "name": "CSR Ouled Ouchih RÚfrigÚrateur 2",
    "lat": 35.06321894070114,
    "lon": -5.9329133
  },
  {
    "name": "CSR ouled ouchih",
    "lat": 35.06318600912202,
    "lon": -5.932913635276127
  },
  { "name": "CSU 1 Hay El  Jadid ", "lat": 35.183834, "lon": -6.1571807 },
  { "name": "centre de santÚ zouada", "lat": 35.04688, "lon": -6.059603 },
  {
    "name": "centre de santÚ zouada",
    "lat": 35.04682180922592,
    "lon": -6.05953862698364
  },
  { "name": "CSR2 L QOLLA REF 1", "lat": 35.069149017, "lon": -5.5683760643 },
  { "name": "CSR2 L QOLLA REF 2", "lat": 35.069237, "lon": -5.568028 },
  { "name": "CSR 2 Souk Tolba", "lat": 35.05677795, "lon": -5.865401268 },
  { "name": "CSR 1 Souaken", "lat": 35.11967849, "lon": -5.95404338 },
  { "name": "CSR2 Beni Guerfet", "lat": 35.2581667, "lon": -5.8304017 },
  { "name": "CSR2 Beni Guerfet", "lat": 35.258152008, "lon": -5.83047008514 },
  {
    "name": "CRS 2 Beni Arouss RÚfrigÚrateur 1",
    "lat": 35.30526592200303,
    "lon": -5.628970145999994
  },
  {
    "name": "CRS 2 Beni Arouss RÚfrigÚrateur 2",
    "lat": 35.30480597616234,
    "lon": -5.628724388599005
  },
  {
    "name": "CSR Tazroute ( Moulay Abdeslam )",
    "lat": 35.316379547,
    "lon": -5.50901651382
  },
  {
    "name": "CSR Tazroute ( Moulay Abdeslam )",
    "lat": 35.31638,
    "lon": -5.509016
  },
  { "name": "CSR 1 Zaaroura", "lat": 35.258381, "lon": -5.703072 },
  { "name": "CSR 1 Zaaroura", "lat": 35.258381, "lon": -5.703072 },
  { "name": "CSR 1 Boujedyan", "lat": 35.108083, "lon": -5.79472 },
  {
    "name": "CSR 1 Boujedyan",
    "lat": 35.11198043823242,
    "lon": -5.805555820465088
  },
  {
    "name": "CSR 2 SIDI REDOUANE",
    "lat": 34.684535967395426,
    "lon": -5.44740104675292
  },
  {
    "name": "CSR 2 SIDI REDOUANE",
    "lat": 34.796164319483104,
    "lon": -5.567460047544579
  },
  { "name": "csc beni quolla", "lat": 34.7370071, "lon": -5.5447483 },
  { "name": "csr rmila", "lat": 34.7447509, "lon": -5.4912319183 },
  { "name": "C/S OULAD BDER", "lat": 34.689681, "lon": -5.335791 },
  { "name": "CSR2 SIDI BOUSBER", "lat": 34.561806, "lon": -5.362573 },
  { "name": "CSR2 SIDI BOUSBER", "lat": 34.562806, "lon": -5.362573 },
  { "name": "DR MESKER", "lat": 34.585602, "lon": -5.396301 },
  { "name": "Csc Asjen", "lat": 34.84510421, "lon": -5.6157298 },
  { "name": "DC Amzou", "lat": 34.918201, "lon": -5.7121415 },
  { "name": "CSC Amzfroun", "lat": 34.83666229, "lon": -5.750546932 },
  { "name": "CS Mesmoda", "lat": 34.78644559579946, "lon": -5.709724542327885 },
  { "name": "Dr. Fersiou", "lat": 34.75067520141601, "lon": -5.68343687057495 },
  {
    "name": "C/S Al Amal RÚfrigÚrateur 1",
    "lat": 35.75442197122198,
    "lon": -5.829203701529222
  },
  {
    "name": "C/S Al Mers RÚfrigÚrateur 2",
    "lat": 35.72264860000006,
    "lon": -5.794808100552262
  },
  { "name": "CS Al Mers ", "lat": 35.7226486, "lon": -5.79480743 },
  { "name": "CS Al Mers ", "lat": 35.72264996097435, "lon": -5.79481044748515 },
  {
    "name": "C/S Ben Dibane RÚfrigÚrateur 1",
    "lat": 35.75924682,
    "lon": -5.81687831
  },
  {
    "name": "C/S Ben Dibane RÚfrigÚrateur 2",
    "lat": 35.75924464344187,
    "lon": -5.816878310000002
  },
  {
    "name": "C/S Beni Touzine Armoires Frigorifique A",
    "lat": 35.73904478200909,
    "lon": -5.848413119049072
  },
  { "name": "CS Bni Makada ", "lat": 35.7578773, "lon": -5.81084489 },
  { "name": "CS Bni Makada", "lat": 35.7578773, "lon": -5.81084489 },
  { "name": "CS Branes ", "lat": 35.75867843, "lon": -5.834584236 },
  {
    "name": "C/S Branes RÚfrigÚrateur 2",
    "lat": 35.758675981354614,
    "lon": -5.834584571276135
  },
  {
    "name": "CS Dehar Kanfoud ",
    "lat": 35.71022788867805,
    "lon": -5.812476820552248
  },
  { "name": "CS EL MARSA", "lat": 35.467339, "lon": -6.034338 },
  { "name": "CS Geznaya", "lat": 35.707378, "lon": -5.90384 },
  { "name": "CS Geznaya ", "lat": 35.705214, "lon": -5.90366 },
  { "name": "CS Had Gharbia ", "lat": 35.515460968, "lon": -5.93060922 },
  {
    "name": "CS Had Gharbia ",
    "lat": 35.51557121990818,
    "lon": -5.930677616329887
  },
  { "name": "CS Itnin Sidi Lyamani ", "lat": 35.36919, "lon": -5.971453 },
  {
    "name": "CS Tnin Sidi Lyamani ",
    "lat": 35.36925124096959,
    "lon": -5.971507985284805
  },
  {
    "name": "CS Tnin Sidi Lyamani ",
    "lat": 35.369245773027785,
    "lon": -5.971418131282806
  },
  { "name": "CS Mejlaw", "lat": 35.36148071, "lon": -6.0376 },
  { "name": "CS Said Noussairi", "lat": 35.77051925, "lon": -5.81440258 },
  {
    "name": "CS Said Noussairi",
    "lat": 35.770526963548946,
    "lon": -5.814524128633444
  },
  {
    "name": "DÚlÚgation Quartier Al Majd",
    "lat": 35.743351009737644,
    "lon": -5.799675574239731
  },
  {
    "name": "DÚlÚgation Quartier Al Majd",
    "lat": 35.74335345885465,
    "lon": -5.799646069940567
  },
  {
    "name": "DÚlÚgation Quartier Al Majd",
    "lat": 35.743348288496485,
    "lon": -5.799646069940567
  },
  { "name": "CSR 2 Khemiss Anjerah ", "lat": 35.669216, "lon": -5.51464 },
  {
    "name": "CSR 2 Khemiss Anjerah ",
    "lat": 35.669279191508316,
    "lon": -5.514709737434395
  },
  {
    "name": "CSR 2 Khemiss Anjerah",
    "lat": 35.669316234782805,
    "lon": -5.5144522453689655
  },
  {
    "name": "CSR Taghramt",
    "lat": 35.79025795578229,
    "lon": -5.46891727619629
  },
  {
    "name": "CS Melloussa ",
    "lat": 35.72868700129135,
    "lon": -5.630386111852423
  },
  {
    "name": "CSC Kessar Sghir ",
    "lat": 35.82776537419305,
    "lon": -5.5639751346644495
  },
  {
    "name": "CSC Kessar Sghir RÚfrigÚrateur 1",
    "lat": 35.827716987501375,
    "lon": -5.56389064508048
  },
  {
    "name": "CSC Kessar Sghir",
    "lat": 35.8278246361584,
    "lon": -5.564065333942576
  },
  {
    "name": "CSC Kessar Sghir",
    "lat": 35.827809957063764,
    "lon": -5.564231630901499
  },
  {
    "name": "C SR Kasar Majaz",
    "lat": 35.85016540451867,
    "lon": -5.5455812822437345
  },
  { "name": "CSR Hakkama", "lat": 35.64937973, "lon": -5.68700551 },
  { "name": "CS Nouinouich ", "lat": 35.792766571, "lon": -5.736135482 },
  { "name": "CS Mokrissat", "lat": 34.9244956, "lon": -5.34856748 },
  {
    "name": "CS Mokrissat",
    "lat": 34.92438949034257,
    "lon": -5.348560774477457
  },
  {
    "name": "CS Mokrissat",
    "lat": 34.829837183313906,
    "lon": -5.576254321662649
  },
  {
    "name": "CS massira",
    "lat": 34.796652606187884,
    "lon": -5.579199790000002
  },
  {
    "name": "DR Asserdoune",
    "lat": 34.9244331987414,
    "lon": -5.3485674799999945
  },
  {
    "name": "CSR 2 Zoumi RÚfrigÚrateur 1",
    "lat": 34.79526138,
    "lon": -5.3502893447
  },
  {
    "name": "CSR 2 Zoumi RÚfrigÚrateur 3",
    "lat": 34.79523632518911,
    "lon": -5.350276268931049
  },
  {
    "name": "CSR2 TEROUAL",
    "lat": 34.67329894126795,
    "lon": -5.271354563159951
  },
  { "name": "CSR2 TEROUAL", "lat": 34.673321, "lon": -5.271545 },
  { "name": "CSR2 TEROUAL", "lat": 34.676321, "lon": -5.271545 },
  { "name": "CSR ZGHIRA 2", "lat": 34.636295, "lon": -5.215174 },
  { "name": "CSR ZGHIRA 2", "lat": 34.637295, "lon": -5.215174 },
  { "name": "CSR1 SIDI AHMED CHERIF", "lat": 34.616902, "lon": -5.290691 },
  { "name": "Csr 2 ain dorrij", "lat": 34.6195221, "lon": -5.2979012 },
  {
    "name": "C/S 2 Kalaat Boukoura",
    "lat": 34.746200561,
    "lon": -5.19392824172
  },
  {
    "name": "CSR BNI KAOULECH",
    "lat": 34.821598052978516,
    "lon": -5.204963684082031
  },
  { "name": "csr 1 ain baida", "lat": 34.987389, "lon": -5.527909 },
  {
    "name": "CS moulay thami",
    "lat": 34.805083416507316,
    "lon": -5.571161699999996
  },
  { "name": "CS moulay thami", "lat": 34.805068, "lon": -5.5711617 },
  {
    "name": "CS moulay thami",
    "lat": 34.80508231532835,
    "lon": -5.571161699999996
  },
  {
    "name": "CS massira",
    "lat": 34.796646824406665,
    "lon": -5.579199790000002
  },
  {
    "name": "CS massira",
    "lat": 34.796639115364435,
    "lon": -5.579199790000002
  },
  { "name": "CS massira", "lat": 34.79665343215659, "lon": -5.579199790000002 },
  {
    "name": "csu moulay abdelah cherif",
    "lat": 34.79411559334312,
    "lon": -5.575737952999999
  },
  {
    "name": "csu moulay abdelah cherif",
    "lat": 34.79411559334312,
    "lon": -5.575737952999999
  },
  {
    "name": "moulay abdalh chrif",
    "lat": 34.82933619266537,
    "lon": -5.567694998779787
  },
  {
    "name": "csu abou kassem zaheraoui AF B",
    "lat": 34.798756253259825,
    "lon": -5.5886214576721205
  },
  {
    "name": "csu abou kassem zaheraoui AF B",
    "lat": 34.79875074694204,
    "lon": -5.588625480985643
  },
  {
    "name": "CSU Abou Kassem Zaheraoui",
    "lat": 34.798738633041644,
    "lon": -5.588629504299165
  },
  {
    "name": "csu abou kassem zaheraoui",
    "lat": 34.798779379790496,
    "lon": -5.588666093254091
  },
  {
    "name": "csu abou kassem zaheraoui",
    "lat": 34.79878818989572,
    "lon": -5.588652682209016
  },
  {
    "name": "csu sidi ahmed bohedan ouezzane ",
    "lat": 34.8013305664,
    "lon": -5.59259557723999
  },
  { "name": "sidi hmed bouhdane", "lat": 34.797193, "lon": -5.5740939 },
  {
    "name": "Centre de SantÚ Urbain Sidi Allal Bahraoui",
    "lat": 34.00315535028129,
    "lon": -6.526719601188664
  },
  {
    "name": "Centre de SantÚ Urbain Sidi Allal Bahraoui",
    "lat": 34.00301971243669,
    "lon": -6.526558668647771
  },
  { "name": "CS ait ehoudrane jamaa", "lat": 33.710938, "lon": -6.264663 },
  {
    "name": "Centre de SantÚ Urbain Maamoura ",
    "lat": 33.83544901906201,
    "lon": -6.073533574239729
  },
  { "name": "CS SAADA", "lat": 33.814404, "lon": -6.068358 },
  {
    "name": "CS Rural Khmis Sidi Yahya",
    "lat": 33.79993317966709,
    "lon": -6.268758796295173
  },
  { "name": "CS Rural Khmis Sidi Yahya", "lat": 33.799873, "lon": -6.268909 },
  { "name": "Centre Jemaat moul blad", "lat": 33.586683, "lon": -6.4335298 },
  { "name": "CSR Ait Belkassem", "lat": 33.827782, "lon": -6.375427 },
  { "name": "centre marchouch", "lat": 33.558267, "lon": -6.694868 },
  {
    "name": "Centre de SantÚ Rural Mkame Tolba - 1",
    "lat": 33.936985,
    "lon": -6.254496
  },
  {
    "name": "CSC Ait Bou Yahya Al Hajjama",
    "lat": 33.879681,
    "lon": -6.270139
  },
  {
    "name": "Centre de SantÚ Rural Maaziz - 2",
    "lat": 33.672493,
    "lon": -6.317923
  },
  {
    "name": "centre de sante sidi el ghandour",
    "lat": 33.846529,
    "lon": -6.063452
  },
  { "name": "centre sfasif", "lat": 33.895455, "lon": -5.804896 },
  {
    "name": "centre de sante ezzhiliga",
    "lat": 33.29578046242674,
    "lon": -6.538999401303241
  },
  { "name": "H¶pital Rommani", "lat": 33.529678, "lon": -6.605923 },
  {
    "name": "H¶pital Rommani",
    "lat": 33.52996642919323,
    "lon": -6.606024923942561
  },
  {
    "name": "Centre de SantÚ Communal Ait Ichou",
    "lat": 33.553783,
    "lon": -6.051051
  },
  { "name": "CSR Yadine", "lat": 33.97434088661451, "lon": -6.015290571758271 },
  { "name": "moulay driss aghbal", "lat": 33.790617, "lon": -6.500252 },
  { "name": "centre sidi meziane", "lat": 33.836981, "lon": -5.995652 },
  {
    "name": "Centre de SantÚ Urbain Al Andalous Tiflet",
    "lat": 33.8140057517017,
    "lon": -6.00585221875
  },
  {
    "name": "CSU Al Andalous Tiflet",
    "lat": 33.80242563407722,
    "lon": -5.954029258373261
  },
  {
    "name": "Centre de santÚ communal bouquachmir",
    "lat": 33.509918,
    "lon": -5.929588
  },
  {
    "name": "Centre de SantÚ Communal Sidi Abderrazak - 1",
    "lat": 33.964605085526784,
    "lon": -6.164154466270448
  },
  {
    "name": "Centre de SantÚ Communal Sidi Abderrazak - 2",
    "lat": 33.96484978877503,
    "lon": -6.16436367857361
  },
  {
    "name": "centre ait siberne  ( oued baht ) ",
    "lat": 33.884805,
    "lon": -5.928345
  },
  { "name": "SRES laayoune", "lat": 27.1498136, "lon": -13.2100283 },
  {
    "name": "Centre de santÚ communal boukechmir",
    "lat": 33.510011369640196,
    "lon": -5.929670477927211
  },
  {
    "name": "Centre de SantÚ Rural Tarmilate",
    "lat": 33.831804009697706,
    "lon": -5.984754718750009
  },
  {
    "name": "Centre de SantÚ Rural Ait Malek - 2",
    "lat": 33.937275,
    "lon": -6.459293
  },
  {
    "name": "Centre de SantÚ Rural Ait Malek - 1",
    "lat": 33.93742409448135,
    "lon": -6.4590891521148786
  },
  {
    "name": "Service de RÚseau des Etablissements Sanitaires KhÚmisset",
    "lat": 33.81953708654,
    "lon": -6.069788880950934
  },
  {
    "name": "Service de RÚseau des Etablissements Sanitaires KhÚmisset",
    "lat": 33.81970421376061,
    "lon": -6.069772787696845
  },
  {
    "name": "Service de RÚseau des Etablissements Sanitaires KhÚmisset",
    "lat": 33.81974878096429,
    "lon": -6.069912262565619
  },
  {
    "name": "Service de RÚseau des Etablissements Sanitaires KhÚmisset",
    "lat": 33.819546,
    "lon": -6.069982
  },
  {
    "name": "Centre de SantÚ Rural Laghoualem - 2",
    "lat": 33.351251947929356,
    "lon": -6.698032495700845
  },
  {
    "name": "Centre de SantÚ Urbain Tiflet",
    "lat": 33.892307,
    "lon": -6.309713
  },
  {
    "name": "Dispensaire Rural Ait Bouyahia",
    "lat": 33.89156,
    "lon": -6.264961
  },
  { "name": "MOUSSA IBN NOUSSAIR", "lat": 33.82766, "lon": -6.069249 },
  { "name": "centre ait mimoune", "lat": 33.78225, "lon": -5.78275 },
  { "name": "centre ait mimoune", "lat": 33.886862, "lon": -5.736235 },
  { "name": "centre sidi allal el msader", "lat": 33.871835, "lon": -6.15095 },
  {
    "name": "Centre SantÚ Rural Tiddas AF A-1",
    "lat": 33.565037,
    "lon": -6.267015
  },
  { "name": "Centre de sante ain Sbit", "lat": 33.5577462, "lon": -6.918656 },
  { "name": "Centre de sante hay salam", "lat": 34.254431, "lon": -5.9209662 },
  { "name": "centre FAJAR", "lat": 34.138725, "lon": -5.96298 },
  { "name": "Centre de sante hay salam", "lat": 34.274957, "lon": -5.922552 },
  { "name": "centre laababda", "lat": 34.435072, "lon": -6.127021 },
  { "name": "CS N1 LAMRADSA", "lat": 34.331854, "lon": -5.96092 },
  {
    "name": "DÚlÚgation Sidi Slimane",
    "lat": 34.265948666672315,
    "lon": -5.917628526983633
  },
  { "name": "Douar wlad Hanoun kciebiya", "lat": 34.346349, "lon": -6.049872 },
  {
    "name": "centre de cente medical de proximite had kourt",
    "lat": 34.627903,
    "lon": -5.730435
  },
  {
    "name": "Centre de SantÚ Communal Nouwirat",
    "lat": 34.5980536,
    "lon": -5.9625774
  },
  {
    "name": "Centre de santÚ rural de chbanat",
    "lat": 34.2963177,
    "lon": -5.7637464
  },
  {
    "name": "centre de sante urbain niveau 2 mechraa bel ksiri",
    "lat": 34.570255,
    "lon": -5.956365
  },
  { "name": "centre houafat", "lat": 34.467552, "lon": -5.922135 },
  { "name": "centre houafat", "lat": 34.469552, "lon": -5.982135 },
  { "name": "CENTRE KHENICHET", "lat": 34.4446, "lon": -5.681593 },
  { "name": "CENTRE KHENICHET", "lat": 34.444612, "lon": -5.68151 },
  { "name": "centre ouled tabet", "lat": 34.258688, "lon": -5.868054 },
  { "name": "CHP SIDI KACEM", "lat": 34.222921, "lon": -5.700865 },
  { "name": "CSR 1 BIR TALEB", "lat": 34.335041, "lon": -5.664219 },
  { "name": "csr1 dar aslouji1", "lat": 34.354385, "lon": -5.969337 },
  { "name": "csu ezzaouia", "lat": 34.196927, "lon": -5.714575 },
  { "name": "CSU Sidi Amar", "lat": 34.793743133, "lon": -5.89016914367 },
  { "name": "Douar chiban kciebiya", "lat": 34.39104, "lon": -6.068425 },
  { "name": "zeggotta sidi kacem", "lat": 34.174613, "lon": -5.531538 },
  { "name": "CSR TOUAZIT", "lat": 34.244267, "lon": -6.319777 },
  {
    "name": "H¶pital Moulay Hassan",
    "lat": 34.25437876813682,
    "lon": -6.580007012974782
  },
  { "name": "centre de sante chouhada", "lat": 34.249493, "lon": -6.543613 },
  { "name": "centre de sante chouhada", "lat": 34.249493, "lon": -6.543613 },
  {
    "name": "CENTRE DE SANTE URBAIN HAJ MANSOUR",
    "lat": 34.251535,
    "lon": -6.521597
  },
  { "name": "centre de sante hzj labzar", "lat": 34.250485, "lon": -6.622564 },
  {
    "name": "CENTRE DE SANTE COMMUNAL LALLA GHANOU",
    "lat": 34.771156,
    "lon": -6.079876
  },
  {
    "name": "CENTRE DE SANTE COMMUNAL LALLA GHANOU",
    "lat": 34.771157,
    "lon": -6.079877
  },
  {
    "name": "CS niveau 1 Houman Le Fetouaki ",
    "lat": 34.263441,
    "lon": -6.612353
  },
  {
    "name": "centre de sante sidi mohamed lahmar",
    "lat": 34.729305,
    "lon": -6.24655
  },
  {
    "name": "Centre de SantÚ Ahmed Bouihi Bab Fes",
    "lat": 34.26638,
    "lon": -6.564173
  },
  {
    "name": "RUE 30 CENTRE BRAHIM SOUSSI KHBAZAT",
    "lat": 34.264275,
    "lon": -6.563212
  },
  {
    "name": "RUE 30 CENTRE BRAHIM SOUSSI KHBAZAT",
    "lat": 34.25437876813682,
    "lon": -6.577045852250179
  },
  {
    "name": "centre de santÚ communal avec maison d'accouchement mograne",
    "lat": 34.4039993,
    "lon": -6.4359904
  },
  {
    "name": "CENTRE MEDICAL SIDI AYACH",
    "lat": 34.29353023058858,
    "lon": -6.494268783028973
  },
  { "name": "Sidi Bobker El haj", "lat": 34.926712, "lon": -6.057163 },
  { "name": "CSU mbarek belgada", "lat": 34.25132, "lon": -6.544742 },
  { "name": "centre douar ouled moussa", "lat": 34.708108, "lon": -6.368724 },
  { "name": "CSC sidi taybi", "lat": 34.203729, "lon": -6.675516 },
  {
    "name": "CS 1 ALLIANCE DARNA EL MEHDIA",
    "lat": 34.249741,
    "lon": -6.656201
  },
  {
    "name": "CENTRE SOUK TLAT",
    "lat": 34.59704151614417,
    "lon": -6.201845456792673
  },
  { "name": "CSU1 lamgadid", "lat": 34.2234169, "lon": -6.510514817 },
  {
    "name": "centre du sante urbain el haddada",
    "lat": 34.269589,
    "lon": -6.619404
  },
  {
    "name": "HOPITAL LOCAL ZOUBAIR SKIREJ",
    "lat": 34.682606,
    "lon": -6.003903
  },
  { "name": "CSU 1 OULAD NSSAR", "lat": 34.183763, "lon": -6.67952 },
  { "name": "CENTRE MEDICAL BIR RAMI", "lat": 34.219223, "lon": -6.629507 },
  { "name": "CENTRE AL KHANSAA KENITRA", "lat": 34.264388, "lon": -6.573641 },
  { "name": "CENTRE AL KHANSAA KENITRA", "lat": 34.264389, "lon": -6.573642 },
  {
    "name": "CENTRE DE SANTE URBAIN NIVEAU 1 HAYN HIND SOUK EL ARBEA",
    "lat": 34.681351,
    "lon": -5.985732
  },
  {
    "name": "centre medical abderhamn nacer",
    "lat": 34.264755,
    "lon": -6.596106
  },
  {
    "name": "CENTRE DE SANTE SAADA",
    "lat": 34.688933463116356,
    "lon": -1.9433330958386712
  },
  { "name": "CSU 2 hay Hassani", "lat": 33.269783, "lon": -7.574 },
  {
    "name": "CSR niveau 1 Riah",
    "lat": 33.15637045476899,
    "lon": -7.3889699374068725
  },
  { "name": "CS SIDI SMAIL", "lat": 34.6831451, "lon": -1.9107394 },
  { "name": "PP AIN AICHA DE TAOUNAT ", "lat": 34.5368404, "lon": -4.640392 },
  {
    "name": "CSU DEUXIEME  nouveaux BIR JDID ",
    "lat": 33.377224,
    "lon": -7.991928
  },
  { "name": "dÚlÚgation fquih ben saleh", "lat": 32.504985, "lon": -6.696017 },
  { "name": "dÚlÚgation fquih ben saleh", "lat": 32.504985, "lon": -6.696017 },
  { "name": "Super congÚlateur N░13", "lat": 33.552962, "lon": -7.55653 },
  { "name": "Super congÚlateur N░9", "lat": 33.552962, "lon": -7.55653 },
  { "name": "CSU Sidi Betach", "lat": 33.568049, "lon": -6.8911258 },
  { "name": "CSR Toumliline", "lat": 29.944612, "lon": -8.724119 },
  { "name": "CS COMMUNAL MOULAY BOUAZZA", "lat": 0, "lon": 0 },
  { "name": "DÚpot rÚgional d'oujda", "lat": 34.701175, "lon": -1.8834 },
  { "name": "DÚpot rÚgional d'oujda", "lat": 34.706375, "lon": -1.8844 },
  { "name": "Cs Al maroua", "lat": 33.622681, "lon": -7.441385 },
  {
    "name": "DELEGATION DE SANTE SIDI KACEM",
    "lat": 34.2110022,
    "lon": -5.705573
  },
  { "name": "Centre de santÚ tizamrine", "lat": 32.2912198, "lon": -8.4371416 },
  { "name": "OULED OUJIH BLOC K", "lat": 34.254562, "lon": -6.616572 },
  {
    "name": "CS Souk El-KhÚmis Dades Armoire frigorifique A-1",
    "lat": 31.309166,
    "lon": -6.036261
  },
  { "name": "CS URBAIN AMALOU 2", "lat": 32.947717, "lon": -5.653149 },
  { "name": "Cs msala", "lat": 34.41119863095358, "lon": -2.888455490319757 },
  {
    "name": "Centre de santÚ s mazza niveau 1",
    "lat": 34.062153,
    "lon": -6.760606
  },
  {
    "name": "Centre de santÚ s mazza niveau 1",
    "lat": 34.062153,
    "lon": -6.760606
  },
  { "name": "CSR ameur", "lat": 34.092316, "lon": -6.767649 },
  { "name": "C S R AMEUR", "lat": 34.082322, "lon": -6.757666 },
  {
    "name": "CSR Issafene",
    "lat": 29.84064389983441,
    "lon": -7.843651964570314
  },
  { "name": "CS Sala Al Jadida", "lat": 34.0003412, "lon": -6.7412281 },
  {
    "name": "H¶pital Ksar Kbir",
    "lat": 35.01100970110754,
    "lon": -5.946248688325877
  },
  {
    "name": "H¶pital Ksar Kbir",
    "lat": 35.01109482866496,
    "lon": -5.946398221478457
  },
  {
    "name": "H¶pital Ksar Kbir",
    "lat": 35.011073409481405,
    "lon": -5.94635731779098
  },
  {
    "name": "H¶pital Ksar Kbir",
    "lat": 35.00564999999999,
    "lon": -5.929551923942562
  },
  {
    "name": "H¶pital Ksar Kbir",
    "lat": 35.00531166356744,
    "lon": -5.931166613769527
  },
  {
    "name": "H¶pital Ksar Kbir",
    "lat": 35.00570931858099,
    "lon": -5.929610932540889
  },
  {
    "name": "PP Tetouane Armoire  E",
    "lat": 35.57372436378352,
    "lon": -5.3547020116567685
  },
  {
    "name": "PP Tetouane Armoire A",
    "lat": 35.57370745621123,
    "lon": -5.354728163194664
  },
  {
    "name": "PP Tetouane armoire C ",
    "lat": 35.573714546483906,
    "lon": -5.354712069940574
  },
  { "name": "CS Beni Touzine ", "lat": 35.738986, "lon": -5.84822 },
  {
    "name": "DÚlÚgation provincial driouch AF A",
    "lat": 34.985194,
    "lon": -3.389422
  },
  {
    "name": "CSR2 ELBARRADIA",
    "lat": 32.21589980929705,
    "lon": -6.32414966732234
  },
  { "name": "CSR1 BIN ELOUIDANE", "lat": 32.102169, "lon": -6.458835 },
  {
    "name": "CS urbain -2 Tahmidant Rich -2",
    "lat": 32.256889,
    "lon": -4.503875
  },
  {
    "name": "CSR2 FOUM JEMAA",
    "lat": 32.00528105406695,
    "lon": -6.534629014603525
  },
  { "name": "CS Sidi abdellah", "lat": 34.092979, "lon": -6.779043 },
  { "name": "CSR2 SIDI BOUSBER", "lat": 34.562806, "lon": -5.362573 },
  { "name": "CSR Sidi Ahmed Ou Abdellah ", "lat": 30.338335, "lon": -8.572222 },
  {
    "name": "CSU 2 Had Soualem",
    "lat": 33.423561718799625,
    "lon": -7.862849052237924
  },
  {
    "name": "Hopital DARRAK Armoire frigorifique D",
    "lat": 30.325211065356854,
    "lon": -5.837979727508551
  },
  {
    "name": "Hopital DARRAK Armoire frigorifique D",
    "lat": 30.324896196065847,
    "lon": -5.837598853828436
  },
  {
    "name": "Hopital DARRAK Armoire frigorifique D",
    "lat": 30.324618369380293,
    "lon": -5.837802701713568
  },
  { "name": "48419A10/SPR AVANT", "lat": 0, "lon": 0 },
  { "name": "48419A10/SPR ARRIERE", "lat": 0, "lon": 0 },
  { "name": "48035A10/SPR AVANT", "lat": 0, "lon": 0 },
  { "name": "48035A10/SPR ARRIERE", "lat": 0, "lon": 0 },
  { "name": "71114-A2/ avant GSPR", "lat": 0, "lon": 0 },
  { "name": "71114-A2/ arriere GSPR\t", "lat": 0, "lon": 0 },
  { "name": "47724A10/GSPR (ArriÞre ) ", "lat": 0, "lon": 0 },
  { "name": "CS NASR 2", "lat": 34.7180365181238, "lon": -1.960451070818248 },
  {
    "name": "Chp hassan 2 khouribga pharmacie",
    "lat": 32.8935761,
    "lon": -6.9067099
  },
  {
    "name": "CSR2 AFOURER",
    "lat": 32.20944439283308,
    "lon": -6.5364126915148155
  },
  { "name": "centre santÚ Ibn haythem", "lat": 34.0362212, "lon": -6.8128512 },
  {
    "name": "CHR BENI MELLAL CH FROID A-1",
    "lat": 32.328732,
    "lon": -6.353681
  },
  {
    "name": "CHR BENI MELLAL CH FROID A-1",
    "lat": 32.325732,
    "lon": -6.353681
  },
  {
    "name": "CS alkaria",
    "lat": 34.016241889667036,
    "lon": -6.845356148691798
  },
  { "name": "CHR BNIMELLAL ARMOIR 3", "lat": 32.322772, "lon": -6.353659 },
  {
    "name": "CS alkaria",
    "lat": 34.024494106352336,
    "lon": -6.7751506257701966
  },
  { "name": "CSR Elbeida", "lat": 30.4534991, "lon": -8.9860218 },
  { "name": "centre de santÚ zouada", "lat": 35.047565, "lon": -6.0601417 },
  { "name": "Sres sidi bennour ", "lat": 32.6489196, "lon": -8.4232002 },
  {
    "name": "Centre de SantÚ Urbain Salam",
    "lat": 33.827662,
    "lon": -6.069251
  },
  { "name": "cs bnitadjit", "lat": 32.294144, "lon": -3.468029 },
  { "name": "CSR 1 TAFTECHNNA", "lat": 30.684547, "lon": -5.953247 },
  {
    "name": "CS urbain-2 AMEZROU -2",
    "lat": 30.309778422118978,
    "lon": -5.8227905656414025
  },
  {
    "name": "csr2 kaf nsour",
    "lat": 32.2809410378237,
    "lon": -6.199996500628559
  },
  { "name": "csu carlotti", "lat": 33.561583, "lon": -7.589248 },
  { "name": "CS omar elkhatab", "lat": 33.5717772, "lon": -7.591482 },
  { "name": "CS ElBEIDA", "lat": 30.4534079, "lon": -8.9862074 },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.18329534156646,
    "lon": -6.156333017485149
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.18260027402786,
    "lon": -6.153516428530221
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.16556225865284,
    "lon": -6.152301923942562
  },
  {
    "name": "DR LAHCHLAFA AHL SEBT",
    "lat": 33.25133273968208,
    "lon": -8.508824743551255
  },
  { "name": "Csu naima", "lat": 34.8854228, "lon": -2.0486524 },
  {
    "name": "CS 1 ALLIANCE DARNA EL MEHDIA",
    "lat": 34.249741,
    "lon": -6.656201
  },
  {
    "name": "chp hassan 2",
    "lat": 32.5335695477503,
    "lon": -6.540408080673212
  },
  {
    "name": "Cs AL FATH 2",
    "lat": 33.669297285505934,
    "lon": -7.391460169006479
  },
  { "name": "CSU 1 Ahdaf", "lat": 33.4424552917, "lon": -5.2233281135 },
  {
    "name": "centre de sante el manzah",
    "lat": 33.84140567323538,
    "lon": -7.04780346720783
  },
  {
    "name": "centre de sante hassan",
    "lat": 33.99802726234877,
    "lon": -6.833496093750001
  },
  {
    "name": "DÚp¶t RÚgionale des MÚdicaments Rabat",
    "lat": 33.996319440747655,
    "lon": -6.850783662228242
  },
  {
    "name": "DÚp¶t RÚgionale des MÚdicaments Rabat",
    "lat": 33.99575015925125,
    "lon": -6.841744462147862
  },
  { "name": "test", "lat": 33.962155800118985, "lon": -6.8678642954077675 },
  { "name": "test", "lat": 33.94335994657882, "lon": -6.823360499630822 },
  { "name": "CS Ouled Saleh", "lat": 33.3850399, "lon": -7.6884806 },
  {
    "name": "maison daccouchement laghiat",
    "lat": 32.036615,
    "lon": -9.1632483
  },
  { "name": "CS TENDRARA", "lat": 33.05299, "lon": -2.002464 },
  {
    "name": "CS El Hajeb ",
    "lat": 33.68596267700195,
    "lon": -5.376326084136963
  },
  {
    "name": "CSU1 BOUMLAN DADES Armoire frigorifique B",
    "lat": 31.37708906055048,
    "lon": -5.976562500000001
  },
  { "name": "CS Sidi Assou", "lat": 33.4323902, "lon": -5.2277108 },
  {
    "name": "centre de santÚ communal avec maison d'accouchement mograne",
    "lat": 34.4039993,
    "lon": -6.4359904
  },
  { "name": "Csr ait isshaq", "lat": 0, "lon": 0 },
  {
    "name": "C/S Dehar kanfoud RÚfrigÚrateur 1",
    "lat": 35.71022788867805,
    "lon": -5.812476820552248
  },
  { "name": "CSU Emsallah ", "lat": 35.774353, "lon": -5.8226928 },
  { "name": "CSU Emsallah", "lat": 35.774353, "lon": -5.8226928 },
  { "name": "CSU LAKHSSAS", "lat": 29.3675913, "lon": -9.7505961 },
  { "name": "CS MIRGHT", "lat": 0, "lon": 0 },
  {
    "name": "CS ait idir",
    "lat": 31.495432803134843,
    "lon": -5.954589843750001
  },
  {
    "name": "CSR KERROUCHEN",
    "lat": 30.5496497760203,
    "lon": -5.740001639847093
  },
  {
    "name": "CS BERKINE ",
    "lat": 34.365606898757434,
    "lon": -3.298471049640957
  },
  {
    "name": "Centre de SantÚ Rural avec les Urgences MÚdicales de Bzou",
    "lat": 31.920048319197807,
    "lon": -6.536090428511433
  },
  { "name": "CS TENDRARA", "lat": 33.05299, "lon": -2.002464 },
  { "name": "csc had hrara", "lat": 32.440506, "lon": -9.133762 },
  {
    "name": "centre de santÚ le valan",
    "lat": 34.245635986328125,
    "lon": -6.580725193023682
  },
  {
    "name": "centre de santÚ le valan",
    "lat": 34.245635986328125,
    "lon": -6.580725193023682
  },
  {
    "name": "H¶pital Mohammed 5 RÚfrigÚrateur 1 ",
    "lat": 35.16947115601343,
    "lon": -5.269702877892494
  },
  { "name": "CSR Ain Hjar Beni Mansour", "lat": 35.158772, "lon": -4.912563 },
  {
    "name": "Pharmacie Provinciale CF A-4",
    "lat": 35.57372446837802,
    "lon": -5.354701341104515
  },
  {
    "name": "Super congÚlateur N░6 BSLS Nouacer",
    "lat": 33.368384379435525,
    "lon": -7.5709548061913035
  },
  { "name": "Centre santÚ msabih talaa", "lat": 31.843918, "lon": -8.507652 },
  { "name": "Centre santÚ msabih talaa", "lat": 31.8441432, "lon": -8.5090595 },
  {
    "name": "DISPENSAIRE RURAL AIN KAHLA",
    "lat": 32.9686282,
    "lon": -6.9085438
  },
  {
    "name": "CS urbain ZAOUYAT LMAJDOUB Armoire frigorifique A",
    "lat": 30.349509402462747,
    "lon": -5.840467214584351
  },
  {
    "name": "CS urbain ZAOUYAT LMAJDOUB Armoire frigorifique A",
    "lat": 30.349208499174512,
    "lon": -5.840338468551636
  },
  {
    "name": "CS urbain ZAOUYAT LMAJDOUB Armoire frigorifique A",
    "lat": 30.349527919557957,
    "lon": -5.840113162994386
  },
  {
    "name": "CSR Lakhnafif",
    "lat": 30.36576602671556,
    "lon": -9.280504116239538
  },
  { "name": "csr 1 ain baida", "lat": 34.987389, "lon": -5.527909 },
  {
    "name": "CS rural-2 MHAMID EK GHZLAN Armoire frigorifique A",
    "lat": 29.825268661848565,
    "lon": -5.718689560890199
  },
  {
    "name": "CS rural-2 MHAMID EK GHZLAN Armoire frigorifique A",
    "lat": 29.825398970415883,
    "lon": -5.71841061115265
  },
  {
    "name": "CS rural-2 MHAMID EK GHZLAN Armoire frigorifique A",
    "lat": 29.82554324041725,
    "lon": -5.7188719511032104
  },
  {
    "name": "commune el kelaa(hopital essalama)",
    "lat": 32.04938415640004,
    "lon": -7.403376996517182
  },
  {
    "name": "commune el kelaa(hopital essalama)",
    "lat": 32.0493625589425,
    "lon": -7.404331862926484
  },
  {
    "name": "commune el kelaa(hopital essalama)",
    "lat": 32.049012452077584,
    "lon": -7.404225915670396
  },
  {
    "name": "commune el kelaa(hopital essalama)",
    "lat": 32.048631652505826,
    "lon": -7.403841018676759
  },
  {
    "name": "commune el kelaa(hopital essalama)",
    "lat": 32.048467965038796,
    "lon": -7.404052913188935
  },
  {
    "name": "commune el kelaa(hopital essalama)",
    "lat": 32.04847251191685,
    "lon": -7.403468191623689
  },
  {
    "name": "commune el kelaa(cs el hana)",
    "lat": 32.05975034760465,
    "lon": -7.411163449287415
  },
  {
    "name": "commune el kelaa(cs el hana)",
    "lat": 32.06015724211894,
    "lon": -7.410940825939179
  },
  { "name": "CS SIDI DAOUD", "lat": 30.9227066, "lon": -6.8965712 },
  {
    "name": "commune el kelaa(hopital essalama)",
    "lat": 32.04873395702404,
    "lon": -7.403303235769273
  },
  { "name": "CSR WALKADI ", "lat": 30.2457016, "lon": -8.4822311 },
  { "name": "DÚlÚgation De La SantÚ Province NADOR ", "lat": 0, "lon": 0 },
  {
    "name": "DÚlÚgation De La SantÚ Province NADOR ",
    "lat": 35.18777651345573,
    "lon": -2.932449896083132
  },
  { "name": "csu ait ourir", "lat": 31.564604, "lon": -7.663096 },
  { "name": "Oulad AISSA", "lat": 32.9461834, "lon": -6.436326 },
  {
    "name": "PP AIN AICHA DE TAOUNAT ",
    "lat": 34.465240211219744,
    "lon": -4.683606932539151
  },
  {
    "name": "C/S OULED BOUALI",
    "lat": 32.1476383948746,
    "lon": -7.216172218322755
  },
  { "name": "CRU 20AOUT OUED ZEM", "lat": 32.863327, "lon": -6.574873 },
  { "name": "CENTRE DE SANTE TOBA", "lat": 34.698505, "lon": -1.91374 },
  { "name": "csc beddouza", "lat": 32.54396, "lon": -9.277317 },
  { "name": "SNTL / 746-02 / avant", "lat": 0, "lon": 0 },
  {
    "name": "CS Mokrissat",
    "lat": 34.924472233895514,
    "lon": -5.348595307918524
  },
  {
    "name": "CS rural BENI ZOULI ",
    "lat": 30.441957686716837,
    "lon": -5.896470449073794
  },
  { "name": "CSU Mozart", "lat": 35.77189009581437, "lon": -5.796990794171628 },
  { "name": "CS ouizeght", "lat": 32.859093, "lon": -4.106011 },
  {
    "name": "Cs GANFOUDA",
    "lat": 34.484556765521646,
    "lon": -2.04568934767573
  },
  {
    "name": "Pharmacie H¶spitaliÞre Inezgane",
    "lat": 30.353059296221016,
    "lon": -9.536120830688477
  },
  {
    "name": "Pharmacie H¶spitaliÞre Inezgane",
    "lat": 30.353223629356524,
    "lon": -9.535975991401672
  },
  {
    "name": "Pharmacie H¶spitaliÞre Inezgane",
    "lat": 30.353331255837954,
    "lon": -9.53577750793457
  },
  {
    "name": "CSR 2 Nkoub Armoire frigorifique A",
    "lat": 30.870127825374507,
    "lon": -5.863673686981201
  },
  {
    "name": "CSR 2 Nkoub Armoire frigorifique A",
    "lat": 30.866609957570937,
    "lon": -5.864853858947754
  },
  {
    "name": "CSR 2 Nkoub Armoire frigorifique A",
    "lat": 30.861268445502777,
    "lon": -5.8687591552734375
  },
  { "name": "DAR CHABAB", "lat": 35.172115, "lon": -2.933248 },
  {
    "name": "Maison des Jeunes Ouad Fes",
    "lat": 34.0589218,
    "lon": -5.0301018
  },
  { "name": "jemaa armoire a1", "lat": 32.351136, "lon": -8.842768 },
  { "name": "C.S jarda", "lat": 34.0447003, "lon": -6.8159418 },
  {
    "name": "centre de santÚ ouled zerrad",
    "lat": 32.078865615075976,
    "lon": -7.6006507873535165
  },
  { "name": "PP Sefrou", "lat": 33.8287926, "lon": -4.8304763 },
  { "name": "DÚlÚgation provincial driouch CHF A", "lat": 0, "lon": 0 },
  {
    "name": "CS Souk El-KhÚmis Dades Armoire frigorifique A-4",
    "lat": 31.307631820153816,
    "lon": -6.0373735427856445
  },
  {
    "name": "CS Souk El-KhÚmis Dades -1",
    "lat": 31.309080132798528,
    "lon": -6.03449821472168
  },
  {
    "name": "CS Souk El-KhÚmis Dades Armoire frigorifique A-3",
    "lat": 31.30686182208712,
    "lon": -6.035871505737305
  },
  { "name": "CS Mesnana", "lat": 35.72997766997718, "lon": -5.759289681217353 },
  { "name": "centre de santÚ le vallan", "lat": 34.2680133, "lon": -6.6004872 },
  { "name": "cs bouanane", "lat": 32.037144, "lon": -3.048010031738284 },
  { "name": "DÚlÚgation El Haouz - A", "lat": 0, "lon": 0 },
  { "name": "DÚlÚgation El Haouz - A", "lat": 0, "lon": 0 },
  {
    "name": "CS rural BENI ZOULI Armoire frigorifique A",
    "lat": 30.441923,
    "lon": -5.896508
  },
  {
    "name": "CS rural BENI ZOULI Armoire frigorifique A",
    "lat": 30.441872126126334,
    "lon": -5.896438262565615
  },
  {
    "name": "CS rural BENI ZOULI Armoire frigorifique A",
    "lat": 30.441964624058716,
    "lon": -5.896438262565615
  },
  { "name": "CSU 1 Hay El  Jadid ", "lat": 35.185222, "lon": -6.15475 },
  {
    "name": "H¶pital Mohammed 5 CF B-3",
    "lat": 35.169589829152315,
    "lon": -5.269722323907852
  },
  {
    "name": "CSU Abou Kassem Zaheraoui",
    "lat": 34.79878268358007,
    "lon": -5.588610728836061
  },
  {
    "name": "CSU Abou Kassem Zaheraoui",
    "lat": 34.79877827852728,
    "lon": -5.588610728836061
  },
  { "name": "labo EPIDEMIO 1", "lat": 0, "lon": 0 },
  { "name": "labo EPIDEMIO 2", "lat": 0, "lon": 0 },
  {
    "name": "centre de sante jorf el melha",
    "lat": 34.49821,
    "lon": -5.506355
  },
  {
    "name": "H¶pital Mohammed 5",
    "lat": 35.169291693836776,
    "lon": -5.270875856193664
  },
  {
    "name": "H¶pital Mohammed 5",
    "lat": 35.16920694662878,
    "lon": -5.272843688649089
  },
  { "name": "CS NASR 1", "lat": 34.71096090768042, "lon": -1.8842143472213646 },
  { "name": "Centre de SantÚ  Lahri", "lat": 32.8571913, "lon": -5.6246847 },
  { "name": "SRES Tata ", "lat": 29.75085102116648, "lon": -7.972190033065791 },
  { "name": "cs ain chouater", "lat": 31.849369, "lon": -2.963259 },
  { "name": "CSU BENI ENSSAR", "lat": 35.263276, "lon": -2.933827 },
  { "name": "CSU BENI ENSSAR ", "lat": 35.262276, "lon": -2.933827 },
  { "name": "C/S SELOUANE", "lat": 35.066444, "lon": -2.939752 },
  {
    "name": "CSR 2 TROUGOUT",
    "lat": 35.075818962748976,
    "lon": -3.801572441194888
  },
  {
    "name": "CSR 2 OULED AMGHAR",
    "lat": 35.05496347358038,
    "lon": -3.764777652656169
  },
  { "name": "CSR 2 OULED AMGHAR", "lat": 35.172772, "lon": -2.932482 },
  { "name": "DR OD RAHOU", "lat": 33.050964, "lon": -7.89735 },
  {
    "name": "Maison de la jeunesee al firdawss",
    "lat": 33.55302,
    "lon": -7.670291
  },
  {
    "name": "Maison de la jeunesee al firdawss",
    "lat": 33.55302,
    "lon": -7.670291
  },
  {
    "name": "Centre de santÚ molay abdelah al jadida",
    "lat": 33.1980271,
    "lon": -8.5898577
  },
  {
    "name": "centre de sante moulay bouchaib",
    "lat": 33.278637,
    "lon": -8.343605
  },
  { "name": "CS TILMI", "lat": 31.795283, "lon": -5.776977 },
  {
    "name": "centre de santÚ beni hadifa",
    "lat": 35.022285461,
    "lon": -4.14278650283
  },
  { "name": "CS KOBBA", "lat": 31.619787, "lon": -7.971303 },
  { "name": "CS Dar Tounsi", "lat": 31.649343, "lon": -7.943857 },
  { "name": "DR 20 AOUT", "lat": 28.4438837, "lon": -11.0920717 },
  { "name": "CSU ibn sina", "lat": 33.9981197, "lon": -6.7345611 },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  { "name": "cs bni ahmed imougzane", "lat": 35.15215682, "lon": -3.85711193 },
  {
    "name": "HOPITAL EL RICH (refrigerteur)",
    "lat": 32.2727859,
    "lon": -4.4874587
  },
  { "name": "centre de sante My Hassan", "lat": 34.2486312, "lon": -6.5776112 },
  { "name": "CSU mohamed zerktouni", "lat": 34.255091, "lon": -6.544987 },
  { "name": "CSU mohamed zerktouni", "lat": 34.253698, "lon": -6.541875 },
  { "name": "centre santÚ nkhakhssa", "lat": 34.296288, "lon": -6.519924 },
  {
    "name": "CENTRE DE SANTE URBAIN NIVEAU 1 HAYN HIND SOUK EL ARBEA",
    "lat": 34.681351,
    "lon": -5.985732
  },
  {
    "name": "centre sidi el hachmi el bahraoui",
    "lat": 34.631813,
    "lon": -6.412898
  },
  {
    "name": "centre de sante raja fillah",
    "lat": 34.25295639038086,
    "lon": -6.556360244750977
  },
  { "name": "centre de sante raja fillah", "lat": 34.252607, "lon": -6.561151 },
  { "name": "CSU mohamed zerktouni", "lat": 34.253698, "lon": -6.541875 },
  { "name": "CSR Tagmout", "lat": 29.962269, "lon": -8.235522 },
  {
    "name": "CS Imouzer ",
    "lat": 30.420270290372798,
    "lon": -9.592754160713188
  },
  {
    "name": "CSR Askaoun",
    "lat": 30.738299876313846,
    "lon": -7.777097494720466
  },
  { "name": "CS Laayoune", "lat": 33.667526, "lon": -7.071202 },
  { "name": "CS COMMUNAL M'TAL", "lat": 32.488255, "lon": -8.37636 },
  {
    "name": "CSR OUAOUMANA",
    "lat": 32.785465240478516,
    "lon": -5.649574279785156
  },
  {
    "name": "CENTRE DE SANTE URBAIN HAJ MANSOUR",
    "lat": 34.251535,
    "lon": -6.521597
  },
  { "name": "CSU A AL AROUIT", "lat": 35.017526, "lon": -2.998028 },
  { "name": "CSR 1 Tissaf", "lat": 33.3964, "lon": -3.58354 },
  {
    "name": "PP D'El Hajeb",
    "lat": 33.68921536838173,
    "lon": -5.378484936523438
  },
  { "name": "DR Ouled Jerar", "lat": 33.5469284, "lon": -3.6348224 },
  {
    "name": "CS HAY KIFAH SALE",
    "lat": 34.01908874511719,
    "lon": -6.7708916664123535
  },
  {
    "name": "C.S hay salam 1",
    "lat": 34.028762179464465,
    "lon": -6.771175894842546
  },
  {
    "name": "Centre de SantÚ El Yassmine",
    "lat": 33.84760762988741,
    "lon": -6.064338160945166
  },
  {
    "name": "Centre de santÚ Lissasfa AR 2 (E)",
    "lat": 33.534240497950975,
    "lon": -7.679070936344706
  },
  {
    "name": "Centre de sante Lissasfa AR 7 (J-1)",
    "lat": 33.53295268428481,
    "lon": -7.6771907446083425
  },
  {
    "name": "centre de santÚ Lissasfa AR 3 (F-1)",
    "lat": 33.53452667616054,
    "lon": -7.672949327327171
  },
  {
    "name": "super congÚlateur N░9 BSLS Nouacer",
    "lat": 33.36379663433047,
    "lon": -7.5695743299222675
  },
  {
    "name": "Centre de SantÚ Urbain avec module d'accouchement Mediouna",
    "lat": 33.461234144932305,
    "lon": -7.521570937554865
  },
  {
    "name": "PHARMACIE PROVINCIAL AALIA BAYDA",
    "lat": 33.447484889088855,
    "lon": -7.5187812440030415
  },
  { "name": "cs figuig", "lat": 32.10749804142473, "lon": -1.2373161476423356 },
  { "name": "PP Tanger", "lat": 35.78874425305937, "lon": -5.819463035276133 },
  { "name": "C/S MOULAY BOUSELHAM", "lat": 34.886086, "lon": -6.290598 },
  {
    "name": "centre d'addictologie KÚnitra",
    "lat": 34.886086,
    "lon": -6.290598
  },
  {
    "name": "Total Tanger Med",
    "lat": 35.75208494531366,
    "lon": -5.850262838083074
  },
  {
    "name": "CSC Bab Taza RÚfrigÚrateur 2",
    "lat": 35.06325031825119,
    "lon": -5.197431164418022
  },
  {
    "name": "DISPENSAIRE RURALE TOUFIST",
    "lat": 35.18278813800229,
    "lon": -3.9991154087438816
  },
  { "name": "CS Ain Karma", "lat": 34.0128797, "lon": -5.6366747 },
  { "name": "CS BAB OUNDIR", "lat": 34.5256577, "lon": -4.6428652 },
  { "name": "CS SOUK SEBT ARMOIRE 2", "lat": 32.504951, "lon": -6.692314 },
  {
    "name": "CSR Berguene",
    "lat": 30.32843936972944,
    "lon": -8.391923492065434
  },
  {
    "name": "CS COMMUNAL AGUELMOUSS",
    "lat": 32.93595321660454,
    "lon": -5.6719578266734185
  },
  { "name": "CENTRE DE SANTE SOWANI", "lat": 34.067543, "lon": -6.804652 },
  { "name": "CS ELKARIA", "lat": 34.031637, "lon": -6.7673 },
  { "name": "47701A10/GPRS ARRIERE", "lat": 0, "lon": 0 },
  { "name": "47701A10/GPRS AVANT", "lat": 0, "lon": 0 },
  { "name": "43569A10 GSPR", "lat": 0, "lon": 0 },
  { "name": "47704A10/GSPR ARRIERE", "lat": 0, "lon": 0 },
  { "name": "47704A10/GSPR AVANT ", "lat": 0, "lon": 0 },
  { "name": "47724A10/GSPR (AVANT)", "lat": 0, "lon": 0 },
  { "name": "47703A10/GSPR AVANT", "lat": 0, "lon": 0 },
  { "name": "47706A10/GSPR  ARRIERE", "lat": 0, "lon": 0 },
  { "name": "47706A10/GSPR   AVANT ", "lat": 0, "lon": 0 },
  { "name": "47705A10/GSPR AVANT ", "lat": 0, "lon": 0 },
  { "name": "47705A10/GSPR ARRIERE", "lat": 0, "lon": 0 },
  { "name": "47702A10/GPRS AVANT ", "lat": 0, "lon": 0 },
  {
    "name": "PP AIN AICHA DE TAOUNAT ",
    "lat": 34.55281678154946,
    "lon": -4.631269839757982
  },
  { "name": "DR Ait Blal", "lat": 32.849987, "lon": -4.2956963 },
  { "name": "CSU Myssor", "lat": 33.0480385, "lon": -3.9898637 },
  { "name": "csc bouchane", "lat": 32.293194, "lon": -8.32805 },
  {
    "name": "CSR 1 Niveau JNANE BOUIH",
    "lat": 32.24416711645735,
    "lon": -8.52810342223539
  },
  {
    "name": "PP AIN AICHA DE TAOUNAT ",
    "lat": 34.46105194091797,
    "lon": -4.690919399261475
  },
  {
    "name": "PP AIN AICHA DE TAOUNAT ",
    "lat": 34.46105194091797,
    "lon": -4.690919399261475
  },
  { "name": "CS ain chkake", "lat": 33.8826133, "lon": -5.0450866 },
  { "name": "hopital bouskoura", "lat": 33.465885, "lon": -7.642975 },
  { "name": "hopital bouskoura", "lat": 33.465885, "lon": -7.642975 },
  { "name": "hopital bouskoura", "lat": 33.465885, "lon": -7.642975 },
  { "name": "hopital bouskoura", "lat": 33.466335, "lon": -7.643331 },
  {
    "name": "CS communal avec maison d'accouchement ALNIF Armoire frigorifique A",
    "lat": 31.115981036909485,
    "lon": -5.168671046627049
  },
  {
    "name": "CS Elmerja -2",
    "lat": 30.19215334868354,
    "lon": -6.409397999999997
  },
  {
    "name": "CS ait mouli",
    "lat": 32.75826357886935,
    "lon": -5.010772542327877
  },
  {
    "name": "Centre de santÚ urbain ALLAH BEN ABD ALLAH",
    "lat": 31.928481715133646,
    "lon": -4.4213991398811325
  },
  { "name": "CSU 1 AL QUODS", "lat": 27.1298372, "lon": 27.1298372 },
  { "name": "CSR Issafene", "lat": 29.860586, "lon": -8.527634 },
  { "name": "CSR2 AIT BOUAOULI (ABACHKOU)", "lat": 31.61787, "lon": -6.62314 },
  {
    "name": "DELEGATION DE SANTE SALE",
    "lat": 34.2347646712817,
    "lon": -6.6454498730755205
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.18643315271791,
    "lon": -6.156374519014363
  },
  {
    "name": "H¶pital Mohammed 5 Armoires Frigorifiques B",
    "lat": 35.16945760426284,
    "lon": -5.269808154596329
  },
  {
    "name": "H¶pital Mohammed 5 Armoires Frigorifiques C",
    "lat": 35.16956504047862,
    "lon": -5.269892644180298
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.18644603141866,
    "lon": -6.156387259507183
  },
  {
    "name": "H¶pital Mohammed 5 Armoires Frigorifiques D-1",
    "lat": 35.16939456764165,
    "lon": -5.269872527612686
  },
  {
    "name": "centre de santÚ beni jamil",
    "lat": 35.0033645,
    "lon": -4.45954036712
  },
  { "name": "CS rural-2 Tinzouline", "lat": 30.50457, "lon": -6.103559 },
  {
    "name": "SRES Tata",
    "lat": 29.750846363824536,
    "lon": -7.9730858908767654
  },
  { "name": "SRES Tata", "lat": 29.751028, "lon": -7.972587 },
  { "name": "SRES Tata", "lat": 29.750795133048857, "lon": -7.972565542327876 },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments",
    "lat": 30.41993491036323,
    "lon": -9.593574916671745
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments",
    "lat": 30.41970823908327,
    "lon": -9.594159638236992
  },
  { "name": "DR Tassamakt", "lat": 33.149841, "lon": -5.223079 },
  { "name": "CS Albasatin", "lat": 33.89228, "lon": -5.5083714 },
  { "name": "CS Albasatin", "lat": 33.8912061, "lon": -5.5439697 },
  {
    "name": "centre de protection de l 'enfance hay el alaouiene",
    "lat": 33.920421,
    "lon": -6.920772
  },
  { "name": "CS MASSIRA 1", "lat": 33.960679, "lon": -6.8118025 },
  { "name": "CS ouled bennacer", "lat": 33.928188, "lon": -6.902546 },
  { "name": "Centre santÚ Harhoura", "lat": 33.926705, "lon": -6.958021 },
  {
    "name": "maison de jeunesse mers lkhir",
    "lat": 33.8660528,
    "lon": -6.9284226
  },
  {
    "name": "CENTRE DE SANTE ELARGI SKHIRAT ",
    "lat": 33.853266,
    "lon": -7.03411
  },
  {
    "name": "CENTRE DE SANTE ELARGI SKHIRAT ",
    "lat": 33.854266,
    "lon": -7.03411
  },
  { "name": "CENTRE DE SANTE ELARGI", "lat": 33.8663571, "lon": -7.0062052 },
  { "name": "CENTRE DE SANTE SKHIRAT 2", "lat": 33.841022, "lon": -7.047922 },
  { "name": "C.S.R Sebbah", "lat": 33.802318, "lon": -7.0353 },
  { "name": "Centre MÚdical Tamesna", "lat": 33.839382, "lon": -6.918656 },
  { "name": "centre medical ouled zears", "lat": 33.806977, "lon": -6.796063 },
  {
    "name": "centre medical ouled zears",
    "lat": 33.80082702,
    "lon": -6.786614894866
  },
  {
    "name": "maison des jeunes sidi el arbi ain el aouda",
    "lat": 33.7913818,
    "lon": -6.7979083
  },
  { "name": "Centre de SantÚ Bouregreg", "lat": 33.991579, "lon": -6.880563 },
  { "name": "centre de sante bourgreg", "lat": 34.002017, "lon": -6.812929 },
  { "name": "Centre de SantÚ Annahda", "lat": 33.9814838, "lon": -6.8111567 },
  { "name": "CS Ain Llah", "lat": 34.0369415, "lon": -5.1406117 },
  { "name": "La Salle Couverte Martil Andalous R 1", "lat": 0, "lon": 0 },
  { "name": "Hopital hassan 2", "lat": 0, "lon": 0 },
  {
    "name": "CSU Bensouda",
    "lat": 34.01495685485903,
    "lon": -5.053916120997813
  },
  { "name": "CSU TICHIBITE", "lat": 31.949156, "lon": -6.585418 },
  {
    "name": "CS Hay Salam",
    "lat": 31.753080810264677,
    "lon": -6.560417457216426
  },
  { "name": "CS lalla hasna", "lat": 32.249781, "lon": -8.522427 },
  { "name": "CS lalla hasna", "lat": 32.250084, "lon": -8.522471 },
  { "name": "CS lalla hasna", "lat": 32.249915, "lon": -8.522542 },
  { "name": "CS lalla hasna", "lat": 32.250006, "lon": -8.522627 },
  { "name": "CSR 1 OUIRGANE", "lat": 31.1749333, "lon": -8.0851032 },
  { "name": "CHU SOUISSI ENFANTS", "lat": 33.986617, "lon": -6.855341 },
  { "name": "Centre de santÚ lalla amina", "lat": 34.008774, "lon": -6.829474 },
  { "name": "CSU ibn sina", "lat": 33.997144, "lon": -6.74087 },
  { "name": "CSU ibn sina", "lat": 34.029678, "lon": -6.753947 },
  { "name": "CSU ibn sina", "lat": 33.9981197, "lon": -6.7345611 },
  { "name": "CSU ibn sina", "lat": 34.000312, "lon": -6.743059 },
  {
    "name": "centre de sante masraa el kettani",
    "lat": 34.122987,
    "lon": -6.563365
  },
  { "name": "CS 1 hssain", "lat": 34.019688, "lon": -6.745031 },
  { "name": "CS 1 hssain", "lat": 34.019678, "lon": -6.745039 },
  {
    "name": "centre de sante urbain souk sebt",
    "lat": 34.234416,
    "lon": -6.542766
  },
  { "name": "CSU N 1 OULAD NSSAR", "lat": 34.183765, "lon": -6.679522 },
  {
    "name": "CENTRE SOUK TLAT",
    "lat": 34.620418548583984,
    "lon": -6.130917072296143
  },
  { "name": "H¶pital Moulay Hassan", "lat": 34.260708, "lon": -6.563235 },
  { "name": "Douar wlad Hanoun kciebiya", "lat": 34.038388, "lon": -6.049862 },
  { "name": "CSR Tamri ", "lat": 30.6934037, "lon": -9.8245588 },
  { "name": "CSR Tamri ", "lat": 30.6934037, "lon": -9.8245588 },
  {
    "name": "CS Hay Kifah",
    "lat": 34.02591682124144,
    "lon": -6.777925948815157
  },
  { "name": "CS HAY KIFAH SALE", "lat": 34.016711, "lon": -6.767861 },
  {
    "name": "CS HAY KIFAH SALE",
    "lat": 34.01165008544922,
    "lon": -6.763499736785889
  },
  {
    "name": "centre de sante moulay ismail",
    "lat": 34.028216,
    "lon": -6.782707
  },
  {
    "name": "centre bab sebta moulay slimane",
    "lat": 34.0417006,
    "lon": -6.8236363
  },
  {
    "name": "centre de sante urbain tabriquet",
    "lat": 34.05634689331055,
    "lon": -6.796754837036133
  },
  {
    "name": "centre de sante mohamed diouri",
    "lat": 34.255682,
    "lon": -6.6024785
  },
  { "name": "CS Bab Khemis", "lat": 34.0385522, "lon": -6.8152433 },
  {
    "name": "centre de sante sidi mohamed lahmar",
    "lat": 34.729305,
    "lon": -6.24655
  },
  { "name": "centre santÚ Ibn haythem", "lat": 34.0650825, "lon": -6.7656784 },
  {
    "name": "Centre de SantÚ Ibn Haytam\r\n\r\n",
    "lat": 34.03274552068691,
    "lon": -6.77252188189516
  },
  {
    "name": "h¶pital moulay youssef",
    "lat": 34.02136404964113,
    "lon": -6.7689528530663035
  },
  {
    "name": "Centre de SantÚ Ibn Haytam",
    "lat": 34.028762179464465,
    "lon": -6.768995964586878
  },
  { "name": "Lala yetto", "lat": 34.342828, "lon": -6.227161 },
  {
    "name": "Centre de santÚ hay errahma",
    "lat": 34.06461715698242,
    "lon": -6.781121730804443
  },
  { "name": "DELEGATION DE SANTE SALE", "lat": 34.036229, "lon": -6.812854 },
  {
    "name": "Vaccindrome bab lamrissa AF CN 1",
    "lat": 34.0334765,
    "lon": -6.8194236
  },
  { "name": "CS BETTANA", "lat": 34.0317591, "lon": -6.8027815 },
  { "name": "Centre de santÚ Bettana", "lat": 34.030853, "lon": -6.802614 },
  { "name": "CSR Taoyalte", "lat": 30.807934, "lon": -7.787615 },
  {
    "name": "pharmacie prefectorale marrakech",
    "lat": 31.636615,
    "lon": -8.000707
  },
  {
    "name": "pharmacie prefectorale marrakech",
    "lat": 31.636417,
    "lon": -8.001343
  },
  { "name": "CSC DAR HERAK", "lat": 34.962994, "lon": -6.127888 },
  {
    "name": "Hopital Sidi Mohamed Ben Abdellah A3",
    "lat": 31.512612,
    "lon": -9.76272
  },
  { "name": "csc sidi rahal", "lat": 31.65089, "lon": -7.474905 },
  { "name": "centre de santÚ collectif ouled sebih", "lat": 0, "lon": 0 },
  { "name": "Super congÚlateur N░6", "lat": 33.552962, "lon": -7.55653 },
  {
    "name": "H¶pital Roi Fahd ",
    "lat": 35.76775585637164,
    "lon": -5.796290102140911
  },
  {
    "name": "DÚlÚgation provincial driouch AF B",
    "lat": 34.987194,
    "lon": -3.389422
  },
  { "name": "DR OD BERKAT", "lat": 0, "lon": 0 },
  { "name": "CS SOUK SEBT ARMOIRE 2", "lat": 32.503951, "lon": -6.692314 },
  { "name": "CSR 1 BENNI MOUSSA ", "lat": 0, "lon": 0 },
  { "name": "H¶pital zemamra", "lat": 32.628208, "lon": -8.702527 },
  { "name": "DR AZILA ISSAGUEN", "lat": 34.8648, "lon": -4.544231 },
  { "name": "Camion BSLS -MILITAIRE CASA- 1006727", "lat": 0, "lon": 0 },
  { "name": "Camion BSLS -MILITAIRE CASA- 1006727  ", "lat": 0, "lon": 0 },
  { "name": "CSU HAY ELFILAHI", "lat": 28.5996323, "lon": -9.4202681 },
  { "name": "DR Talaaint", "lat": 29.500601, "lon": -9.687013 },
  {
    "name": "CS Monfleuri",
    "lat": 34.01100540161133,
    "lon": -4.983373165130615
  },
  {
    "name": "centre de sante youssoufia 1",
    "lat": 33.995994,
    "lon": -6.815294
  },
  { "name": "CENTRE DE SANTE BOUHLAL", "lat": 33.994484, "lon": -6.876508 },
  { "name": "CSU 2 Boufakrane", "lat": 33.7586403, "lon": -5.489542 },
  { "name": "CS Jbabra", "lat": 33.869713, "lon": -5.5565347 },
  { "name": "CSU 1 SEBRA", "lat": 34.932426, "lon": -2.747998 },
  {
    "name": "centre de santÚ rural niveau 2 IJARMAOUAS",
    "lat": 35.060966665677384,
    "lon": -3.682027493254094
  },
  { "name": "C/S TALILIT", "lat": 35.160461, "lon": -3.527491 },
  { "name": "C/S BENI MARGHINE", "lat": 35.160473, "lon": -3.6024 },
  {
    "name": "CS TENDRARA",
    "lat": 33.050758361816406,
    "lon": -2.0029890537261963
  },
  {
    "name": "pharmacie provinciale al hociema A",
    "lat": 35.23828932024224,
    "lon": -3.928807317790992
  },
  { "name": "csr mezguitem", "lat": 34.502441, "lon": -3.632548 },
  { "name": "csu jaaidat", "lat": 31.70265758, "lon": -7.6842741 },
  { "name": "CSU AL BAHIA", "lat": 31.622548, "lon": -7.985168 },
  { "name": "Csr ait imour", "lat": 31.5532945, "lon": -8.3059356 },
  { "name": "csu maatallah", "lat": 31.596046, "lon": -8.037341 },
  { "name": "csu-1 mouassine", "lat": 31.62894, "lon": -7.993004 },
  { "name": "cs arsat moha", "lat": 31.623893, "lon": -7.988789 },
  { "name": "CSU CHOUHADA", "lat": 31.595448, "lon": -7.961678 },
  {
    "name": "CSC ET MAISON D ACCOUCHEMENT LOUDAYA",
    "lat": 31.637049,
    "lon": -8.249298
  },
  { "name": "cs anoual", "lat": 32.680359, "lon": -3.101322 },
  { "name": "CENTRE MEDICAL OULED ISSA", "lat": 34.649883, "lon": -5.909313 },
  {
    "name": "foyer fÚminin had ouled jaloul",
    "lat": 34.538128,
    "lon": -6.365845
  },
  { "name": "ouled assal", "lat": 34.376808, "lon": -6.537343 },
  { "name": "C/S KARIAT BEN OUDA", "lat": 34.771591, "lon": -5.952906 },
  { "name": "CSU BAB DOUKALA ", "lat": 31.637849, "lon": -8.001055 },
  {
    "name": "CENTRE SANITAIRE EL FEKHKHARA",
    "lat": 31.636441,
    "lon": -7.976925
  },
  { "name": "dar caid si issa", "lat": 32.418196, "lon": -9.011785 },
  { "name": "csu jaaidat armoire A1", "lat": 31.70265756, "lon": -7.6842739 },
  { "name": "CSU ISRANE", "lat": 0, "lon": 0 },
  {
    "name": "H¶pital Mohammed 6",
    "lat": 35.69203076025774,
    "lon": -5.329542877315521
  },
  { "name": "AIN itti", "lat": 31.6478904, "lon": -7.9854168 },
  { "name": "CSCA HANCHANE", "lat": 31.526565, "lon": -9.441309 },
  { "name": "CS TAMELALT", "lat": 0, "lon": 0 },
  {
    "name": "C/S MARBOUH",
    "lat": 31.94750122367064,
    "lon": -7.4743753883736295
  },
  { "name": "Csr sidi zouine", "lat": 31.6640633, "lon": -8.3499917 },
  { "name": "CSR  laqsbi", "lat": 28.998025, "lon": -10.172943 },
  { "name": "CSR 2 Laouamra Armoires Frigorifique A", "lat": 0, "lon": 0 },
  { "name": "CS ARKMAN", "lat": 35.105272, "lon": -2.741471 },
  { "name": "Csu harbil", "lat": 31.7449215, "lon": -8.1098428 },
  {
    "name": "Centre de santÚ Lissasfa AR 1 (D-1)",
    "lat": 33.534019,
    "lon": -7.66926
  },
  { "name": "centre de santÚ alamria", "lat": 0, "lon": 0 },
  { "name": "CS Massira", "lat": 33.399849, "lon": -7.529937 },
  { "name": "HOPITAL HASSAN 2", "lat": 32.6774406, "lon": -4.7404394 },
  {
    "name": "CS Tarrast ",
    "lat": 30.192618218499273,
    "lon": -9.541625976562502
  },
  {
    "name": "Laboratoire De Diagnostic EpidÚmiologique Et D'hygiÞne Du Milieu ",
    "lat": 34.0420189,
    "lon": -4.9870815
  },
  {
    "name": "Laboratoire De Diagnostic EpidÚmiologique Et D'hygiÞne Du Milieu ",
    "lat": 34.0420189,
    "lon": -4.9870815
  },
  {
    "name": "Laboratoire De Diagnostic EpidÚmiologique Et D'hygiÞne Du Milieu ",
    "lat": 34.0420189,
    "lon": -4.9870815
  },
  {
    "name": "Laboratoire De Diagnostic EpidÚmiologique Et D'hygiÞne Du Milieu ",
    "lat": 34.0420189,
    "lon": -4.9870815
  },
  {
    "name": "commune el kelaa(hopital essalama)",
    "lat": 32.049399,
    "lon": -7.0402658
  },
  {
    "name": "Laboratoire De Diagnostic EpidÚmiologique Et D'hygiÞne Du Milieu ",
    "lat": 34.0420189,
    "lon": -4.9870815
  },
  { "name": "CHR BNIMELLAL ARMOIR 3", "lat": 32.324772, "lon": -6.353659 },
  {
    "name": "CSR2 ELBARRADIA",
    "lat": 32.451747751220275,
    "lon": -6.537144184112549
  },
  { "name": "DR Aglagale", "lat": 30.314795, "lon": -8.622933 },
  { "name": "CS Skoura", "lat": 0, "lon": 0 },
  { "name": "CS eskoura", "lat": 33.35691456311512, "lon": -4.725842455387951 },
  {
    "name": "hopital lalla hasna ARMOIRE FRIGORIFIQUE C1",
    "lat": 32.250285,
    "lon": -8.522856
  },
  {
    "name": "hopital lalla hasna ARMOIRE FRIGORIFIQUE C1",
    "lat": 32.250283,
    "lon": -8.52285
  },
  {
    "name": "hopital lalla hasna ARMOIRE FRIGORIFIQUE C1",
    "lat": 32.250284,
    "lon": -8.522851
  },
  {
    "name": "H¶pital Jirari",
    "lat": 35.751401714267246,
    "lon": -5.812695361971289
  },
  {
    "name": "H¶pital Jirari ",
    "lat": 35.751401714267246,
    "lon": -5.812695361971289
  },
  { "name": "CSU BARRACA AF A", "lat": 35.171429, "lon": -2.938596 },
  { "name": "CSU BARRACA AF A", "lat": 35.170429, "lon": -2.938596 },
  {
    "name": "HOPITAL MILITAIRE RABAT Super congÚlarteur ",
    "lat": 33.3981773,
    "lon": -7.5713205
  },
  { "name": "Place Des Arenes", "lat": 35.754898, "lon": -5.79567432 },
  { "name": "SCU SFA", "lat": 32.289262, "lon": -9.218023 },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  { "name": "CS Ouled Frej", "lat": 32.949745, "lon": -8.209166 },
  { "name": "DR OULAD HMID", "lat": 34.059757, "lon": -3.400363 },
  { "name": "DP de Sidi Ifni", "lat": 29.364823, "lon": -10.181707 },
  { "name": "CSC Tamrot", "lat": 34.93681970828119, "lon": -4.779534965284804 },
  {
    "name": "DÚlÚgation RACHIDIA CF B-3",
    "lat": 31.93056313462175,
    "lon": -4.429705309192649
  },
  {
    "name": "CS urbain MUSTAPHA MAANI -4",
    "lat": 31.919949321024426,
    "lon": -4.425459974205017
  },
  {
    "name": "Pharmacie prÚfectorale marrakech Armoire 1-C",
    "lat": 31.636795,
    "lon": -8.001505
  },
  { "name": "DR iligh", "lat": 29.9594155, "lon": -7.8603941 },
  { "name": "H¶pital zemamra", "lat": 32.627141, "lon": -8.697712 },
  { "name": "centre zitoune ", "lat": 32.877252, "lon": -6.890532 },
  { "name": "csc bouchane", "lat": 32.2883084, "lon": -8.3195448 },
  { "name": "CSU ADZOUZ", "lat": 31.8899006, "lon": -7.3201669 },
  {
    "name": "CSU d'accochement Foum Lhisn",
    "lat": 29.012343225247346,
    "lon": -8.888085157077782
  },
  { "name": "CSR Amsekroud ", "lat": 30.526625, "lon": -9.3321841 },
  { "name": "CSR Amsekroud ", "lat": 30.526625, "lon": -9.3321841 },
  { "name": "CSR 1 KAMOUNE", "lat": 32.56663, "lon": -6.16494 },
  { "name": "CSR Ouneine", "lat": 30.867445, "lon": -8.184896 },
  {
    "name": "CSR2 AIT BOUGUEMAZ (TABANT )",
    "lat": 31.6586524,
    "lon": -6.4215746
  },
  { "name": "DR TISLIT", "lat": 32.015605, "lon": -6.828903 },
  { "name": "CS CHOARA", "lat": 32.0732655510424, "lon": -7.289363597313509 },
  { "name": "CS CHOUITER", "lat": 31.578407, "lon": -7.827061 },
  { "name": "CSR FREIJA", "lat": 30.506271, "lon": -8.721698 },
  {
    "name": "HOPITAL MILITAIRE RABAT Super congÚlarteur ",
    "lat": 33.3981773,
    "lon": -7.5713205
  },
  {
    "name": "CSR Ouled Aissa",
    "lat": 30.561495621294604,
    "lon": -8.613294251312267
  },
  { "name": "CSU 2 Beni Bouayach", "lat": 35.10298538, "lon": -3.85290026 },
  {
    "name": "CSR1 AIT BAKHOUCH (AIT MAZIGH)",
    "lat": 32.075907,
    "lon": -6.357497
  },
  { "name": "csu-1 rahal ben ahmed", "lat": 31.504099, "lon": -9.7696262 },
  {
    "name": "chambre froide de dÚlÚgation chichaoua  ",
    "lat": 31.5454811,
    "lon": -8.7636778
  },
  {
    "name": "Delegation provinciale chichaoua armoire 2",
    "lat": 31.546456,
    "lon": -8.761484
  },
  {
    "name": "Delegation provinciale chichaoua Armoire 1 ",
    "lat": 31.546458,
    "lon": -8.761486
  },
  { "name": "CSC AGHBALA", "lat": 32.473644, "lon": -5.644924 },
  { "name": "CSU DEMNAT KESBAH", "lat": 31.731821, "lon": -6.999422 },
  { "name": "DÚp¶t rÚgional de OUJDA ", "lat": 34.705375, "lon": -1.8834 },
  { "name": "DÚp¶t rÚgional de OUJDA", "lat": 34.703375, "lon": -1.8834 },
  { "name": "Delegation SRES", "lat": 33.5543239, "lon": -7.6025876 },
  { "name": "Delegation SRES", "lat": 33.5543239, "lon": -7.6003989 },
  { "name": "Delegation SRES", "lat": 33.5543239, "lon": -7.6025876 },
  { "name": "Delegation SRES", "lat": 33.5543239, "lon": -7.6003989 },
  { "name": "Delegation SRES", "lat": 0, "lon": 0 },
  {
    "name": "CENTRE DE DIAGNOSTIC DE LA TUBERCULOSE ET DES MALADIES RESPIRATOIRE",
    "lat": 33.5658807,
    "lon": -7.6096715
  },
  { "name": "Delegation SRES", "lat": 0, "lon": 0 },
  { "name": "CS ben ahmed dachra ar", "lat": 33.0621681, "lon": -7.2435761 },
  { "name": "Centre AIN BIEDA", "lat": 32.988796, "lon": -6.9713697 },
  {
    "name": "pharmacie prefectorale marrakech AR-4",
    "lat": 31.636769,
    "lon": -8.001053
  },
  {
    "name": "pharmacie prefectorale marrakech AR-4",
    "lat": 31.636766,
    "lon": -8.00105
  },
  { "name": "chp hassan 2", "lat": 32.53666, "lon": -6.54235 },
  {
    "name": "Pharmacie Provinciale Armoire Frigorifique C",
    "lat": 35.186420002144,
    "lon": -6.156438214
  },
  { "name": "Super congÚlateur N░2", "lat": 33.552694, "lon": -7.5567122 },
  { "name": "CSU1 SOUK SEBT", "lat": 0, "lon": 0 },
  { "name": "CS COMMUNAL OULED SAID L OUED", "lat": 0, "lon": 0 },
  {
    "name": "CENTRE HOSPITALIER DU CANCER",
    "lat": 27.13518191647138,
    "lon": -13.152362098147576
  },
  { "name": "csr 2 douirane", "lat": 31.213381, "lon": -8.779302 },
  { "name": "delegation tahannaout", "lat": 31.36045, "lon": -7.93432 },
  { "name": "delegation tahannaout", "lat": 31.360444, "lon": -7.934314 },
  { "name": "delegation tahannaout", "lat": 31.360448, "lon": -7.934319 },
  { "name": "delegation tahannaout", "lat": 31.360438, "lon": -7.9343012 },
  { "name": "Csr 1 Takerkoust", "lat": 31.3597267, "lon": -8.13478 },
  {
    "name": "CSR tangarfa",
    "lat": 29.374396619361637,
    "lon": -10.17162322998047
  },
  { "name": "CS ait idir", "lat": 31.4917021, "lon": -5.9530977 },
  { "name": "CSU Boulemane", "lat": 33.368618, "lon": -4.7307677 },
  {
    "name": "Centre de santÚ sidi bouzid",
    "lat": 33.2285919,
    "lon": -8.525569
  },
  { "name": "CSR MIRLEFT", "lat": 0, "lon": 0 },
  {
    "name": "H¶pital Princesse Lalla Hasna Chambre Froide A-4",
    "lat": 32.26081373461362,
    "lon": -8.530356649119463
  },
  { "name": "Douar chiban kciebiya", "lat": 34.39102, "lon": -6.068416 },
  {
    "name": "centre medical dhar lakbar",
    "lat": 34.29457747521626,
    "lon": -6.432840837573239
  },
  {
    "name": "Dispensaire douar riah qabliya",
    "lat": 34.55085,
    "lon": -6.422648
  },
  { "name": "DISPENSAIRE RURAL LAHFAYA", "lat": 34.768658, "lon": -6.155552 },
  { "name": "CS AMRSEID -2", "lat": 32.755015, "lon": -4.466067 },
  { "name": "CS IMETCHIMEN", "lat": 32.519383, "lon": -5.035381 },
  { "name": "DR Lmssaidira", "lat": 0, "lon": 0 },
  { "name": "CSR 1 OULED BOUAZZA", "lat": 32.294079, "lon": -6.661628 },
  { "name": "CS Ait Elman", "lat": 33.5164452, "lon": -4.3496199 },
  { "name": "CS Ait Elman", "lat": 33.5164452, "lon": -4.3496199 },
  { "name": "Cs tendite", "lat": 33.6643562, "lon": -3.6251006 },
  { "name": "CSU BIADA", "lat": 32.308119, "lon": -9.234678 },
  { "name": "CS URBAIN HAJRA", "lat": 32.601149, "lon": -6.27112 },
  {
    "name": "Cetnre de santÚ oulad hassoun",
    "lat": 32.356595,
    "lon": 7.938358
  },
  { "name": "CS AL FATH", "lat": 33.661728, "lon": -7.358494 },
  { "name": "CSU Taliouine", "lat": 0, "lon": 0 },
  {
    "name": "CS Ouhat sidi ibrahim",
    "lat": 31.595952823871336,
    "lon": -7.993734744983701
  },
  { "name": "CSU  avec UMP LAKHSSAS", "lat": 29.3661874, "lon": 9.7532157 },
  { "name": "Csu ait kadif", "lat": 30.927741, "lon": -6.882455 },
  { "name": "CSU 1 AL AOUDA", "lat": 27.1318866, "lon": -13.1681168 },
  { "name": "CS Les Cretes", "lat": 33.5420503, "lon": -7.6359982 },
  { "name": "Csu ait kadif", "lat": 30.927651, "lon": -6.882846 },
  { "name": "CSU SIDI DAOUD", "lat": 30.92294, "lon": -6.894029 },
  { "name": "CSU, douar chems 2", "lat": 30.9260883, "lon": -6.922555 },
  { "name": "Csu alwahda 2", "lat": 27.1368421, "lon": -13.2027166 },
  { "name": "CSU, douar chems 3", "lat": 30.9266767, "lon": -6.9231482 },
  {
    "name": "quartier administratif",
    "lat": 32.95080739985984,
    "lon": -5.728149712085725
  },
  {
    "name": "CHP HASSAN 2 DELEGATION",
    "lat": 32.89241679694734,
    "lon": -6.906029941319123
  },
  {
    "name": "CHP HASSAN 2 DELEGATION",
    "lat": 32.89241679694734,
    "lon": -6.906029941319123
  },
  {
    "name": "CHP HASSAN 2 DELEGATION",
    "lat": 32.89241679694734,
    "lon": -6.906029941319123
  },
  {
    "name": "CHP HASSAN 2 DELEGATION",
    "lat": 32.96873106335808,
    "lon": -6.939936032351883
  },
  { "name": "CS Moualin El Ouade", "lat": 33.447696, "lon": -7.3483469 },
  { "name": "CS Brikcha ", "lat": 0, "lon": 0 },
  {
    "name": " CS  Ain lahjar",
    "lat": 34.27487078111901,
    "lon": -2.8634585867984073
  },
  { "name": "CS mikkes", "lat": 0, "lon": 0 },
  {
    "name": "CS Ain lahjar",
    "lat": 34.43916448477287,
    "lon": -2.472210807586475
  },
  {
    "name": "CS laajajra ",
    "lat": 34.10953051327533,
    "lon": -5.170117142896821
  },
  { "name": "CSR 2 Nkoub 1", "lat": 30.867917, "lon": -5.8700958 },
  {
    "name": "CSR1 SGUATT",
    "lat": 31.950090784307505,
    "lon": -6.546392571452505
  },
  { "name": "CS tahjaouit", "lat": 33.10187, "lon": -5.3342 },
  { "name": "CSR Oum Elguerdane", "lat": 29.654991, "lon": -7.952969 },
  { "name": "point de lecture public", "lat": 27.13696, "lon": -13.183951 },
  {
    "name": "CS communal et maison d'accouchement TAOUARBAT",
    "lat": 32.141861,
    "lon": -5.352574
  },
  { "name": "CSR Mesti", "lat": 29.2630233, "lon": -10.1147237 },
  {
    "name": "MAISON D'ACCOUCHEMENT KETTARA",
    "lat": 31.866362446038185,
    "lon": -8.183337426069018
  },
  {
    "name": "CS Bab Titi",
    "lat": 34.050952698832965,
    "lon": -4.148756153948153
  },
  {
    "name": "Centre santÚ tamansourt B",
    "lat": 31.717783694891146,
    "lon": -8.00732011542047
  },
  { "name": "CSU TICHIBIT", "lat": 31.9483129, "lon": -6.5854622 },
  { "name": "CS AZLI", "lat": 0, "lon": 0 },
  { "name": "CS Madinat jadida", "lat": 27.1096357, "lon": -13.1715479 },
  { "name": "CS Bir Taour", "lat": 33.2807353, "lon": -7.4959604 },
  { "name": "pharmacie provincial", "lat": 0, "lon": 0 },
  { "name": "CSU el mers", "lat": 29.698756, "lon": -9.725442 },
  { "name": "CS Tarrast ", "lat": 3970.35132, "lon": -9.5534187 },
  {
    "name": "PP Inezgane",
    "lat": 30.353353244029197,
    "lon": -9.534962116394043
  },
  {
    "name": "PP Inezgane",
    "lat": 30.353547665716356,
    "lon": -9.534728764209747
  },
  {
    "name": "PP Inezgane",
    "lat": 30.353239831199954,
    "lon": -9.535165964279175
  },
  {
    "name": "commune ouled lgarn",
    "lat": 32.125616389766755,
    "lon": -7.3878173118826265
  },
  {
    "name": "CS Sebt Sahel",
    "lat": 33.24481321062535,
    "lon": -7.559643510084321
  },
  {
    "name": "CS Beni Hilal",
    "lat": 33.415967956844625,
    "lon": -7.494976622222946
  },
  { "name": "CS toutline", "lat": 0, "lon": 0 },
  { "name": "Centre de santÚ Aounat", "lat": 32.7418771, "lon": -8.1969716 },
  {
    "name": "CSU Imane Oubelouche Zag ",
    "lat": 28.028580664756966,
    "lon": -9.296245275463109
  },
  { "name": "Cs sidi boyahya", "lat": 32.7087625, "lon": -8.3131841 },
  { "name": "CSR-2 TOUAMA", "lat": 31.53177, "lon": -7.48567 },
  {
    "name": "Delegation provinciale chichaoua",
    "lat": 31.546478,
    "lon": -8.761504
  },
  {
    "name": "Delegation provinciale chichaoua",
    "lat": 31.546027408535316,
    "lon": -8.75879381895759
  },
  { "name": "dÚp¶t provincial TINGHIR", "lat": 31.5215017, "lon": -5.52954 },
  { "name": "dÚp¶t provincial TINGHIR", "lat": 31.5215683, "lon": -5.5293533 },
  {
    "name": "CSR 2 TALAT N'YACOUB",
    "lat": 30.989788882995597,
    "lon": -8.183261169311528
  },
  {
    "name": "DR Khmis Louta",
    "lat": 35.213636565209455,
    "lon": -4.994349746032718
  },
  {
    "name": "CS communal avec modele d'accouchement TELOUET  (IAM)",
    "lat": 31.2886978203521,
    "lon": -7.2431800111522335
  },
  { "name": "csc beni quolla", "lat": 34.7370071, "lon": -5.5447483 },
  {
    "name": "COMMUNE JOUALLA ",
    "lat": 32.11398546326384,
    "lon": -7.410213206688509
  },
  { "name": "CSR Oulad Cherki ", "lat": 32.2309647, "lon": -7.4472714 },
  { "name": "CS Ouhat sidi ibrahim", "lat": 0, "lon": 0 },
  {
    "name": "Merj Boutaib",
    "lat": 35.30840140169165,
    "lon": -5.820286541293212
  },
  {
    "name": "csc ouargui",
    "lat": 32.22519389809338,
    "lon": -7.385539888360738
  },
  { "name": "csc sidi aissa ", "lat": 0, "lon": 0 },
  { "name": "csc sahrij", "lat": 31.802413, "lon": -7.147618 },
  { "name": "dispensaire rural lahmadna", "lat": 0, "lon": 0 },
  {
    "name": " CSR1 SIDI AHMED CHERIF",
    "lat": 34.79858080633698,
    "lon": -5.5707572386027415
  },
  {
    "name": "pharmacie delegation ben m'sick",
    "lat": 33.544266,
    "lon": -7.5725
  },
  { "name": "H¶pital Mohammed 5 CF B-3", "lat": 0, "lon": 0 },
  {
    "name": "H¶pital Mohammed 5 CF B-3",
    "lat": 35.16875674189175,
    "lon": -5.26862870578859
  },
  {
    "name": "BSLS Nouaceur Chambre Froide (Reactif 2)",
    "lat": 33.396599,
    "lon": -7.57315
  },
  {
    "name": "BSLS Nouaceur Chambre Froide (Reactif 2)",
    "lat": 33.396599,
    "lon": -7.57315
  },
  {
    "name": "BSLS Nouaceur Chambre Froide (Reactif 1)",
    "lat": 33.396599,
    "lon": -7.57315
  },
  {
    "name": "BSLS Nouaceur Chambre Froide (Reactif 1)",
    "lat": 33.396599,
    "lon": -7.57315
  },
  {
    "name": "BSLS Nouaceur Chambre Froide (Reactif 5)",
    "lat": 33.398186,
    "lon": -7.573648
  },
  {
    "name": "H¶pital Mohammed 5 CF B-3",
    "lat": 35.16889705722005,
    "lon": -5.266913250815817
  },
  {
    "name": "BSLS Nouaceur Chambre Froide (Reactif 5)",
    "lat": 33.398186,
    "lon": -7.573648
  },
  {
    "name": "CSU Aouama Gharbia",
    "lat": 35.49534396410845,
    "lon": -6.021980521910162
  },
  { "name": "CSU Emsallah ", "lat": 35.77460898408, "lon": -5.81944614013353 },
  { "name": "Hopital hassan 2", "lat": 28.4743364, "lon": -11.143776 },
  {
    "name": "Centre de santÚ Lissasfa AR C-1",
    "lat": 33.534918,
    "lon": -7.669252
  },
  {
    "name": "CS LALA MIMOUNA",
    "lat": 33.950195282757015,
    "lon": -6.742365108403555
  },
  {
    "name": "BSLS Nouaceur Chambre Froide (Reactif 3)",
    "lat": 33.396874,
    "lon": -7.572947
  },
  { "name": "CSR 2 Bensmim", "lat": 33.504364, "lon": 5.188038 },
  { "name": "CS AIN MEDIOUNA", "lat": 34.4719276, "lon": -4.683559 },
  { "name": "DR Bab Larbaa", "lat": 34.0017703, "lon": -4.1045084 },
  { "name": "CSC Skoura", "lat": 0, "lon": 0 },
  {
    "name": "Super congÚlateur N░2 BSLS nouacer",
    "lat": 33.3981773,
    "lon": -7.5713205
  },
  {
    "name": "Super congÚlateur N░12 BSLS Nouacer",
    "lat": 33.39594,
    "lon": -7.57343
  },
  {
    "name": "H¶pital el mers niveau 2",
    "lat": 33.375647861088716,
    "lon": -4.742391370679017
  },
  {
    "name": "Hopital Moulay Ismail",
    "lat": 33.88865750124075,
    "lon": -5.559082031250001
  },
  { "name": "Hopital Moulay Ismail", "lat": 33.8918076, "lon": -5.5445585 },
  {
    "name": "dÚlÚgation fquih ben saleh ",
    "lat": 32.50165469728803,
    "lon": -6.691168498292528
  },
  {
    "name": "CSU Imane Oubelouche Zag ",
    "lat": 28.028593686674803,
    "lon": -9.296221135581975
  },
  { "name": "CS LKARIA", "lat": 32.6489196, "lon": -8.4232002 },
  { "name": "CS JABRIA", "lat": 32.562735, "lon": -8.31613 },
  {
    "name": "HOPITAL MOHAMMED 5",
    "lat": 33.215712251730736,
    "lon": -8.470450284157074
  },
  { "name": "HOPITAL MOHAMMED 5", "lat": 0, "lon": 0 },
  {
    "name": "SRES laayoune",
    "lat": 26.98735623682772,
    "lon": -13.018987330768356
  },
  {
    "name": "CS Laamour",
    "lat": 33.545727123362674,
    "lon": -7.346962226545767
  },
  {
    "name": "CENTRE DE DIAGNOSTIC DE LA TUBERCULOSE ET DES MALADIES RESPIRATOIRE ERRAHMA",
    "lat": 31.27855085894653,
    "lon": -6.808832074375358
  },
  {
    "name": "CSR  Bounaamane",
    "lat": 29.948588896549875,
    "lon": -9.668303867612442
  },
  { "name": "CSU KESBAH", "lat": 31.74031231097125, "lon": -7.00858997971685 },
  { "name": "cs riouni", "lat": 32.7574675, "lon": -8.4826182 },
  { "name": "Cs sidi bennour", "lat": 32.6500786, "lon": -8.4218351 },
  { "name": "Cs sidi bennour", "lat": 32.649089, "lon": -8.4218963 },
  { "name": "Cs sidi bennour", "lat": 32.649089, "lon": -8.4218963 },
  { "name": "DÚp¶t provincial tinghir", "lat": 31.5215017, "lon": -5.52954 },
  { "name": "DÚp¶t provincial tinghir", "lat": 31.5215017, "lon": -5.52954 },
  {
    "name": "DÚlÚgation Quartier Al Majd  ",
    "lat": 35.7433472,
    "lon": -5.799634
  },
  { "name": "CSU Mghogha ", "lat": 35.7478, "lon": -5.7732 },
  {
    "name": "CSR Taghramt ",
    "lat": 35.78935720697359,
    "lon": -5.468386198811341
  },
  {
    "name": "CSR 2 Khemiss Anjerah",
    "lat": 35.669460049686116,
    "lon": -5.514414694442757
  },
  { "name": "CS RURAL 1 KERROUCHEN", "lat": 32.798779, "lon": -5.322177 },
  {
    "name": "CSR Kasar Majaz ",
    "lat": 35.85026432455676,
    "lon": -5.545563847885138
  },
  { "name": "CS Tizgui -1", "lat": 31.578455, "lon": -5.584528 },
  {
    "name": "CS Melloussa ",
    "lat": 35.72911376933783,
    "lon": -5.629581449147955
  },
  { "name": "PP Tanger", "lat": 35.790428161, "lon": -5.82228851318 },
  { "name": "csr-1 sidi moussa", "lat": 31.537503, "lon": -7.968257 },
  { "name": "PP Tanger", "lat": 35.790272597956665, "lon": -5.822474926706533 },
  { "name": "PP Tanger", "lat": 35.79037485621526, "lon": -5.822525888677816 },
  { "name": "PP Tanger", "lat": 35.79051192558952, "lon": -5.822378367181997 },
  {
    "name": " DR sidi ali ben brahim",
    "lat": 32.199188232421875,
    "lon": -6.735280513763428
  },
  {
    "name": "DÚp¶t rÚgional de OUJDA",
    "lat": 34.70639484492788,
    "lon": -1.88543755092621
  },
  {
    "name": "Pharmacie prÚfectorale marrakech Armoire 14",
    "lat": 31.637598826058124,
    "lon": -8.003307444458002
  },
  { "name": "CSU-1 DAR AL BACHA", "lat": 31.631607, "lon": -7.991861 },
  {
    "name": "Pharmacie prÚfectorale marrakech Armoire 15",
    "lat": 31.637087301188657,
    "lon": -8.002277476196284
  },
  {
    "name": "maison de jeunesse mers lkhir",
    "lat": 33.865706,
    "lon": -6.928566
  },
  {
    "name": "Centre de SantÚ Urbain Sidi Allal Bahraoui - 2",
    "lat": 34.00325096371371,
    "lon": -6.526856393848424
  },
  {
    "name": "CSU Ouled Berhil",
    "lat": 30.636084464435346,
    "lon": -8.482998797622681
  },
  {
    "name": "Csu Dradeb RÚfrigÚrateur 1",
    "lat": 35.78763198,
    "lon": -5.83222866
  },
  { "name": "centre de sante souani", "lat": 34.053187, "lon": -6.813281 },
  { "name": "CSR Tahla", "lat": 34.0482502, "lon": -4.4261596 },
  {
    "name": "CSR Hakkama",
    "lat": 35.64957534524602,
    "lon": -5.687016909388316
  },
  { "name": "CSU AIN CHAIR", "lat": 32.207653, "lon": -2.518772 },
  { "name": "CSC Souk Had", "lat": 35.0275192, "lon": -5.4086551 },
  {
    "name": "vaccinodrome salle omnisport midelt ",
    "lat": 32.680874,
    "lon": -4.732788
  },
  { "name": "Cs oulad Boussaken", "lat": 0, "lon": 0 },
  { "name": "CHP MY YOUSSEF", "lat": 34.0103974, "lon": -6.8622554 },
  { "name": "CHP MY YOUSSEF", "lat": 34.0103974, "lon": -6.8622554 },
  { "name": "CHP MY YOUSSEF", "lat": 34.0103974, "lon": -6.8622554 },
  { "name": "Cs mtal", "lat": 32.498180028410765, "lon": -8.338623046875002 },
  { "name": "CSU METRANE", "lat": 0, "lon": 0 },
  { "name": "DP de Sidi Ifni", "lat": 29.3809597, "lon": -10.1746273 },
  {
    "name": "DP de Sidi Ifni",
    "lat": 29.358239465729348,
    "lon": -10.16922833714099
  },
  {
    "name": "CSU Ait Yazza",
    "lat": 30.500750980290693,
    "lon": -8.797096052951131
  },
  {
    "name": "cs oulad ghedbane ",
    "lat": 33.224903086263964,
    "lon": -8.525390625000002
  },
  { "name": "CS Ain sebaa", "lat": 33.6058828, "lon": -7.5495888 },
  { "name": "CS SIDI CHENNAN ", "lat": 0, "lon": 0 },
  { "name": "CS SIDI BOUZID", "lat": 33.2274879, "lon": -8.5521704 },
  { "name": "CS SIDI BOUZID", "lat": 33.2274879, "lon": -8.5521704 },
  {
    "name": "CSU Sidi Daoud ",
    "lat": 30.29612852511691,
    "lon": -9.455011322753906
  },
  { "name": "DP de Sidi Ifni", "lat": 29.3809597, "lon": -10.1746273 },
  { "name": "CSU Niveau 1 sidi ifni", "lat": 29.3745579, "lon": -10.1741264 },
  { "name": "Delegation SRES", "lat": 33.589508, "lon": -7.552338 },
  { "name": "Delegation SRES", "lat": 33.589508, "lon": -7.552338 },
  {
    "name": "CS Sidi Bettach",
    "lat": 33.57486831814041,
    "lon": -7.169664009444454
  },
  { "name": "CS Sidi Bettach", "lat": 33.5678906, "lon": -6.8916891 },
  {
    "name": "CSR Ida Ougnidif",
    "lat": 29.86187013062751,
    "lon": -9.008313439147956
  },
  {
    "name": "Delegation provinciale chichaoua armoire 1",
    "lat": 31.546476,
    "lon": -8.761502
  },
  {
    "name": "Delegation provinciale chichaoua armoire 1",
    "lat": 31.546482,
    "lon": -8.761508
  },
  {
    "name": "Delegation provinciale chichaoua armoire 3",
    "lat": 31.546484,
    "lon": -8.76151
  },
  {
    "name": "Delegation provinciale chichaoua armoire 3",
    "lat": 31.546474,
    "lon": -8.7615
  },
  { "name": "\t DÚp¶t rÚgional de OUJDA", "lat": 34.706375, "lon": -1.8834 },
  {
    "name": "DEPOT REGIONAL DES MEDICAMENTS A",
    "lat": 34.701939348889816,
    "lon": -1.8832938717756198
  },
  {
    "name": "DEPOT REGIONAL DES MEDICAMENTS A",
    "lat": 34.70109632262505,
    "lon": -1.882607896820061
  },
  {
    "name": "DEPOT REGIONAL DES MEDICAMENTS A",
    "lat": 34.701856,
    "lon": -1.883316
  },
  {
    "name": "Super congÚlateur N░3",
    "lat": 33.55296223481728,
    "lon": -7.556529809786983
  },
  {
    "name": "Super congÚlateur N░4",
    "lat": 33.56341824926748,
    "lon": -7.568725050262448
  },
  {
    "name": "Supercongelateur N 5",
    "lat": 33.56341824926748,
    "lon": -7.568725050262448
  },
  { "name": "Hopital bouskoura", "lat": 33.466164, "lon": -7.643297 },
  { "name": "DR OULED ZAAJ", "lat": 35.062565, "lon": -2.75945 },
  { "name": "CSU ZEGANGAN", "lat": 35.154976, "lon": -3.006459 },
  { "name": " CSU Beni Bouyafrour", "lat": 35.149052, "lon": -2.989111 },
  { "name": "CS Ksar Lbhar", "lat": 33.549507, "lon": -7.583471 },
  { "name": "CENTRE DE SANTE AKKARI", "lat": 34.007629, "lon": -6.862876 },
  { "name": "CS TAOUIMA", "lat": 35.124573, "lon": -2.929437 },
  { "name": "CS IKSSANE", "lat": 35.113946, "lon": -2.984806 },
  {
    "name": "CS D'ALBARKANYENE",
    "lat": 35.06427075962039,
    "lon": -2.6012698492126485
  },
  {
    "name": "Centre MÚdical de proximitÚ Sidi Moumen",
    "lat": 33.595089,
    "lon": -7.504688
  },
  {
    "name": "Centre de sante Lissasfa AR 5 (H-2)",
    "lat": 33.535019,
    "lon": -7.66926
  },
  { "name": "CS Sidi Al Makhfi", "lat": 33.360804, "lon": -5.3469677 },
  { "name": "CS Ahl Ghoulam", "lat": 33.5982622, "lon": -7.4887885 },
  { "name": "CSR Ait erkha ", "lat": 29.36065, "lon": -9.6202983 },
  { "name": "CSR Ait erkha ", "lat": 29.36065, "lon": -9.6202983 },
  { "name": "CS Derb Ghallef", "lat": 33.2406787, "lon": -8.4974168 },
  { "name": "CHP BOUARFA", "lat": 32.5257149, "lon": -1.9622742 },
  {
    "name": "CS WAHA PROVINCE",
    "lat": 33.608900856100234,
    "lon": -7.489242553710938
  },
  { "name": "DR LKHLAFENA", "lat": 32.478642, "lon": -7.20407 },
  {
    "name": "CSU Ouled Berhil",
    "lat": 30.635821371494607,
    "lon": -8.4827681276474
  },
  {
    "name": "CSR TAJJAJT",
    "lat": 29.35345166863502,
    "lon": -10.170593261718752
  },
  {
    "name": "CS sidi el mekki",
    "lat": 33.21169095802985,
    "lon": -7.715492248535157
  },
  { "name": "hopital med 6", "lat": 31.539421, "lon": -8.768471 },
  { "name": "hopital med 6", "lat": 31.539425, "lon": -8.768475 },
  { "name": "hopital med 6", "lat": 31.539423, "lon": -8.768473 },
  { "name": "hopital med 6", "lat": 31.539403, "lon": -8.768453 },
  { "name": "hopital med 6", "lat": 31.539399, "lon": -8.768449 },
  { "name": "hopital med 6", "lat": 31.539401, "lon": -8.768451 },
  { "name": "hopital med 6", "lat": 31.539405, "lon": -8.768455 },
  { "name": "CSR Kerrouchen", "lat": 32.798779, "lon": -5.322177 },
  { "name": "CSR1 BENI AYAT ", "lat": 32.216348, "lon": -6.584463 },
  { "name": "DP Essmara", "lat": 26.739413, "lon": -11.6633813 },
  { "name": "Cs Kassita", "lat": 34.8910812, "lon": -3.7279959 },
  { "name": "CSR TIGHIRT ", "lat": 29.3752, "lon": -9.454156 },
  { "name": "CSC sidi moussa", "lat": 32.273699, "lon": -7.232507 },
  {
    "name": "Cs hechlafa ahl sebt",
    "lat": 32.55144352864431,
    "lon": -8.410034179687502
  },
  {
    "name": "Cs sidi momen",
    "lat": 33.58186270742511,
    "lon": -7.545042869727457
  },
  {
    "name": "CSR ibder",
    "lat": 29.377164022720596,
    "lon": -10.169476954068376
  },
  { "name": "CSR 1 BOUGHRIBA", "lat": 34.872662, "lon": -2.502792 },
  { "name": "CS OUMKALTOUM", "lat": 33.574438, "lon": -7.5184414 },
  { "name": "CS Tilal", "lat": 33.7293522, "lon": -7.3311509 },
  { "name": "CS Tilal", "lat": 0, "lon": 0 },
  { "name": "Cs salam 1", "lat": 33.61690655353997, "lon": -7.486105987992445 },
  { "name": "cs sid bernoussi ", "lat": 33.6111993, "lon": -7.5132315 },
  { "name": "cs sidi bernoussi ", "lat": 0, "lon": 0 },
  { "name": "CSR Amellou", "lat": 29.29279, "lon": -10.0241954 },
  {
    "name": "cs ouled said",
    "lat": 33.00866349457558,
    "lon": -7.640991210937501
  },
  {
    "name": "Hopital Mohammed 5 (Armoire 5)",
    "lat": 33.251281980382664,
    "lon": -8.511066958283799
  },
  {
    "name": "CSU  Inezgane",
    "lat": 30.353197916198283,
    "lon": -9.535272585319511
  },
  { "name": "DR RAHOUANA", "lat": 35.233044, "lon": -3.035051 },
  { "name": " DR SIDI ABDERAHMANE", "lat": 34.4612503, "lon": -4.6930714 },
  {
    "name": "CS 11 janvier ",
    "lat": 33.60502481893267,
    "lon": -7.542761295569195
  },
  { "name": "CHU SOUISSI ENFANTS", "lat": 33.986617, "lon": -6.855341 },
  {
    "name": "CSR Argana",
    "lat": 30.759170036596757,
    "lon": -9.166373872354693
  },
  { "name": "CSR Argana", "lat": 30.75983384208294, "lon": -9.172534633193372 },
  { "name": "CS Elmoustakbal", "lat": 33.5293703, "lon": -7.6510398 },
  {
    "name": "CS Boulanoir",
    "lat": 34.521479263589804,
    "lon": -2.058224588303808
  },
  { "name": "CS Roche Noire", "lat": 33.5980043, "lon": -7.5792366 },
  { "name": "CSR2 AGOUDID", "lat": 31.9787075, "lon": -6.5454689 },
  { "name": "csu akiod", "lat": 0, "lon": 0 },
  { "name": "csu akiod", "lat": 0, "lon": 0 },
  { "name": "Cs Úcole koudiat", "lat": 33.5859769, "lon": -7.5620296 },
  { "name": "CSR Brakssa", "lat": 32.9509395, "lon": -6.4065943 },
  {
    "name": "DR bni khlaften",
    "lat": 34.279347024340446,
    "lon": -3.956751155618963
  },
  {
    "name": "Centre de santÚ El Karia",
    "lat": 33.02248191961359,
    "lon": -7.0855956180091475
  },
  {
    "name": "CSU hay tarik ",
    "lat": 34.03199768066406,
    "lon": -5.026745319366455
  },
  {
    "name": "CSU hay tarik ",
    "lat": 34.03158373196733,
    "lon": -5.023977716364404
  },
  {
    "name": "Csr isaguen",
    "lat": 35.02212433874885,
    "lon": -4.138529969254492
  },
  {
    "name": "CSU hay tarik 1",
    "lat": 34.03129577636719,
    "lon": -5.028174877166748
  },
  { "name": "Cs kridide", "lat": 32.4952098, "lon": -8.5767151 },
  { "name": "CS labrouj haut ", "lat": 32.5049813, "lon": -7.1884673 },
  {
    "name": "CSU Ras Aghil",
    "lat": 33.90742981034446,
    "lon": -5.5592017091755705
  },
  { "name": "CSR chatea abyad", "lat": 0, "lon": 0 },
  {
    "name": "CSU El Baladia",
    "lat": 33.895729064941406,
    "lon": -5.557989120483398
  },
  { "name": "CSR chatie abied", "lat": 0, "lon": 0 },
  { "name": "CS OUED LAHJAR", "lat": 31.6462596, "lon": -7.8484151 },
  { "name": "Cs labrouj haut", "lat": 32.5039733, "lon": -7.1910704 },
  { "name": "CSU Myssor", "lat": 33.04811583765997, "lon": -3.988149748973229 },
  { "name": "Cs Oualidia", "lat": 32.7315489, "lon": -9.0355319 },
  { "name": "Cs Oualidia", "lat": 32.7315489, "lon": -9.0355319 },
  {
    "name": "CSU TOUISSIT",
    "lat": 34.48008180798972,
    "lon": -1.7630915266875882
  },
  { "name": "CSR ANZAL", "lat": 30.758786, "lon": -7.288817 },
  { "name": "Cs lgharbia", "lat": 0, "lon": 0 },
  { "name": "CS MAROUA Chelalate", "lat": 33.6225896, "lon": -7.4428813 },
  { "name": "Cs zemamra", "lat": 32.627411, "lon": -8.704941 },
  {
    "name": "CSU hay tarik",
    "lat": 34.03414438926381,
    "lon": -5.025314691371888
  },
  { "name": "Cs zemamra", "lat": 32.627411, "lon": -8.704941 },
  { "name": "CSU Jebabra", "lat": 33.87009485678321, "lon": -5.5547809366512 },
  { "name": "CS el gara", "lat": 33.279163, "lon": -7.233247 },
  {
    "name": "CSU Lala Soukaina",
    "lat": 34.018856364106036,
    "lon": -5.0255392697784185
  },
  { "name": "CS SIDI moussa el mejdoub", "lat": 33.6095722, "lon": -7.3886312 },
  {
    "name": "CSU 1 tajamouti",
    "lat": 34.01877929509115,
    "lon": -5.048484891167235
  },
  { "name": "CS BAHMED", "lat": 33.614101, "lon": -7.352025 },
  {
    "name": "CSU Bensouda",
    "lat": 34.01495685485903,
    "lon": -5.053916120997813
  },
  {
    "name": "CSU Al Massira",
    "lat": 34.005818762727316,
    "lon": -5.048304556331372
  },
  { "name": "CS  AIT DAOUD", "lat": 31.4891115, "lon": -8.2001389 },
  {
    "name": "CSU Al Massira",
    "lat": 34.03445233183273,
    "lon": -4.988442086017817
  },
  { "name": "CSU EL OUATIA ", "lat": 0, "lon": 0 },
  {
    "name": "H¶pital midelt Armoire frigorifique E",
    "lat": 32.67709026230874,
    "lon": -4.739367289688114
  },
  {
    "name": "CS monfleuri ",
    "lat": 34.01100540161133,
    "lon": -4.983373165130615
  },
  {
    "name": "C/s al abrar",
    "lat": 33.468036355897375,
    "lon": -7.537888502313121
  },
  { "name": "CS AL ABRAR", "lat": 33.4681518, "lon": -7.5380818 },
  {
    "name": "CSU 1 Bit Ghoulem",
    "lat": 34.18340579323081,
    "lon": -3.868625092489131
  },
  {
    "name": "CSU Riyad Saiss",
    "lat": 34.015384674072266,
    "lon": -4.960081577301025
  },
  {
    "name": "C/S Riyad Saiss",
    "lat": 34.015384674072266,
    "lon": -4.960081577301025
  },
  {
    "name": "CSU  Adarissa",
    "lat": 34.017547607421875,
    "lon": -5.013519763946533
  },
  {
    "name": "CS Lmssalla ",
    "lat": 34.0521354675293,
    "lon": -4.962144374847412
  },
  {
    "name": "CS Lmssalla ",
    "lat": 34.04823455280502,
    "lon": -5.048065055161715
  },
  {
    "name": "CSU Ait-Melloul ",
    "lat": 30.34451088541919,
    "lon": -9.501322923942558
  },
  {
    "name": "CSU Ait-Melloul ",
    "lat": 30.344626623117957,
    "lon": -9.501166014715187
  },
  { "name": "chp hassan 2", "lat": 32.53866, "lon": -6.54235 },
  { "name": "Centre de santÚ sebt saiss", "lat": 32.782223, "lon": -8.625409 },
  { "name": "CS OULED DAOUD", "lat": 34.966076, "lon": -2.503532 },
  { "name": "Centre de santÚ sebt sais", "lat": 32.782223, "lon": -8.625409 },
  {
    "name": "CSR 1 LKRIFAT",
    "lat": 32.47866871797268,
    "lon": -6.729362485449795
  },
  { "name": "CS ben yakhlef", "lat": 33.6879555, "lon": -7.3408514 },
  { "name": "DR TAOURIRT", "lat": 35.345383, "lon": -3.00912 },
  { "name": "CSR Adis", "lat": 29.676223332341795, "lon": -7.98020768220901 },
  { "name": "CS Ennour", "lat": 33.536665, "lon": -7.5961733 },
  { "name": "CS mogress", "lat": 32.892696, "lon": -8.421263 },
  { "name": "CS Ennour", "lat": 33.536665, "lon": -7.5961733 },
  { "name": "CS BRADAA", "lat": 33.6975519, "lon": -7.3704401 },
  {
    "name": "DELEGATION DE LA SANT╔",
    "lat": 31.966776443862152,
    "lon": -6.567221302951002
  },
  {
    "name": "DELEGATION DE LA SANT╔",
    "lat": 31.96679464758876,
    "lon": -6.567238472603462
  },
  {
    "name": "Cs Beradaa ",
    "lat": 33.70352387381761,
    "lon": -7.378962135079143
  },
  { "name": "CSU d'ouled teima", "lat": 30.3944415, "lon": -9.2117252 },
  { "name": "CS  lekouassem", "lat": 0, "lon": 0 },
  {
    "name": "DELEGATION DE LA SANTE AZILAL",
    "lat": 31.96679919851985,
    "lon": -6.567211927301465
  },
  {
    "name": "CSR El Ganzra",
    "lat": 34.063222779535316,
    "lon": -5.937860469617364
  },
  { "name": "CSR SIDI HAJAJ", "lat": 33.5435843, "lon": -7.4126948 },
  { "name": "CSU 2 RAS EL MA", "lat": 35.13805, "lon": -2.423821 },
  { "name": "Depot SRES ref", "lat": 33.6892166, "lon": -7.3907623 },
  { "name": "Depot SRES AR4", "lat": 33.6892166, "lon": -7.3907623 },
  { "name": "Depot SRES AR4", "lat": 33.6892166, "lon": -7.3907623 },
  { "name": "Depot SRES AR3", "lat": 33.6892166, "lon": -7.3907623 },
  { "name": "Depot SRES AR1", "lat": 33.6892166, "lon": -7.3907623 },
  { "name": "Depot SRES AR1", "lat": 33.6892166, "lon": -7.3907623 },
  { "name": "Centre sante kasbah", "lat": 33.6980034, "lon": -7.390198 },
  {
    "name": "DELEGATION DE LA SANTE AZILAL",
    "lat": 31.966790096657437,
    "lon": -6.5672332004481495
  },
  { "name": "CS Farkhana", "lat": 35.276179, "lon": -2.965462 },
  {
    "name": "Centre de santÚ urbain Al alia",
    "lat": 33.6892602,
    "lon": -7.391092
  },
  {
    "name": "CSU Anza Al Oulia Taddart",
    "lat": 30.457412819500252,
    "lon": -9.637802732471458
  },
  { "name": "C/S ELNASR", "lat": 33.42185, "lon": -7.683154 },
  {
    "name": "chambre froide de dÚlÚgation chichaoua",
    "lat": 31.5454811,
    "lon": -8.7636778
  },
  {
    "name": "chambre froide de dÚlÚgation chichaoua",
    "lat": 31.5454811,
    "lon": -8.7636778
  },
  {
    "name": "Hopital Mohammed 5 (Armoire 5)",
    "lat": 33.2512817,
    "lon": -8.5110569
  },
  { "name": "Dar ATTADAMON", "lat": 34.643727, "lon": -1.972571 },
  { "name": "CS Dar kebdani", "lat": 35.118072, "lon": -3.335761 },
  { "name": "Centre sante kasbah", "lat": 33.6980096, "lon": -7.3902371 },
  { "name": "CSR oulad m'hammad", "lat": 0, "lon": 0 },
  {
    "name": "CSR Ain Bida",
    "lat": 33.98303521486157,
    "lon": -4.922693176812501
  },
  { "name": "centre santÚ rural niveau 1 oulad M'hammed", "lat": 0, "lon": 0 },
  { "name": "CSR Sidi Hrazem ", "lat": 34.02275, "lon": -4.881528 },
  { "name": "Cs oulad amrane", "lat": 32.3936201, "lon": -8.5183377 },
  { "name": "csc irohalen", "lat": 31.084977, "lon": -8.937026 },
  {
    "name": "DELEGATION PROVINCALE DE SANTE KENITRA",
    "lat": 34.24862,
    "lon": -6.57762
  },
  { "name": "CSR LEGHNADRA", "lat": 32.6300225, "lon": -8.6159756 },
  { "name": "Cs saniat berguigu", "lat": 32.7145286, "lon": -8.6217699 },
  { "name": "CSU 2 Had Soualem", "lat": 33.4231166, "lon": -7.8648192 },
  { "name": "CSU 2 Had Soualem", "lat": 33.4231138, "lon": -7.864814 },
  { "name": "CS WAHA PROVINCE", "lat": 33.5882416, "lon": -7.5255412 },
  {
    "name": "CSR2 OUAOUIZEGHT",
    "lat": 32.1587185571927,
    "lon": -6.353755245368957
  },
  { "name": "CS SIDI MOHAMMED AKHDIM", "lat": 32.7437883, "lon": -8.7281863 },
  { "name": "CS OULED DAOUD", "lat": 34.965076, "lon": -2.503532 },
  { "name": "CSU BNI DRAR", "lat": 34.8586552, "lon": -1.9926413 },
  {
    "name": "DR TANZARTE",
    "lat": 34.09474769880026,
    "lon": -2.8358401546688627
  },
  {
    "name": "DR Tanzarte",
    "lat": 34.09474769880026,
    "lon": -2.8088962250449083
  },
  { "name": "SRES Tata", "lat": 29.7511630625838, "lon": -7.9730107890243485 },
  { "name": "CSC Lahsasna", "lat": 33.173378, "lon": -7.616548 },
  { "name": "Hopital Moulay Ismail ", "lat": 33.8918076, "lon": -5.5445585 },
  {
    "name": "centre de sante urbain niveau 2 mechraa bel ksiri",
    "lat": 34.570355,
    "lon": -5.956465
  },
  { "name": "csu1  jamila", "lat": 32.505025, "lon": -6.695881 },
  { "name": "csr-2 abdel moumen", "lat": 31.265242, "lon": -8.937927 },
  { "name": "Delegation SRES", "lat": 33.589508, "lon": -7.552338 },
  { "name": "Delegation SRES", "lat": 33.58953, "lon": -7.552299 },
  {
    "name": "Pharmacie dÚlÚgation ouarzazate",
    "lat": 30.9303688,
    "lon": -6.9188447
  },
  {
    "name": "Pharmacie dÚlÚgation ouarzazate",
    "lat": 30.9303688,
    "lon": -6.9188447
  },
  {
    "name": "Pharmacie dÚlÚgation ouarzazate",
    "lat": 30.9303688,
    "lon": -6.9188447
  },
  { "name": "CS Ryad", "lat": 33.5942549, "lon": -7.5710122 },
  {
    "name": "CS et maison d'accouchement ITEZER",
    "lat": 32.877857,
    "lon": -5.049826
  },
  {
    "name": "Centre de santÚ urbain Al alia",
    "lat": 33.6874352,
    "lon": -7.3902073
  },
  {
    "name": "Centre de santÚ urbain Al alia",
    "lat": 33.6874352,
    "lon": -7.3902073
  },
  {
    "name": "Centre MÚdical de proximitÚ Sidi Moumen",
    "lat": 33.595093,
    "lon": -7.504629
  },
  {
    "name": "Centre MÚdical de proximitÚ Sidi Moumen",
    "lat": 33.594954,
    "lon": -7.504722
  },
  {
    "name": "Centre MÚdical de proximitÚ Sidi Moumen",
    "lat": 33.59552595319464,
    "lon": -7.502576232788091
  },
  {
    "name": "Centre MÚdical de proximitÚ Sidi Moumen",
    "lat": 33.594954,
    "lon": -7.504722
  },
  {
    "name": "PP  TÚtouane Armoire E",
    "lat": 35.57372436378352,
    "lon": -5.3547020116567685
  },
  { "name": "CSR-2 bouabout", "lat": 31.26709, "lon": -9.177801 },
  { "name": "CSU allal ben abdellah", "lat": 34.24376, "lon": -6.548408 },
  { "name": "CSC TIZI NESLI ", "lat": 32.466758, "lon": -5.77153 },
  { "name": "CSU SOUK LARBAE GHARB", "lat": 34.682175, "lon": -6.004103 },
  { "name": "C/S HAY SALAM", "lat": 34.673786, "lon": -5.98982 },
  { "name": "CSR1 OUED LAABID", "lat": 32.216312, "lon": -6.583943 },
  { "name": "CS Palmier", "lat": 33.613411, "lon": -7.532822 },
  { "name": "DÚlÚgation de la SantÚ Taroudant", "lat": 0, "lon": 0 },
  { "name": "DÚlÚgation de la SantÚ Taroudant", "lat": 0, "lon": 0 },
  {
    "name": "DÚlÚgation de la SantÚ Taroudant",
    "lat": 30.45459295698008,
    "lon": -8.989644380311228
  },
  {
    "name": "CS BNIKHLOUF",
    "lat": 32.621448512675016,
    "lon": -7.480481804454076
  },
  {
    "name": "CS BNIKHLOUF",
    "lat": 32.61161640317033,
    "lon": -7.482144396221075
  },
  { "name": "RSE Idaougmad", "lat": 30.701794, "lon": -8.242144 },
  { "name": "CSU Mhaita", "lat": 30.4748033, "lon": -8.8673764 },
  { "name": "DR Ouled ZAAJ", "lat": 35.0615705, "lon": -2.7603077 },
  {
    "name": "CENTRE DE SANTE COMMUNAL DAR HERAK",
    "lat": 34.962994,
    "lon": -6.127888
  },
  {
    "name": "MAISON DES JEUNES BOUHDILA",
    "lat": 34.92733583246595,
    "lon": -2.342756600312597
  },
  { "name": "MAISON DES JEUNES BOUHDILA", "lat": 34.928001, "lon": -2.34224 },
  { "name": "CS Boulemane", "lat": 33.3684196, "lon": -4.7289176 },
  {
    "name": "DELEGATION PROVINCALE DE SANTE KENITRA",
    "lat": 34.248636,
    "lon": -6.577613
  },
  { "name": "DR Tagnit", "lat": 33.3472685, "lon": -5.2933746 },
  { "name": "CENTRE KHENICHET", "lat": 34.444601, "lon": -5.681593 },
  {
    "name": "CSR2 AIT AATAB",
    "lat": 31.96414141636072,
    "lon": -6.57175464619044
  },
  { "name": "CSU Niveau 1 Riyad Kostani", "lat": 0, "lon": 0 },
  {
    "name": "CSU MISSOUR",
    "lat": 33.04761505126953,
    "lon": -3.9875218868255615
  },
  { "name": "CSU Niveau 1 BAB RHA", "lat": 33.8798677, "lon": -5.5687289 },
  { "name": "CS Ahl Ghoulam", "lat": 33.5985733, "lon": -7.4891478 },
  { "name": "DR  bir mrah", "lat": 0, "lon": 0 },
  { "name": "CS essalam 2", "lat": 33.582597, "lon": -7.482816 },
  {
    "name": "CS HAY HASSANI",
    "lat": 33.638473789166056,
    "lon": -7.47707224669529
  },
  { "name": "CSR Ait Aissa Addi", "lat": 33.8269081, "lon": -5.6823659 },
  {
    "name": "Hopital Sidi Mohamed Ben Abdellah",
    "lat": 31.5114382,
    "lon": -9.76483
  },
  {
    "name": "Hopital Sidi Mohamed Ben Abdellah",
    "lat": 31.5114376,
    "lon": -9.7648296
  },
  {
    "name": "Hopital Sidi Mohamed Ben Abdellah",
    "lat": 31.511438,
    "lon": -9.7648299
  },
  {
    "name": "CS HAY MOHAMMEDI",
    "lat": 33.633550960958935,
    "lon": -7.488797632723905
  },
  { "name": "hopital med 5", "lat": 32.287924, "lon": -9.237251 },
  { "name": "hopital med 5", "lat": 32.2877929, "lon": -9.23726 },
  { "name": "hopital med 5", "lat": 32.287932, "lon": -9.237262 },
  {
    "name": "hopital med 5 chambre froide B",
    "lat": 32.287927,
    "lon": -9.237258
  },
  {
    "name": "hopital med 5 chambre froide B",
    "lat": 32.287925,
    "lon": -9.237253
  },
  {
    "name": "hopital med 5 chambre froide B",
    "lat": 32.28793,
    "lon": -9.237262
  },
  {
    "name": "CSR2 ZAOUIT AHANSAL",
    "lat": 32.13634133006071,
    "lon": -6.515934710024338
  },
  { "name": "commune el kelaa(cs el hana)", "lat": 32.0586, "lon": -7.411122 },
  {
    "name": "CS Boulaaouane charika",
    "lat": 33.22949814144951,
    "lon": -8.357751332881483
  },
  { "name": "Cs koudiat saada", "lat": 33.5832793, "lon": -7.5563887 },
  {
    "name": "CS OUED ELHIMER",
    "lat": 34.46041299911099,
    "lon": -1.8983326942586065
  },
  { "name": "CS Bir Taour", "lat": 33.280708, "lon": -7.497285 },
  {
    "name": "Centre de SantÚ Urbain avec module d'accouchement Mediouna",
    "lat": 33.509193,
    "lon": -7.495116
  },
  {
    "name": "Centre de SantÚ Urbain avec module d'accouchement Mediouna",
    "lat": 33.4588428,
    "lon": -7.5174682
  },
  { "name": " H¶pital Moulay Hassan", "lat": 33.6794763, "lon": -5.3770942 },
  { "name": "H¶pital Moulay Hassan", "lat": 33.6794763, "lon": -5.3770942 },
  { "name": "CSR Imi Mqourn", "lat": 0, "lon": 0 },
  { "name": "Csr 1 amaghrass", "lat": 31.2106478, "lon": -8.1845404 },
  { "name": "Cs Mharza sahel", "lat": 0, "lon": 0 },
  {
    "name": "BSLS Nouaceur Chambre Froide (Pharma 3)",
    "lat": 33.396874,
    "lon": -7.572947
  },
  {
    "name": "BSLS Nouaceur Chambre Froide (Pharma 3)",
    "lat": 33.396874,
    "lon": -7.572947
  },
  { "name": "CSC MZOUDA ", "lat": 31.2901953, "lon": -8.595751 },
  { "name": "centre de santÚ mejjat", "lat": 31.353735, "lon": -8.510239 },
  {
    "name": "centre de santÚ mejjat",
    "lat": 31.358437773383738,
    "lon": -8.509700477882108
  },
  { "name": "CHP FQUIH BEN SALEH", "lat": 32.30455, "lon": -6.41028 },
  { "name": "CHP FQUIH BEN SALEH", "lat": 32.30355, "lon": -6.41028 },
  { "name": "CHP FQUIH BEN SALEH", "lat": 32.504295, "lon": -6.684111 },
  {
    "name": "Laboratoire De Diagnostic EpidÚmiologique Et D'hygiÞne Du Milieu ",
    "lat": 0,
    "lon": 0
  },
  {
    "name": "CS Zaouia saiss",
    "lat": 30.32403030029668,
    "lon": -5.83774369311524
  },
  { "name": "CS Sidi smail", "lat": 32.827591, "lon": -8.508939 },
  {
    "name": "Centre de santÚ urbain avec unitÚ d'accouchement BOUDNIB",
    "lat": 31.947649132467344,
    "lon": -3.601862796295159
  },
  { "name": "CS Hay Jamal fouarate", "lat": 33.5742701, "lon": -7.5767926 },
  {
    "name": "Csc Z.Sidi Abdel Kader",
    "lat": 34.88740945883824,
    "lon": -4.084898412816399
  },
  { "name": "CHU El Hajeb", "lat": 0, "lon": 0 },
  { "name": "CSU attaoune", "lat": 27.1496633, "lon": -13.2079722 },
  {
    "name": "Centre de santÚ AMRAOUI IBRAHIM",
    "lat": 31.613335,
    "lon": -5.129183
  },
  { "name": "CSR Chetouka", "lat": 0, "lon": 0 },
  { "name": "BSLS Nouaceur Chambre Froide (Pharma 3)", "lat": 0, "lon": 0 },
  { "name": "chp hassan 2", "lat": 32.53966, "lon": -6.54235 },
  { "name": "chp hassan 2", "lat": 32.53766, "lon": -6.54235 },
  { "name": "DR Tagourarte", "lat": 33.3551514, "lon": -8.2938772 },
  {
    "name": "Pharmacie provinciale ",
    "lat": 34.24132422972856,
    "lon": -6.600191049961865
  },
  {
    "name": "CSU Doukarat",
    "lat": 34.09588492955209,
    "lon": -4.987792968750001
  },
  {
    "name": "CSR-2 Assif El Mal",
    "lat": 31.229477973822522,
    "lon": -8.43981456578921
  },
  { "name": "Super congÚlateur N░ 1", "lat": 0, "lon": 0 },
  { "name": "Super congÚlateur N░7", "lat": 33.552962, "lon": -7.55653 },
  { "name": "CSR-2 Assif El Mal", "lat": 31.227198, "lon": -8.443178 },
  {
    "name": "CS rural-2 IMLIL Armoire frigorifique A",
    "lat": 32.156739996789845,
    "lon": -5.63152502923217
  },
  { "name": "CS Sidi Ibrahim", "lat": 34.029705, "lon": -4.9891539 },
  { "name": "csc  sidi bouzide ", "lat": 31.495455, "lon": -8.7902405 },
  { "name": "Dr Al hamri omar", "lat": 34.9853379, "lon": -4.395773 },
  {
    "name": "CSU Narjiss",
    "lat": 34.01981735229492,
    "lon": -4.978253364562988
  },
  { "name": "centre santÚ imintanoute", "lat": 31.177867, "lon": -8.847285 },
  { "name": "DÚlÚgation sres", "lat": 33.2616306, "lon": -7.5886451 },
  { "name": "CS Derb sidi Ohtmane", "lat": 33.5550128, "lon": -7.570632 },
  {
    "name": "dÚlÚgation sres",
    "lat": 33.26050844872995,
    "lon": -7.558698356605599
  },
  { "name": "pharmacie delegation ben m'sick", "lat": 0, "lon": 0 },
  { "name": "DELEGATION DE MOULAY RACHID", "lat": 33.558952, "lon": -7.573748 },
  {
    "name": "RÚfrigÚrateur cs chichaoua eq1 pharmacie",
    "lat": 31.58321506275729,
    "lon": -8.801608549211387
  },
  { "name": "Centre MÚdical de proximitÚ", "lat": 0, "lon": 0 },
  { "name": "C.s Chichaoua", "lat": 31.54224, "lon": -8.767308 },
  { "name": "DEPOT BEAUSEJOUR A(2)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(2)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(2)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(2)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(2)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(2)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(2)", "lat": 33.562134, "lon": -7.64786 },
  {
    "name": "CSR saada ",
    "lat": 31.629953838476258,
    "lon": -8.111182371035593
  },
  { "name": "DEPOT BEAUSEJOUR A(1)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(4)", "lat": 33.562046, "lon": -7.648339 },
  { "name": "DEPOT BEAUSEJOUR A(1)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(4)", "lat": 33.562046, "lon": -7.648339 },
  { "name": "DEPOT BEAUSEJOUR A(1)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(2)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(4)", "lat": 33.562046, "lon": -7.648339 },
  { "name": "DEPOT BEAUSEJOUR A(3)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(4)", "lat": 33.562046, "lon": -7.648339 },
  { "name": "DEPOT BEAUSEJOUR A(3)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(1)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(3)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(3)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(4)", "lat": 33.562046, "lon": -7.648339 },
  { "name": "DEPOT BEAUSEJOUR A(2)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(5)", "lat": 33.562306, "lon": -7.648583 },
  { "name": "DEPOT BEAUSEJOUR A(4)", "lat": 33.562046, "lon": -7.648339 },
  { "name": "DEPOT BEAUSEJOUR A(1)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(3)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(1)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(1)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(3)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(3)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(1)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(1)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(1)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(3)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(4)", "lat": 33.562046, "lon": -7.648339 },
  { "name": "DEPOT BEAUSEJOUR A(1)", "lat": 33.562134, "lon": -7.64786 },
  { "name": "DEPOT BEAUSEJOUR A(4)", "lat": 33.562046, "lon": -7.648339 },
  { "name": "DEPOT BEAUSEJOUR A(5)", "lat": 33.562306, "lon": -7.648583 },
  { "name": "DEPOT BEAUSEJOUR A(5)", "lat": 33.562306, "lon": -7.648583 },
  { "name": "DEPOT BEAUSEJOUR A(5)", "lat": 33.562306, "lon": -7.648583 },
  { "name": "CSR Sidi Dahmane", "lat": 0, "lon": 0 },
  {
    "name": "csu zemran charquia",
    "lat": 31.769915749874112,
    "lon": -7.372341156005859
  },
  { "name": "dispensaire sidi mokhtar ", "lat": 31.5807678, "lon": -9.0142169 },
  {
    "name": "SID LMOKHTAR",
    "lat": 32.32427558887655,
    "lon": -6.724240539657179
  },
  {
    "name": "SID LMOKHTAR",
    "lat": 31.580095236133097,
    "lon": -8.725206949669428
  },
  { "name": "CS Bni Makada", "lat": 35.75146865, "lon": -5.8126444 },
  { "name": "CS  IFEGH", "lat": 31.630304, "lon": -5.30987 },
  {
    "name": "CSU SOUK LARBAE GHARB",
    "lat": 34.6822624206543,
    "lon": -6.0040788650512695
  },
  {
    "name": "Centre tamansourt A",
    "lat": 31.716583196242627,
    "lon": -8.007469460453471
  },
  { "name": "DR DAR Hakoune", "lat": 33.808441162109375, "lon": -4.5374637 },
  { "name": "CSR2 ZAOUIT AHANSAL", "lat": 31.833855, "lon": -6.1062154 },
  {
    "name": "JERADA MDINA",
    "lat": 34.289084965418795,
    "lon": -2.165220487991759
  },
  {
    "name": "la pharmacie provinciale kÚnitra ",
    "lat": 34.2620816,
    "lon": -6.5743653
  },
  { "name": "CS al omrane", "lat": 33.5293468, "lon": -7.5226967 },
  { "name": "la pharmacie provinciale ", "lat": 34.2619572, "lon": -6.5744858 },
  { "name": "Centre de santÚ Al hamed", "lat": 0, "lon": 0 },
  { "name": "La pharmacie provinciale", "lat": 34.2619572, "lon": -6.5744858 },
  {
    "name": "Hopital med 6 pharmacie ",
    "lat": 31.53991946406062,
    "lon": -8.768792421612016
  },
  { "name": "CSR Bni Abdelah", "lat": 34.3035383, "lon": -4.4687773 },
  { "name": "CSU 25 Mars", "lat": 27.1659288, "lon": -13.2037766 },
  { "name": "DÚlÚgation Ifrane", "lat": 33.6540102, "lon": -5.0106493 },
  {
    "name": "CS Ouled Azzouz",
    "lat": 32.491230287947594,
    "lon": -7.632481115630454
  },
  {
    "name": "SRES Tata ",
    "lat": 29.750441174247115,
    "lon": -7.9716106759185745
  },
  { "name": "SRES Tata ", "lat": 29.75106857332809, "lon": -7.971463828033443 },
  { "name": "SRES Tata ", "lat": 0, "lon": 0 },
  {
    "name": "Hopital bouskoura",
    "lat": 33.243281858479484,
    "lon": -7.640828426877549
  },
  {
    "name": "CSC Tanakoub",
    "lat": 35.10657662858033,
    "lon": -5.457728291104502
  },
  { "name": "CS Ouled malek", "lat": 33.55505, "lon": -7.631732 },
  { "name": "CS moulay rchid Grp 4", "lat": 33.5675314, "lon": -7.5478725 },
  { "name": "CS moulay rchid Grp 4", "lat": 33.5675314, "lon": -7.5478725 },
  { "name": "CS moulay rchid Grp 6", "lat": 33.5699283, "lon": -7.5273854 },
  {
    "name": "CSU niveau 1 moulay rachid",
    "lat": 33.5699283,
    "lon": -7.5273854
  },
  { "name": "CS Ouled hamdan", "lat": 33.104108, "lon": -8.2519319 },
  { "name": "Hopital bouskoura", "lat": 33.466335, "lon": -7.643331 },
  {
    "name": "Centre Hospitalier Provincial",
    "lat": 35.57372850891917,
    "lon": -5.3547020116567685
  },
  {
    "name": "Centre Hospitalier Provincial",
    "lat": 35.573726654,
    "lon": -5.3547
  },
  { "name": "Dr KRAZZA", "lat": 32.246784, "lon": -6.571195 },
  { "name": "CSU Beaussite", "lat": 0, "lon": 0 },
  { "name": "Dr Inraren", "lat": 30.5532917, "lon": -9.5611867 },
  {
    "name": "CS OULED SALAH",
    "lat": 34.263855248919455,
    "lon": -3.2477057887485943
  },
  {
    "name": "DR Khmiss Al M'diq",
    "lat": 35.06450574790057,
    "lon": -5.036189635581976
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments ",
    "lat": 30.421106727101478,
    "lon": -9.592460749668128
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments ",
    "lat": 30.421114822397392,
    "lon": -9.592395035547263
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments ",
    "lat": 30.421002644665798,
    "lon": -9.592861739915854
  },
  {
    "name": "DÚp¶t RÚgional de MÚdicaments ",
    "lat": 30.421227,
    "lon": -9.592619
  },
  {
    "name": "DÚlÚgation de la SantÚ Agadir ",
    "lat": 30.42058018609107,
    "lon": -9.59065408731079
  },
  {
    "name": "DÚlÚgation de la SantÚ Agadir ",
    "lat": 30.420598689723427,
    "lon": -9.589452457672119
  },
  {
    "name": "DÚlÚgation de la SantÚ Agadir ",
    "lat": 30.41942370210661,
    "lon": -9.590782833343505
  },
  { "name": "CS Imouzer", "lat": 30.420987305837, "lon": -9.592716863754267 },
  {
    "name": "CSR Aghbalou",
    "lat": 30.02226865971386,
    "lon": -9.63474068220901
  },
  { "name": "CSR Aghbalou", "lat": 30.022408, "lon": -9.634738 },
  { "name": "H¶pital Biougra ", "lat": 30.214479, "lon": -9.377707 },
  {
    "name": "H¶pital Biougra ",
    "lat": 30.214562441651623,
    "lon": -9.377701635581975
  },
  {
    "name": "H¶pital Biougra ",
    "lat": 30.214720053467147,
    "lon": -9.377401228172307
  },
  {
    "name": "H¶pital Biougra ",
    "lat": 30.21441410088872,
    "lon": -9.377889390213017
  },
  { "name": "CSR Ouad Essafa", "lat": 30.255747, "lon": -9.421124 },
  { "name": "CSR Ouad Essafa", "lat": 30.255747, "lon": -9.421124 },
  { "name": "CHP TATA", "lat": 29.755108714817734, "lon": -7.972597202377318 },
  { "name": "CHP TATA", "lat": 29.755360200278723, "lon": -7.972637435512541 },
  { "name": "CHP TATA", "lat": 29.75487585734678, "lon": -7.972546240406035 },
  { "name": "CHP TATA", "lat": 29.754631356420052, "lon": -7.9725837913322435 },
  {
    "name": "SRES Tata ",
    "lat": 29.750845021155897,
    "lon": -7.971812515205379
  },
  {
    "name": "CSU Abdellah Chefchaouni",
    "lat": 29.69616264903844,
    "lon": -9.732872328697203
  },
  {
    "name": "CS Tata Avenue Mohammed 5",
    "lat": 29.754959686098644,
    "lon": -7.972961982803343
  },
  { "name": "CSC Allougoum", "lat": 30.260538, "lon": -6.845783 },
  { "name": "PP TIZNIT", "lat": 29.70739996823263, "lon": -9.741951305557254 },
  { "name": "PP TIZNIT", "lat": 29.707595661130313, "lon": -9.741634804893497 },
  { "name": "PP TIZNIT", "lat": 29.707469858597047, "lon": -9.741012532402506 },
  { "name": "PP TIZNIT", "lat": 29.707320759094156, "lon": -9.741371948410038 },
  {
    "name": "H¶pital Hassan I ",
    "lat": 29.705273485107277,
    "lon": -9.742844289688115
  },
  {
    "name": "H¶pital Hassan I ",
    "lat": 29.70549713883792,
    "lon": -9.74146026983643
  },
  {
    "name": "H¶pital Hassan I ",
    "lat": 29.70655948725659,
    "lon": -9.741181320098882
  },
  {
    "name": "H¶pital Hassan I ",
    "lat": 29.70682973199114,
    "lon": -9.742822832015996
  },
  { "name": "DR Douar Khriouiaa", "lat": 30.261959, "lon": -6.738196 },
  {
    "name": "CSR Tissinte",
    "lat": 29.89985952843425,
    "lon": -7.320651029430398
  },
  {
    "name": "CS Lakhmaiss ",
    "lat": 30.29647600684301,
    "lon": -9.467862431300164
  },
  {
    "name": "CS Lakhmaiss ",
    "lat": 30.296371791712577,
    "lon": -9.467862431300164
  },
  {
    "name": "CSR Tazeroualte",
    "lat": 29.501980132017056,
    "lon": -9.454958103179937
  },
  { "name": "PPTiznit", "lat": 29.707801130730026, "lon": -9.741592981475828 },
  { "name": "PP Tiznit", "lat": 29.704474306412568, "lon": -9.741592981475828 },
  { "name": "PP Tiznit", "lat": 29.705229141763784, "lon": -9.74054155554199 },
  { "name": "PP Tiznit", "lat": 29.70542483889194, "lon": -9.74403915609741 },
  { "name": "PP Tiznit", "lat": 29.707502932486708, "lon": -9.74328813757324 },
  { "name": "PP Tiznit", "lat": 29.70714882092264, "lon": -9.739758350509641 },
  {
    "name": "CSR Tighmi",
    "lat": 29.582129430169907,
    "lon": -9.402354804260256
  },
  {
    "name": "CSR Ouled Aissa",
    "lat": 30.560460911814744,
    "lon": -8.611298687805187
  },
  {
    "name": "CSR Ida Ougailal",
    "lat": 30.62909953603148,
    "lon": -8.636591922615045
  },
  { "name": "dÚlÚgation santÚ settat", "lat": 33.004596, "lon": -7.611264 },
  {
    "name": "CSR Irigh N'Tahala",
    "lat": 29.687904186960758,
    "lon": -9.016965222228997
  },
  {
    "name": "PP Inezgane",
    "lat": 30.353589327456184,
    "lon": -9.535200832996368
  },
  {
    "name": "PP  Inezgane",
    "lat": 30.353501374873506,
    "lon": -9.53539395204544
  },
  { "name": "CSR Temsia ", "lat": 30.3636056427244, "lon": -9.41277485400199 },
  {
    "name": "CSR Temsia ",
    "lat": 30.363491084627924,
    "lon": -9.412813746032706
  },
  {
    "name": "CSR Temsia ",
    "lat": 30.363760700944255,
    "lon": -9.412777536211005
  },
  { "name": "PP  Inzegane", "lat": 30.352732, "lon": -9.535965 },
  {
    "name": "PP  Inzegane",
    "lat": 30.353019005129468,
    "lon": -9.53618225893021
  },
  {
    "name": "PP  Inzegane",
    "lat": 30.353512003910925,
    "lon": -9.536238585319524
  },
  {
    "name": "PP  Inzegane",
    "lat": 30.35329212213219,
    "lon": -9.536174212303166
  },
  {
    "name": "PP  Inzegane",
    "lat": 30.352891704571103,
    "lon": -9.535675321426396
  },
  {
    "name": "PP  Inzegane",
    "lat": 30.353123160008572,
    "lon": -9.53572091897965
  },
  {
    "name": "CSU  Inezgane",
    "lat": 30.353491863590044,
    "lon": -9.535347687171928
  },
  { "name": "CHP TATA", "lat": 29.75471751395752, "lon": -7.972945889549254 },
  { "name": "DR  Lmssite", "lat": 29.631191, "lon": -9.957111 },
  { "name": "CSR Igli", "lat": 30.549971, "lon": -8.555586 },
  {
    "name": "DÚlÚgation de la SantÚ Taroudant",
    "lat": 30.475779836804815,
    "lon": -8.864798288360591
  },
  {
    "name": "DÚlÚgation de la SantÚ Taroudant",
    "lat": 30.475557918655312,
    "lon": -8.865112106815333
  },
  {
    "name": "DÚlÚgation de la SantÚ Taroudant",
    "lat": 30.475511685643824,
    "lon": -8.864724527612681
  },
  { "name": "CSR Magnoune", "lat": 30.328444, "lon": -8.391666 },
  {
    "name": "CSR Tafingoult",
    "lat": 30.765015323223924,
    "lon": -8.391078426914218
  },
  {
    "name": "PP Taroudant",
    "lat": 30.476093807731232,
    "lon": -8.863983783988363
  },
  {
    "name": "PP Taroudant",
    "lat": 30.476394320116725,
    "lon": -8.86343929555834
  },
  { "name": "PP Taroudant", "lat": 30.4760961193685, "lon": -8.8637557962221 },
  {
    "name": "PP Taroudant",
    "lat": 30.476711012934512,
    "lon": -8.86356535938204
  },
  {
    "name": "PP Taroudant",
    "lat": 30.476172403367784,
    "lon": -8.864042792586691
  },
  {
    "name": "PP Taroudant",
    "lat": 30.47648216202348,
    "lon": -8.862980637816793
  },
  {
    "name": "PP Taroudant",
    "lat": 30.476350399133693,
    "lon": -8.862854573993094
  },
  {
    "name": "PP Taroudant",
    "lat": 30.47623944077236,
    "lon": -8.863256905345327
  },
  {
    "name": "PP Taroudant",
    "lat": 30.47636195728897,
    "lon": -8.863967690734274
  },
  {
    "name": "PP Taroudant",
    "lat": 30.47625793384133,
    "lon": -8.864123258857138
  },
  {
    "name": "PP Taroudant",
    "lat": 30.474954026768497,
    "lon": -8.864996288360587
  },
  { "name": "RSE Idaougmad", "lat": 30.701794, "lon": -8.242144 },
  { "name": "DR la Marche Verte", "lat": 30.487263, "lon": -9.113737 },
  {
    "name": "PP Taroudant",
    "lat": 30.475254542671912,
    "lon": -8.864575181545248
  },
  {
    "name": "PP Taroudant",
    "lat": 30.475474149860872,
    "lon": -8.864832673610678
  },
  {
    "name": "PP Taroudant",
    "lat": 30.475395553660675,
    "lon": -8.864709291995993
  },
  { "name": "CS Tizgzaouine", "lat": 30.406916, "lon": -8.083405 },
  { "name": "CS TAOUYALT", "lat": 30.8121646, "lon": -8.4366652 },
  {
    "name": "PP Taroudant",
    "lat": 30.476042951697416,
    "lon": -8.862894807128317
  },
  {
    "name": "PP Taroudant",
    "lat": 30.476341152608327,
    "lon": -8.864217136172659
  },
  {
    "name": "PP Taroudant",
    "lat": 30.475587420743665,
    "lon": -8.864575181545248
  },
  { "name": "PP Taroudant", "lat": 30.4751065964969, "lon": -8.86479512268447 },
  { "name": "PP Biougra", "lat": 30.21600358208494, "lon": -9.377347685844414 },
  {
    "name": "PP Biougra",
    "lat": 30.215711540272594,
    "lon": -9.377583720237725
  },
  {
    "name": "CSU Ighreme",
    "lat": 30.08800260897531,
    "lon": -8.460333580356604
  },
  {
    "name": "PP Biougra",
    "lat": 30.216114835880692,
    "lon": -9.377932407409661
  },
  {
    "name": "CSU Ighreme",
    "lat": 30.088069912110523,
    "lon": -8.46007877050019
  },
  { "name": "CSR Igoudar", "lat": 30.615949019115302, "lon": -8.4494134834671 },
  { "name": "CSR Anzergue", "lat": 29.935394, "lon": -8.522377 },
  { "name": "CSC Tignatine (ADAR)", "lat": 30.1233692, "lon": -8.3807005 },
  {
    "name": "CSR Ait Hamed",
    "lat": 30.631301444071113,
    "lon": -8.402627991401667
  },
  {
    "name": "CSR Lamhara",
    "lat": 30.569631038212606,
    "lon": -8.487902807374963
  },
  { "name": "DR Oulad Mhalla", "lat": 30.423014, "lon": -8.961526 },
  { "name": "CSR Nihit", "lat": 30.246574, "lon": -8.477734 },
  { "name": "CSU Ettouaghil", "lat": 28.9767965, "lon": -10.0633486 },
  { "name": "Dr idsalem", "lat": 29.1898899, "lon": -9.5190319 },
  {
    "name": "CSR transmission de transfÞre de sang",
    "lat": 28.9685737,
    "lon": -10.0340577
  },
  {
    "name": "CSR  transmission de transfÞre de sang",
    "lat": 28.9685547,
    "lon": -10.0341757
  },
  { "name": "CSU Douar Elloh", "lat": 28.9721031, "lon": -10.0717659 },
  { "name": "DP de Sidi Ifni", "lat": 29.3809597, "lon": -10.1746273 },
  { "name": "DP de Sidi Ifni", "lat": 29.3809597, "lon": -10.1746273 },
  { "name": "CSU BOUIZAKARNE ", "lat": 29.1796283, "lon": -9.7124883 },
  {
    "name": "CSU Imane Oubelouche Zag ",
    "lat": 28.028593686674803,
    "lon": -9.296221135581975
  },
  { "name": "CSU HAY TIHOUNA", "lat": 28.6142883, "lon": -9.4250555 },
  { "name": "CSR ADY", "lat": 29.051825, "lon": -9.360292 },
  { "name": "CSC Taghjijt ", "lat": 29.0514431, "lon": -9.4307547 },
  { "name": "CS Ait abdelah sidi ifni", "lat": 29.3393838, "lon": -9.8831463 },
  { "name": "CS lahraouiine", "lat": 33.548949, "lon": -7.5360516 },
  { "name": "CSU niveau 1 Baladia", "lat": 33.5645343, "lon": -7.5726592 },
  { "name": "CS Hay sadri", "lat": 33.569458, "lon": -7.55198 },
  {
    "name": "CS Tisserghate ",
    "lat": 30.669315338134766,
    "lon": -6.348111629486084
  },
  {
    "name": "pharmacie delegation ben m'sick",
    "lat": 33.544334,
    "lon": -7.572363
  },
  {
    "name": "pharmacie delegation ben m'sick",
    "lat": 33.544441,
    "lon": -7.572357
  },
  { "name": "CS Nasr ", "lat": 33.536446, "lon": -7.549703 },
  {
    "name": "CSU mers sultan rue ibn khayran",
    "lat": 33.571022,
    "lon": -7.608718
  },
  { "name": "Pharmacie Hopital Med5 ", "lat": 33.59301, "lon": -7.55299 },
  { "name": "Pharmacie Hopital Med5 ", "lat": 33.59301, "lon": -7.55299 },
  { "name": "Pharmacie Hopital Med5 ", "lat": 33.59301, "lon": -7.55299 },
  { "name": "Pharmacie Hopital Med5 ", "lat": 33.59301, "lon": -7.55299 },
  { "name": "CS Derb Moulay Cherif", "lat": 33.594963, "lon": -7.566535 },
  { "name": "CSU Roche Noire", "lat": 33.598351, "lon": -7.579284 },
  { "name": "CS Gare", "lat": 33.591751, "lon": -7.591318 },
  { "name": "CS la villette", "lat": 33.588913, "lon": -7.5817714 },
  { "name": "CS Palmier", "lat": 33.613811, "lon": -7.532547 },
  { "name": "CS Derb Moulay Cherif", "lat": 33.593506, "lon": -7.564419 },
  { "name": "PP Benslimane", "lat": 33.613751, "lon": -7.126031 },
  { "name": "PP Benslimane", "lat": 33.613918, "lon": -7.126325 },
  { "name": "PP Benslimane", "lat": 33.613918, "lon": -7.126325 },
  { "name": "PP Benslimane", "lat": 33.614097, "lon": -7.126031 },
  { "name": "PP Benslimane", "lat": 33.613918, "lon": -7.126325 },
  { "name": "PP Benslimane", "lat": 33.613918, "lon": -7.126325 },
  { "name": "CS ben Chaqchaq", "lat": 33.7639853, "lon": -7.2642246 },
  {
    "name": "Hopital Mohammed 5 (Armoire 6)",
    "lat": 33.251282,
    "lon": -8.508868
  },
  {
    "name": "Hopital bowafi sidi maarouf",
    "lat": 33.521576484231176,
    "lon": -7.636157517337319
  },
  {
    "name": "Hopital bowafi sidi maarouf",
    "lat": 33.521558595442,
    "lon": -7.636157517337319
  },
  { "name": "CSC ELGOURAANI", "lat": 32.321607, "lon": -8.777736 },
  { "name": "CSR Ouled Brik", "lat": 33.221416, "lon": -7.621978 },
  { "name": "CSR Ouled Brik", "lat": 33.220634, "lon": -7.62058 },
  { "name": "CS MASSIRA 1", "lat": 33.930679, "lon": -6.898025 },
  { "name": "CS olad Abbou", "lat": 33.116604, "lon": -7.938091 },
  { "name": "CS olad Abbou", "lat": 33.116604, "lon": -7.938091 },
  { "name": "CSR Lghnmiyin", "lat": 33.19471, "lon": -7.867634 },
  { "name": "CSR Lghnmiyin", "lat": 33.19471, "lon": -7.867634 },
  { "name": "CSC Ghoulen", "lat": 33.437275, "lon": -7.916182 },
  { "name": "cs adasil", "lat": 31.113483, "lon": -8.4885937 },
  { "name": "CSU 2 Had Soualem", "lat": 33.42281, "lon": -7.862507 },
  { "name": "CSU 2 Had Soualem", "lat": 33.42281, "lon": -7.862507 },
  { "name": "CSU 2 Had Soualem", "lat": 33.423539, "lon": -7.862714 },
  { "name": "CSC Ghoulen", "lat": 33.437122, "lon": -7.916499 },
  { "name": "Hopital La Marche Verte", "lat": 33.0480957, "lon": -3.9968379 },
  { "name": "H¶pital La Marche Verte", "lat": 33.0480957, "lon": -3.9968379 },
  { "name": "DR Gaa Jaber", "lat": 33.008217, "lon": -3.996801 },
  { "name": "DR Taferdoust Skoura", "lat": 33.4841495, "lon": -4.6083772 },
  { "name": "CS almis marmoucha", "lat": 33.324879, "lon": -4.18404 },
  { "name": "DR Aaslouj", "lat": 33.178846, "lon": -4.7945673 },
  {
    "name": "CSU Massira Fnideq",
    "lat": 35.85345286732652,
    "lon": -5.354380244418035
  },
  { "name": " CS RYAD armoire", "lat": 33.6856168, "lon": -7.3836302 },
  { "name": "CS RYAD", "lat": 33.6856168, "lon": -7.3836302 },
  { "name": " CS RYAD armoire", "lat": 33.6860219, "lon": -7.3858463 },
  { "name": "CSR AHL ANGAD", "lat": 34.753708, "lon": -1.86185 },
  { "name": "Csr-1 kourimat", "lat": 31.4510012, "lon": -9.3370713 },
  { "name": "CSU 1 CHOUIBIR", "lat": 34.242611, "lon": -3.33788 },
  { "name": "H¶pital moulay hassan", "lat": 34.260708, "lon": -6.563235 },
  { "name": "DELEGATION SRES", "lat": 33.261349, "lon": -7.586554 },
  { "name": "DELEGATION SRES", "lat": 33.261349, "lon": -7.586554 },
  { "name": "DELEGATION SRES", "lat": 33.261349, "lon": -7.586554 },
  { "name": "DEPOT BEAUSEJOUR A(6)", "lat": 33.562306, "lon": -7.648583 },
  { "name": "DEPOT BEAUSEJOUR A(6)", "lat": 33.562306, "lon": -7.648583 },
  { "name": "DEPOT BEAUSEJOUR A(6)", "lat": 33.562306, "lon": -7.648583 },
  { "name": "DEPOT BEAUSEJOUR A(6)", "lat": 33.562306, "lon": -7.648583 },
  {
    "name": "H¶pital Princesse Lalla Hasna Chambre Froide A-4",
    "lat": 32.237476522018106,
    "lon": -8.51244773281861
  },
  {
    "name": "H¶pital Princesse Lalla Hasna Chambre Froide A-4",
    "lat": 32.238229737778255,
    "lon": -8.511321205032354
  },
  { "name": "C/S AIT HMAD", "lat": 32.416134, "lon": -3.773988 },
  { "name": "HOPITAL local mrirt", "lat": 33.162308, "lon": -5.560855 },
  {
    "name": "HOPITAL local mrirt",
    "lat": 33.16229452794418,
    "lon": -5.560825495700829
  },
  {
    "name": "HOPITAL local mrirt",
    "lat": 33.16228105588625,
    "lon": -5.560849635581963
  },
  { "name": "CS Mabrouka", "lat": 0, "lon": 0 },
  {
    "name": "CSR1 TIZI NOUBADOU (AIT BLAL)",
    "lat": 32.0121671,
    "lon": -6.7178884
  },
  { "name": "47685A10", "lat": 0, "lon": 0 },
  { "name": "CSR2 Ksar Ait Saadane", "lat": 0, "lon": 0 },
  { "name": "Centre riad rehamna", "lat": 32.2270405, "lon": -7.9490462 },
  { "name": "Centre riad rehamna", "lat": 32.2270405, "lon": -7.9490462 },
  { "name": "CS Laghdira", "lat": 0, "lon": 0 },
  { "name": "cs bouanane", "lat": 32.035759, "lon": -3.031491 },
  { "name": "CSU1 OULED HAMDAN", "lat": 32.32337593, "lon": -6.371611 },
  { "name": "CSU1 OULED HAMDAN", "lat": 32.32337593, "lon": -6.371611 },
  {
    "name": "CSC Oued Laou ",
    "lat": 35.496456056584165,
    "lon": -5.329432663150067
  },
  {
    "name": "SRES lAAYOUN",
    "lat": 27.130850631777538,
    "lon": -13.01487376308919
  },
  { "name": "CSR 2 Tabarrant", "lat": 34.71236, "lon": -4.519401 },
  { "name": "CS essalam", "lat": 34.6561325, "lon": -1.9289955 },
  { "name": "CHU El Hajeb", "lat": 33.6960099, "lon": -5.3719946 },
  { "name": "DISPENSAIRE RURAL SEFSAFAT", "lat": 34.185463, "lon": -3.534245 },
  { "name": "CHU SOUISSI", "lat": 33.984302, "lon": -6.849114 },
  { "name": "centre de sante bouitat", "lat": 33.986619, "lon": -6.879587 },
  { "name": "Centre santÚ Sidi fatah", "lat": 34.027094, "lon": -6.839589 },
  { "name": "Maison des jeunes Qbibat", "lat": 34.0129996, "lon": -6.8534696 },
  { "name": "CS almasjid", "lat": 34.003114, "lon": -6.869913 },
  { "name": "Centre de santÚ almohit", "lat": 34.020621, "lon": -6.848116 },
  { "name": "centre Al Massira 2", "lat": 33.9180603, "lon": -6.8932614326 },
  { "name": "CHU SOUISSI ENFANTS", "lat": 33.984051, "lon": -6.85095 },
  { "name": "CHU SOUISSI ENFANTS", "lat": 33.984051, "lon": -6.85095 },
  { "name": "CHU SOUISSI ENFANTS", "lat": 33.984051, "lon": -6.85095 },
  { "name": "CHU SOUISSI ENFANTS", "lat": 33.984051, "lon": -6.85095 },
  { "name": "CHU SOUISSI ENFANTS", "lat": 33.986617, "lon": -6.855341 },
  { "name": "CHU SOUISSI ENFANTS", "lat": 33.984051, "lon": -6.85095 },
  { "name": "CHR BENI MELLAL C2", "lat": 32.327731, "lon": -6.353681 },
  { "name": "DISPENSAIRE OULAD LAARBI", "lat": 34.379944, "lon": -6.498243 },
  { "name": "CHR BENI MELLAL C1", "lat": 32.328731, "lon": -6.353681 },
  {
    "name": "DELEGATION SRES",
    "lat": 32.7688004848817,
    "lon": -7.906257550132877
  },
  {
    "name": "Centre d'addictologie DÚp¶t vaccin",
    "lat": 34.247015,
    "lon": -6.5751611
  },
  {
    "name": "CSR TAFRANTE",
    "lat": 34.5683987056034,
    "lon": -4.688363503003155
  },
  { "name": "CSR 2 TAFRANTE", "lat": 0, "lon": 0 },
  { "name": "PP AIN AICHA DE TAOUNAT ", "lat": 0, "lon": 0 },
  {
    "name": "Centre ouled hmaid",
    "lat": 34.462032133857576,
    "lon": -4.679705129515835
  },
  { "name": "CS kissan", "lat": 34.45070852860144, "lon": -4.619575039815969 },
  { "name": "CSR SIDI EL MAKHFI", "lat": 0, "lon": 0 },
  { "name": "DR BENI BERBER", "lat": 0, "lon": 0 },
  {
    "name": "CS bab ouandir",
    "lat": 33.82175426105578,
    "lon": -3.0457096840284286
  },
  { "name": "CS Mesnana", "lat": 35.75395039824271, "lon": -5.854299505522544 },
  {
    "name": "C/S Beni Touzine Armoires Frigorifique A",
    "lat": 35.73886843585166,
    "lon": -5.848332652778625
  },
  {
    "name": "CSU Touilaa",
    "lat": 35.62158189955968,
    "lon": -5.388798295421454
  },
  { "name": "CSU el kalaa ref 2 ", "lat": 0, "lon": 0 },
  { "name": "CSU el kalaa", "lat": 0, "lon": 0 },
  { "name": "CSU diza", "lat": 0, "lon": 0 },
  {
    "name": "Centre de santÚ urbain MUSTAPHA MAANI",
    "lat": 31.91990606512273,
    "lon": -4.425513618385315
  },
  {
    "name": "CSC Tanakoub",
    "lat": 35.106642730982564,
    "lon": -5.4577604776126805
  },
  { "name": "H¶pital Mohammed 6", "lat": 35.691722, "lon": -5.329781 },
  { "name": "C/S Nfifa", "lat": 31.55220809512996, "lon": -8.766812663638099 },
  {
    "name": "PHARMACIE PROVINCIAL AALIA BAYDA",
    "lat": 33.570858,
    "lon": -7.478446
  },
  { "name": "Dispensaire imintanoute", "lat": 31.177867, "lon": -8.847285 },
  { "name": "DELEGATION SRES", "lat": 33.560051, "lon": -7.608086 },
  {
    "name": "Pharmacie Provinciale Armoire Frigorifique D",
    "lat": 35.186405425704024,
    "lon": -6.156423493458043
  },
  { "name": "CS BENI CHIKER", "lat": 35.278638, "lon": -3.014133 },
  { "name": "CS BENI CHIKER", "lat": 35.279638, "lon": -3.014133 },
  { "name": "CS FARKHANA", "lat": 35.286373, "lon": -2.981656 },
  { "name": "CS Bni Ouassine", "lat": 0, "lon": 0 },
  { "name": "SIDI RADAWAN", "lat": 34.6855147, "lon": -5.4467931 },
  { "name": "Csu kouacem", "lat": 0, "lon": 0 },
  { "name": "CSU LAATAMNA", "lat": 31.912615, "lon": -7.334962 },
  { "name": "CS Geznaya", "lat": 35.70735366517526, "lon": -5.903806705522545 },
  {
    "name": "CSU Sidi Slimane",
    "lat": 34.90833467672606,
    "lon": -2.3438762676389717
  },
  { "name": "CHU SOUISSI ENFANTS", "lat": 33.984051, "lon": -6.85095 },
  {
    "name": "PHARMACIE PROVINCIALE",
    "lat": 33.447484889088855,
    "lon": -7.5187812440030415
  },
  {
    "name": "Centre d'isolement covid 19",
    "lat": 34.7961235046,
    "lon": -5.567286491
  },
  { "name": "CSU IGHRI", "lat": 0, "lon": 0 },
  { "name": "cs bouanane", "lat": 32.0369678, "lon": -3.0491392 },
  { "name": "csu amzmiz", "lat": 31.218897, "lon": -8.233631 },
  { "name": "csu amzmiz", "lat": 31.218895, "lon": -8.233633 },
  {
    "name": "DÚlÚgation El KelaÔ des Sraghna",
    "lat": 32.0519068,
    "lon": -7.40418
  },
  {
    "name": "DÚlÚgation El KelaÔ des Sraghna",
    "lat": 32.0519068,
    "lon": -7.40418
  },
  {
    "name": "DÚlÚgation El KelaÔ des Sraghna",
    "lat": 32.0519068,
    "lon": -7.40418
  },
  {
    "name": "DÚlÚgation El KelaÔ des Sraghna",
    "lat": 32.0519068,
    "lon": -7.40418
  },
  { "name": "centre bni khlef", "lat": 32.9533303, "lon": -6.8240976 },
  { "name": "h¶pital moulay Hassan", "lat": 33.5165782, "lon": -7.8177082 },
  { "name": "h¶pital moulay Hassan", "lat": 0, "lon": 0 },
  {
    "name": "Centre MÚdical de proximitÚ",
    "lat": 34.0522741,
    "lon": -6.7977977
  },
  {
    "name": "Centre MÚdical de proximitÚ",
    "lat": 34.0522741,
    "lon": -6.7977977
  },
  {
    "name": "DELEGATION PROVINCALE DE SANTE KENITRA",
    "lat": 34.2486311,
    "lon": -6.5776111
  },
  { "name": "CSR SIDI BOUBKER", "lat": 31.831735, "lon": -7.736124 },
  { "name": "Csu 20 ao¹t", "lat": 27.1531997, "lon": -13.2029426 },
  { "name": "DIRECTION REGIONAL DE OUJDA", "lat": 34.680428, "lon": -1.92452 },
  { "name": "DIRECTION REGIONAL DE OUJDA", "lat": 34.680428, "lon": -1.92152 },
  { "name": "DIRECTION REGIONAL DE OUJDA", "lat": 34.680428, "lon": -1.92252 },
  { "name": "CSR2 FOUM JEMAA", "lat": 31.95967, "lon": -6.981146 },
  { "name": "CSU Dersa 1", "lat": 35.57561, "lon": -5.38539 },
  { "name": "CSU attaoune", "lat": 27.1532707, "lon": -13.2029934 },
  { "name": "Centre MÚdical de proximitÚ", "lat": 0, "lon": 0 },
  {
    "name": "Centre MÚdical de proximitÚ",
    "lat": 34.0519652,
    "lon": -6.7980456
  },
  { "name": "DELEGATION SRES ", "lat": 33.560051, "lon": -7.608086 },
  {
    "name": "Centre MÚdical de proximitÚ",
    "lat": 34.0519658,
    "lon": -6.7980452
  },
  {
    "name": "Csu slim Bachir ben Ammar n1",
    "lat": 27.1367594,
    "lon": -13.2005479
  },
  { "name": "CS Jbel Lhbib", "lat": 35.467961, "lon": -5.797779 },
  {
    "name": "Hopital bowafi sidi maarouf",
    "lat": 33.521558595442,
    "lon": -7.636157517337319
  },
  { "name": "CHU SOUISSI ENFANTS", "lat": 33.986617, "lon": -6.855341 },
  { "name": "CS Jirari", "lat": 0, "lon": 0 },
  { "name": "CSU EL MELLAH", "lat": 0, "lon": 0 },
  { "name": "CSU SIDI FRIG", "lat": 0, "lon": 0 },
  { "name": "chp hassan 2", "lat": 32.53766, "lon": -6.54235 },
  { "name": "CS laayayda", "lat": 0, "lon": 0 },
  {
    "name": "CS laayayda",
    "lat": 34.02003615823472,
    "lon": -6.745985840155354
  },
  { "name": "CS moulay thami", "lat": 34.796203, "lon": -5.567383 },
  { "name": "DR Bridia", "lat": 33.7890739, "lon": -5.5535336 },
  { "name": "CS Sidi Baba", "lat": 33.8809852, "lon": -5.6080603 },
  {
    "name": "CSR niveau 1 sidi Slimane Moul Lkifane",
    "lat": 33.8414307,
    "lon": -5.4671846
  },
  { "name": "DR Moussaoua", "lat": 34.0127373, "lon": -5.4635887 },
  { "name": "DR Tnin Taifa", "lat": 34.3883781, "lon": -4.0850535 },
  { "name": "PP Taza", "lat": 34.2173233, "lon": -4.0069132 },
  { "name": "PP Taza", "lat": 34.2170601, "lon": -4.0061684 },
  { "name": "PP Taza", "lat": 34.216869, "lon": -4.004391 },
  { "name": "H¶pital Moulay Hassan", "lat": 33.6794763, "lon": -5.3770942 },
  { "name": "CHU El Hajeb ", "lat": 33.6836186, "lon": -5.3839177 },
  { "name": "H¶pital 20 Aout ", "lat": 33.4378586, "lon": -5.2136874 },
  { "name": "H¶pital 20 Aout ", "lat": 33.4378586, "lon": -5.2136874 },
  { "name": "H¶pital 20 Aout ", "lat": 33.4378586, "lon": -5.2136874 },
  { "name": "CSR 2 Niveau Ain Leuh", "lat": 33.291832, "lon": -5.3404699 },
  { "name": "PP Moulay Yaakoub", "lat": 34.0620956, "lon": -4.9916363 },
  { "name": "PP Moulay Yaakoub", "lat": 34.0620956, "lon": -4.9916363 },
  { "name": "PP Moulay Yaakoub", "lat": 34.0620956, "lon": -4.9916363 },
  {
    "name": "Maison des Jeunes Oulad Mimoun",
    "lat": 34.2006111,
    "lon": -5.0516105
  },
  { "name": "HOPITAL Laarissa", "lat": 34.1192703, "lon": -5.07867 },
  { "name": "PP Moulay Yaakoub", "lat": 34.0620956, "lon": -4.9916363 },
  { "name": "DR BOUHRAZENE", "lat": 0, "lon": 0 },
  { "name": "CSU Aouama gharbia ", "lat": 35.725921, "lon": -5.803645 },
  { "name": "CSC moulay abdelkarim", "lat": 34.4828411, "lon": -5.245359 },
  { "name": "CS kissan", "lat": 34.5861282, "lon": -5.0615869 },
  { "name": "CS ain mediouna", "lat": 34.4719276, "lon": -4.683559 },
  { "name": "CSC OUTABOUABANE", "lat": 34.260732, "lon": -4.411543 },
  { "name": "CSR NIVEAU 2 BOUHOUDA", "lat": 34.5919762, "lon": -4.5525537 },
  { "name": "CS zmamra bni selmane", "lat": 34.4954338, "lon": -4.5084052 },
  { "name": "CS TAZODA", "lat": 34.5374025, "lon": -4.5322416 },
  { "name": "PP AIN AICHA", "lat": 34.462835, "lon": -4.690275 },
  { "name": "CSR 2 BNI OULID", "lat": 34.5921969, "lon": -4.4507897 },
  { "name": "CS AIN KHEMISS", "lat": 34.44751, "lon": -4.483678 },
  { "name": "CS zrarda", "lat": 33.7592107, "lon": -4.6279961 },
  { "name": "PP Sefrou", "lat": 33.8287926, "lon": -4.8304763 },
  { "name": "CSA bouzemlane", "lat": 34.0223, "lon": -4.3518 },
  { "name": "PP  Sefrou", "lat": 33.8287926, "lon": -4.8304763 },
  { "name": "CS COMMUNAL OULED YOUSSEF", "lat": 32.28329, "lon": -6.22394 },
  {
    "name": "CSU Hay Hassan ",
    "lat": 32.13840869677251,
    "lon": -6.609738945732835
  },
  { "name": "CSR Izammouren", "lat": 35.1842866, "lon": -3.9968979 },
  { "name": "CSU Jebha", "lat": 35.204049123561525, "lon": -4.665906402446744 },
  { "name": "CSR Dlallha", "lat": 34.844321, "lon": -6.222495 },
  { "name": "CSU 2 TISSA", "lat": 34.2892673, "lon": -4.6635977 },
  { "name": "CS Ben Abid", "lat": 33.4967975, "lon": -7.8683729 },
  { "name": "CSU SOUK LARBAE GHARB", "lat": 34.682316, "lon": -6.004271 },
  { "name": "H¶pital moulay youssef", "lat": 0, "lon": 0 },
  { "name": "H¶pital moulay youssef", "lat": 0, "lon": 0 },
  { "name": "H¶pital moulay youssef", "lat": 0, "lon": 0 },
  { "name": "H¶pital moulay youssef", "lat": 0, "lon": 0 },
  { "name": "H¶pital moulay youssef", "lat": 0, "lon": 0 },
  { "name": "C s sowani", "lat": 0, "lon": 0 },
  { "name": "csr-1 sidi ahmed", "lat": 32.2119711, "lon": -8.4937781 },
  {
    "name": "CS rural-2 IMLIL Armoire frigorifique A",
    "lat": 32.156383104721044,
    "lon": -5.631333084655756
  },
  {
    "name": "CENTRE DE SANTE GUETTAYA",
    "lat": 32.570323409907914,
    "lon": -6.3367310535977595
  },
  { "name": "Csr2 ouled mbarek", "lat": 32.27666, "lon": -6.424824 },
  {
    "name": "Centre De RÚfÚrences Des Maladies Chroniques",
    "lat": 34.0583266,
    "lon": -5.0210114
  },
  {
    "name": "Centre De RÚfÚrences Des Maladies Chroniques",
    "lat": 34.058502197265625,
    "lon": -5.020919322967529
  },
  {
    "name": "Centre De RÚfÚrences Des Maladies Chroniques",
    "lat": 34.058502197265625,
    "lon": -5.020919322967529
  },
  { "name": "CSR TIGNATINE", "lat": 30.1233862, "lon": -8.380393 },
  {
    "name": "PHARMACIE Provinciale de KENITRA",
    "lat": 34.25260517256066,
    "lon": -6.587848663330079
  },
  {
    "name": "PHARMACIE Provinciale de KENITRA",
    "lat": 34.20044475954112,
    "lon": -6.564588260581504
  },
  {
    "name": "Dr ihrissane",
    "lat": 31.21943037243621,
    "lon": -8.243918431877136
  },
  {
    "name": "HOPITAL EDDERRAK",
    "lat": 34.92422301690581,
    "lon": -2.32576559017494
  },
  {
    "name": "HOPITAL EDDERRAK",
    "lat": 34.92414824509968,
    "lon": -2.3260277346637164
  },
  {
    "name": "HOPITAL EDDERRAK",
    "lat": 34.924354966985774,
    "lon": -2.326248018212598
  },
  {
    "name": "DEPOT PROVINCIAL BERKANE",
    "lat": 34.92561527579646,
    "lon": -2.3252224745698307
  },
  {
    "name": "DEPOT PROVINCIAL BERKANE",
    "lat": 34.92530488358259,
    "lon": -2.325121316291477
  },
  {
    "name": "DEPOT PROVINCIAL BERKANE",
    "lat": 34.9256537607396,
    "lon": -2.325776301798788
  },
  { "name": "centre bibliothÞque", "lat": 33.01174, "lon": -7.6186383 },
  { "name": "CS CHOUITER", "lat": 31.5782805, "lon": -7.8278955 },
  { "name": "Cs Diyar Al mansour", "lat": 33.6658837, "lon": -7.4401361 },
  { "name": "Cs Diyar Al mansour", "lat": 33.6658837, "lon": -7.4401361 },
  {
    "name": "CS Ribat lkhir (ahermomou)",
    "lat": 33.8151741027832,
    "lon": -4.405305862426758
  },
  {
    "name": "Pharmacie prÚfectorale",
    "lat": 31.636813268851206,
    "lon": -8.001425874834055
  },
  {
    "name": "Pharmacie prÚfectorale",
    "lat": 31.63678586557305,
    "lon": -8.001912695770258
  },
  {
    "name": "Pharmacie prÚfectorale",
    "lat": 31.63686008276604,
    "lon": -8.001585466270441
  },
  {
    "name": "Pharmacie prÚfectorale",
    "lat": 31.63684523933217,
    "lon": -8.0015599852848
  },
  { "name": "Pharmacie prÚfectorale", "lat": 31.636795, "lon": -8.001505 },
  {
    "name": "Pharmacie prÚfectorale",
    "lat": 31.63675360962071,
    "lon": -8.001513381903166
  },
  { "name": "CS laarjat", "lat": 34.02629089355469, "lon": -6.653528690338135 },
  { "name": "CS GUERCIF", "lat": 34.326438, "lon": -3.368163 },
  {
    "name": "Complexe ideal bourgogne CF",
    "lat": 33.59481148652137,
    "lon": -7.644805294477459
  },
  {
    "name": "Complexe ideal bourgogne CF",
    "lat": 33.594804225360456,
    "lon": -7.644803953372952
  },
  {
    "name": "Complexe ideal bourgogne CF",
    "lat": 33.594726582247894,
    "lon": -7.6447035852111895
  },
  { "name": "DELEGATION SRES", "lat": 33.560154, "lon": -7.608125 },
  { "name": "DELEGATION SRES", "lat": 33.560154, "lon": -7.608125 },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.23828411728278,
    "lon": -3.92880329447747
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.23829917848025,
    "lon": -3.9288113411045145
  },
  {
    "name": "Pharmacie dÚlÚgation OUARZAZATE",
    "lat": 30.933381,
    "lon": -6.916488
  },
  { "name": "CSR Amtar", "lat": 35.240947259484436, "lon": -4.787486034393318 },
  { "name": "CSR Amtar", "lat": 35.24064056819824, "lon": -4.7872446355819775 },
  {
    "name": "Pharmacie dÚlÚgation OUARZAZATE",
    "lat": 30.932381,
    "lon": -6.916488
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.23829397552145,
    "lon": -3.928812346932895
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.23829589240105,
    "lon": -3.9288116763806413
  },
  {
    "name": "DÚlÚgation provincial driouch CHF A",
    "lat": 34.981194,
    "lon": -3.389422
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.238298356960456,
    "lon": -3.9288133527612756
  },
  { "name": "CSR Amtar", "lat": 35.24064056819824, "lon": -4.787008601188667 },
  {
    "name": "Hopital azemmour",
    "lat": 33.283198270368814,
    "lon": -8.357084416873464
  },
  { "name": "CS ALMASJID", "lat": 35.182465, "lon": -2.926809 },
  { "name": "CS ALMASJID", "lat": 35.183465, "lon": -2.926809 },
  {
    "name": "JERADA MDINA",
    "lat": 34.336066960553765,
    "lon": -2.148529920013214
  },
  {
    "name": "CSU Abou Kassem Zaheraoui",
    "lat": 34.79874083556912,
    "lon": -5.588622798776628
  },
  { "name": "CS ALMASJID", "lat": 35.186465, "lon": -2.926809 },
  { "name": "PP Tanger", "lat": 35.78875241212469, "lon": -5.819462700000006 },
  {
    "name": "Hopital azemmour",
    "lat": 33.28318537747766,
    "lon": -8.357076705522546
  },
  {
    "name": "PP Tetouane CF",
    "lat": 35.573722286755995,
    "lon": -5.354702682209022
  },
  { "name": "PP Tetouane CF", "lat": 35.57372665, "lon": -5.3547 },
  {
    "name": "PP Tetouane CF",
    "lat": 35.57372446837802,
    "lon": -5.354701341104515
  },
  {
    "name": "CHP DRIOUCH",
    "lat": 34.98920239644855,
    "lon": -3.368717465576172
  },
  { "name": "H¶pital militaire Mohamed 5", "lat": 33.97781, "lon": -6.890417 },
  {
    "name": "Pharmacie prÚfectorale Marrakech",
    "lat": 31.636051083332184,
    "lon": -8.001714107969295
  },
  { "name": "DELEGATION GUERCIF", "lat": 34.24955, "lon": -3.33788 },
  { "name": "DELEGATION GUERCIF", "lat": 34.24155, "lon": -3.33788 },
  { "name": "DELEGATION GUERCIF", "lat": 34.24155, "lon": -3.33788 },
  { "name": "DELEGATION GUERCIF", "lat": 34.24655, "lon": -3.33788 },
  {
    "name": "JERADA MDINA",
    "lat": 34.33294570925954,
    "lon": -2.1528133655042185
  },
  {
    "name": "JERADA MDINA",
    "lat": 34.332397509200355,
    "lon": -2.148676976974422
  },
  { "name": "CHU OUJDA", "lat": 34.65612080895127, "lon": -1.9114957754631123 },
  {
    "name": "centre de sante urbain souk sebt",
    "lat": 34.234416,
    "lon": -6.542766
  },
  {
    "name": "CSU Abou Kassem Zaheraoui",
    "lat": 34.79874193683282,
    "lon": -5.588625480985643
  },
  {
    "name": "CSU Abou Kassem Zaheraoui",
    "lat": 34.798743038096525,
    "lon": -5.588624139881135
  },
  {
    "name": "Pharmacie Provinciale",
    "lat": 35.1864586361025,
    "lon": -6.1564013411045115
  },
  { "name": "PP Tanger", "lat": 35.7887535, "lon": -5.8194627 },
  { "name": "PP Tanger", "lat": 35.788750780311695, "lon": -5.819463035276133 },
  { "name": "PP Tanger", "lat": 35.788748060623305, "lon": -5.819462029447752 },
  {
    "name": "H¶pital Mohammed 6",
    "lat": 35.69171628168965,
    "lon": -5.329781335276118
  },
  { "name": "hopitale mohammed 5 - chefchaouni (2)", "lat": 0, "lon": 0 },
  {
    "name": "hopitale mohammed 5 - chefchaouni",
    "lat": 35.16875672476207,
    "lon": -5.270614971490984
  },
  { "name": "hopitale mohammed 5 - chefchaouni (3)", "lat": 0, "lon": 0 },
  { "name": "dÚlÚgation fquih ben saleh", "lat": 32.508052, "lon": -6.696055 },
  { "name": "dÚlÚgation fquih ben saleh", "lat": 32.507052, "lon": -6.696055 },
  { "name": "dÚlÚgation fquih ben saleh", "lat": 32.506052, "lon": -6.696055 },
  { "name": "dÚlÚgation fquih ben saleh", "lat": 32.30882, "lon": -6.41458 },
  { "name": "CS dakhla", "lat": 33.0118889, "lon": -7.6198889 },
  { "name": "PP benslimane", "lat": 33.6140608, "lon": -7.1282804 },
  { "name": "PP benslimane", "lat": 33.6140608, "lon": -7.1282804 },
  { "name": "CS Farah", "lat": 33.9909938, "lon": -6.8073576 },
  {
    "name": "H¶pital Mohammed 5 (Armoire 4)",
    "lat": 33.2513361042721,
    "lon": -8.508909233135224
  },
  {
    "name": "H¶pital Mohammed 5 (Armoire 4)",
    "lat": 33.25127778469337,
    "lon": -8.508839495700837
  },
  { "name": "CS mejjatia", "lat": 33.4459872, "lon": -7.5008526 },
  {
    "name": "DÚlÚgation De La SantÚ Province NADOR ",
    "lat": 35.17683,
    "lon": -2.935911
  },
  {
    "name": "DÚlÚgation De La SantÚ Province NADOR ",
    "lat": 35.17693,
    "lon": -2.936544
  },
  {
    "name": "DÚlÚgation De La SantÚ Province NADOR ",
    "lat": 35.176799,
    "lon": -2.935857
  },
  {
    "name": "DÚlÚgation De La SantÚ Province NADOR",
    "lat": 35.17993,
    "lon": -2.936544
  },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  { "name": "CSU OULED HELAL", "lat": 30.1407522, "lon": -6.8515909 },
  { "name": "Csu sidi yahya", "lat": 34.3063774, "lon": -6.3063445 },
  { "name": "Csu sidi yahya", "lat": 34.3063774, "lon": -6.3063445 },
  { "name": "CS Amraoui Ibrahim", "lat": 31.713335, "lon": -5.229183 },
  {
    "name": "CSU MUSTAPHA MAANI",
    "lat": 31.919785403815734,
    "lon": -4.425677233135223
  },
  { "name": "Cs el aioun", "lat": 34.58416044224686, "lon": -2.50571392635254 },
  { "name": "CSR Moussa", "lat": 0, "lon": 0 },
  { "name": "CS urbain-2 AMEZROU -1", "lat": 30.30979, "lon": -5.822808 },
  {
    "name": "CSU JERADA MEDINA",
    "lat": 34.33469401621776,
    "lon": -2.1913294369298812
  },
  { "name": "CS LALA MIMOUNA", "lat": 34.847875, "lon": -6.070223 },
  { "name": "DR AIT OUAHLILI", "lat": 31.574633, "lon": -7.481059 },
  {
    "name": "Cs kalaa megouna",
    "lat": 31.2393856048584,
    "lon": -6.1248626708984375
  },
  { "name": "centre el brachoua", "lat": 33.690667, "lon": -6.632098 },
  { "name": "Csu-1 Attaouia", "lat": 31.8347867, "lon": -7.3135634 },
  { "name": "CS SIDI YAHYA ", "lat": 0, "lon": 0 },
  { "name": "H¶pital La Marche Verte ", "lat": 33.0480957, "lon": -3.9968379 },
  { "name": "H¶pital La Marche Verte ", "lat": 33.0480957, "lon": -3.9968379 },
  { "name": "H¶pital La Marche Verte ", "lat": 33.0480957, "lon": -3.9968379 },
  { "name": "H¶pital La Marche Verte ", "lat": 33.0480957, "lon": -3.9968379 },
  { "name": "H¶pital La Marche Verte ", "lat": 33.0480957, "lon": -3.9968379 },
  { "name": "H¶pital La Marche Verte ", "lat": 33.0480957, "lon": -3.9968379 },
  { "name": "csu ait ourir", "lat": 31.564606, "lon": -7.663097 },
  {
    "name": "Centre de SantÚ Urbain Maamoura ",
    "lat": 33.835312,
    "lon": -6.073492
  },
  {
    "name": "CSR IDA OUAZA  ",
    "lat": 31.147290956313935,
    "lon": -9.752136468887329
  },
  {
    "name": "CENTRE SANITAIRE EL FEKHKHARA",
    "lat": 31.636562,
    "lon": -7.976982
  },
  { "name": "CSC mohamed zerktouni", "lat": 31.9262323, "lon": -4.4423819 },
  { "name": "DÚlÚgation Ifrane", "lat": 33.6540102, "lon": -5.0106493 },
  { "name": "csr ouled amer", "lat": 0, "lon": 0 },
  { "name": "CS LAMZAM", "lat": 0, "lon": 0 },
  { "name": "CS Ouled Saleh", "lat": 33.3850399, "lon": -7.6884806 },
  { "name": "CS MASSIRA 1", "lat": 33.940679, "lon": -6.8108025 },
  { "name": "CS labrouj ar", "lat": 32.50494, "lon": -7.188422 },
  { "name": "CS BAZZAZA", "lat": 32.441265, "lon": -6.378449 },
  { "name": "CS labrouj", "lat": 32.50494, "lon": -7.188422 },
  { "name": "CS DAR BOUAZZA", "lat": 33.518395, "lon": -7.814352 },
  { "name": "CS BAZZAZA", "lat": 32.441265, "lon": -6.378449 },
  {
    "name": "CS Lissasfa",
    "lat": 33.53494936242746,
    "lon": -7.669300198552258
  },
  {
    "name": "CS biranzarane ( tinghir )",
    "lat": 31.495819091796875,
    "lon": -5.534651279449463
  },
  { "name": "CSU 1 OULAD NSSAR", "lat": 34.183763, "lon": -6.67952 },
  { "name": "CS almassira", "lat": 33.97309494, "lon": -6.8890619277 },
  {
    "name": "CSC Guisser Armoire",
    "lat": 32.77228676995293,
    "lon": -7.510638825725557
  },
  { "name": "CS saada el jadida", "lat": 33.24324, "lon": -8.530827 },
  { "name": "CSR Chetouka", "lat": 33.313232, "lon": -8.162499 },
  { "name": "CS inbiaat", "lat": 33.99332, "lon": -6.809585 },
  { "name": "CS HAY ALMATAR", "lat": 33.231098, "lon": -8.525569 },
  { "name": "CS Chaibaat", "lat": 32.995035, "lon": -8.320041 },
  { "name": "CS IDEAL BOURGOGNE", "lat": 33.594837, "lon": -7.645012 },
  { "name": "CS laayayda", "lat": 34.028021, "lon": -6.713512 },
  { "name": "CS IBNU NAFISS", "lat": 33.5745222, "lon": -7.6878355 },
  { "name": "CS hay salam 1", "lat": 34.016861, "lon": -6.767917 },
  {
    "name": "CSU niveau 2 mechraa bel ksiri",
    "lat": 34.570255,
    "lon": -5.848053
  },
  { "name": "CS said hajji", "lat": 34.0310947, "lon": -6.8110613 },
  { "name": "CS IBNU NAFISS", "lat": 33.5745222, "lon": -7.6878355 },
  { "name": "CS el melk", "lat": 33.988884, "lon": -6.874331 },
  {
    "name": "CS ain beni mathar",
    "lat": 34.040765659268295,
    "lon": -1.6945335215369763
  },
  { "name": "CS haj lebzar", "lat": 34.250486, "lon": -6.548256 },
  { "name": "CSU souk sebt", "lat": 34.234416, "lon": -6.542766 },
  { "name": "CS hay riad", "lat": 33.960939, "lon": -6.87933 },
  { "name": "CS boumeriem", "lat": 32.67979, "lon": -3.787414 },
  {
    "name": "CSC avec module d'accouchement Ighrem nougdal A",
    "lat": 31.2303333,
    "lon": -7.4214144
  },
  { "name": "CS El Yassmine", "lat": 33.831375, "lon": -6.086522 },
  { "name": "CSR Debdou", "lat": 34.024826, "lon": -3.037353 },
  { "name": "CS TENDRARA", "lat": 33.05499, "lon": -2.002464 },
  { "name": "CS TENDRARA", "lat": 33.05399, "lon": -2.002464 },
  { "name": "cs talsint", "lat": 32.53548, "lon": -3.447681 },
  { "name": "CS figuig", "lat": 32.109829, "lon": -1.23126 },
  { "name": "CS tagiutont", "lat": 32.289509, "lon": -3.465256 },
  { "name": "CS ALBARKANYENE", "lat": 34.242611, "lon": -3.33788 },
  { "name": "CSR Debdou", "lat": 34.02055772891953, "lon": -3.037996730163577 },
  { "name": "PP taourirt", "lat": 34.41517, "lon": -2.891104 },
  { "name": "PP taourirt", "lat": 34.41317, "lon": -2.892104 },
  { "name": "CS EL AIOUN SIDI MELLOUK ", "lat": 34.58511, "lon": -2.505945 },
  {
    "name": "CSR Debdou",
    "lat": 34.02397236295635,
    "lon": -3.0357865899353054
  },
  { "name": "CS MECHRAA HAMMADI", "lat": 34.731099, "lon": -2.798406 },
  {
    "name": "CS EL AIOUN SIDI MELLOUK",
    "lat": 34.58496646560662,
    "lon": -2.5058082073402366
  },
  { "name": "CHU OUJDA ", "lat": 34.655903, "lon": -1.910604 },
  { "name": "DÚp¶t rÚgional de OUJDA", "lat": 34.707375, "lon": -1.8834 },
  { "name": "CS MAKCEM", "lat": 34.672352, "lon": -1.909933 },
  { "name": "CS BOUDIR", "lat": 34.69442, "lon": -1.899312 },
  {
    "name": "PP D'El Hajeb",
    "lat": 33.68921536838173,
    "lon": -5.378484936523438
  },
  {
    "name": "PP D'El Hajeb",
    "lat": 33.68921536838173,
    "lon": -5.378484936523438
  },
  {
    "name": "DIRECTION REGIONAL DE L'ORIENTAL ",
    "lat": 34.679794,
    "lon": -1.921522
  },
  {
    "name": "DIRECTION REGIONAL DE L'ORIENTAL ",
    "lat": 34.678794,
    "lon": -1.921522
  },
  {
    "name": "Centre de santÚU AL JORF",
    "lat": 31.491359087562685,
    "lon": -4.400784829360968
  },
  { "name": "CS ARKMAN", "lat": 35.103272, "lon": -2.741471 },
  { "name": "CS HASSI BERKANE", "lat": 34.745244, "lon": -2.990213 },
  { "name": "CS TIZTOUTINE", "lat": 34.972878, "lon": -3.154909 },
  { "name": "CS ARKMAN", "lat": 35.102272, "lon": -2.741471 },
  { "name": "CS TIZTOUTINE", "lat": 34.974878, "lon": -3.154909 },
  { "name": "CS HASSI BERKANE", "lat": 34.744244, "lon": -2.990213 },
  { "name": "CS BENI ENSAR", "lat": 35.266276, "lon": -2.933827 },
  { "name": "CS BENI SIDEL LOUTA ", "lat": 35.11142, "lon": -3.068782 },
  { "name": "CS IHADADEN", "lat": 35.164757, "lon": -2.966349 },
  { "name": "CS ALMASJID ", "lat": 35.181465, "lon": -2.926809 },
  { "name": "CS D'ALBARKANYENE", "lat": 35.06406, "lon": -2.600433 },
  { "name": "CS IDOUDOUHEN", "lat": 35.298477, "lon": -2.960429 },
  { "name": "HOPITAL DE PROXIMITE ZAIO ", "lat": 34.943361, "lon": -2.745968 },
  { "name": "HOPITAL DE PROXIMITE ZAIO ", "lat": 34.943161, "lon": -2.745968 },
  { "name": "CS AL MASJID AF A", "lat": 35.183465, "lon": -2.927809 },
  { "name": "Pharmacie de l'h¶pital", "lat": 0, "lon": 0 },
  { "name": "Pharmacie de l'h¶pital", "lat": 0, "lon": 0 },
  {
    "name": "Pharmacie de l'h¶pital",
    "lat": 32.32953969999999,
    "lon": -6.355347999999992
  },
  {
    "name": "Merj Boutaib",
    "lat": 35.45841598510612,
    "lon": -6.036189079284553
  },
  {
    "name": "CSU Aouama Gharbia AR",
    "lat": 35.7288527295648,
    "lon": -5.803315734673113
  },
  { "name": "H¶pital Bir Chifa ", "lat": 35.7433, "lon": -5.8236813 },
  {
    "name": "H¶pital Hay Jadid ",
    "lat": 35.76529799294588,
    "lon": -5.826966498182304
  },
  { "name": "CSU Dradeb", "lat": 35.78762953224598, "lon": -5.832228324723864 },
  {
    "name": "H¶pital Mohammed 6 ",
    "lat": 35.69171274178303,
    "lon": -5.329782005828371
  },
  {
    "name": "H¶pital Mohammed 6 ",
    "lat": 35.69178063019295,
    "lon": -5.329499617143236
  },
  {
    "name": "H¶pital Mohammed 6 ",
    "lat": 35.69172091079807,
    "lon": -5.329781335276118
  },
  {
    "name": "H¶pital Mohammed 6 ",
    "lat": 35.691717643192135,
    "lon": -5.329782341104498
  },
  {
    "name": "H¶pital Mohammed 6 ",
    "lat": 35.69178498699736,
    "lon": -5.329500958247744
  },
  { "name": "CSR Bni Harchane", "lat": 35.52217483, "lon": -5.633245944 },
  {
    "name": "PP Tetouane armoire D",
    "lat": 35.57369327566399,
    "lon": -5.354717434358604
  },
  {
    "name": "PP Tetouane armoire F",
    "lat": 35.57369872972091,
    "lon": -5.3547261515379025
  },
  { "name": "CS Colema ", "lat": 35.5645408630102, "lon": -5.349265098570221 },
  {
    "name": "DÚlÚgation Quartier Al Majd",
    "lat": 35.7433472,
    "lon": -5.799634
  },
  {
    "name": "CSC Kessar Sghir ",
    "lat": 35.827716987501375,
    "lon": -5.56396440582839
  },
  { "name": "CSU BIR ANZARAN -2", "lat": 31.50085, "lon": -5.531969 },
  {
    "name": "CSC avec module d'accouchement Ighrem nougdal A",
    "lat": 31.233635925205103,
    "lon": -7.422358537573253
  },
  { "name": "CSU TASSOUMAATE", "lat": 30.9224707, "lon": -6.9328934 },
  { "name": "Centre de santÚ N'khila", "lat": 32.955278, "lon": -7.070167 },
  { "name": "Csr 1 Ezzaouit", "lat": 31.3814536, "lon": -9.4165113 },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.932797,
    "lon": -5.671093
  },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.967637,
    "lon": -5.661609
  },
  { "name": "Csr Boufkrane", "lat": 33.7591864, "lon": -5.4842581 },
  { "name": "CS sgamna", "lat": 0, "lon": 0 },
  { "name": "CS Sidi hajaj Ar", "lat": 32.915146, "lon": -7.260401 },
  { "name": "CS Sidi hajaj ar", "lat": 32.915146, "lon": -7.260401 },
  { "name": "CS  BNIKHLOUG", "lat": 32.64288, "lon": -7.382632 },
  { "name": "CSU AL BAHRI", "lat": 35.179745, "lon": -2.923962 },
  {
    "name": "CSU A AL AROUIT",
    "lat": 35.02999636902566,
    "lon": -2.9336177034649684
  },
  {
    "name": "CSU A AL AROUIT",
    "lat": 35.152851730033916,
    "lon": -2.948378293700154
  },
  { "name": "CSU 1 LAARI CHEIKH", "lat": 35.1723368, "lon": -2.9294112 },
  { "name": "CSU 1 LAARI CHEIKH", "lat": 35.17083, "lon": -2.928364 },
  { "name": "CSU 1 LAARI CHEIKH", "lat": 35.17183, "lon": -2.928364 },
  { "name": "CSU Barraca", "lat": 35.170742, "lon": -2.939241 },
  { "name": "CS AL MASJID AF A", "lat": 35.183465, "lon": -2.926809 },
  { "name": "CSR 1 BNI OUKIL", "lat": 34.999557, "lon": -3.047897 },
  { "name": "CSR 1 BNI OUKIL", "lat": 34.998557, "lon": -3.047897 },
  { "name": "C/S TIZTOUTINE", "lat": 34.973878, "lon": -3.154909 },
  {
    "name": "CSU Mghogha AR",
    "lat": 35.74779809523765,
    "lon": -5.7731993294477375
  },
  {
    "name": "CSU Mghogha AR",
    "lat": 35.7478217687092,
    "lon": -5.773293206763259
  },
  {
    "name": "CSU Aouama Gharbia AR",
    "lat": 35.72880373829052,
    "lon": -5.803229233432383
  },
  { "name": "cs hay hassani", "lat": 0, "lon": 0 },
  { "name": "CSU 2 hay Hassani", "lat": 33.290134, "lon": -7.574344 },
  { "name": "CSU Tanja Balia", "lat": 35.7682024, "lon": -5.7721124 },
  { "name": "CSU Tanja Balia", "lat": 0, "lon": 0 },
  { "name": "CS ouled bennacer", "lat": 33.9295515, "lon": -6.9013595 },
  { "name": "DELEGATION DE SANTE SALE", "lat": 34.0360318, "lon": -6.8153462 },
  { "name": "DELEGATION DE SANTE SALE", "lat": 34.036229, "lon": -6.812854 },
  { "name": "DELEGATION DE SANTE SALE", "lat": 34.036305, "lon": -6.812746 },
  {
    "name": "CSR-1 Taouzint",
    "lat": 32.097345210061796,
    "lon": -7.288645505905152
  },
  { "name": "CENTRE MEDICAL ALKARIA", "lat": 34.0379525, "lon": -6.8244973 },
  { "name": "CS Boudnib", "lat": 0, "lon": 0 },
  { "name": "CSR 1 Mejjat", "lat": 0, "lon": 0 },
  { "name": "Jerada Ibn Rochd", "lat": 34.316025, "lon": -2.159576 },
  { "name": "Jerada Ibn Rochd", "lat": 34.317025, "lon": -2.159576 },
  { "name": "CSU Avec UnitÚ D'accouchement BOUDNIB", "lat": 0, "lon": 0 },
  { "name": "cs bni karich", "lat": 0, "lon": 0 },
  { "name": "CS ELKARIA", "lat": 0, "lon": 0 },
  { "name": "CS Derb Ghallef", "lat": 33.2406787, "lon": -8.4974168 },
  { "name": "CS AMAL", "lat": 33.51477816622265, "lon": -7.783798063894958 },
  {
    "name": "Cs hay el massira",
    "lat": 23.68524742126465,
    "lon": -15.94776439666748
  },
  { "name": "Hopital bouskoura", "lat": 33.466335, "lon": -7.643331 },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  { "name": "Centre SRES", "lat": 33.261776, "lon": -7.5864 },
  { "name": "PP AZILAL", "lat": 31.965599, "lon": -6.567479 },
  { "name": "PP AZILAL", "lat": 31.962599, "lon": -6.567479 },
  {
    "name": "pharmacie prefectorale marrakech AR-12",
    "lat": 31.63674,
    "lon": -8.001083
  },
  {
    "name": "pharmacie prefectorale marrakech AR-12",
    "lat": 31.63675,
    "lon": -8.001085
  },
  {
    "name": "pharmacie prefectorale marrakech AR-12",
    "lat": 31.63677,
    "lon": -8.001087
  },
  {
    "name": "pharmacie prefectorale marrakech AR-10",
    "lat": 31.63699,
    "lon": -8.001079
  },
  {
    "name": "pharmacie prefectorale marrakech AR-10",
    "lat": 31.63679,
    "lon": -8.001089
  },
  {
    "name": "pharmacie prefectorale marrakech Ar-6",
    "lat": 31.63682,
    "lon": -8.001064
  },
  {
    "name": "pharmacie prefectorale marrakech Ar-6",
    "lat": 31.6368,
    "lon": -8.001062
  },
  {
    "name": "pharmacie prefectorale marrakech AR-7",
    "lat": 31.63687,
    "lon": -8.001069
  },
  {
    "name": "pharmacie prefectorale marrakech AR-7",
    "lat": 31.63686,
    "lon": -8.001068
  },
  {
    "name": "pharmacie prefectorale marrakech",
    "lat": 31.636778,
    "lon": -8.00106
  },
  {
    "name": "pharmacie prefectorale marrakech",
    "lat": 31.63674,
    "lon": -8.001058
  },
  {
    "name": "pharmacie prefectorale marrakech AR 8",
    "lat": 31.6369,
    "lon": -8.001073
  },
  {
    "name": "pharmacie prefectorale marrakech AR 8",
    "lat": 31.63689,
    "lon": -8.001071
  },
  {
    "name": "CSU 20 AOUT GOULMIMA ",
    "lat": 31.940775334391823,
    "lon": -4.392900422693183
  },
  { "name": "CSU AHADAF", "lat": 33.4424552917, "lon": -5.2233281135 },
  {
    "name": "pharmacie prefectorale marrakech AR-9",
    "lat": 31.63697,
    "lon": -8.001077
  },
  {
    "name": "pharmacie prefectorale marrakech AR-9",
    "lat": 31.636795,
    "lon": -8.001075
  },
  { "name": "centre santÚ El adarissa", "lat": 0, "lon": 0 },
  { "name": "DÚlÚgation Settat", "lat": 33.0047529, "lon": -7.6141364 },
  { "name": "DÚlÚgation Settat", "lat": 33.0047529, "lon": -7.6141364 },
  { "name": "CS N1 tahe", "lat": 27.6733736, "lon": -12.9562818 },
  { "name": "Dr Sidi bouabbad", "lat": 32.9325846, "lon": -6.1288538 },
  { "name": "DR ighoud", "lat": 33.3303436, "lon": -5.3451524 },
  { "name": "ouled taima", "lat": 30.3945811, "lon": -9.2124702 },
  { "name": "DELEGATION GUERCIF", "lat": 34.24255, "lon": -3.33788 },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.937797,
    "lon": -5.671093
  },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.938797,
    "lon": -5.671093
  },
  { "name": "DR Ouled Driss", "lat": 0, "lon": 0 },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.56741,
    "lon": -5.401501
  },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.56841,
    "lon": -5.401501
  },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.56941,
    "lon": -5.670837
  },
  {
    "name": "csr rachida",
    "lat": 0.7591636365194359,
    "lon": 3.690612044332609
  },
  { "name": "CS mejjatia", "lat": 33.4459872, "lon": -7.5008526 },
  { "name": "D ouled ali touallõa", "lat": 33.4589231, "lon": -7.1935803 },
  { "name": "Centre de santÚ lemsaada", "lat": 33.5503479, "lon": -7.4689864 },
  {
    "name": "DELEGATION DE SANTE SIDI SLIMANE",
    "lat": 34.26831,
    "lon": -5.925174
  },
  { "name": "cs mers sultan fida ", "lat": 0, "lon": 0 },
  { "name": "CSU mers sultan rue ibn khayran", "lat": 0, "lon": 0 },
  { "name": "CS Mers sultan", "lat": 0, "lon": 0 },
  {
    "name": "CSU mers sultan rue ibn khayran",
    "lat": 33.5713827,
    "lon": -7.6090052
  },
  { "name": "CSR1 Jdour", "lat": 32.111112, "lon": -8.782184 },
  { "name": "SRES Tata ", "lat": 0, "lon": 0 },
  { "name": "SRES Tata", "lat": 29.815228137702178, "lon": -7.969297135334806 },
  {
    "name": "commune el kelaa(csu alqods)",
    "lat": 32.060319,
    "lon": -7.378497
  },
  { "name": "CS Nouvelle mÚdina", "lat": 0, "lon": 0 },
  { "name": "CS Nouvelle mÚdina", "lat": 0, "lon": 0 },
  { "name": "PHARMACIE PROVINCIALE ", "lat": 31.966599, "lon": -6.567479 },
  {
    "name": "commune el kelaa(csu alqods)",
    "lat": 32.060102,
    "lon": -7.378409
  },
  {
    "name": "DELEGATION DU MINISTERE DE LA SANTE SETTAT ARMOIRE A",
    "lat": 33.00621742678152,
    "lon": -7.6095097202377255
  },
  { "name": "Csr 2 abdellah ghiat", "lat": 31.5189817, "lon": -7.85064 },
  {
    "name": "MAISON D ACCOUCHEMENT DAR OULD ZIDOUH",
    "lat": 32.50074,
    "lon": -6.677337
  },
  {
    "name": "CSU AVEC MODULE D'ACCOUCHEMENT",
    "lat": 33.4558214,
    "lon": -7.520339
  },
  {
    "name": "CSU AVEC MODULE D'ACCOUCHEMENT",
    "lat": 33.4558214,
    "lon": -7.520339
  },
  {
    "name": "CSR 1 Beni layeth",
    "lat": 35.594599560510744,
    "lon": -5.355148315429688
  },
  { "name": "CS lahraouiine2", "lat": 33.5401847, "lon": -7.5348101 },
  { "name": "CS bni bounssar", "lat": 34.8440973, "lon": -4.4226704 },
  { "name": "csr mezguitem", "lat": 34.502441, "lon": -3.632548 },
  { "name": "C/S BENI SIDEL LOUTA (2)", "lat": 35.11242, "lon": -3.068782 },
  { "name": "CS CHERIFA", "lat": 33.535111, "lon": -7.607167 },
  { "name": "Pharmacie bernoussi", "lat": 0, "lon": 0 },
  { "name": "CS al amal", "lat": 33.3772089, "lon": -7.6227712 },
  { "name": "CSR tarmigte", "lat": 30.8999672, "lon": -6.9143272 },
  { "name": "DR TAOURTA", "lat": 23.795679, "lon": -15.914164 },
  {
    "name": "DR Taourta",
    "lat": 23.795679092407227,
    "lon": -15.914163589477539
  },
  {
    "name": "CS EL WAHDA",
    "lat": 23.852347086870157,
    "lon": -15.407093721682093
  },
  { "name": "CS EL WAHDA", "lat": 0, "lon": 0 },
  { "name": "CSU Niveau 1 AL WAHDA", "lat": 0, "lon": 0 },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.49941,
    "lon": -5.670837
  },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.50941,
    "lon": -5.670837
  },
  {
    "name": "Pharmacie provinciale KHENIFRA",
    "lat": 32.47941,
    "lon": -5.670837
  },
  { "name": "Dr amgriwe", "lat": 27.67588, "lon": -13.163103 },
  { "name": "Pharmacie Bernoussi", "lat": 33.5950217, "lon": -7.5044015 },
  {
    "name": "Pharmacie Provinciale Chambre Froide",
    "lat": 35.238298356960456,
    "lon": -3.928807653067119
  },
  { "name": "CS akhfanir", "lat": 28.094133, "lon": -12.0522531 },
  { "name": "csr-2 lamzoudia", "lat": 31.582496, "lon": -8.49098 },
  { "name": "CSU carlotti ", "lat": 33.577012, "lon": -7.598575 },
  { "name": "CS almassira", "lat": 33.5656208, "lon": -7.5813188 },
  {
    "name": "CS almassira",
    "lat": 32.30570601389429,
    "lon": -5.381173736199887
  },
  { "name": "Csr isseksi ", "lat": 32.2318525, "lon": -6.2750303 },
  { "name": "CSR TIZIGUIT", "lat": 0, "lon": 0 },
  { "name": "CSR 1 Isseksi", "lat": 0, "lon": 0 },
  {
    "name": "Csu Jules Cot",
    "lat": 35.78344917827366,
    "lon": -5.81349142889549
  },
  { "name": "DÚlÚgation Ifrane", "lat": 0, "lon": 0 },
  { "name": "DÚlÚgation Ifrane AR 2", "lat": 0, "lon": 0 },
  { "name": "DÚlÚgation Ifrane AR 2", "lat": 0, "lon": 0 },
  { "name": "DÚlÚgation Ifrane", "lat": 0, "lon": 0 },
  { "name": "DÚlÚgation Ifrane", "lat": 0, "lon": 0 },
  { "name": "DÚlÚgation Ifrane ", "lat": 0, "lon": 0 },
  { "name": "test 004", "lat": 0, "lon": 0 },
  { "name": "test 005", "lat": 0, "lon": 0 },
  { "name": "tes 006 ", "lat": 0, "lon": 0 },
  { "name": "H¶pital Mohammed 5 chambre froide ", "lat": 0, "lon": 0 },
  { "name": "test 008", "lat": 0, "lon": 0 },
  { "name": "CS Lamkanssa", "lat": 33.510151, "lon": -7.567581 },
  { "name": "Cs Jawadi", "lat": 33.5539269, "lon": -7.5829605 },
  { "name": "Cs jawadi", "lat": 31.653381399664, "lon": -7.357931435212097 },
  { "name": "CSR GUIGUO", "lat": 33.3863869, "lon": -4.8360248 },
  { "name": "centre commune labrikiyinne", "lat": 0, "lon": 0 },
  {
    "name": "maison d'Útudiante sidi yahya zaer",
    "lat": 33.825893,
    "lon": -6.905005
  },
  { "name": "centre commune labrikiyinne", "lat": 0, "lon": 0 },
  { "name": "CS Al fida", "lat": 33.5675928, "lon": -7.6047768 },
  { "name": "CS Al fida", "lat": 33.5675928, "lon": -7.6047768 },
  { "name": "CS Al fida", "lat": 0, "lon": 0 },
  { "name": "Cs Al maroua", "lat": 33.6225754, "lon": -7.4407868 },
  { "name": "Cs Al maroua", "lat": 0, "lon": 0 },
  { "name": "Complexe Medico Social Al Ouroud", "lat": 0, "lon": 0 },
  { "name": "Complex auroud", "lat": 33.560486, "lon": -7.6121218 },
  { "name": "Complex auroud", "lat": 0, "lon": 0 },
  { "name": "Abbassy", "lat": 0, "lon": 0 },
  { "name": "Complexe Auroud", "lat": 33.560486, "lon": -7.6121218 },
  {
    "name": "Cs sidi maarouf",
    "lat": 33.5749397277832,
    "lon": -7.587719440460205
  },
  {
    "name": "cs sidi maarouf",
    "lat": 33.51678183592831,
    "lon": -7.6494533220149785
  },
  { "name": "CS Nasr", "lat": 33.5362035, "lon": 7.5497346 },
  { "name": "CS ARKMAN", "lat": 35.104272, "lon": -2.741471 },
  { "name": "H¶pital zemamra", "lat": 32.627141, "lon": -8.697712 },
  { "name": " CHP SIDI KACEM", "lat": 34.222921, "lon": -5.700865 },
  { "name": "CS ain Cheggag", "lat": 33.878624, "lon": -5.0438647 },
  { "name": "CS Bab marzouka", "lat": 0, "lon": 0 },
  { "name": "tst 2 ", "lat": 0, "lon": 0 },
  { "name": "test 3", "lat": 0, "lon": 0 },
  { "name": "test 4", "lat": 0, "lon": 0 },
  { "name": "csr-2 lalla takerkoust", "lat": 0, "lon": 0 },
  {
    "name": "CS COMMUNAL AIT IKKOU AIT ALI OUHAMMOU",
    "lat": 32.519969,
    "lon": -6.145074
  },
  {
    "name": "H¶pital midelt Armoire frigorifique G",
    "lat": 32.67767725900603,
    "lon": -4.739045424606327
  },
  {
    "name": "H¶pital midelt Armoire frigorifique D",
    "lat": 32.67700898553892,
    "lon": -4.738433880950931
  },
  {
    "name": "H¶pital midelt Armoire frigorifique B",
    "lat": 32.67727990781736,
    "lon": -4.738852305557255
  },
  {
    "name": "H¶pital midelt Armoire frigorifique C",
    "lat": 32.677234754161354,
    "lon": -4.739034695770267
  },
  {
    "name": "H¶pital midelt Armoire frigorifique H",
    "lat": 32.677162508264274,
    "lon": -4.738257423278817
  },
  {
    "name": "H¶pital midelt Armoire frigorifique A",
    "lat": 32.677307,
    "lon": -4.738627
  },
  {
    "name": "Dr beggara",
    "lat": 34.342586517333984,
    "lon": -5.867319107055664
  },
  { "name": "Centre de santÚ  Ighazrane", "lat": 33.806366, "lon": -4.3768167 },
  { "name": "CS azzouzia", "lat": 31.679914, "lon": -8.058283 },
  { "name": "DR LAHCHLAFA AHL SEBT", "lat": 33.064892, "lon": -8.512514 },
  { "name": "DR LAHCHLAFA AHL SEBT", "lat": 33.064892, "lon": -8.512514 },
  { "name": " Cs al massira 1", "lat": 33.56048, "lon": -7.539596 },
  { "name": "Cs Zrarda", "lat": 0, "lon": 0 },
  { "name": " CS al Baida", "lat": 33.5613333, "lon": -7.5329429 },
  { "name": "CSR1 IWARIDEN ", "lat": 0, "lon": 0 },
  { "name": "csr-1 lalla takerkoust", "lat": 31.359694, "lon": -8.135164 },
  {
    "name": "Centre de SantÚ Urbain Sidi Allal Bahraoui - 1",
    "lat": 34.0034244017583,
    "lon": -6.52707365277863
  },
  {
    "name": "CS niveau 1 Houman Le Fetouaki",
    "lat": 34.264441,
    "lon": -6.612353
  },
  { "name": "Commune Aquouass Briech", "lat": 35.5591125, "lon": -5.99555 },
  {
    "name": "DÚlÚgation de la santÚ de settat 2",
    "lat": 32.97033207869476,
    "lon": -7.671115296875001
  },
  { "name": "Csu1 El Ouafae", "lat": 0, "lon": 0 },
  { "name": "Pharmacie", "lat": 0, "lon": 0 },
  { "name": "CSU El Kalaa ", "lat": 0, "lon": 0 },
  { "name": "cs hay sadri", "lat": 0, "lon": 0 },
  { "name": "hopitale mohammed 5 - chefchaouni", "lat": 0, "lon": 0 },
  { "name": "Csu2 al massira", "lat": 0, "lon": 0 },
  {
    "name": "Csc Z.Sidi Abdel Kader ",
    "lat": 35.110921809704756,
    "lon": -3.8893428002877344
  },
  {
    "name": "DELEGATION DE MOULAY RACHID ",
    "lat": 33.558952,
    "lon": -7.573748
  },
  { "name": "DELEGATION DE MOULAY RACHID", "lat": 33.558952, "lon": -7.573748 },
  {
    "name": "CS Derb sidi Ohtmane",
    "lat": 32.565333160841035,
    "lon": -7.581001417146086
  },
  { "name": "H¶pital Sidi Bennour", "lat": 32.652348, "lon": -8.4135 },
  { "name": "H¶pital Sidi Bennour", "lat": 32.652348, "lon": -8.4135 },
  { "name": "H¶pital Sidi Bennour", "lat": 32.652348, "lon": -8.4135 },
  {
    "name": "HOPITAL MOHAMMED 5 (Armoire 8)",
    "lat": 33.251289,
    "lon": -8.508869
  },
  {
    "name": "HOPITAL MOHAMMED 5 (Armoire 8)",
    "lat": 33.251289,
    "lon": -8.508869
  },
  { "name": "H¶pital Mohammed 5", "lat": 0, "lon": 0 },
  {
    "name": "HOPITAL MOHAMMED 5 (Armoire 7)",
    "lat": 33.251282,
    "lon": -8.508868
  },
  {
    "name": "H¶pital Mohammed 5 A-1",
    "lat": 33.251232923452505,
    "lon": -8.508836813491822
  },
  {
    "name": "H¶pital Mohammed 5 A-1",
    "lat": 33.25123068038985,
    "lon": -8.508928008598328
  },
  { "name": "CSR Arazane ", "lat": 0, "lon": 0 },
  { "name": "hopitale mohammed 5", "lat": 0, "lon": 0 },
  {
    "name": "Cs rass lma",
    "lat": 33.970577239990234,
    "lon": -5.09466028213501
  },
  { "name": "DÚlÚgation Ben m'sick", "lat": 0, "lon": 0 },
  { "name": "DÚlÚgation Ben m'sick", "lat": 0, "lon": 0 },
  { "name": "Csu alwahda 2", "lat": 27.1367583, "lon": -13.2031449 },
  { "name": "CS Ras ain chawia", "lat": 32.9777675, "lon": -7.4055786 },
  { "name": "cs bouanane", "lat": 32.037144, "lon": -3.0483533544921904 },
  { "name": "CHP MIDELT Chambre froide B", "lat": 32.691401, "lon": -4.757972 },
  { "name": "CHP MIDELT Chambre froide B", "lat": 32.692401, "lon": -4.757972 },
  { "name": "CHP MIDELT Chambre froide B", "lat": 32.699401, "lon": -4.757972 },
  { "name": "CSR2 BOUMIA", "lat": 32.728904, "lon": -5.101181 },
  { "name": "CSR2 BOUMIA", "lat": 32.726904, "lon": -5.101181 },
  { "name": "CSR2 BOUMIA", "lat": 32.726904, "lon": -5.101181 },
  { "name": "DR CHBABATE", "lat": 0, "lon": 0 },
  {
    "name": "CS communal avec maison d'accouchement LGHREM NOUGDAL",
    "lat": 31.230239,
    "lon": -7.41897
  },
  { "name": "csr-2 Agouidir", "lat": 31.3881176, "lon": -8.80738 },
  { "name": "csr-1 s.abdalleh ghiat", "lat": 31.519402, "lon": -7.848991 },
  { "name": "CSR2 AIT BOUAOULI (ABACHKOU)", "lat": 31.61787, "lon": -6.630867 },
  { "name": "DR SREMETE", "lat": 31.7363717, "lon": -6.4418609 },
  { "name": "Dr amakhlij", "lat": 31.3691246, "lon": -7.8480933 },
  { "name": "Dr amakhlij", "lat": 31.3691246, "lon": -7.8480933 },
  { "name": "CSC BIR TAMTAM", "lat": 0, "lon": 0 },
  { "name": "CSU Jebha", "lat": 35.20396584104143, "lon": -4.665992233135221 },
  { "name": "CSC Toufalaazte", "lat": 30.067059, "lon": -8.827717 },
  { "name": "CS Nouinouich", "lat": 0, "lon": 0 },
  {
    "name": "DÚlÚgation Quartier Al Majd",
    "lat": 35.7433472,
    "lon": -5.799634
  },
  { "name": "CSU Val Fleuri", "lat": 35.77347946, "lon": -5.832633972 },
  { "name": "CSU Mozart", "lat": 35.771892, "lon": -5.7969918 },
  {
    "name": "Service de RÚseau des Etablissements Sanitaires KhÚmisset",
    "lat": 33.81953708654,
    "lon": -6.069788880950934
  },
  {
    "name": "Centre de santÚ tit mellile",
    "lat": 33.5493826,
    "lon": -7.4859148
  },
  { "name": "Vaccinodrome bab lamrissa", "lat": 0, "lon": 0 },
  {
    "name": "CSC Kaa Assras",
    "lat": 35.4134613282992,
    "lon": -5.068748426371965
  },
  { "name": "CSR HAD BENMOUSSA", "lat": 32.235245, "lon": -6.942788 },
  {
    "name": "Cs 2 assoul ",
    "lat": 31.949147518068603,
    "lon": -5.2069946097869835
  },
  { "name": "CS nouvelle mÚdina", "lat": 33.5674505, "lon": -7.6068819 },
  { "name": "CS pÚpiniÞre", "lat": 33.5721204, "lon": -7.5870099 },
  { "name": "cs imintanoute", "lat": 31.17699, "lon": -8.8579214 },
  { "name": "DÚlÚgation Ifrane", "lat": 0, "lon": 0 },
  {
    "name": "DR BOUZERGUAN",
    "lat": 30.433933997804374,
    "lon": -5.845423635581977
  },
  { "name": "csr-2 Tamesloht", "lat": 31.497292, "lon": -8.09805 },
  { "name": "Vaccinodrome CASA-ANFA", "lat": 33.6047898, "lon": -7.6329967 },
  { "name": "C/S MOSTAFRIKI", "lat": 34.477643, "lon": -2.239047 },
  { "name": "Csu niveau 2 Nord Lahraouine", "lat": 0, "lon": 0 },
  { "name": "dispensaire rural bab aylane", "lat": 31.62885, "lon": -7.972293 },
  { "name": "Csu niveau 2 Nord Lahraouine", "lat": 0, "lon": 0 },
  {
    "name": "csr2 sidi Hammadi ",
    "lat": 32.260780334472656,
    "lon": -6.551026344299316
  },
  { "name": "CSU NIVEAU 2 NORD LAHARAOUINE", "lat": 0, "lon": 0 },
  { "name": "centre medical ouled mesbah", "lat": 34.789742, "lon": -6.315548 },
  { "name": "CS mikkes", "lat": 34.13634490966797, "lon": -5.336243629455566 },
  { "name": "Centre de santÚ laatamna", "lat": 32.6768443, "lon": -8.3670615 },
  { "name": "CSC Lmbarkkiyine", "lat": 33.2061399, "lon": -7.4985371 },
  { "name": "Csu massira ", "lat": 32.8786264, "lon": -6.9207729 },
  { "name": "cs laatatra", "lat": 0, "lon": 0 },
  { "name": "Csr dar belamri", "lat": 34.1868997, "lon": -5.9792537 },
  { "name": "cs lhssasna", "lat": 33.1738672, "lon": -7.6188479 },
  { "name": "Csr 2 msemrir", "lat": 31.6965332, "lon": -5.8200269 },
  { "name": "OULED OUJIH BLOC K", "lat": 34.259331, "lon": -6.622564 },
  { "name": "Vaccinodrome CASA-ANFA", "lat": 33.6048153, "lon": -7.6331516 },
  { "name": "C/S ARKMAN", "lat": 35.1021478, "lon": -2.7410988 },
  {
    "name": "DR IHADADAN",
    "lat": 35.16782131160203,
    "lon": -2.9458856334796812
  },
  { "name": "OULED OUJIH bloc k", "lat": 0, "lon": 0 },
  {
    "name": "CSU Khalkhali",
    "lat": 34.993950408101995,
    "lon": -5.910848148479457
  },
  { "name": "DR Taouss", "lat": 32.12910537866886, "lon": -4.483826829136431 },
  { "name": "Hopital DARRAK Chambre froide A", "lat": 0, "lon": 0 },
  { "name": "CSR1 TIRST", "lat": 32.0371458, "lon": -6.9350234 },
  { "name": "DR IBARAGHENE", "lat": 0, "lon": 0 },
  { "name": "SNTL / 914-02 / arriere", "lat": 0, "lon": 0 },
  { "name": "CS Ouled Frej ", "lat": 32.9554483, "lon": -8.219781 },
  { "name": "CSU 2 imouzar kandar", "lat": 0, "lon": 0 },
  { "name": "CS MIDAR HAUT", "lat": 35.094017, "lon": -3.331729 },
  { "name": "Salle Sportif Al Bostane", "lat": 33.9471626, "lon": -6.8637567 },
  { "name": " CS KALAAT MEGOUNA", "lat": 31.2373273, "lon": -6.1224021 },
  { "name": "test moulay yaacoub", "lat": 0, "lon": 0 },
  { "name": "test moulay yaacoub", "lat": 0, "lon": 0 },
  { "name": "Cs tikni", "lat": 0, "lon": 0 },
  { "name": "test moulay yaacoub", "lat": 0, "lon": 0 },
  { "name": "chp hassan 2", "lat": 32.53466, "lon": -6.54235 },
  {
    "name": "Laboratoire De Diagnostic EpidÚmiologique Et D'hygiÞne Du Milieu",
    "lat": 0,
    "lon": 0
  },
  { "name": "47683A10/GSPR", "lat": 0, "lon": 0 },
  { "name": "47684A10/GSPR", "lat": 0, "lon": 0 },
  { "name": "CSR Taouss Ait Amira", "lat": 30.181841, "lon": -9.491457 },
  { "name": "CSR TOUNFIT -1", "lat": 32.4675786, "lon": -5.3113126 },
  { "name": "DR BOUZERGANE", "lat": 30.4337909, "lon": -5.8479999 },
  { "name": "CSU 1 SEBRA", "lat": 34.934426, "lon": -2.747998 },
  { "name": "CSU Bouhdila", "lat": 34.9298527, "lon": -2.343607 },
  {
    "name": "H¶pital Mohamed 5",
    "lat": 35.131889742976675,
    "lon": -5.255071547053976
  },
  {
    "name": "H¶pital Mohamed 5",
    "lat": 35.230663571319624,
    "lon": -5.298614718419045
  },
  {
    "name": "Hopital Sidi Mohamed Ben Abdellah A3",
    "lat": 31.51261,
    "lon": -9.762718
  },
  { "name": "cs zenada", "lat": 32.072261810302734, "lon": -7.368775367736816 },
  { "name": "cs zenada", "lat": 32.072261810302734, "lon": -7.368775367736816 },
  { "name": "Dispensaire Rural NKHILA", "lat": 33.71613, "lon": -6.631913 },
  { "name": "Dr Zaouia", "lat": 33.1959434, "lon": -5.4231216 },
  { "name": "CHP BOUARFA A3", "lat": 32.527803, "lon": -1.962791 },
  { "name": "CSU SELOUAN JADID", "lat": 35.06849, "lon": -2.92053 },
  {
    "name": "delegation provinciale rhamna",
    "lat": 32.241389,
    "lon": -7.961193
  },
  {
    "name": "delegation provinciale rhamna",
    "lat": 32.241279,
    "lon": -7.961294
  },
  { "name": "CSU SELOUAN JADID", "lat": 35.06649, "lon": -2.92053 },
  { "name": "UnitÚ mobile TATAOUT", "lat": 30.704057, "lon": -8.88582 },
  { "name": "CSU 2 BENI ENSSAR", "lat": 35.26936, "lon": -2.958837 },
  { "name": "CSR 2 TAGUELEFT", "lat": 0, "lon": 0 },
  { "name": "Pharmacie CHP KHENIFRA", "lat": 0, "lon": 0 },
  { "name": "Pharmacie CHP KHENIFRA", "lat": 0, "lon": 0 },
  { "name": "CS SIDI YAHYA", "lat": 34.666948, "lon": -1.869702 },
  {
    "name": "pharmacie prefectorale marrakech AR-11",
    "lat": 31.63673,
    "lon": -8.001082
  },
  {
    "name": "pharmacie prefectorale marrakech AR-11",
    "lat": 31.63672,
    "lon": -8.001081
  },
  { "name": "CS boufekrane ", "lat": 0, "lon": 0 },
  {
    "name": "centre de sante urbain kasbat mehdiya",
    "lat": 34.266018,
    "lon": -6.648573
  },
  { "name": "CS AJDIR", "lat": 35.84156609139674, "lon": -5.76029183787837 },
  { "name": "centre de sante urbain kasbat mehdiya", "lat": 0, "lon": 0 },
  { "name": "Centre santÚ ain atiq", "lat": 33.8947207, "lon": -6.954536 },
  { "name": "Cs Lahraouiine", "lat": 33.5685999, "lon": -7.5486296 },
  { "name": "commune el kelaa(hopital essalama)", "lat": 0, "lon": 0 },
  { "name": "commune el kelaa(hopital essalama)", "lat": 0, "lon": 0 },
  { "name": "commune el kelaa(hopital essalama)", "lat": 0, "lon": 0 },
  {
    "name": "UnitÚ Mobile Tigouga",
    "lat": 30.555953835796373,
    "lon": -8.997534640285032
  },
  {
    "name": "UnitÚ Mobile Tizi n'Test",
    "lat": 30.861572367104987,
    "lon": -8.378405570983887
  },
  { "name": "Cs Almassira2", "lat": 0, "lon": 0 },
  { "name": "CSU Ras Aghil", "lat": 33.9073996, "lon": 5.561753 },
  { "name": "Cs tendite", "lat": 33.6647568, "lon": -3.6257334 },
  { "name": "Cs tendite", "lat": 33.6647568, "lon": -3.6257334 },
  { "name": "Cs tendite", "lat": 33.6647568, "lon": -3.6257334 },
  {
    "name": "CSU 1 HAY EL JADID",
    "lat": 34.30679822512532,
    "lon": -2.1687376499176025
  },
  {
    "name": "DELEGATION DE LA SANTE AZILAL",
    "lat": 31.967599,
    "lon": -6.5657479
  },
  {
    "name": "DELEGATION DE LA SANTE AZILAL",
    "lat": 31.968599,
    "lon": -6.5657479
  },
  {
    "name": "DELEGATION DE LA SANTE AZILAL",
    "lat": 31.969599,
    "lon": -6.5657479
  },
  { "name": "CS Bni Frassen", "lat": 34.379101, "lon": -4.376167 },
  { "name": "CSR SIDI BOUSHAB", "lat": 30.074495, "lon": -9.279778 },
  {
    "name": "DÚlÚgation provincial driouch CHF A",
    "lat": 34.980194,
    "lon": -3.389422
  },
  {
    "name": "dÚlÚgation provinciale du haouz",
    "lat": 31.361431,
    "lon": -7.934028
  },
  {
    "name": "dÚlÚgation provinciale du haouz",
    "lat": 31.361836,
    "lon": -7.934044
  },
  {
    "name": "dÚlÚgation provinciale du haouz",
    "lat": 31.361577,
    "lon": -7.934428
  },
  { "name": "delegation sres", "lat": 33.261369, "lon": -7.586554 },
  { "name": "delegation sres", "lat": 0, "lon": 0 },
  { "name": "DÚlÚgation De La SantÚ Province NADOR", "lat": 0, "lon": 0 },
  { "name": "CSU AIN CHQEF 2", "lat": 33.9601215, "lon": -5.0293854 },
  { "name": "CS SKHINAT", "lat": 34.0356165, "lon": -4.8621577 },
  { "name": "CS lalla Soukaina ", "lat": 34.0231247, "lon": -5.0310726 },
  { "name": "CSR AFSOU", "lat": 34.972443, "lon": -3.234682 },
  { "name": "CHU SOUISSI ENFANTS", "lat": 33.984053, "lon": -6.850953 },
  { "name": "CR COMMUNAL AIT ATTAB", "lat": 32.112819, "lon": -6.69743 },
  {
    "name": "PHARMACIE PROVINCIAL BERCHID",
    "lat": 33.2622717,
    "lon": -7.585745
  },
  {
    "name": "PHARMACIE PROVINCIAL BERCHID",
    "lat": 33.2622717,
    "lon": -7.585745
  },
  {
    "name": "CSR 2 Lamzoudia",
    "lat": 32.27784451498272,
    "lon": -7.953962359013264
  },
  {
    "name": "Centre de SantÚ Ain Johra Sidi Boukhalkhal - 1",
    "lat": 33.898754,
    "lon": -6.370545
  },
  {
    "name": "Pharmacie prÚfectorale marrakech Armoire 2-C",
    "lat": 0,
    "lon": 0
  },
  { "name": "CS TAMELALT", "lat": 31.814819, "lon": -7.5127469 },
  { "name": "CS SIDI EL AIDI", "lat": 33.1205238, "lon": -7.6191115 },
  {
    "name": "C/S TAFOUGHALT",
    "lat": 34.80897537193294,
    "lon": -2.4089245619673343
  },
  {
    "name": "DÚlÚgation El KelaÔ des Sraghna",
    "lat": 32.0516984,
    "lon": -7.4046693
  },
  {
    "name": "DÚlÚgation El KelaÔ des Sraghna",
    "lat": 32.0516984,
    "lon": -7.4046693
  },
  {
    "name": "DÚlÚgation El KelaÔ des Sraghna",
    "lat": 32.0516984,
    "lon": -7.4046693
  },
  {
    "name": "DÚlÚgation El KelaÔ des Sraghna",
    "lat": 32.0516984,
    "lon": -7.4046693
  },
  { "name": "CHR BENI MELLAL ", "lat": 0, "lon": 0 },
  { "name": "CHR BENI MELLAL", "lat": 0, "lon": 0 },
  { "name": "CHR BENI MELLAL", "lat": 0, "lon": 0 },
  { "name": "CS COMMUNAL AGUELMOUSS", "lat": 33.09293, "lon": -5.50384 },
  {
    "name": "DÚp¶t SRESS B",
    "lat": 34.92401248137937,
    "lon": -2.3266714329763487
  },
  { "name": "DELEGATION SRES", "lat": 33.560051, "lon": -7.608086 },
  { "name": "Csu istiklal", "lat": 0, "lon": 0 },
  { "name": "Csu istiklal", "lat": 0, "lon": 0 },
  {
    "name": "DEPOT PROVINCIAL DU VACCIN COVID-19 BERKANE",
    "lat": 34.92397273725756,
    "lon": -2.326796156319442
  },
  { "name": "chp hassan 2", "lat": 32.53566, "lon": -6.54235 },
  { "name": "cs sidi bouothmane rhamna", "lat": 31.90056, "lon": -7.941875 },
  { "name": "cs sidi bouothmane rhamna", "lat": 31.900584, "lon": -7.942133 },
  { "name": "CSR-2 BOUGUEDRA", "lat": 32.257614, "lon": -8.98133 },
  { "name": "CSR-2 BOUGUEDRA", "lat": 32.257616, "lon": -8.981232 },
  { "name": "csc ouled khalouf", "lat": 31.783112, "lon": -7.086023 },
  { "name": "commune ouled arrad ", "lat": 0, "lon": 0 },
  { "name": "cs Ouled masoud", "lat": 0, "lon": 0 },
  { "name": "DELEGATION DE SANTE RABAT", "lat": 34.0181149, "lon": -6.8433015 },
  { "name": "CS fnideq ", "lat": 0, "lon": 0 },
  { "name": "DR ISKISS", "lat": 31.677921, "lon": -5.861598 },
  {
    "name": "laboratoire de diagnostic ÚpidÚmiologique et d'hygiÞne du milieu CHAMBRE FROIDE 1 - A",
    "lat": 0,
    "lon": 0
  },
  {
    "name": "Pharmacie provincial agadir",
    "lat": 30.4134836,
    "lon": -9.5889401
  },
  {
    "name": "Pharmacie provincial agadir",
    "lat": 30.4134836,
    "lon": -9.5889401
  },
  {
    "name": "Pharmacie provincial agadir",
    "lat": 30.4134836,
    "lon": -9.5889401
  },
  { "name": "Csr-1 Mayat od saber", "lat": 32.275231, "lon": -7.527079 },
  {
    "name": "Pharmacie provincial agadir chp 2",
    "lat": 30.4134836,
    "lon": -9.5889401
  },
  {
    "name": "Pharmacie provincial agadir chp 2",
    "lat": 30.4134836,
    "lon": -9.5889401
  },
  {
    "name": "Pharmacie provincial agadir chp 2",
    "lat": 30.4134836,
    "lon": -9.5889401
  },
  { "name": "Csr-1 Mayat od saber", "lat": 0, "lon": 0 },
  { "name": "test", "lat": 0, "lon": 0 },
  { "name": "test ", "lat": 0, "lon": 0 },
  { "name": "DR AIT WIRAR", "lat": 0, "lon": 0 },
  { "name": "csc chtaiba", "lat": 0, "lon": 0 },
  { "name": "H¶pital hassan 2 fnideq ", "lat": 0, "lon": 0 },
  { "name": "\t CS rural SIDI EL HETAB", "lat": 0, "lon": 0 },
  { "name": "CENTRE DE SANTE RIAD", "lat": 0, "lon": 0 },
  { "name": "H¶pital hassan 2 fnideq A2", "lat": 0, "lon": 0 },
  { "name": "commune ouled arrad", "lat": 0, "lon": 0 },
  { "name": "CSR2 TANANT", "lat": 0, "lon": 0 },
  { "name": "CSR1 TISQUI", "lat": 32.116905, "lon": -6.614573 },
  { "name": "CSU allal ben abdellah", "lat": 34.24376, "lon": -6.548408 },
  {
    "name": "Pharmacie dÚlÚgation OUARZAZATE ",
    "lat": 30.931381,
    "lon": -6.916488
  },
  { "name": "cs ksar bjir", "lat": 0, "lon": 0 },
  { "name": "CSR2 TEROUAL", "lat": 34.674321, "lon": -5.271545 },
  { "name": "Cs koulouch", "lat": 0, "lon": 0 },
  {
    "name": "CS COMMUNE ATTAOUIA CHAIBIA",
    "lat": 31.834052906456527,
    "lon": -7.314188405845419
  },
  { "name": "CSC Avec Module D'accouchement ASSAFALAT", "lat": 0, "lon": 0 },
  { "name": "CSR2 TILOUGUITE", "lat": 0, "lon": 0 },
  {
    "name": "CSC Avec Module D'accouchement ASSAFALAT",
    "lat": 31.2390442,
    "lon": -4.274952
  },
  { "name": "CS Mesmoda", "lat": 34.78644559579946, "lon": -5.709724542327885 },
  { "name": "Hopital sidi ifni", "lat": 29.3809127, "lon": -10.1747887 },
  { "name": "Hopital sidi ifni", "lat": 29.3809407, "lon": -10.1746707 },
  { "name": "Hopital sidi ifni", "lat": 29.3809127, "lon": -10.1747887 },
  { "name": "centre majmaa tolba", "lat": 33.797064, "lon": -6.06704 },
  {
    "name": "csu Moulay Driss zerhoun ",
    "lat": 34.05755615234375,
    "lon": -5.516959190368652
  },
  { "name": "CS Nouvelle mÚdina", "lat": 33.5737452, "lon": -7.6057505 },
  { "name": "CSU2 Chemaia", "lat": 32.0782, "lon": -8.625494 },
  { "name": "CSU mbarek belgada", "lat": 34.259076, "lon": -6.5557591 },
  { "name": "CSU JARDA", "lat": 34.041846, "lon": -6.827159 },
  { "name": "DR ZIAR", "lat": 33.0397309, "lon": -5.7265801 },
  { "name": "CS ait ikko", "lat": 33.710938, "lon": -6.264663 },
  { "name": "CSR Amtar", "lat": 35.240741338320206, "lon": -4.787193673610695 },
  {
    "name": "centre de sante urbain, district administratif",
    "lat": 32.051999999999985,
    "lon": -7.4040000000000035
  },
  {
    "name": "pharmacie prÚfectorale Marrakech",
    "lat": 31.6358807,
    "lon": -8.0044253
  },
  {
    "name": "pharmacie prÚfectorale Marrakech",
    "lat": 31.6358807,
    "lon": -8.0044253
  },
  {
    "name": "pharmacie prÚfectorale Marrakech",
    "lat": 31.6358807,
    "lon": -8.0044253
  },
  { "name": "AHMED BOUIHI RUE BAB FES", "lat": 34.266109, "lon": -6.563121 },
  { "name": "cs bouarfa ", "lat": 32.535812, "lon": -1.957636 },
  { "name": "commune ouled msabbel", "lat": 0, "lon": 0 },
  {
    "name": "centre socio sportif et culturel bir anzaran",
    "lat": 34.016847,
    "lon": -6.767905
  },
  { "name": "CSU1 TARFAYA", "lat": 27.9325912, "lon": -12.9278106 },
  { "name": "CSU1 TARFAYA", "lat": 27.9325568, "lon": -12.928117 },
  { "name": "CS AZLEF ", "lat": 0, "lon": 0 },
  { "name": "CS ET MAISON D ACCOUCHEMENT LEQBAB", "lat": 0, "lon": 0 },
  {
    "name": "Centre de santÚ Lissasfa AR 6 (I-1)",
    "lat": 33.535019,
    "lon": -7.66926
  },
  {
    "name": "Centre de santÚ Lissasfa AR B-1",
    "lat": 33.53492,
    "lon": -7.669252
  },
  {
    "name": "CSU SIDI GHANEM AVEC MAISON D'ACOUCHEMENT",
    "lat": 31.679914,
    "lon": -8.058283
  },
  { "name": "CS VILLE NOUVELLE", "lat": 33.8945847, "lon": -5.5451135 },
  { "name": "Centre de santÚ atlas ", "lat": 0, "lon": 0 },
  { "name": "Cs Salam", "lat": 0, "lon": 0 },
  { "name": "Cs Salam", "lat": 0, "lon": 0 },
  { "name": "CHP KhÚnifra", "lat": 32.967544, "lon": -5.662159 },
  { "name": "CHP KHENIFRA ", "lat": 32.966544, "lon": -5.662159 },
  { "name": "CHP KHENIFRA ", "lat": 32.965544, "lon": -5.662159 },
  { "name": "CSU avec module d'accouchement SBAAYOUNE ", "lat": 0, "lon": 0 },
  { "name": "centre legfaf", "lat": 32.922391, "lon": -6.72448 },
  { "name": "CSC SOUIHLA", "lat": 31.631068, "lon": -8.164925 },
  { "name": "CSU2 Chemaia", "lat": 32.078077, "lon": -8.625738 },
  {
    "name": "Pharmacie provinciale Zagora ",
    "lat": 30.3243009,
    "lon": -5.839103
  },
  { "name": "CSU BIR ANZARAN", "lat": 31.50385, "lon": -5.531969 },
  { "name": "CSR 2 CHEKRANE", "lat": 34.953201, "lon": -3.999669 },
  {
    "name": "HOPITAL EL RICH Chambre froide A",
    "lat": 32.278066,
    "lon": -4.490627
  },
  {
    "name": "HOPITAL EL RICH Chambre froide A",
    "lat": 32.276066,
    "lon": -4.490627
  },
  {
    "name": "HOPITAL EL RICH Chambre froide A",
    "lat": 32.275066,
    "lon": -4.490627
  },
  { "name": "CHP BOUARFA A1", "lat": 32.525803, "lon": -1.962791 },
  { "name": "CHP BOUARFA A2", "lat": 32.526803, "lon": -1.962791 },
  { "name": "CHP BOUARFA", "lat": 32.5298803, "lon": -1.962791 },
  { "name": "CSU1 KOUASSEM", "lat": 32.30091, "lon": -6.41081 },
  { "name": "csr sidi moussa lamhaya", "lat": 34.543309, "lon": -2.056257 },
  { "name": "CS Albaraka", "lat": 0, "lon": 0 },
  { "name": "CS ALBARAKA", "lat": 0, "lon": 0 },
  { "name": "CSR 2 Tabarrant", "lat": 34.71236, "lon": -4.519401 },
  {
    "name": "CSC Tanakoub",
    "lat": 35.10656922291368,
    "lon": -5.457686716864771
  },
  { "name": "CSU KASBA TADLA", "lat": 32.35497, "lon": -6.15513 },
  { "name": "CSU KASBA TADLA", "lat": 32.35697, "lon": -6.15513 },
  { "name": "CS BIR ANZARAN", "lat": 23.693966, "lon": -15.949857 },
  { "name": "CS BIR ANZARAN", "lat": 23.693966, "lon": -15.949857 },
  { "name": "CSU IBN ROCHD", "lat": 34.317025, "lon": -2.159576 },
  { "name": "DR Tislane", "lat": 29.1113291, "lon": -9.5901487 },
  {
    "name": "UnitÚ mobile nsoula",
    "lat": 30.231020961420526,
    "lon": -6.987637281417847
  },
  { "name": "cs molay rachid", "lat": 0, "lon": 0 },
  { "name": "cs molay rachid", "lat": 0, "lon": 0 },
  { "name": "Cs oulad ghanem", "lat": 0, "lon": 0 },
  { "name": "DR TAKHSAYT", "lat": 32.198452, "lon": -6.7713423 },
  {
    "name": "Dr teggour",
    "lat": 33.19155502319336,
    "lon": -3.8522188663482666
  },
  { "name": "Cs Salam     ", "lat": 0, "lon": 0 },
  { "name": "Cs Salam    ", "lat": 0, "lon": 0 },
  { "name": "CHP TINGHIR", "lat": 0, "lon": 0 },
  { "name": "Cs salam", "lat": 23.69234848022461, "lon": -15.950321197509766 },
  { "name": "TESTSALOUA ", "lat": 0, "lon": 0 },
  { "name": "UnitÚ mobile  (tizi amanouz)", "lat": 0, "lon": 0 },
  { "name": "Cs Salam  ", "lat": 0, "lon": 0 },
  { "name": "CSR 2 ABACHKOU (AIT BOULLI)", "lat": 0, "lon": 0 },
  { "name": "CSR 2 ABACHKOU (AIT BOULLI)", "lat": 0, "lon": 0 },
  { "name": "Cs ben yakhlef", "lat": 0, "lon": 0 },
  {
    "name": "csr2 sidi Hammadi",
    "lat": 32.260780334472656,
    "lon": -6.551026344299316
  }
]

export const postNotNear = [
  { "name": "MERZOUGA", "lat": "30.906379", "lon": "-3.997409" },
  { "name": "AGUELMOUSS", "lat": "33.158546", "lon": "-5.844335" },
  { "name": "BOUAROUS", "lat": "34.366478", "lon": "-4.81081" },
  { "name": "SEBT AIT IKKOU", "lat": "33.67041", "lon": "-6.136423" },
  { "name": "EL OUATIA", "lat": "28.4984952", "lon": "-11.3320971" },
  { "name": "AOUSSERD", "lat": "22.548334", "lon": "-14.331043" },
  { "name": "BIR GANDOUZ", "lat": "21.612778", "lon": "-16.467444" },
  { "name": "OULED BOUGHADI", "lat": "33.073693", "lon": "-6.711759" }
]