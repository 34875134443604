<script>
import Layout from "../../layouts/main";

import HorizontalMenu from "../../../components/horizontal-navbar-2.vue";
import PostsMap from "./posts-map";

import {
  dataPost,
  dataNext,
  postNotNear,
} from "./cash";

import {
  layoutComputed,
  layoutMethods
} from "@/state/helpers";
/**
 * Devices component
 */
export default {
  page: {
    title: "Poste-Maroc",
    meta: [{ name: "Poste-Maroc" }],
  },
  components: {
    Layout,
    HorizontalMenu,
    PostsMap,
  },
  mounted() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "hidden";
    this.changeMenuType();
  },
  created() {
    


  },
  data() {
    return {
      title: "poste-maroc",
      items: [
        {
          text: "Nextrack",
        },
        {
          text: "Poste-Maroc",
          active: true,
        },
      ],

      loaderStatus: false,

      dataPostData: dataPost,
      dataNextData: dataNext,
      postNotNearData: postNotNear,

      route: "/poste-maroc",
    };
  },
  computed: {
    ...layoutComputed,
  },
  methods: {
    ...layoutMethods,
    changeMenuType() {
      this.changeLeftSidebarType({ leftSidebarType: "icon" });
    },
    
  },
  watch: {},
  beforeDestroy() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "visible";
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <Horizontal-menu class="horiMenu" v-bind:page="route" />
    <PostsMap
      v-bind:dataPost="dataPostData"
      :dataNext="dataNextData"
      :postNotNear="postNotNearData"
      :loader="loaderStatus"
    />
  </Layout>
</template>
<style scoped>
@media (min-width: 761px) {
  div /deep/ .footer {
    display: none;
  }
  div /deep/ #page-topbar {
    display: none;
  }
  div /deep/ .vertical-menu {
    display: none;
  }
}

@media (max-width: 760px) {
  .horiMenu {
    display: none;
  }
}

div /deep/ .container-fluid {
  padding: 0px;
}
div /deep/ .page-content {
  min-height: 1320px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
div /deep/ .main-content {
  margin-left: 0px;
}
</style>